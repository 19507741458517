export const status = (state = "", action) => {// eslint-disable-line
  switch (action.type) {
    case "USER_LOGIN":
      return "USER_LOGIN";
    case "USER_LOGIN_SUCCESS":
      return "USER_LOGIN_SUCCESS";
    case "USER_LOGIN_ERROR":
      return "USER_LOGIN_ERROR";
    case "USER_LOGOUT":
      return "USER_LOGOUT";
    case "USER_LOGOUT_SUCCESS":
      return "USER_LOGOUT_SUCCESS";
    case "USER_LOGOUT_ERROR":
      return "USER_LOGOUT_ERROR";
    case "USER_PASSWORD_CHANGE":
      return "USER_PASSWORD_CHANGE";
    case "USER_PASSWORD_CHANGE_SUCCESS":
      return "USER_PASSWORD_CHANGE_SUCCESS";
    case "USER_PASSWORD_CHANGE_ERROR":
      return "USER_PASSWORD_CHANGE_ERROR";
    case "USER_FORGOT_PASSWORD":
      return "USER_FORGOT_PASSWORD";
    case "USER_FORGOT_PASSWORD_SUCCESS":
      return "USER_FORGOT_PASSWORD_SUCCESS";
    case "USER_FORGOT_PASSWORD_ERROR":
      return "USER_FORGOT_PASSWORD_ERROR";
    default:
      return action.type;
  }
};
