import React from 'react'
import './checkbox.css'
import Checkbox from '@mui/material/Checkbox';
import PropTypes from "prop-types";

const propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool
};
function CheckBox(props) {
  return (
    <Checkbox
      checked={props.checked}
      onChange={props.handleChange}
      className='check-box'
      sx={{
        color: '#14009B',
        '&.Mui-checked': {
          color: '#14009B',
        },
      }}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={props.disabled ? true : false}
    />
  )
}
CheckBox.propTypes = propTypes;
export default CheckBox