import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from "@mui/material/CircularProgress";
import './loader.css'
// set display name for component
const displayName = 'CommonLoader'

// validate component properties
const propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
}

const LoadingComponent = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return <div
      className={
        isLoading ? "loader_block" : "loader_block hide-display"
      }
      style={{ position: 'absolute' }}
    >
      <div className="root">
        <CircularProgress disableShrink={isLoading === true ? false : true} />
      </div>
    </div>
  }
  // Handle the error state
  else if (error) {

    // This resolves an issue that newly named code-splitted js files make
    if (error['name'] && error['name'] == "ChunkLoadError") {
      // window.location.reload();
    }

    return <div>Sorry, there was a problem loading the page.</div>
  }
  else {
    return null
  }
}

LoadingComponent.displayName = displayName
LoadingComponent.propTypes = propTypes

export default LoadingComponent