import * as types from "./action-types";
import * as API from "./api";
import store from "../../../store/index";

export const getDashboardDetails = (data) => {
  const request = API.getDashboardDetails(data);
  return {
    type: types.GET_DASHBOARD_DETAILS,
    payload: request,
  };
};

export const getDashboardDetailsSuccess = (result) => {
  return {
    type: types.GET_DASHBOARD_DETAILS_SUCCESS,
    result,
  };
};

export const getDashboardDetailsFailure = (errorMessage) => {
  return {
    type: types.GET_DASHBOARD_DETAILS_ERROR,
    errorMessage,
  }
};

export const getTeamDetails = (quarterId) => {
  const request = API.getTeamDetails(quarterId);
  return {
    type: types.GET_TEAM_DETAILS,
    payload: request,
  };
};

export const getTeamDetailsSuccess = (result) => {
  return {
    type: types.GET_TEAM_DETAILS_SUCCESS,
    result,
  };
};

export const getTeamDetailsFailure = (errorMessage) => {
  return {
    type: types.GET_TEAM_DETAILS_ERROR,
    errorMessage,
  }
};

export const getSelfieGraphDetails = (quarterId) => {
  const request = API.getSelfieGraphDetails(quarterId);
  return {
    type: types.GET_SELFIE_GRAPH_DETAILS,
    payload: request,
  };
};

export const getSelfieGraphDetailsSuccess = (result) => {
  return {
    type: types.GET_SELFIE_GRAPH_DETAILS_SUCCESS,
    result,
  };
};

export const getSelfieGraphDetailsFailure = (errorMessage) => {
  return {
    type: types.GET_SELFIE_GRAPH_DETAILS_ERROR,
    errorMessage,
  }
};

export const postExtendDate = (data) => {
  const request = API.postExtendDate(data);
  return {
    type: types.EXTEND_DATE,
    payload: request,
  };
};

export const postExtendDateSuccess = (result) => {
  return {
    type: types.EXTEND_DATE_SUCCESS,
    result,
  };
};

export const postExtendDateFailure = (errorMessage) => {
  return {
    type: types.EXTEND_DATE_ERROR,
    errorMessage,
  }
};

export const getMeDetails = (quarterId) => {
  const request = API.getMeDetails(quarterId);
  return {
    type: types.GET_ME_DETAILS,
    payload: request,
  };
};

export const getMeDetailsSuccess = (result) => {
  return {
    type: types.GET_ME_DETAILS_SUCCESS,
    result,
  };
};

export const getMeDetailsFailure = (errorMessage) => {
  return {
    type: types.GET_ME_DETAILS_ERROR,
    errorMessage,
  }
};

export const getGoalDetails = (quarterId, data) => {
  const request = API.getGoalDetails(quarterId, data);
  return {
    type: types.GET_GOAL_DETAILS,
    payload: request,
  };
};

export const getGoalDetailsSuccess = (result) => {
  return {
    type: types.GET_GOAL_DETAILS_SUCCESS,
    result,
  };
};

export const getGoalDetailsFailure = (errorMessage) => {
  return {
    type: types.GET_GOAL_DETAILS_ERROR,
    errorMessage,
  }
};

export const getQuarterListData = (payload) => {
  API.getQuarterListData(payload, store.getState().dashboard.selectedQuarter);
  return {
    type: types.GET_QUARTER_LIST
  };
};

export const getQuarterListDataSuccess = (result) => {
  return {
    type: types.GET_QUARTER_LIST_SUCCESS,
    result,
  };
};

export const getQuarterListDataFailure = (errorMessage) => ({
  type: types.GET_QUARTER_LIST_ERROR,
  errorMessage,
});

export const setSelectedQuarter = (quaryerId) => {
  return {
    type: types.SET_SELECTED_QUARTER,
    quaryerId,
  }
};

export const setSelectedYear = (year) => {
  return {
    type: types.SET_SELECTED_YEAR,
    year,
  }
};

export const excludeUnExcludePeer = (quarterId, data) => {
  const request = API.excludeUnExcludePeer(quarterId, data);
  return {
    type: types.EXCLUDE_UNEXCLUDE_PEER,
    payload: request
  }
}

export const excludeUnExcludePeerSuccess = (result) => {
  return {
    type: types.EXCLUDE_UNEXCLUDE_PEER_SUCCESS,
    result
  };
};

export const excludeUnExcludePeerFailure = (errorMessage) => {
  return {
    type: types.EXCLUDE_UNEXCLUDE_PEER_ERROR,
    errorMessage
  }
}