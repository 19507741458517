import React, { useState, useEffect } from 'react';
import styled from '../../css/dashboard.module.css';
import PropTypes from "prop-types";
import "../../css/dashboard.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { utils, writeFileXLSX } from 'xlsx';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import Button from '@mui/material/Button';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

const propTypes = {
    users: PropTypes.array,
    goBack: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    compositionsOwned: PropTypes.array,
    selectedCompositionId: PropTypes.any,
    publicGoalUserList: PropTypes.array,
    buttonClick: PropTypes.func,
    meDetails: PropTypes.array,
    teamDetails: PropTypes.array,
    isDesktopLayout: PropTypes.bool,
    isMobileLayout: PropTypes.bool,
    excludeUnexcludePeer: PropTypes.func,
    selectedQuarter: PropTypes.any
};

const UserList = (props) => {

    const [localUsers, setLocalUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [allChecked, setAllChecked] = useState(true);
    const [filledChecked, setFilledChecked] = useState(true);
    const [notFilledChecked, setNotFilledChecked] = useState(true);
    const [activeChecked, setActiveChecked] = useState(false);
    const [inActiveChecked, setInActiveChecked] = useState(false);

    useEffect(() => {
        setLocalUsers(props.users);
        setUserData(props?.users);
    }, [props.users])

    useEffect(() => {
        switch (props?.status) {
            case "EXCLUDE_UNEXCLUDE_PEER_SUCCESS":
                setTimeout(() => props.getDashboardDetails({ quarterId: props?.selectedQuarter }), 2000);
                break;
        }
    }, [props?.status]);

    const handleChangeAll = (e) => {
        setActiveChecked(false);
        setInActiveChecked(false);
        setAllChecked(e.target.checked);
        if (!e.target.checked) {
            setLocalUsers([]);
            setUserData([]);
            setFilledChecked(false);
            setNotFilledChecked(false);
        } else {
            setLocalUsers(props.users);
            setUserData(props.users);
            setFilledChecked(true);
            setNotFilledChecked(true);
        }
    }

    const handleChangeFilledData = (e) => {
        setActiveChecked(false);
        setInActiveChecked(false);
        setFilledChecked(e.target.checked);
        if (!e.target.checked) {
            if (notFilledChecked) {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 0);
                setLocalUsers(temp);
                setUserData(temp);
            } else {
                setLocalUsers([]);
                setUserData([]);
            }
            setAllChecked(false);
        } else {
            if (notFilledChecked) {
                setAllChecked(true);
                setLocalUsers(props.users);
                setUserData(props?.users);
            } else {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 1);
                setLocalUsers(temp);
                setUserData(temp);
            }
        }
    }

    const handleChangeNotFilledData = (e) => {
        setActiveChecked(false);
        setInActiveChecked(false);
        setNotFilledChecked(e.target.checked);
        if (!e.target.checked) {
            if (filledChecked) {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 1);
                setLocalUsers(temp);
                setUserData(temp);
            } else {
                setLocalUsers([]);
                setUserData([]);
            }
            setAllChecked(false);
        } else {
            if (filledChecked) {
                setAllChecked(true);
                setLocalUsers(props.users);
                setUserData(props?.users);
            } else {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 0);
                setLocalUsers(temp);
            }
        }
    }

    const handleChangeFilled = (e) => {
        setActiveChecked(false);
        setInActiveChecked(false);
        setFilledChecked(e.target.checked);
        if (!e.target.checked) {
            if (notFilledChecked) {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 0);
                setLocalUsers(temp);
                setUserData(temp);
            } else {
                setLocalUsers([]);
                setUserData([]);
            }
            setAllChecked(false);
        } else {
            if (notFilledChecked) {
                setAllChecked(true);
                setLocalUsers(props.users);
                setUserData(props?.users);
            } else {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 1);
                setLocalUsers(temp);
                setUserData(temp);
            }
        }
    }

    const handleChangeNotFilled = (e) => {
        setActiveChecked(false);
        setInActiveChecked(false);
        setNotFilledChecked(e.target.checked);
        if (!e.target.checked) {
            if (filledChecked) {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 1);
                setLocalUsers(temp);
                setUserData(temp);
            } else {
                setLocalUsers([]);
                setUserData([]);
            }
            setAllChecked(false);
        } else {
            if (filledChecked) {
                setAllChecked(true);
                setLocalUsers(props.users);
                setUserData(props?.users);
            } else {
                let temp = JSON.parse(JSON.stringify(props.users));
                temp = temp.filter(obj => obj.filled == 0);
                setLocalUsers(temp);
                setUserData(temp);
            }
        }
    }

    const handleChangeActive = (e) => {
        setInActiveChecked(false);
        setActiveChecked(e.target.checked);
        let data = JSON.parse(JSON.stringify(userData));
        if (!e.target.checked) {
            setLocalUsers(userData);
        } else {
            setLocalUsers(data.filter(obj => obj.isExcluded === 0));
        }
    }

    const handleChangeInActive = (e) => {
        setActiveChecked(false);
        setInActiveChecked(e.target.checked);
        let data = JSON.parse(JSON.stringify(userData));
        if (!e.target.checked) {
            setLocalUsers(userData);
        } else {
            setLocalUsers(data.filter(obj => obj.isExcluded === 1));
        }
    }

    const parseUserToExcelFormat = (data) => {
        let arr = [];
        data.map(user => {
            arr.push({
                NAME: user.name,
                STATUS: props.type === 'Selfie' || props.type === 'Review' ?
                    `${user.filled} / ${user.sent}` :
                    user.filled ? 'Completed' : 'Not Completed',
                Performance: props.compositionsOwned.includes(props.selectedCompositionId) ? user.performanceReviewStatus : null,
                Team: user.team,
                Lead: user.lead,
                Role: null,
            })
        });
        return arr;
    }

    const exportToExcel = () => {
        const ws = utils.json_to_sheet(parseUserToExcelFormat(localUsers));
        ws["!cols"] = [{ wch: 30 }, { wch: 10 }];
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "Sheet.xlsx");
    }

    const actionButton = (item) => {
        if (item.id === props.meDetails[0].id) {
            props.buttonClick(props.meDetails[0], 'me');
        } else {
            props.buttonClick(item, 'team');
            //     props.teamDetails.map((obj)=>{
            //         if (obj.id === item.id) {
            //             props.buttonClick(obj, 'team');
            //         }
            //   })
        }
    };
    const actionActivateButton = (item, excludeType) => {
        let totalUserData = JSON.parse(JSON.stringify(localUsers));
        var foundIndex = totalUserData.findIndex(x => x.id == item.id);
        if (foundIndex > -1) {
            totalUserData[foundIndex].isExcluded = excludeType;
        }
        setLocalUsers(totalUserData);
        let list = [];
        let data = {
            userId: item.id,
            excluded: excludeType
        }
        list.push(data);
        props.excludeUnexcludePeer(props?.selectedQuarter, { "list": list });
        // props.getDashboardDetails({ quarterId: props?.selectedQuarter })
        // props.getDashboardDetails({ quarterId: props?.selectedQuarter, compositionId: props.selectedCompositionId })
    };

    return (
        <div className={styled.userList}>
            <div className='flex-space userlist-header' style={{ width: '30%' }}>
                <button className={styled.backBtn} onClick={props.goBack}><ArrowBackIcon style={{ fontSize: '16px', marginRight: '3px' }} />Back to Dashboard</button>
                <button className="printBtn" onClick={exportToExcel} style={{ margin: 0 }} disabled={!localUsers?.length}>
                    <div className="text" style={{ margin: '2px 5px 0 0' }}>Export</div><SimCardDownloadIcon style={{ fontSize: '20px' }} />
                </button>
            </div>
            <p className={styled.subHead}>{props.name} - {props.type} </p>
            {
                props.type === "Finalized Reviews" ?
                    <React.Fragment>
                        <p style={{ margin: '10px 0 5px 0', fontWeight: 500 }}>Review Rating Stats:</p><br />
                        <div className='flex'>
                            <div className='stats-data'>Under Performing ({props.users?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length > 0 ? `${((props.users?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length / props.users?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                            <div className='stats-data'>Performing ({props.users?.filter(ele => ele.performanceReviewStatus === 'Performing').length > 0 ? `${((props.users?.filter(ele => ele.performanceReviewStatus === 'Performing').length / props.users?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                            <div className='stats-data'>Over Performing ({props.users?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length > 0 ? `${((props.users?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length / props.users?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                        </div>
                    </React.Fragment> : null
            }
            {
                props.type !== 'Selfie' && props.type !== 'Review' ?
                    <div className='flex checkbox-status' style={{
                        margin: props.type === "Finalized Reviews" ? '20px 0 0 10px' : '40px 0 0 10px'
                    }}>
                        <FormControlLabel
                            label={`All Users (${props?.users?.length})`}
                            control={
                                <Checkbox
                                    checked={allChecked}
                                    onChange={handleChangeAll}
                                    className='check-box'
                                    sx={{
                                        color: '#14009B',
                                        '&.Mui-checked': {
                                            color: '#14009B',
                                        },
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                        <FormControlLabel
                            label={`Completed (${props?.users?.filter(obj => obj.filled == 1)?.length})`}
                            control={
                                <Checkbox
                                    checked={filledChecked}
                                    onChange={props?.compositionsOwned.includes(props?.selectedCompositionId) && props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ? handleChangeFilled : handleChangeFilledData}
                                    className='check-box'
                                    sx={{
                                        color: '#14009B',
                                        '&.Mui-checked': {
                                            color: '#14009B',
                                        },
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                        <FormControlLabel
                            label={`Not Completed (${props?.users?.filter(obj => obj.filled == 0)?.length})`}
                            control={
                                <Checkbox
                                    checked={notFilledChecked}
                                    onChange={props?.compositionsOwned.includes(props?.selectedCompositionId) && props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ? handleChangeNotFilled : handleChangeNotFilledData}
                                    className='check-box'
                                    sx={{
                                        color: '#14009B',
                                        '&.Mui-checked': {
                                            color: '#14009B',
                                        },
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                        {/* {
                            props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                                <div>
                                    <FormControlLabel
                                        label={`Active (${props?.users?.filter(obj => obj.isExcluded == 0)?.length})`}
                                        control={
                                            <Checkbox
                                                checked={activeChecked}
                                                onChange={handleChangeActive}
                                                className='check-box'
                                                sx={{
                                                    color: '#14009B',
                                                    '&.Mui-checked': {
                                                        color: '#14009B',
                                                    },
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    />
                                    <FormControlLabel
                                        label={`Inactive (${props?.users?.filter(obj => obj.isExcluded == 1)?.length})`}
                                        control={
                                            <Checkbox
                                                checked={inActiveChecked}
                                                onChange={handleChangeInActive}
                                                className='check-box'
                                                sx={{
                                                    color: '#14009B',
                                                    '&.Mui-checked': {
                                                        color: '#14009B',
                                                    },
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    />
                                </div>
                                :
                                null

                        } */}

                    </div> : null
            }
            {
                props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                    <div className='flex checkbox-status' style={{
                        margin: "20px 0 0 10px"
                    }}>
                        {
                            <>
                                <FormControlLabel
                                    label={`Active (${props?.users?.filter(obj => obj.isExcluded == 0)?.length})`}
                                    control={
                                        <Checkbox
                                            checked={activeChecked}
                                            onChange={handleChangeActive}
                                            className='check-box'
                                            sx={{
                                                color: '#14009B',
                                                '&.Mui-checked': {
                                                    color: '#14009B',
                                                },
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                />
                                <FormControlLabel
                                    label={`Inactive (${props?.users?.filter(obj => obj.isExcluded == 1)?.length})`}
                                    control={
                                        <Checkbox
                                            checked={inActiveChecked}
                                            onChange={handleChangeInActive}
                                            className='check-box'
                                            sx={{
                                                color: '#14009B',
                                                '&.Mui-checked': {
                                                    color: '#14009B',
                                                },
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                />
                            </>

                        }
                    </div>
                    :
                    null
            }
            <div className='flex'>
                <ul className={styled.listUsers} style={{ width: "70%" }}>
                    <li className={styled.listHead}>
                        <div className={styled.listHeadCol1}>
                            Name
                        </div>
                        <div style={props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ? { width: '40%' } : { width: '20%' }} className={styled.listHeadCol2}>
                            Status
                        </div>
                        {props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                            (<div className={styled.listHeadCol3}>
                                Activate Status
                            </div>) : null
                        }
                        {props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                            (<div className={styled.listHeadCol4}>
                                {`View Objectives`}
                            </div>) : null
                        }


                    </li>
                    {
                        localUsers?.length ? localUsers?.map((user, i) =>
                            <li key={i} className={styled.listRow}>
                                <div className={styled.listHeadRow1}>
                                    {user.name}
                                    {props.compositionsOwned.includes(props.selectedCompositionId) ?
                                        <span>{' '}<span className={user.performanceReviewStatus ? styled.mapCardRowspanOther : null}>{user.performanceReviewStatus}</span></span> : null}
                                </div>
                                <div style={props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ? { width: '40%' } : { width: '20%' }} className={styled.listHeadRow2}>
                                    {props.isDesktopLayout ?

                                        props.type === 'Selfie' || props.type === 'Review' ?
                                            `${user.filled} / ${user.sent}` :
                                            user.filled ? <div style={{ display: 'flex' }}><CheckIcon className={'icon-inside green'} /><span className={styled.textStatus}>Yes</span></div> : <div style={{ display: 'flex' }}><CloseIcon className={'icon-inside red'} /><span className={styled.textStatus}>No</span></div>
                                        :
                                        props.type === 'Selfie' || props.type === 'Review' ?
                                            `${user.filled} / ${user.sent}` :
                                            user.filled ? <div><span className={styled.textStatus} style={{ color: 'green', fontWeight: 'bold' }}>Yes</span></div> : <div><span className={styled.textStatus} style={{ color: 'red', fontWeight: 'bold' }}>No</span></div>}
                                </div>
                                {props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                                    (<div className={styled.listHeadRow3}>
                                        <IconButton style={{ display: 'inline-block' }} aria-label="review" title={`${user.isExcluded == 1 ? 'Inactive' : 'Active'}`} disabled={props.compositionsOwned.includes(props.selectedCompositionId) ? false : true} onClick={() => props.compositionsOwned.includes(props.selectedCompositionId) ? actionActivateButton(user, user?.isExcluded == 1 ? 0 : 1) : ""} className="iconBtn-with-text">

                                            {
                                                user?.isExcluded == 1 ?
                                                    <ToggleOffIcon style={{ color: 'red', fontSize: "30px" }} />
                                                    :
                                                    <ToggleOnIcon style={{ color: 'green', fontSize: "30px" }} />
                                            }
                                        </IconButton>

                                    </div>) : null
                                }
                                {props.type !== 'Selfie' && props.type !== 'Review' && props.type !== "Finalized Reviews" ?
                                    (<div className={styled.listHeadRow4}>
                                        {(props.publicGoalUserList.includes(user.email)) ?
                                            (<IconButton style={{ display: 'inline-block', marginTop: "-10px" }} aria-label="review" title={`View Objectives`} onClick={() => actionButton(user)} className="iconBtn-with-text">
                                                <VisibilityIcon style={{ color: '#14009b' }} />
                                                {/* <div className="btn-small-text">{`View Goals`}</div> */}
                                            </IconButton>) : null
                                        }
                                    </div>) : null
                                }

                            </li>
                        ) :
                            <li>
                                No User found
                            </li>
                    }
                </ul>
                {props.isDesktopLayout ?
                    <div className={styled.userListImage}></div> : null}
            </div>
        </div>
    )
}

UserList.propTypes = propTypes;
export default UserList