import { axiosInstance, axiosInstanceOther, reExecuteInstance } from "../../../utils/Http";
import {
  userLoginSuccess,
  userLoginFailure,
  userLogoutSuccess,
  userLogoutFailure,
  userPasswordChangeSuccess,
  userPasswordChangeFailure,
  userForgotPasswordSuccess,
  userForgotPasswordFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  submitFeedbackSuccess,
  submitFeedbackFailure
} from "./actions";
import store from "../../../store/index";
import { generateError } from "../../../utils/Resusables";
import {
  getReviewMinutes
} from "../../Survey/store/actions";

export const postLogin = (data, localConnectId) => {
  axiosInstanceOther.defaults.headers.common['connectId'] = localConnectId
  return axiosInstanceOther
    .post(`logins?type=customer`, data)
    .then((response) => {
      const base64Url = response?.data?.data?.token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      localStorage.setItem("access_token", JSON.parse(window.atob(base64)).sid);
      return store.dispatch(userLoginSuccess(response.data.data));
    })
    .catch((error) => {
      return store.dispatch(userLoginFailure(generateError(error, true)))
    });
};

export const logout = () => {
  return axiosInstance
    .get(`logout`)
    .then((response) => store.dispatch(userLogoutSuccess(response.data.result)))
    .catch((error) => store.dispatch(userLogoutFailure(generateError(error))));
};

export const passwordChange = (data) => {
  return axiosInstance
    .post(`changes/users/passwords`, data)
    .then((response) =>
      store.dispatch(userPasswordChangeSuccess(response.data.result))
    )
    .catch((error) =>
      store.dispatch(userPasswordChangeFailure(generateError(error)))
    );
};

export const forgotPassword = (data) => {
  return axiosInstanceOther
    .post(`customers/passwords/forgots`, data)
    .then((response) =>
      store.dispatch(userForgotPasswordSuccess(response.data.result))
    )
    .catch((error) =>
      store.dispatch(userForgotPasswordFailure(generateError(error)))
    );
};

export const getUserDetails = () => {
  reExecuteInstance();
  return axiosInstance
    .get(`logged/user/detail`)
    .then((response) => {
      if (location?.href.indexOf('review') > -1 && location?.href.indexOf('report') > -1) {
        const id = location?.href.split('/review/')[1]?.split('/report/')[0]
        getReviewMinutes(id);
      }
      return store.dispatch(getUserDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getUserDetailsFailure(generateError(error)))
    );
};

export const submiteFeedback = (data) => {
  return axiosInstance
    .post(`customers/feedback`, data)
    .then((response) =>
      store.dispatch(submitFeedbackSuccess(response.data.result))
    )
    .catch((error) =>
      store.dispatch(submitFeedbackFailure(generateError(error)))
    );
};
