import { axiosInstance } from "../../../utils/Http";
import {
  createSurveySuccess,
  createSurveyFailure,
  getSurveyDescriptionFailure,
  getSurveyDescriptionSuccess,
  postAddQuestionSuccess,
  postAddQuestionFailure,
  getPreviewQuestionFailure,
  getPreviewQuestionSuccess,
  getCategoreyListFailure,
  getCategoreyListSuccess,
  editQuestionsFailure,
  editQuestionsSuccess,
  getSubCategoreyListFailure,
  getSubCategoreyListSuccess,
  getDefaultCategoreyListFailure,
  getDefaultCategoreyListSuccess,
  getPeersListFailure,
  getPeersListSuccess,
  addExternalPeersSuccess,
  addExternalPeersFailure,
  invitePeersSuccess,
  invitePeersFailure,
  editSurveyFailure,
  editSurveySuccess,
  searchPeerSuccess,
  searchPeerFailure,
  searchAddPeerFailure,
  searchAddPeerSuccess,
  sendSurveyPeerSuccess,
  sendSurveyPeerFailure,
  getScheduleCandidateSuccess,
  getScheduleCandidateFailure,
  deleteScheduleCandidateSuccess,
  deleteScheduleCandidateFailure,
  questionDeleteSuccess,
  questionDeleteFailure,
  deleteGoalTitleSuccess,
  deleteGoalTitleFailure,
  editGoalTitleSuccess,
  editGoalTitleFailure,
  getSentCandidateSuccess,
  getSentCandidateFailure,
  sendSurveySuccess,
  sendSurveyFailure,
  importGoalsSuccess,
  importGoalsFailure,
  getImportQuestionSuccess,
  getImportQuestionFailure
} from "./actions";
import store from "../../../store/index";
import { generateError } from "../../../utils/Resusables";

export const postCreateSurvey = (data) => {
  return axiosInstance
    .post(`customers/surveys/creates`, data)
    .then((response) => {
      return store.dispatch(createSurveySuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(createSurveyFailure(generateError(error)))
    );
};

export const getSurveyDescription = () => {
  return axiosInstance
    .get(`defaults/en/description?type=customer`)
    .then((response) => {
      return store.dispatch(getSurveyDescriptionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSurveyDescriptionFailure(generateError(error)))
    );
};

export const postAddQuestion = (data) => {
  return axiosInstance
    .post(`customers/surveys/questioncreates`, data)
    .then((response) => {
      return store.dispatch(postAddQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(postAddQuestionFailure(generateError(error)))
    );
};

export const getPreviewQuestion = (data) => {
  return axiosInstance
    .get(`customers/${data}/survey/questions`)
    .then((response) => {
      return store.dispatch(getPreviewQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getPreviewQuestionFailure(generateError(error)))
    );
};

export const getCategoreyList = (data) => {
  return axiosInstance
    .get(`surveys/${data}/categories`)
    .then((response) => {
      return store.dispatch(getCategoreyListSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getCategoreyListFailure(generateError(error)))
    );
};

export const editQuestions = (data) => {
  return axiosInstance
    .post(`customers/surveys/questionedits`, data)
    .then((response) => {
      return store.dispatch(editQuestionsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(editQuestionsFailure(generateError(error)))
    );
};

export const getSubCategoreyList = (data, surveyId) => {
  return axiosInstance
    .get(`surveys/${data}/subcategories/${surveyId}`)
    .then((response) => {
      return store.dispatch(getSubCategoreyListSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSubCategoreyListFailure(generateError(error)))
    );
};

export const getDefaultCategoreyList = () => {
  return axiosInstance
    .get(`customer/category/default`)
    .then((response) => {
      return store.dispatch(getDefaultCategoreyListSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getDefaultCategoreyListFailure(generateError(error)))
    );
};
export const getPeersList = (data) => {
  return axiosInstance
    .get(`logged/user/peers?offset=${data.offset ? data.offset : 0}${data.recPerPage ? `&recPerPage=${data.recPerPage}` : ''}${data.search && data.search !== '' ? `&search=${data.search}` : ''}${data.all ? `&all=true` : '&all=false'}${data.surveyId ? `&surveyId=${data.surveyId}` : ''}`)
    .then((response) => {
      return store.dispatch(getPeersListSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getPeersListFailure(generateError(error)))
    );
};

export const addExternalPeers = (data) => {
  return axiosInstance
    .post(`customers/surveys/addexternalpeers`, data)
    .then((response) => {
      return store.dispatch(addExternalPeersSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(addExternalPeersFailure(generateError(error)))
    );
};

export const invitePeers = (data) => {
  return axiosInstance
    .post(`customers/surveys/excludepeers`, data)
    .then((response) => {
      return store.dispatch(invitePeersSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(invitePeersFailure(generateError(error)))
    );
};

export const editSurvey = (data) => {
  return axiosInstance
    .post(`customers/surveys/edits`, data)
    .then((response) => {
      return store.dispatch(editSurveySuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(editSurveyFailure(generateError(error)))
    );
};

export const searchPeer = (data) => {
  return axiosInstance
    .get(`user/list?search=${data}&offset=0&recPerPage=50`)
    .then((response) => {
      return store.dispatch(searchPeerSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(searchPeerFailure(generateError(error)))
    );
};

export const searchAddPeer = (data) => {
  return axiosInstance
    .post(`customers/surveys/addusertopeers`, data)
    .then((response) => {
      return store.dispatch(searchAddPeerSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(searchAddPeerFailure(generateError(error)))
    );
};

export const sendSurveyPeer = (data) => {
  return axiosInstance
    .post(`customers/surveys/edits`, data)
    .then((response) => {
      return store.dispatch(sendSurveyPeerSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(sendSurveyPeerFailure(generateError(error)))
    );
};

export const getScheduleCandidate = (data) => {
  return axiosInstance
    .get(`customers/${data.id}/schedule?offset=${data.page}${data.recPerPage ? `&recPerPage=${data.recPerPage}` : ''}${data.name ? `&name=${data.name}` : ''}`)
    .then((response) => {
      return store.dispatch(getScheduleCandidateSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getScheduleCandidateFailure(generateError(error)))
    );
};

export const deleteScheduleCandidate = (data) => {
  return axiosInstance
    .delete(`customers/${data}/schedule`)
    .then((response) => {
      return store.dispatch(deleteScheduleCandidateSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(deleteScheduleCandidateFailure(generateError(error)))
    );
};

export const questionDelete = (data) => {
  return axiosInstance
    .delete(`customers/${data}/question`)
    .then((response) => {
      return store.dispatch(questionDeleteSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(questionDeleteFailure(generateError(error)))
    );
};

export const deleteGoalTitle = (data) => {
  return axiosInstance
    .delete(`customers/${data}/subcategory`)
    .then((response) => {
      return store.dispatch(deleteGoalTitleSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(deleteGoalTitleFailure(generateError(error)))
    );
};

export const editGoalTitle = (data) => {
  return axiosInstance
    .post(`customers/surveys/subcatgoryedits`, data)
    .then((response) => {
      return store.dispatch(editGoalTitleSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(editGoalTitleFailure(generateError(error)))
    );
};

export const getSentCandidate = (data) => {
  return axiosInstance
    .get(`customers/${data.id}/sent?offset=${data.page}${data.recPerPage ? `&recPerPage=${data.recPerPage}` : ''}${data.name ? `&name=${data.name}` : ''}`)
    .then((response) => {
      return store.dispatch(getSentCandidateSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSentCandidateFailure(generateError(error)))
    );
};

export const sendSurvey = (data,surveyId) => {
  return axiosInstance
    .post(`customers/${surveyId}/surveys/shares`, data)
    .then((response) => {
      return store.dispatch(sendSurveySuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(sendSurveyFailure(generateError(error)))
    );
};

export const importGoals = (data,surveyId) => {
  return axiosInstance
    .post(`customers/${surveyId}/imports/goals`, data)
    .then((response) => {
      return store.dispatch(importGoalsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(importGoalsFailure(generateError(error)))
    );
};

export const getImportQuestion = (data) => {
  return axiosInstance
    .get(`customers/${data}/survey/questions`)
    .then((response) => {
      return store.dispatch(getImportQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getImportQuestionFailure(generateError(error)))
    );
};
