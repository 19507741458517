import { connect } from 'react-redux'
import AddQuestions from './components/addQuestions'
import {
  copySurveyQuestion,
  getPreviewQuestion,
  postAddQuestion,
  getCategoreyList,
  getDefaultCategoreyList,
  deleteGoalTitle,
  editGoalTitle,
  sendSurveyPeer,
  importGoals,
  getImportQuestion,
} from "../../store/actions";
import {
  resetAddQuestion,
} from "../../../CreateSurvey/store/actions";
import { getSurveyDetails } from '../../../Survey/store/actions';
import { getSurveySent } from '../../../Survey/store/actions';
import { getQuarterListData } from '../../../Dashboard/store/actions';
import { getIkigaiDetails } from '../../../Ikigai/store/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.createSurvey.errorMessage,
    isLoading: state.createSurvey.isLoading,
    status: state.status,
    ownProps,
    surveyDetail: state.surveyList.surveyDetail,
    questionDetail: state.createSurvey.questionDetail,
    previewQuestion: state.createSurvey.previewQuestion,
    categoryList: state.createSurvey.categoryList,
    subCategoryList: state.createSurvey.subCategoryList,
    defaultCategory: state.createSurvey.defaultCategory,
    surveyList: state.surveyList.items,
    quarterList: state.surveyList.quarter.items,
    importQuestion: state.createSurvey.importQuestion,
    ikigaiDetails: state.ikigai.ikigaiDetails,
    isDesktopLayout: state.browser.screenLayout === "desktop",
    isTabletLayout: state.browser.screenLayout === "tablet",
    isMobileLayout: state.browser.screenLayout === "mobile",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    copySurveyQuestion,
    getPreviewQuestion: (data) => {
      dispatch(getPreviewQuestion(data));
    },
    getSurveyDetails: (data) => {
      dispatch(getSurveyDetails(data));
    },
    resetAddQuestion: (data) => {
      dispatch(resetAddQuestion(data));
    },
    deleteGoalTitle: (data) => {
      dispatch(deleteGoalTitle(data));
    },
    editGoalTitle: (data) => {
      dispatch(editGoalTitle(data));
    },
    sendSurveyPeer: (data) => {
      dispatch(sendSurveyPeer(data));
    },
    getSurveySent: (data) => {
      dispatch(getSurveySent(data));
    },
    importGoals: (data,surveyId) => {
      dispatch(importGoals(data,surveyId));
    },
    getImportQuestion: (data) => {
      dispatch(getImportQuestion(data));
    },
    getQuarterList: (data) => {
      dispatch(getQuarterListData(data));
    },
    getIkigaiDetails: () => {
      dispatch(getIkigaiDetails());
    },
    postAddQuestion,
    getCategoreyList,
    getDefaultCategoreyList
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestions)