import React, { useState } from 'react'
import "./feedback.css";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Constants from "../../config";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RatingQuestion from '../../modules/CreateSurvey/pages/addQuestions/components/ratingQuestion';
import { connect } from "react-redux";
import { submitFeedback } from "../../modules/auth/store/actions";
import { getUserDetails } from "../../modules/auth/store/actions";
import ToastNotify from "../Toast/ToastNotify";

const propTypes = {
    position: PropTypes.string,
    submitFeedback: PropTypes.func,
    status: PropTypes.string,
    getUserDetails: PropTypes.func,
    userDetail: PropTypes.any
};

const Feedback = (props) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [rating, setRating] = useState('');
    const [description, setDescription] = useState('');
    const [isError, setError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    React.useEffect(() => {
        switch (props?.status) {
            case "SUBMIT_FEEDBACK_SUCCESS":
                props.getUserDetails();
                setShowSuccess(true);
                setTimeout(() => setShowSuccess(false), 3000);
                break;
        }
    }, [props?.status]);

    const onChangeDescription = (e) => {
        if (e.target.value.trim() !== '' || e.target.value === '') {
            if (e.target.value.length <= 500) {
                setDescription(e.target.value);
                setError(false);
            }
        }
    }

    const onSubmit = () => {
        if (description === '') {
            setError(true)
        } else {
            let data = {
                rating,
                description
            }
            props.submitFeedback(data);
        }
    }

    return (
        <React.Fragment>
            {
                props?.userDetail?.isFeedbackVisible ?
                    <div className="myfeedback-widget myfeedback-widget--engager myfeedback-widget--left myfeedback-widget--horizontal myfeedback-widget--medium is-ready" data-type="engager-v2">
                        <div className="myfeedback-widget__container">
                            <a className="myfeedback-widget__caller is-closed" onClick={() => setPopupOpen(true)}>Give your Feedback</a>
                            {
                                isPopupOpen ?
                                    <div className="myfeedback-widget__popup">
                                        <div className="myfeedback-widget__popup__wrapper">
                                            <div className='flex-end'>
                                                <IconButton
                                                    aria-label="toggle search and close button"
                                                    onClick={() => {
                                                        setPopupOpen(false);
                                                        setDescription('');
                                                        setRating('');
                                                    }}
                                                    edge="end"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                            <div>
                                                <div className='step-wrapper' style={{ marginBottom: '50px' }}>

                                                    <p style={{ fontSize: '16px', fontWeight: 600 }}>
                                                        How would you rate the app based on your experience? <span className='redStar'>*</span>
                                                    </p>
                                                    <div>
                                                        <RatingQuestion
                                                            value={rating}
                                                            setValue={setRating}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='step-wrapper'>
                                                    <p style={{ fontSize: '16px', fontWeight: 600 }}>
                                                        Any feedback for our team? <span className='redStar'>*</span>
                                                    </p>
                                                    <div>
                                                        <TextField
                                                            multiline={true}
                                                            rows={4}
                                                            onChange={onChangeDescription}
                                                            value={description}
                                                            error={isError}
                                                            fullWidth={true}
                                                        />
                                                    </div>
                                                    <div style={{ position: 'absolute', bottom: '20px', right: '30px' }}>
                                                        <Button className="questionSaveText" onClick={onSubmit} disabled={rating === ''}>
                                                            {Constants.SUBMIT}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <ArrowDropDownIcon className='pointer-icon' />
                                    </div> : null
                            }
                        </div>
                    </div> : null
            }
            <ToastNotify
                data={'Your Feedback has been submitted successfully'}
                openAlert={showSuccess}
                type={"success"}
            />
        </React.Fragment>
    );
};

Feedback.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    return {
        status: state.status,
        userDetail: state.auth.userDetail,
        ownProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDetails: () => {
            dispatch(getUserDetails());
        },
        submitFeedback: (data) => {
            dispatch(submitFeedback(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
