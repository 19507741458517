import { connect } from "react-redux";
import ReviewMinutes from "./components/reviewMinutes";
import { getReportSurvey, getReportSurveyDetailData, getReportGraph, getReportQuestion, getReviewUserList, getReviewMinutes, createReviewMinutes, finalizeReviewMinutes, deleteReviewMinute, updateReviewMinute, getReviewMinuteDetail, shareReviewMinutes, getReviewMinutesPeer } from "../../store/actions";
import { getPeersList } from "../../../CreateSurvey/store/actions";
import { getQuarterList } from "../../store/actions";
import { getGoalDetails } from "../../../Dashboard/store/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.surveyList.errorMessage,
    isLoading: state.surveyList.isLoadingList,
    status: state.status,
    surveyList: state.surveyList,
    quarterList: state.surveyList.quarter.items,
    isLoadingQuarter: state.surveyList.quarter.isLoading,
    quarterTotalRecords: state.surveyList.quarter.totalRecords,
    userDetail: state.auth.userDetail,
    peerList: state?.createSurvey?.peerList?.list,
    ownProps,
    reportQuestionsItems: state.surveyList.reportQuestions?.reportQuestionsItems,
    reportSurveyItems: state.surveyList.reportSurvey?.reportSurveyItems,
    reportGraphItemsNew: state.surveyList.reportGraph?.reportGraphItemsNew,
    reportDetailItems: state.surveyList.reportDetail?.reportDetailItems,
    reviewUserListItems: state.surveyList.reviewUserList?.reviewUserListItems,
    reviewMinutesDetails: state.surveyList.reviewUserList?.reviewMinutesDetails,
    isDesktopLayout: state.browser.screenLayout === "desktop",
    isTabletLayout: state.browser.screenLayout === "tablet",
    isMobileLayout: state.browser.screenLayout === "mobile",
    browserWidth: state.browser.width,
    selectedQuarter: state.dashboard.selectedQuarter,
    reviewMinutesDetailData: state.surveyList.reviewMinutesDetailData,
    reviewMinutesPeer: state.surveyList.reviewMinutesPeer,
    goalDetails: state.dashboard.goalDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReportSurvey: (data) => {
      dispatch(getReportSurvey(data));
    },
    getReportSurveyDetail: (data) => {
      dispatch(getReportSurveyDetailData(data));
    },
    //   getSurveyReports: (data) => {
    //     dispatch(getSurveyReports(data));
    //   },
    getReportGraph: (data) => {
      dispatch(getReportGraph(data));
    },
    getReportQuestion: (data) => {
      dispatch(getReportQuestion(data));
    },
    getReviewUserList: (data) => {
      dispatch(getReviewUserList(data));
    },
    getReviewMinutes: (data) => {
      dispatch(getReviewMinutes(data));
    },
    createReviewMinutes: (data) => {
      dispatch(createReviewMinutes(data));
    },
    getPeersList: (data) => {
      dispatch(getPeersList(data));
    },
    finalizeReviewMinutes: (data) => {
      dispatch(finalizeReviewMinutes(data));
    },
    getQuarterList: (data) => {
      dispatch(getQuarterList(data));
    },
    deleteReviewMinutes: (identifier) => {
      dispatch(deleteReviewMinute(identifier));
    },
    updateReviewMinutes: (identifier, payload) => {
      dispatch(updateReviewMinute(identifier, payload));
    },
    getReviewMinuteDetail: (quarterId, userId) => {
      dispatch(getReviewMinuteDetail(quarterId, userId));
    },
    shareReviewMinutes: (surveyId, payload) => {
      dispatch(shareReviewMinutes(surveyId, payload));
    },
    getReviewMinutesPeer: (quarterId) => {
      dispatch(getReviewMinutesPeer(quarterId));
    },
    getGoalDetails: (surveyId, request) => {
      dispatch(getGoalDetails(surveyId, request));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewMinutes);
