export const GET_IKIGAI_QUESTION = 'GET_IKIGAI_QUESTION';
export const GET_IKIGAI_QUESTION_SUCCESS = 'GET_IKIGAI_QUESTION_SUCCESS';
export const GET_IKIGAI_QUESTION_ERROR = 'GET_IKIGAI_QUESTION_ERROR';

export const GET_IKIGAI_DETAILS = 'GET_IKIGAI_DETAILS';
export const GET_IKIGAI_DETAILS_SUCCESS = 'GET_IKIGAI_DETAILS_SUCCESS';
export const GET_IKIGAI_DETAILS_ERROR = 'GET_IKIGAI_DETAILS_ERROR';

export const POST_IKIGAI_QUESTION = 'POST_IKIGAI_QUESTION';
export const POST_IKIGAI_QUESTION_SUCCESS = 'POST_IKIGAI_QUESTION_SUCCESS';
export const POST_IKIGAI_QUESTION_ERROR = 'POST_IKIGAI_QUESTION_ERROR';

export const EDIT_IKIGAI_QUESTION = 'EDIT_IKIGAI_QUESTION';
export const EDIT_IKIGAI_QUESTION_SUCCESS = 'EDIT_IKIGAI_QUESTION_SUCCESS';
export const EDIT_IKIGAI_QUESTION_ERROR = 'EDIT_IKIGAI_QUESTION_ERROR';

export const GET_IKIGAI_QUESTION_RESPONSE = 'GET_IKIGAI_QUESTION_RESPONSE';
export const GET_IKIGAI_QUESTION_RESPONSE_SUCCESS = 'GET_IKIGAI_QUESTION_RESPONSE_SUCCESS';
export const GET_IKIGAI_QUESTION_RESPONSE_ERROR = 'GET_IKIGAI_QUESTION_RESPONSE_ERROR';

export const GET_ALL_IKIGAI_QUESTIONS = 'GET_ALL_IKIGAI_QUESTIONS';
export const GET_ALL_IKIGAI_QUESTIONS_SUCCESS = 'GET_ALL_IKIGAI_QUESTIONS_SUCCESS';
export const GET_ALL_IKIGAI_QUESTIONS_ERROR = 'GET_ALL_IKIGAI_QUESTIONS_ERROR';