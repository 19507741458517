/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import translations from "../../../../../config/translations.json";
import styled from "../../../css/auth.module.css";
import forgotPassImg from "../../../../../assets/forgotPass.svg";
import xeleron from "../../../../../assets/xeleron.svg";
import msgArrow from "../../../../../assets/msgArrow.svg";
import Field from "../../../../../common/Field";
import PrimaryButton from "../../../../../common/PrimaryButton";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import Constants from "../../../../../config";
import waadaLogo from "../../../../../assets/waadaLogo.svg";
import Loader from "../../../../../common/loader/loader";
import { useNavigate } from "react-router-dom";
const displayName = "forgotPassword";
const propTypes = {
  isDesktopLayout: PropTypes.bool,
  isTabletLayout: PropTypes.bool,
  isMobileLayout: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  userLogin: PropTypes.func,
  status: PropTypes.string,
  userForgotPassword: PropTypes.func,
};

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [showError, setError] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [forgotPasswordObj, setForgotPasswordData] = useState({
    email: { value: "", error: false },
  });
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);

  useEffect(() => {
    switch (props.status) {
      case "USER_FORGOT_PASSWORD_SUCCESS":
        setForgotPasswordSuccess(true);
        setShowSuccessMsg(true);
        break;
      case "USER_FORGOT_PASSWORD_ERROR":
        setError(true);
        setTimeout(() => setError(false), 3000);
        break;
    }
  }, [props.status]);

  const onForgotPasswordChange = (event) => {
    const value = event.target.value;
    setForgotPasswordData({
      ...forgotPasswordSuccess,
      [event.target.name]: { value, error: false },
    });
  };

  const emailValidation = (email) => {
    const regex = RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regex.test(email);
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    setForgotPasswordData({
      ...forgotPasswordObj,
      email: {
        ...forgotPasswordObj["email"],
        error:
          forgotPasswordObj["email"].value.trim() === "" ||
          !emailValidation(forgotPasswordObj["email"].value.trim()),
      },
    });
    if (
      forgotPasswordObj["email"].value !== "" &&
      emailValidation(forgotPasswordObj["email"].value.trim())
    ) {
      props.userForgotPassword({
        email: forgotPasswordObj["email"].value,
      });
    }
  };

  return (
    <div className="login-container bg-color-login">
      <div className={styled.authPage}>
        <div className={styled.authLeftSec}>
          <div className={styled.authHead}>
            {Constants.AUTH_SIDEBOX_HEADING}
          </div>
          <div className={styled.authPara}>
            {Constants.AUTH_SIDEBOX_PARA}
          </div>
          <div className={styled.loginImageDiv}>
            <img
              src={forgotPassImg}
              alt="loginImg"
              className={styled.loginImage}
            />
          </div>
        </div>
        <div className={styled.authRightSec}>
          {props.isLoading && <Loader is_loading={props.isLoading} />}
          <img
            src={waadaLogo}
            alt="waadaLogo"
            className={styled.mainLogo}
          />
          <img src={forgotPassImg} alt="loginImg" className={styled.isMobile} />
          <div className={styled.loginHeading}>{Constants.FORGOT_PASSWORD}</div>
          {
            showSuccessMsg ?
              <div className={styled.forgotPassBox}>
                <img src={msgArrow} alt="msgArrow" />
                <div className={styled.forgotPassText}>{Constants.TEMPORARY_PASSWORD_TEXT}</div>
                <div className={styled.goBack}>Go back to <a onClick={() => window.location.replace("/login")}>Sign In</a></div>
              </div>
              :
              <div className={styled.formField}>
                <div className={styled.formLable}>{Constants.ENTER_EMAIL}</div>
                <Field
                  placeholder={Constants.ENTER_EMAIL}
                  name={"email"}
                  value={forgotPasswordObj.email.value}
                  error={forgotPasswordObj.email.error}
                  helperText={
                    forgotPasswordObj.email.error
                      ? translations.errorMessage.email1[0]
                      : null
                  }
                  onChange={onForgotPasswordChange}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      onForgotPassword(event);
                    }
                  }}
                />
                <div className={styled.loginButton}>
                  {showError ? (
                    <ToastNotify data={props.errorMessage} openAlert={true} type={"error"} />
                  ) : null}
                  <PrimaryButton
                    text={Constants.SEND_TEMPORARY_PASSWORD}
                    className={styled.temporaryButton}
                    onClick={onForgotPassword}
                  />
                </div>
              </div>
          }
          <div style={{ textAlign: 'center', }}>
            <img
              src={xeleron}
              alt="Xeleron"
              style={{marginTop: '30px'}}
              className="xeleron-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPassword.displayName = displayName;
ForgotPassword.propTypes = propTypes;

export default ForgotPassword;
