import React, { useEffect, useState } from 'react';
import styled from '../../css/dashboard.module.css';
import Header from '../../../../common/Header';
import DropDown from "../../../../common/DropDown";
import Skeleton from "@mui/material/Skeleton";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import "../../css/dashboard.css";
import UserList from './userList';
import Constants from "../../../../config";
import MainTable from "../../../../common/TableOther";
import BasicModal from "../../../../common/Modal";
import PrimaryButton from "../../../../common/PrimaryButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from '@mui/material/InputLabel';
// import AnalysisGraph from './analysisGraph';
import ToastNotify from "../../../../common/Toast/ToastNotify";
import moment from 'moment';
import Loader from "../../../../common/loader/index";
import styles from "../../../CreateSurvey/css/addQuestion.module.css";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import arrowRight from "../../../../assets/arrowRight.png";
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';
//import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import MainTableSmall from '../../../../common/TableSmall';


const propTypes = {
    errorMessage: PropTypes.any,
    isLoading: PropTypes.bool,
    status: PropTypes.string,
    dashboardDetails: PropTypes.array,
    isDesktopLayout: PropTypes.bool,
    isMobileLayout: PropTypes.bool,
    getDashboardDetails: PropTypes.func,
    getQuarterList: PropTypes.func,
    quarterList: PropTypes.array,
    getTeamDetails: PropTypes.func,
    teamDetails: PropTypes.array,
    userDetail: PropTypes.any,
    getSelfieGraphDetails: PropTypes.func,
    selfieGraphDetails: PropTypes.any,
    postExtendDate: PropTypes.func,
    getMeDetails: PropTypes.func,
    meDetails: PropTypes.any,
    goalDetails: PropTypes.array,
    getGoalDetails: PropTypes.func,
    setSelectedQuarter: PropTypes.func,
    selectedQuarter: PropTypes.string,
    excludeUnexcludePeer: PropTypes.func,
    publicGoalUserList: PropTypes.array,
    setSelectedYear: PropTypes.func,
    selectedYear: PropTypes.string
};

//const yearOptions = ['2022', '2023', '2024', '2025'];

const typeOptions = [
    { type: "shortText", text: "Short Text" },
    { type: "scale", text: "Ratings" }
];
const columns = [
    {
        id: "name",
        type: "myTeam",
        identifier: 'surveyId',
        label: Constants.PEER_NAME,
        minWidth: 220
    },
    {
        id: "surveyId",
        label: Constants.GOALS,
        align: "center",
        minWidth: 100,
    },
    {
        id: "filledSelfCount",
        type: "totalSelfCount",
        label: Constants.SELFIE,
        minWidth: 100,
    },
    {
        id: "selfieScheduleDate",
        label: Constants.NEXT_SCHEDULED_SELFIE,
        minWidth: 140,
    },
    {
        id: "reviewFilledCount",
        type: "totalReviewCount",
        label: Constants.REVIEWS,
        minWidth: 100,
    },
    {
        id: "peerScheduleDate",
        label: Constants.NEXT_SCHEDULED_REVIEW,
        minWidth: 140,
    },
    {
        id: "reviewMinutesStatus",
        label: Constants.MINUTES_FINALIZED,
        align: "center",
        minWidth: 100,
    },
    {
        id: "Action",
        label: Constants.ACTION,
        minWidth: 170,
        align: "center",
        type: "reports",
        buttonText2: Constants.VIEW_GOALS,
    }
];

function SkeletonBox() {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%">
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: '57%' }} />
            </Skeleton>
        </div>
    );
}

const ModelContent = ({
    handleClose,
    checkedList,
    quarterId,
    postExtendDate,
    rows
}) => {
    const [date, setDate] = useState(new Date());
    const [type, setType] = useState('Selfie');
    const handleDateChange = (date) => {
        if (date === null) {
            setDate('');
        } else {
            setDate(date);
        }
    };

    const handleChangeRadio = (e) => {
        setType(e.target.value);
    }

    const onSave = (e) => {
        e.preventDefault();
        let newList = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isExcluded === 0 && checkedList.includes(rows[i].surveyId) && rows[i].surveyId !== '') {
                newList.push(rows[i].surveyId);
            }
        }
        newList = newList.filter(elem => elem !== '');
        let data = {
            surveyIds: newList,
            quarterId: quarterId,
            date: moment(date).format("YYYY-MM-DD"),
            type: type
        };
        postExtendDate(data);
    };
    return (
        <div className="extend-date-container">
            <div className='flex mobile-flex'>
                <div>
                    <InputLabel className="date-label-custom other">{"Select a date to extend"}</InputLabel>
                    <DatePicker
                        name={"scheduleDate"}
                        id={"scheduleDate"}
                        placeholderText={"Select a date"}
                        autoComplete={"off"}
                        onChange={handleDateChange}
                        selected={date}
                        minDate={new Date()}
                        shouldCloseOnSelect={true}
                        isClearable={false}
                        className={"new-layout-input"}
                        dateFormat={"MMM d, yyyy"}
                        popperPlacement="right"
                    />
                </div>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={type}
                        onChange={handleChangeRadio}
                    >
                        <FormControlLabel value="Selfie" control={<Radio />} label="Selfie" />
                        <FormControlLabel value="Review" control={<Radio />} label="Review" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="bottom-buttons" style={{ marginTop: '50px' }}>
                <div className="cardModalButton">
                    <PrimaryButton
                        text={Constants.CANCEL}
                        onClick={handleClose}
                        className="cancelButton"
                    />
                    <PrimaryButton
                        text={Constants.SAVE}
                        onClick={onSave}
                        className="saveButton"
                    />
                </div>
            </div>
        </div>
    );
};

const ModelContentGoal = ({ goalDetails, handleClose, isGoalLoading, goalName }) => {
    return (
        <div>
            <div className='flex-end'>
                <CloseIcon onClick={handleClose} style={{ color: '#f56102', fontSize: '32px', cursor: 'pointer' }} title="Close" />
            </div>
            <p style={{ fontSize: '20px', fontWeight: 600 }}>{goalName}</p>
            {
                isGoalLoading ?
                    <Loader isLoading={true} /> :
                    goalDetails.map((item, index) =>
                        <div key={index} style={{ marginBottom: '20px' }}>
                            <Typography
                                className={styles.previewQuestionTexts}
                                sx={{ color: "text.secondary" }}
                            >
                                {/* Q{index + 1}.
                    <img
                      src={arrowRight}
                      alt="arrowRight"
                      style={{
                        margin: "0 20px 0 8px",
                      }}
                    />
                    {item?.question} */}
                                <div>
                                    <div className={styles.questionFieldPreview}>
                                        <div className={styles.surveyDetailSpan}>
                                            Objective {index + 1}
                                        </div>
                                        <div>
                                            <img
                                                src={arrowRight}
                                                alt="arrowRight"
                                                className={styles.surveyDetailimg}
                                            />
                                        </div>
                                        <div className={styles.surveyDetailQuestion} style={{ textAlign: 'left' }}>
                                            {item?.question}
                                        </div>
                                    </div>
                                    <div className={styles.moreSummary}>
                                        {/*<div className={styles.typeSummary}>
                          <span style={{ color: '#50687d' }}>Type:</span> {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                        </div>*/}
                                        <div className={styles.typeSummary}>
                                            {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                                        </div>
                                        <div className={styles.catSummary}>
                                            {item?.category_name && item?.category_name !== '' ? item?.category_name : '-'}
                                            {
                                                item?.subcategory_name && item?.subcategory_name !== '' ?
                                                    <React.Fragment>
                                                        <ArrowRightAltIcon style={{ color: '#fff', margin: '0 5px', fontSize: '16px' }} />
                                                        {item?.subcategory_name}
                                                    </React.Fragment> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                            {
                                item?.keyResults && item?.keyResults?.length ?
                                    <div className={styles.explaination} style={{ margin: '5px 0 0 78px' }}>
                                        <div className={styles.surveyDetailSpanSmall}>
                                            {Constants.KEY_RESULTS} :
                                        </div>
                                        <div className={styles.surveyDetailExplain + " genericTextArea"} style={{ textAlign: 'left' }}>
                                            <div>
                                                {item?.keyResults.map(i => (
                                                    <div key={item.text} className={styles.surveyDetailExplain + " genericTextArea"}>
                                                        <p key={i.text}>{parse(i.text)}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </div>
                    )
            }
        </div>
    );
};

const ModelContentReviews = ({ handleClose, goalReviews }) => {
    return (
        <div>
            <div className='flex-end'>
                <CloseIcon onClick={handleClose} style={{ color: '#f56102', fontSize: '32px', cursor: 'pointer' }} title="Close" />
            </div>
            <div className='flex'>
                <ul className={styled.listReviews}>
                    <li className={styled.listHead}>
                        <div className={styled.listHeadCol1} style={{ textAlign: 'left' }}>
                            Name
                        </div>
                        <div className={styled.listHeadCol2}>
                            Status
                        </div>
                    </li>
                    {goalReviews?.length ? goalReviews?.map((user, i) => <li key={i} className={styled.listRow}>
                        <div className={styled.listHeadRow1} style={{ textAlign: 'left' }}>
                            {user.name}
                        </div>
                        <div className={styled.listHeadRow2}>
                            {user.filled ? <div style={{ display: 'flex' }}><CheckIcon className={'icon-inside green'} /><span className={styled.textStatus}>Yes</span></div> : <div style={{ display: 'flex' }}><CloseIcon className={'icon-inside red'} /><span className={styled.textStatus}>No</span></div>}
                        </div>
                    </li>
                    ) :
                        <li>
                            No User found
                        </li>}
                </ul>
            </div>
        </div>
    )

}

const Dashboard = (props) => {
    const [loader, setLoader] = useState(false);
    //const [selectedYear, setSelectedYear] = useState(props.selectedYear);
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedComposition, setSelectedComposition] = useState('');
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [showUserList, setShowUserList] = useState(false);
    const [userData, setUserData] = useState([]);
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [viewType, setViewType] = useState(1);
    const [open, setOpen] = useState(false);
    const [checkedList, setCheckedList] = React.useState([]);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [viewGoal, setViewGoal] = useState(false);
    const [isGoalLoading, setGoalLoading] = useState(false);
    const [goalName, setGoalName] = useState('');
    const [scrollPosition, setScrollPosition] = useState(0);
    const [excludeUserId, setExcludeUserId] = useState([]);
    const [includeUserId, setIncludeUserId] = useState([]);
    const [openPopup, setOpenPopUp] = useState(false);
    const [isLoadingPeers, setIsLoadingPeers] = useState(false);
    const [ExcludedPeerSuccessMsg, setExcludedPeerSuccessMsg] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [viewReviews, setViewReviews] = useState(false);
    const [reviewUserList, setReviewUserList] = useState([]);
    const [compositionsOwned, setCompositionsOwned] = useState([]);
    const [selectedCompositionId, setSelectedCompositionId] = useState('');
    const [compositionList, setCompositionList] = useState([]);
    const [publicGoalUserList, setPublicGoalUserList] = useState([]);
    const [errorMsgEmptyQuarter, setErrorMsgEmptyQuarter] = useState(false);

    //const navigate = useNavigate();
    const onScroll = () => {
        setScrollPosition(window.scrollY);
    }

    useEffect(() => {
        props.getQuarterList({ type: 'all' });
        if (props.selectedQuarter && props.selectedQuarter !== '') {
            setSelectedQuarter(props.selectedQuarter);
            props.getDashboardDetails({ quarterId: props.selectedQuarter });
            props.getTeamDetails(props.selectedQuarter);
            props.getMeDetails(props.selectedQuarter)
            props.getSelfieGraphDetails(props.selectedQuarter);
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        setSelectedQuarter(props.selectedQuarter);
    }, [props.selectedQuarter]);

    useEffect(() => {
        switch (props?.status) {
            case "GET_QUARTER_LIST_SUCCESS":
                // props.getDashboardDetails({ quarterId: props.quarterList?.length ? props.quarterList[0]?._id : '' });
                if (!props.selectedQuarter || props.selectedQuarter === '') {
                    if (!props.quarterList?.length) {
                        setErrorMsgEmptyQuarter(true);
                    }
                }
                break;
            case "GET_DASHBOARD_DETAILS_SUCCESS":
                handleCompositionOwners();
                setSelectedComposition(props.dashboardDetails?.length ? props.dashboardDetails[0].id : '');
                if (breadcrumbList.length && currentIndex > 1) {
                    let temp = JSON.parse(JSON.stringify(breadcrumbList));
                    temp.map(ele => {
                        ele.link = true;
                    })
                    temp.push({ id: props.dashboardDetails[0].id, name: props.dashboardDetails[0].name, link: false });
                    setBreadcrumbList(temp);
                } else {
                    setBreadcrumbList([{ id: props.dashboardDetails[0].id, name: props.dashboardDetails[0].name, link: false }]);
                }
                setPublicGoalUserList(props.publicGoalUserList);
                break;
            case "EXTEND_DATE_SUCCESS":
                setSuccessMsg(true);
                setTimeout(() => setSuccessMsg(false), 2000);
                setOpen(false);
                setCheckedList([]);
                setIsDisabled(true);
                setExcludeUserId([]);
                setIncludeUserId([])
                break;
            case "EXTEND_DATE_ERROR":
                setErrorMsg(true);
                setTimeout(() => setErrorMsg(false), 2000);
                break;
            case "GET_GOAL_DETAILS":
                setGoalLoading(true);
                break;
            case "GET_GOAL_DETAILS_SUCCESS":
                setGoalLoading(false);
                break;
            case "GET_GOAL_DETAILS_ERROR":
                setGoalLoading(false);
                break;
            case "EXCLUDE_UNEXCLUDE_PEER_SUCCESS":
                setExcludedPeerSuccessMsg(true);
                setTimeout(() => setExcludedPeerSuccessMsg(false), 2000);
                props.getTeamDetails(props.selectedQuarter);
                setOpenPopUp(false);
                setIsLoadingPeers(false);
                setExcludeUserId([]);
                setIncludeUserId([]);
                setCheckedList([]);
                setIsDisabled(true);
                break;
            case "EXCLUDE_UNEXCLUDE_PEER_ERROR":
                setErrorMsg(true);
                setTimeout(() => setErrorMsg(false), 2000);
                break;
            case "GET_USER_DETAILS_SUCCESS":
                handleInitialCompositionOwners();
        }
    }, [props?.status]);

    useEffect(() => {
        setLoader(props.isLoading)
    }, [props.isLoading])

    /*const handleChangeYear = (value) => {
        setSelectedYear(value);
        props.setSelectedYear(value);
        props.setSelectedQuarter('');
        props.getQuarterList({ type: 'all', year: value });
        setErrorMsgEmptyQuarter(false);
    }*/

    const handleChangeQuarter = (value) => {
        setBreadcrumbList([]);
        props.getDashboardDetails({ quarterId: value });
        props.getTeamDetails(value);
        props.getMeDetails(value);
        props.getSelfieGraphDetails(value);
        setSelectedQuarter(value);
        setCheckedList([]);
        setIsDisabled(true);
        setExcludeUserId([]);
        setIncludeUserId([]);
        props.setSelectedQuarter(value);
        setSelectedCompositionId('');
        setCompositionsOwned([]);
        setErrorMsgEmptyQuarter(false);
        if (props.quarterList.find(ele => ele._id === value)) {
            props.setSelectedYear(new Date(props.quarterList.find(ele => ele._id === value).startDate)?.getFullYear());
        }
    }

    const getColcor = (data) => {
        return data >= 80 ? styled.rowParaGreenCheck : data >= 60 && data < 80 ? styled.rowParaOrangeCheck : styled.rowParaRedCheck;
    }

    const viewGoals = (item, type) => {
        if (type === 'me') {
            window.open(`/add-goals?id=${item?.surveyId}`);
        } else {
            setViewGoal(true);
            setGoalName(item.name);
            props.getGoalDetails(item.surveyId, {});
        }
    }

    const viewPublicGoals = (item, type) => {
        if (type === 'me') {
            window.open(`/add-goals?id=${item?.surveyId}`);
        } else {
            setViewGoal(true);
            setGoalName(item.name);
            props.getGoalDetails('123', { email: item.email, quarterId: selectedQuarter });
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseGoal = () => {
        setViewGoal(false);
        setGoalName('');
    }

    const handleExcludePeers = () => {
        let list = [];
        for (let i = 0; i < includeUserId.length; i++) {
            let data = {
                userId: includeUserId[i],
                excluded: 1
            }
            list.push(data);
        }
        props.excludeUnexcludePeer(selectedQuarter, { "list": list });
    }

    const handleIncludePeers = () => {
        let list = [];
        for (let i = 0; i < excludeUserId.length; i++) {
            let data = {
                userId: excludeUserId[i],
                excluded: 0
            }
            list.push(data);
        }
        props.excludeUnexcludePeer(selectedQuarter, { "list": list });
    }


    const handleReviewClick = (item) => {
        if (item.reviewUserList.length) {
            setViewReviews(true);
            setReviewUserList(item.reviewUserList);
        }
    }

    const handleCloseReviews = () => {
        setViewReviews(false);
        setReviewUserList([]);
    }

    const handleCompositionOwners = () => {
        let curr = compositionsOwned;
        let data = props.dashboardDetails;
        for (let i = 0; i < data.length; i++) {
            if (!curr.includes(data[i].id) && compositionList.includes(data[i].id)) {
                curr.push(data[i].id);
            }
            let children = data[i].children;
            if (children !== null) {
                for (let j = 0; j < children.length; j++) {
                    if (!curr.includes(children[j].id) && (compositionList.includes(children[j].id) || compositionList.includes(data[i].id))) {
                        curr.push(children[j].id);
                    }
                }
            }
        }
        setCompositionsOwned(curr);
    }
    const handleInitialCompositionOwners = () => {
        let data = props.userDetail.compositionList;
        if (!compositionList.length && data) {
            let temp = [];
            for (let i = 0; i < data.length; i++) {
                temp.push(data[i]._id);
            }
            setCompositionList(temp);
            setCompositionsOwned(temp);
        }
    }

    return (
        <>
            <Header active="Dashboard" />
            <div className={styled.dashBoardPage + ' bg-color-login-other'}>
                {
                    showUserList ?
                        <UserList
                            users={userData}
                            goBack={() => {
                                setShowUserList(false);
                                setUserData([]);
                                setType('');
                                setName('');
                                setSelectedCompositionId('');
                            }}
                            type={type}
                            name={name}
                            publicGoalUserList={publicGoalUserList}
                            buttonClick={(item, type) => viewPublicGoals(item, type)}
                            meDetails={props?.meDetails}
                            teamDetails={props?.teamDetails}
                            isDesktopLayout={props?.isDesktopLayout}
                            isMobileLayout={props?.isMobileLayout}
                            selectedCompositionId={selectedCompositionId}
                            compositionsOwned={compositionsOwned}
                            excludeUnexcludePeer={props?.excludeUnexcludePeer}
                            selectedQuarter={selectedQuarter}
                            getDashboardDetails={props?.getDashboardDetails}
                            status={props?.status}
                        /> : null
                }
                <div className={styled.dashBoardContainer} style={{ display: showUserList ? 'none' : 'block' }} id="waada-component">
                    <div className='flex-space' id="dashboard-header" style={{ marginBottom: breadcrumbList.length > 1 ? '30px' : '40px', width: '90%', background: scrollPosition > 50 ? '#f9f9f9' : 'none', height: '50px', zIndex: 9 }}>
                        <div className="flex-space" style={{ width: props.userDetail?.isLead ? '20%' : '10%' }} id="dashboardTabs">
                            <div className={`dash-tab${viewType === 1 ? ' active' : ''}`} onClick={() => {
                                setViewType(1);
                                window.scrollTo({ top: document.getElementById("waada-component")?.offsetTop - 100, behavior: 'smooth' });
                            }}>
                                <div>Waada</div>
                            </div>
                            {
                                props.userDetail?.isLead ?
                                    <div className={`dash-tab${viewType === 2 ? ' active' : ''}`} onClick={() => {
                                        setViewType(2);
                                        window.scrollTo({ top: document.getElementById("my-team")?.offsetTop - 150, behavior: 'smooth' });
                                    }}>
                                        <div>My team</div>
                                    </div> : null
                            }
                            <div className={`dash-tab${viewType === 3 ? ' active' : ''}`} onClick={() => {
                                setViewType(3);
                                window.scrollTo({ top: document.getElementById("me-area")?.offsetTop - 100, behavior: 'smooth' });
                            }}>
                                <div>Me</div>
                            </div>
                        </div>
                        <div className='flex' style={{ marginTop: '15px' }}>
                            {/*<DropDown
                                options={yearOptions}
                                style={{ color: "#333333" }}
                                updateValue={handleChangeYear}
                                placeholder="Select year"
                                value={selectedYear}
                                label={'Select year'}
                                className="dashboard-dropdown"
                            //hideAddBtn={true}
                        />*/}
                            <DropDown
                                options={props.quarterList?.length ? props.quarterList : []}
                                style={{ color: "#333333" }}
                                updateValue={handleChangeQuarter}
                                placeholder="Select quarter"
                                value={selectedQuarter}
                                label={'Select quarter'}
                                className="dashboard-dropdown quarter"
                            />
                        </div>
                    </div>
                    {loader ?
                        <div style={{ margin: '60px auto 0', width: '100%' }}>
                            <Skeleton variant="text" height={75} width={500} />
                            <Grid container spacing={6}>
                                <Grid item xs>
                                    <SkeletonBox />
                                </Grid>
                                <Grid item xs>
                                    <SkeletonBox />
                                </Grid>
                                <Grid item xs>
                                    <SkeletonBox />
                                </Grid>
                                <Grid item xs>
                                    <SkeletonBox />
                                </Grid>
                            </Grid>
                            <Skeleton variant="text" height={75} />
                            <Skeleton variant="text" height={75} />
                            <Skeleton variant="text" height={75} />
                            <Skeleton variant="text" height={75} />
                        </div>
                        :
                        breadcrumbList.length > 1 ?
                            <div className={styled.breadcrumbs} style={{ marginTop: '80px' }}>
                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                    {
                                        breadcrumbList.map((obj, i) =>
                                            obj.link ?
                                                <Link
                                                    underline="hover"
                                                    color="inherit"
                                                    key={i}
                                                    onClick={() => {
                                                        let temp = JSON.parse(JSON.stringify(breadcrumbList));
                                                        temp.splice(i, breadcrumbList.length);
                                                        setBreadcrumbList(temp);
                                                        props.getDashboardDetails({ quarterId: selectedQuarter, compositionId: i == 0 ? '' : obj.id });
                                                        setCurrentIndex(i + 1);
                                                    }}
                                                >
                                                    {obj.name}
                                                </Link> :
                                                <Typography color="text.primary" key={i}>{obj.name}</Typography>

                                        )
                                    }
                                </Breadcrumbs>
                            </div> : null
                    }
                    {
                        !loader ?
                            <React.Fragment>
                                <div className={styled.topRow} style={{ marginBottom: '20px', marginTop: breadcrumbList.length > 1 ? '0' : '90px' }}>
                                    {
                                        props.dashboardDetails?.map((obj, i) =>
                                            <div
                                                className={styled.colCard1}
                                                key={i}
                                                style={{ background: selectedComposition === obj.id ? '#f4f8fb' : '#fff' }}
                                                onClick={() => {
                                                    setSelectedComposition(obj.id);
                                                    breadcrumbList.length === 1 ? setBreadcrumbList([{ id: obj.id, name: obj.name, link: false }]) : null
                                                }}
                                            >
                                                <div className={styled.heading}>{obj.name}</div>
                                                {
                                                    <div className='flex'>
                                                        <div className='stats-data'>Under Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                        <div className='stats-data'>Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                        <div className='stats-data'>Over Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                    </div>
                                                }
                                                <div className='flex-space' id='top-card' style={{ marginTop: "3%" }}>
                                                    <div className={styled.hoverWrapper} onClick={() => { setShowUserList(true); setUserData(obj.userList); setType('Objectives'); setName(obj.name); setSelectedCompositionId(obj.id); }}>
                                                        <div className={styled.rowHeadCheck} style={{ textAlign: 'left' }}>Objectives</div>
                                                        <div className={getColcor(obj.goalPercentage)}>{obj.goalPercentage.toFixed(1)}%</div>
                                                    </div>
                                                    <div className={obj.selfiePercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.selfiePercentage > 0) { setShowUserList(true); setUserData(obj.selfieList); setType('Selfie'); setName(obj.name) } }}>
                                                        <div className={styled.rowHeadCheck}>Selfie Completed</div>
                                                        <div className={getColcor(obj.selfiePercentage)}>{obj.selfiePercentage.toFixed(1)}%</div>
                                                    </div>
                                                    <div className={obj.reviewPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewPercentage > 0) { setShowUserList(true); setUserData(obj.reviewList); setType('Review'); setName(obj.name) } }}>
                                                        <div className={styled.rowHeadCheck}>Assessment Completed</div>
                                                        <div className={getColcor(obj.reviewPercentage)}>{obj.reviewPercentage ? obj.reviewPercentage.toFixed(1) : 0}%</div>
                                                    </div>
                                                    <div className={obj.reviewMinutesPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewMinutesPercentage > 0) { setShowUserList(true); setUserData(obj.reviewMinutesList); setType('Finalized Reviews'); setName(obj.name); setSelectedCompositionId(obj.id); } }}>
                                                        <div className={styled.rowHeadCheck}>Finalized Reviews</div>
                                                        <div className={getColcor(obj.reviewMinutesPercentage)}>{obj.reviewMinutesPercentage ? obj.reviewMinutesPercentage.toFixed(1) : 0}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    props.dashboardDetails.find(ele => ele.id === selectedComposition)?.children?.length || props.dashboardDetails.find(ele => ele.id === selectedComposition)?.team?.length ?
                                        <div className={styled.topRowWrap} style={{ marginBottom: '40px' }}>
                                            {
                                                props.dashboardDetails.find(ele => ele.id === selectedComposition)?.children?.length ? props.dashboardDetails.find(ele => ele.id === selectedComposition)?.children?.map((obj, i) =>
                                                    <div className={obj?.hasChildren || obj?.hasChildren == 0 && obj?.team?.length ? styled.colCardWrapHover : styled.colCardWrap}
                                                        style={{ cursor: obj?.hasChildren || obj?.hasChildren == 0 && obj?.team?.length ? 'pointer' : 'default', background: selectedComposition === obj?.id ? '#f4f8fb' : '#fff', marginLeft: i === 0 || (i) % 3 === 0 ? 0 : '10px' }}
                                                        onClick={(e) => {
                                                            if (e.target.className.indexOf('Check') === -1) {
                                                                obj?.hasChildren || obj?.hasChildren == 0 && obj?.team?.length ? props.getDashboardDetails({ quarterId: selectedQuarter, compositionId: obj.id }) : null;
                                                                obj?.hasChildren || obj?.hasChildren == 0 && obj?.team?.length ? setCurrentIndex(currentIndex + 1) : null
                                                            }
                                                        }}
                                                        key={i}
                                                    >
                                                        <div className={styled.heading}>{obj.name}</div>
                                                        {
                                                            <div className='flex'>
                                                                <div className='stats-data'>Under Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                                <div className='stats-data'>Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                                <div className='stats-data'>Over Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                            </div>
                                                        }
                                                        <div className='flex-space' style={{ marginTop: "3%" }}>
                                                            <div className={obj.goalPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.goalPercentage > 0) { setShowUserList(true); setUserData(obj.userList); setType('Objectives'); setName(obj.name); setSelectedCompositionId(obj.id); } }}>
                                                                <div className={styled.rowHeadCheck} style={{ textAlign: 'left' }}>Objectives</div>
                                                                <div className={getColcor(obj.goalPercentage)}>{obj.goalPercentage ? obj.goalPercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </div>
                                                            <div className={obj.selfiePercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.selfiePercentage > 0) { setShowUserList(true); setUserData(obj.selfieList); setType('Selfie'); setName(obj.name) } }}>
                                                                <div className={styled.rowHeadCheck}>Selfie Completed</div>
                                                                <div className={getColcor(obj.selfiePercentage)}>{obj.selfiePercentage ? obj.selfiePercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </div>
                                                            <div className={obj.reviewPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewPercentage > 0) { setShowUserList(true); setUserData(obj.reviewList); setType('Review'); setName(obj.name) } }}>
                                                                <div className={styled.rowHeadCheck}>Assessment Completed</div>
                                                                <div className={getColcor(obj.reviewPercentage)}>{obj.reviewPercentage ? obj.reviewPercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </  div>
                                                            <div className={obj.reviewMinutesPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewMinutesPercentage > 0) { setShowUserList(true); setUserData(obj.reviewMinutesList); setType('Finalized Reviews'); setName(obj.name); setSelectedCompositionId(obj.id); } }}>
                                                                <div className={styled.rowHeadCheck}>Finalized Reviews</div>
                                                                <div className={getColcor(obj.reviewMinutesPercentage)}>{obj.reviewMinutesPercentage ? obj.reviewMinutesPercentage.toFixed(1) : 0}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                props.dashboardDetails.find(ele => ele.id === selectedComposition)?.team?.length ? props.dashboardDetails.find(ele => ele.id === selectedComposition)?.team?.map((obj, i) =>
                                                    <div className={obj.hasChildren ? styled.colCardWrapHover : styled.colCardWrap}
                                                        style={{ cursor: obj.hasChildren ? 'pointer' : 'default', background: selectedComposition === obj.id ? '#f4f8fb' : '#fff', marginLeft: '10px' }}
                                                        key={i}
                                                    >
                                                        <div className={styled.heading}>{obj.name} <span className={styled.mapCardRowspanOther}>TEAM</span></div>
                                                        {
                                                            <div className='flex'>
                                                                <div className='stats-data'>Under Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Under Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                                <div className='stats-data'>Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                                <div className='stats-data'>Over Performing ({obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length > 0 ? `${((obj?.reviewMinutesList?.filter(ele => ele.performanceReviewStatus === 'Over Performing').length / obj?.reviewMinutesList?.length) * 100).toFixed(1).replace(/[.,]0+$/, "")} %` : "0%"})</div>
                                                            </div>
                                                        }
                                                        <div className='flex-space' style={{ marginTop: "3%" }}>
                                                            <div className={obj.goalPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.goalPercentage > 0) { setShowUserList(true); setUserData(obj.userList); setType('Objectives'); setName(obj.name); setSelectedCompositionId(obj.id); } }}>
                                                                <div className={styled.rowHeadCheck} style={{ textAlign: 'left' }}>Objectives</div>
                                                                <div className={getColcor(obj.goalPercentage)}>{obj.goalPercentage ? obj.goalPercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </div>
                                                            <div className={obj.selfiePercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.selfiePercentage > 0) { setShowUserList(true); setUserData(obj.selfieList); setType('Selfie'); setName(obj.name) } }}>
                                                                <div className={styled.rowHeadCheck}>Selfie Completed</div>
                                                                <div className={getColcor(obj.selfiePercentage)}>{obj.selfiePercentage ? obj.selfiePercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </div>
                                                            <div className={obj.reviewPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewPercentage > 0) { setShowUserList(true); setUserData(obj.reviewList); setType('Review'); setName(obj.name) } }}>
                                                                <div className={styled.rowHeadCheck}>Assessment Completed</div>
                                                                <div className={getColcor(obj.reviewPercentage)}>{obj.reviewPercentage ? obj.reviewPercentage.toFixed(1).replace(/[.,]0+$/, "") : 0}%</div>
                                                            </  div>
                                                            <div className={obj.reviewMinutesPercentage > 0 ? styled.hoverWrapper : styled.noHover} onClick={() => { if (obj.reviewMinutesPercentage > 0) { setShowUserList(true); setUserData(obj.reviewMinutesList); setType('Finalized Reviews'); setName(obj.name); setSelectedCompositionId(obj.id); } }}>
                                                                <div className={styled.rowHeadCheck}>Finalized Reviews</div>
                                                                <div className={getColcor(obj.reviewMinutesPercentage)}>{obj.reviewMinutesPercentage ? obj.reviewMinutesPercentage.toFixed(1) : 0}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div> : null
                                }
                            </React.Fragment> : null
                    }
                    {
                        !loader && props.userDetail?.isLead ?
                            <div id="my-team">
                                <p className={styled.myTeamHeading}>{Constants.MY_TEAM}</p>
                                {props.isDesktopLayout ?
                                    <div className='myTeamBox'>
                                        <MainTable
                                            columns={columns}
                                            rows={props?.teamDetails}
                                            buttonClick={(item) => viewGoals(item, 'team')}
                                            isPagination={false}
                                            openModal={(flag) => setOpen(flag)}
                                            setCheckedList={(data) => setCheckedList(data)}
                                            checkedList={checkedList}
                                            selectedYear={props.selectedYear}
                                            selectedQuarter={selectedQuarter}
                                            userDetail={props.userDetail}
                                            reviewsClick={(item) => handleReviewClick(item)}
                                            // handleExcludeUnExcludePeer={handleExcludeUnExcludePeer}
                                            handleExcludePeers={handleExcludePeers}
                                            handleIncludePeers={handleIncludePeers}
                                            excludeUserId={excludeUserId}
                                            includeUserId={includeUserId}
                                            setExcludeUserId={(data) => setExcludeUserId(data)}
                                            setIncludeUserId={(data) => setIncludeUserId(data)}
                                            openPopup={openPopup}
                                            setOpenPopUp={setOpenPopUp}
                                            isLoadingPeers={isLoadingPeers}
                                            setIsLoadingPeers={setIsLoadingPeers}
                                            isDisabled={isDisabled}
                                            setIsDisabled={setIsDisabled}
                                        />
                                    </div> :
                                    <MainTableSmall
                                        columns={columns}
                                        rows={props?.teamDetails}
                                        buttonClick={(item) => viewGoals(item, 'team')}
                                        isPagination={false}
                                        openModal={(flag) => setOpen(flag)}
                                        setCheckedList={(data) => setCheckedList(data)}
                                        checkedList={checkedList}
                                        selectedYear={props.selectedYear}
                                        selectedQuarter={selectedQuarter}
                                        userDetail={props.userDetail}
                                        reviewsClick={(item) => handleReviewClick(item)}
                                        // handleExcludeUnExcludePeer={handleExcludeUnExcludePeer}
                                        handleExcludePeers={handleExcludePeers}
                                        handleIncludePeers={handleIncludePeers}
                                        excludeUserId={excludeUserId}
                                        includeUserId={includeUserId}
                                        setExcludeUserId={(data) => setExcludeUserId(data)}
                                        setIncludeUserId={(data) => setIncludeUserId(data)}
                                        openPopup={openPopup}
                                        setOpenPopUp={setOpenPopUp}
                                        isLoadingPeers={isLoadingPeers}
                                        setIsLoadingPeers={setIsLoadingPeers}
                                        isDisabled={isDisabled}
                                        setIsDisabled={setIsDisabled}
                                    />}
                            </div> : null
                    }
                    {
                        !loader && !errorMsgEmptyQuarter ?
                            <div id="me-area">
                                <p className={styled.myTeamHeading}>{Constants.ME}</p>
                                {props.isDesktopLayout ?
                                    <div className='myTeamBox'>
                                        <MainTable
                                            columns={columns}
                                            rows={props?.meDetails}
                                            buttonClick={(item) => viewGoals(item, 'me')}
                                            isPagination={false}
                                            withoutCheckBox={true}
                                            reviewsClick={(item) => handleReviewClick(item)}
                                            meSection={true}
                                        />
                                    </div> : <MainTableSmall
                                        columns={columns}
                                        rows={props?.meDetails}
                                        buttonClick={(item) => viewGoals(item, 'me')}
                                        isPagination={false}
                                        withoutCheckBox={true}
                                        reviewsClick={(item) => handleReviewClick(item)}
                                        meSection={true}
                                    />}
                                {/* <AnalysisGraph
                                    graphData={props.selfieGraphDetails}
                                /> */}
                            </div> : null
                    }
                </div>
                {open ? (
                    <BasicModal
                        open={open}
                        handleClose={handleClose}
                        heading={Constants.EXTEND_DATE}
                        className={'extend-date-modal'}
                        content={
                            <ModelContent
                                handleClose={handleClose}
                                checkedList={checkedList}
                                quarterId={selectedQuarter}
                                postExtendDate={props.postExtendDate}
                                rows={props.teamDetails}
                            />
                        }
                    />
                ) : (
                    <></>
                )}
                {
                    viewGoal ?
                        <BasicModal
                            open={viewGoal}
                            handleClose={handleCloseGoal}
                            className={'view-goal-modal'}
                            content={
                                <ModelContentGoal
                                    handleClose={handleCloseGoal}
                                    goalDetails={JSON.parse(JSON.stringify(props.goalDetails))}
                                    isGoalLoading={isGoalLoading}
                                    goalName={goalName}
                                />
                            }
                        /> : null
                }
                {
                    viewReviews ?
                        <BasicModal
                            open={viewReviews}
                            handleClose={handleCloseReviews}
                            className={'view-review-modal'}
                            content={
                                <ModelContentReviews
                                    handleClose={handleCloseReviews}
                                    goalReviews={reviewUserList}
                                />
                            }
                        /> : null
                }
                {successMsg ? (
                    <ToastNotify
                        data={"Dates has been extended successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {errorMsg ? (
                    <ToastNotify
                        data={props.errorMessage && props.errorMessage !== '' ? props.errorMessage : 'Something went wrong!'}
                        openAlert={true}
                        type={"error"}
                    />
                ) : (
                    ""
                )}
                {errorMsgEmptyQuarter ? (
                    <ToastNotify
                        data={'No Quarter available!'}
                        openAlert={true}
                        type={"error"}
                    />
                ) : (
                    ""
                )}
                {ExcludedPeerSuccessMsg ? (
                    <ToastNotify
                        data={"Status updated successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {errorMsg ? (
                    <ToastNotify
                        data={props.errorMessage && props.errorMessage !== '' ? props.errorMessage : 'Something went wrong!'}
                        openAlert={true}
                        type={"error"}
                    />
                ) : (
                    ""
                )}
            </div>
        </>
    )
}

Dashboard.propTypes = propTypes;
export default Dashboard