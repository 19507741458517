import React from "react";
import styles from "../../../css/unauthorised.module.css";
import xeleron from "../../../../../assets/xeleron.svg";
import noAuth from "../../../../../assets/noAuth.svg";
import PrimaryButton from "../../../../../common/PrimaryButton";
import Constants from "../../../../../config";
import { useNavigate } from "react-router-dom";

function Unauthorised() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (location.href.includes('?redirect=true')) {
      setTimeout(() => navigate('/'), 1500);
    }
  }, [])
  return (
    <div className={styles.pageNotFound}>
      <div className={styles.pageNotFoundInner}>
        <div className={styles.notFoundImg1}>
          <img src={xeleron} className='xeleron-logo' alt="xeleron" />
        </div>
        <div className={styles.noAuthImg}>
          <img src={noAuth} alt="notFound" className={styles.image404} />
        </div>
        <div className={styles.oopsText}>{Constants.OOPS}</div>
        <div className={styles.pageNotFoundText}>{Constants.YOU_ARE_NOT_AUTHORIZED}</div>
        <div className={styles.pageNotFoundButton}>
          <div className={styles.notFoundInnerButton}>
            <PrimaryButton text={Constants.GO_TO_HOME} className={styles.goToHomeButton} onClick={() => navigate('/')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unauthorised;
