import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./dropDown.css";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Field from "../Field";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
  addButton: PropTypes.func,
  updateCategory: PropTypes.func,
  label: PropTypes.string,
  addOption: PropTypes.func,
  hideAddBtn: PropTypes.bool,
  className: PropTypes.string,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  removeLocalSubCategory: PropTypes.func
};

function getStyles() {
  return {
    fontWeight: 500,
    fontSize: "12px",
    color: "#1F384C",
    justifyContent: 'space-between'
  };
}

const DropDown = (props) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState("");
  const [isField, setIsField] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const [list, setList] = React.useState([]);
  const [catText, setCatText] = React.useState('');
  const [isEdit, setEdit] = React.useState(false);
  const [editId, setEditId] = React.useState('');

  React.useEffect(() => {
    if (props.placeholder === "Objective Type") {
      var result = props?.options?.map((a) => a.name);
      setList(result);
      if (props?.value !== "") {
        var filterValue = props?.options?.filter(
          (a) => a?._id === props?.value
        );
        if (filterValue?.length > 0) {
          setPersonName(filterValue[0]?.name);
        } else {
          setPersonName("");
        }
      }
    } else if (props.placeholder === "Objective Title") {
      var resultSub = props?.options?.map((a) => a.name);
      setList(resultSub);

      if (props?.value !== "") {
        var filterValueSub = props?.options?.filter(
          (a) => a?.identifier === props?.value
        );
        if (filterValueSub?.length > 0) {
          setPersonName(filterValueSub[0]?.name);
        } else {
          setPersonName("");
        }
      }
    } else if (props.placeholder === "Ikigai") {
      var ikigaiOptions = props?.options?.map((a) => a.benchmarkData);
      setList(ikigaiOptions);

      if (props?.value !== "") {
        var filterValueIkigai = props?.options?.filter(
          (a) => a?.identifier === props?.value
        );
        if (filterValueIkigai?.length > 0) {
          setPersonName(filterValueIkigai[0]?.benchmarkData);
        } else {
          setPersonName("");
        }
      }
    }
    else {
      setList(props?.options);
      setPersonName(props?.value);
    }
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    setCatText('');
  };

  const handleOpen = () => {
    setIsField(false);
    setEdit(false);
    setEditId('');
    setOpen(true);
    setCategory('');
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value);
    props?.updateValue(value);
    setOpen(false);
  };

  const handleOption = (e) => {
    setCategory(e.target.value);
    props?.updateCategory(e.target.value);
  };

  const catUpdateFunc = (val) => {
    if (val.trim().length) {
      if (isEdit) {
        const index = props?.options.findIndex(obj => obj.identifier && obj.identifier !== '' && obj.identifier === editId);
        if (index > -1) {
          props?.editGoalTitle({
            id: editId,
            name: val
          });
          setIsField(false);
          setEdit(false);
          setEditId('');
        } else {
          //setList([...list, val]);
          props.addOption(val.trim(), editId);
          props?.updateCategory(val.trim());
          setIsField(false);
          setEdit(false);
          setEditId('');
          setOpen(false);
          setPersonName(val.trim());
        }
      } else {
        //setList([...list, val]);
        props.addOption(val.trim());
        props?.updateCategory(val.trim());
        setIsField(false);
        setEdit(false);
        setEditId('');
        setOpen(false);
        setPersonName(val.trim());
      }
    }
  }

  return (
    <div className={`dropDownField${props?.className ? ` ${props.className}` : ''}`}>
      <FormControl fullWidth>
        <InputLabel id={`custom-dropdown-${props?.label}`}>{props?.label}</InputLabel>
        <Select
          labelId={`custom-dropdown-${props?.label}`}
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          style={props?.style}
          label={props?.label}
          disabled={props.hideAddBtn}
        >
          {props?.placeholder === "Objective Type" ||
            props?.placeholder === "Objective Title" ? (
            isField || isEdit ? (
              <MenuItem style={{ padding: " 5px" }} onKeyDown={(e) => e.stopPropagation()}>
                <Field
                  placeholder="Add New"
                  type={"text"}
                  name={"AddCategory"}
                  autoFocus={true}
                  value={category}
                  onChange={handleOption}
                  inputProps={{ maxLength: 30 }}
                  onKeyUp={(event) => {
                    if (event.key !== "Tab") {
                      setCatText(event.target.value);
                    }
                    if (event.key === "Enter" && event.target.value.trim() != "") {
                      catUpdateFunc(event.target.value);
                    }
                  }}
                  endadornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add data"
                        onMouseDown={() => catUpdateFunc(catText)}
                        value={category}
                        onClick={() => {
                          catUpdateFunc(catText);
                        }}
                        edge="end"
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </MenuItem>
            ) : (props.hideAddBtn || props?.placeholder === "Select Reviewer" || props?.placeholder === "Objective Type" ? null :
              <MenuItem style={{ padding: "5px" }} onKeyDown={(e) => e.stopPropagation()}>
                <Button
                  className="dropDownButton"
                  onClick={() => setIsField(true)}
                >
                  Add New +
                </Button>
              </MenuItem>
            )
          ) : (
            []
          )}
          {list?.length ? list?.map((name) => (
            <MenuItem
              key={props.placeholder === 'Select Objective Title' ? name.categoryId : props.placeholder === 'Select quarter' || props?.placeholder === "Select Reviewer" ? name._id : props?.placeholder === 'Quarter' ? name.surveyId : name}
              value={props.placeholder === 'Select Objective Title' ? name.categoryId : props.placeholder === 'Select quarter' || props?.placeholder === "Select Reviewer" ? name._id : props?.placeholder === 'Quarter' ? name.surveyId : name}
              style={getStyles(name, personName, theme)}
              onKeyDown={(e) => e.stopPropagation()}
            >
              {props.placeholder === 'Select Objective Title' ? name.categoryName : props.placeholder === 'Select quarter' || props?.placeholder === "Select Reviewer" ? name.title : props?.placeholder === 'Quarter' ? name.name : name}
              {
                props.label === 'Goal Objective' ?
                  <div className="actionBtn">
                    <IconButton aria-label="edit" size="small" onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEdit(true); setCategory(name); setEditId(props.options.find(obj => obj.name === name).identifier ? props.options.find(obj => obj.name === name).identifier : props.options.findIndex(obj => obj.name === name)); }}>
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="delete" size="small" onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const index = props.options.findIndex(obj => obj.name.trim() === name.trim() && obj.identifier && obj.identifier !== '');
                      if (index > -1) {
                        props.deleteGoalTitle(props.options[index].identifier);
                      } else {
                        const index = props.options.findIndex(obj => obj.name.trim() === name.trim());
                        if (index > -1) {
                          props.removeLocalSubCategory(index);
                          setPersonName('');
                        }
                      }
                    }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div> : null
              }
            </MenuItem>
          )) : null}
        </Select>
      </FormControl>
    </div >
  );
};
DropDown.propTypes = propTypes;
export default DropDown;
