import React from "react";
import Button from "@mui/material/Button";
import "./primaryButtton.css";
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

function PrimaryButton(props) {
  return (
    <Button variant="outlined" className={`primary-button ${props?.className}`} onClick={props?.onClick} style={props?.style} disabled={props?.disabled}>
      {props.text}
    </Button>
  );
}

PrimaryButton.propTypes = propTypes;
export default PrimaryButton;
