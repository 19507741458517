import { connect } from "react-redux";
import Reports from "./components/reports";
import { getSurveyReceive, getSurveySent, getSurveyReports, deleteGoal, getQuarterList } from "../../store/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.surveyList.errorMessage,
    isLoading: state.surveyList.isLoadingList,
    status: state.status,
    surveyList: state.surveyList,
    quarterList: state.surveyList.quarter.items,
    isLoadingQuarter: state.surveyList.quarter.isLoading,
    quarterTotalRecords: state.surveyList.quarter.totalRecords,
    userDetail: state.auth.userDetail,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyReceive: (data) => {
      dispatch(getSurveyReceive(data));
    },
    getSurveySent: (data) => {
      dispatch(getSurveySent(data));
    },
    getSurveyReports: (data) => {
      dispatch(getSurveyReports(data));
    },
    deleteGoal: (data) => {
      dispatch(deleteGoal(data));
    },
    getQuarterList: (data) => {
      dispatch(getQuarterList(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
