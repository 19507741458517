import { connect } from 'react-redux';
import Ikigai from './components/ikigai';
import {
  getIkigaiQuestion,
  getIkigaiDetails,
  postIkigaiQuestion,
  editIkigaiQuestion,
  getIkigaiQuestionResponse,
  getAllIkigaiQuestions
} from "../store/actions";
import { getUserDetails } from '../../auth/store/api';

const mapStateToProps = (state, ownProps) => {
  return {
    ikigaiQuestion: state.ikigai.ikigaiQuestion,
    ikigaiDetails: state.ikigai.ikigaiDetails,
    ikigaiResponse: state.ikigai.ikigaiResponse,
    ikigaiAllQuestions: state.ikigai.ikigaiAllQuestions,
    errorMessage: state.ikigai.errorMessage,
    isLoading: state.ikigai.isLoading,
    ikigaiDetailsCount: state.ikigai.ikigaiDetailsCount,
    status: state.status,
    userDetail: state.auth.userDetail,
    ownProps,
    isDesktopLayout: state.browser.screenLayout === "desktop",
    isTabletLayout: state.browser.screenLayout === "tablet",
    isMobileLayout: state.browser.screenLayout === "mobile",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIkigaiQuestion: () => {
      dispatch(getIkigaiQuestion());
    },
    getIkigaiDetails: () => {
      dispatch(getIkigaiDetails());
    },
    postIkigaiQuestion: (data) => {
      dispatch(postIkigaiQuestion(data));
    },
    editIkigaiQuestion: (identifier, data) => {
      dispatch(editIkigaiQuestion(identifier, data));
    },
    getIkigaiQuestionResponse: (data) => {
      dispatch(getIkigaiQuestionResponse(data));
    },
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    getAllIkigaiQuestions: () => {
      dispatch(getAllIkigaiQuestions());
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Ikigai)