import { axiosInstance } from "../../../utils/Http";
import {
   getIkigaiQuestionSuccess,
   getIkigaiQuestionError,
   getIkigaiDetailsSuccess,
   getIkigaiDetailsError,
   postIkigaiQuestionSuccess,
   postIkigaiQuestionError,
   editIkigaiQuestionSuccess,
   editIkigaiQuestionError,
   getIkigaiQuestionResponseSuccess,
   getIkigaiQuestionResponseError,
   getAllIkigaiQuestionsSuccess,
   getAllIkigaiQuestionsError
} from "./actions";
import store from "../../../store/index";
import { generateError } from "../../../utils/Resusables";

export const getIkigaiQuestion = () => {
  return axiosInstance
    .get(`ikigai/question`)
    .then((response) => {
      return store.dispatch(getIkigaiQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getIkigaiQuestionError(generateError(error)))
    );
};

export const getIkigaiDetails = () => {
   return axiosInstance
     .get(`ikigai/details`)
     .then((response) => {
       return store.dispatch(getIkigaiDetailsSuccess(response.data.data));
     })
     .catch((error) =>
       store.dispatch(getIkigaiDetailsError(generateError(error)))
     );
 };
 

export const postIkigaiQuestion = (data) => {
  return axiosInstance
    .post(`ikigais/questions`, data)
    .then((response) => {
      return store.dispatch(postIkigaiQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(postIkigaiQuestionError(generateError(error)))
    );
};

export const editIkigaiQuestion = (identifier, data) => {
   return axiosInstance
     .put(`ikigais/${identifier}/question`, data)
     .then((response) => {
       return store.dispatch(editIkigaiQuestionSuccess(response.data.data));
     })
     .catch((error) =>
       store.dispatch(editIkigaiQuestionError(generateError(error)))
     );
 };

export const getIkigaiQuestionResponse = () => {
   return axiosInstance
      .get(`ikigai/data`)
      .then((response) => {
         return store.dispatch(getIkigaiQuestionResponseSuccess(response.data.data));
      })
      .catch((error) =>
         store.dispatch(getIkigaiQuestionResponseError(generateError(error)))
      );
};

export const getAllIkigaiQuestions = () => {
  return axiosInstance
     .get(`ikigai/all/questions`)
     .then((response) => {
        return store.dispatch(getAllIkigaiQuestionsSuccess(response.data.data));
     })
     .catch((error) =>
        store.dispatch(getAllIkigaiQuestionsError(generateError(error)))
     );
};

