import React, { useEffect } from "react";
import Header from "../../../../common/Header";
import IkigaiPreviewQuestions from "./ikigaiPreviewQuestion";
// import IkigaiDetails from "./ikigaiDetails";
import "../../css/ikigai.css";
import PropTypes from "prop-types";
import Loader from "../../../../common/loader/loader";
import PrintIcon from '@mui/icons-material/Print';
import { responseConfig } from "../../../../utils/Resusables";

const propTypes = {
  ikigaiQuestion: PropTypes.array,
  ikigaiDetails: PropTypes.array,
  ikigaiResponse: PropTypes.obj,
  getIkigaiQuestion: PropTypes.func,
  getIkigaiDetails: PropTypes.func,
  postIkigaiQuestion: PropTypes.func,
  editIkigaiQuestion: PropTypes.func,
  getIkigaiQuestionResponse: PropTypes.func,
  getAllIkigaiQuestions: PropTypes.func,
  ikigaiAllQuestions: PropTypes.array,
  ikigaiDetailsCount: PropTypes.obj,
  status: PropTypes.any,
  userDetail: PropTypes.obj,
  isMobileLayout: PropTypes.bool,
  isDesktopLayout: PropTypes.bool
};

const Ikigai = (props) => {

  const [isLoading, setLoader] = React.useState(false);
  const [ikigaiQues, setIkigaiQues] = React.useState([]);

  useEffect(() => {
    props.getIkigaiQuestion();
    props.getAllIkigaiQuestions();
    props.getIkigaiDetails();
    props.getIkigaiQuestionResponse();
  }, []);

  React.useEffect(() => {
    switch (props?.status) {
      case "POST_IKIGAI_QUESTION_SUCCESS":
        props.getIkigaiQuestionResponse();
        break;
      case "EDIT_IKIGAI_QUESTION":
        setLoader(true);
        break;
      case "EDIT_IKIGAI_QUESTION_SUCCESS":
        props.getIkigaiQuestionResponse();
        setLoader(false);
        break;
      case "GET_IKIGAI_QUESTION_SUCCESS":
        // props.getIkigaiDetails();
        // props.getIkigaiQuestionResponse();
        break;
      case "GET_IKIGAI_DETAILS_SUCCESS":
        var questionsArr = JSON.parse(JSON.stringify(props.ikigaiQuestion));
        var detailsArr = JSON.parse(JSON.stringify(props.ikigaiDetails));
        var mergedArr = questionsArr.concat(detailsArr);
        var sortedData = mergedArr.sort((a, b) => a.order - b.order);
        setIkigaiQues(sortedData);
        break;
    }
  }, [props?.status]);

  const downloadIkigaiPdf = () => {
    window.open(
      `${responseConfig.baseURL}/ikigais/${props?.userDetail?._id}/pdf`
    );
  };

  return (
    <>
      <Loader is_loading={isLoading} />
      <Header active="Ikigai" />
      <div style={{marginTop: '100px'}}>
        <p className="heading-ikigai">IKIGAI</p>
        {/* <p className="description-ikigai-heading">How much Ikigai do you have in your life?</p> */}
      </div>
      <div className="flex-end" style={{ marginTop: '-60px', marginRight: props.isMobileLayout ? '20px':'100px' }}>
        <button className="printBtn" onClick={downloadIkigaiPdf} style={{height:'auto', marginTop: props.isMobileLayout ? '66px': null}} disabled={Object.keys(props?.ikigaiResponse)?.length === 0}>
        <PrintIcon /><div style={{marginLeft: '8px'}} className="text">Print My Ikigai</div>
        </button>
      </div>
      <div style={{marginTop: props.isMobileLayout ? '0px' : '50px'}}>
        <IkigaiPreviewQuestions
          ikigaiQuestion={props.ikigaiQuestion}
          ikigaiResponse={props.ikigaiResponse}
          postIkigaiQuestion={props.postIkigaiQuestion}
          editIkigaiQuestion={props.editIkigaiQuestion}
          getIkigaiQuestion={props.getIkigaiQuestion}
          getIkigaiQuestionResponse={props.getIkigaiQuestionResponse}
          status={props.status}
          isDesktopLayout={props.isDesktopLayout}
          isMobileLayout={props.isMobileLayout}
          ikigaiDetails={props.ikigaiDetails}
          ikigaiQues={ikigaiQues}
          ikigaiAllQuestions={props.ikigaiAllQuestions}
          ikigaiDetailsCount={props.ikigaiDetailsCount}
        />
      </div>
      {/* <div style={{marginTop: '50px'}}>
        <p className="heading-ikigai">Add Details</p>
        <p className="description-ikigai-heading">How much Ikigai do you have in your life?</p>
      </div> */}
      {/* <div>
        <IkigaiDetails 
          ikigaiDetails={props.ikigaiDetails}
          ikigaiResponse={props.ikigaiResponse}
          status={props.status}
          postIkigaiQuestion={props.postIkigaiQuestion}
          editIkigaiQuestion={props.editIkigaiQuestion}
          getIkigaiQuestion={props.getIkigaiQuestion}
          getIkigaiQuestionResponse={props.getIkigaiQuestionResponse}
          isDesktopLayout={props.isDesktopLayout}
          isMobileLayout={props.isMobileLayout}
        />
      </div> */}
    </> 
  );
}

Ikigai.propTypes = propTypes;
export default Ikigai;
