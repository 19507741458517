import { configureStore } from '@reduxjs/toolkit';
//import logger from 'redux-logger';
import { reduxBatch } from '@manaflair/redux-batch';
import rootReducer from './reducers';
//import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import { responsiveStoreEnhancer } from 'redux-responsive'
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
/*const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer());*/

export const store = configureStore({
  enhancers: [responsiveStoreEnhancer, reduxBatch],
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
});