import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

let FontStyle = Quill.import('attributors/class/font');
Quill.register(FontStyle, true);

let SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ["10px", "12px", "13px", "14px", "16px", "18px", "20px", "24px", "26px", "32px"]
Quill.register(SizeStyle, true);

const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
Font.whitelist = ["Montserrat", "Sans-serif", "Raleway", "Roboto", "Lato", "Rubik", "Calibri", "Helvetica"]; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

let AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const toolbarOptions = [
  [{ 'font': Font.whitelist }],
  [{ 'size': SizeStyle.whitelist }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
  ['bold', 'italic', 'underline', 'strike']
];

let formats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula'
  // 'image'
  // 'video'
];

// import React from 'react'
import PropTypes from 'prop-types';

const displayName = 'TextEditor';
const propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  setDescription: PropTypes.func,
  textDescription: PropTypes.string,
  maxCharacters: PropTypes.number,
  id: PropTypes.string
};


const TextEditor = ({ setDescription, textDescription, id }) => {
  const [text, setText] = useState('');

  const editorRef = React.createRef();

  useEffect(() => {
    setText(textDescription);
    editorRef?.current?.focus();
  }, []);

  useEffect(() => {
    setText(textDescription);
  }, [textDescription]);

  const handleChangeDescription = (value) => {
    setText(value);
    setDescription(id, value);
  }

  // const checkCharacterCount = (event) => {
  //   if (textDescription?.length > maxCharacters && event.key !== 'Backspace') {
  //     event.preventDefault();
  //   }
  // }
  return (
    <div>
      <ReactQuill
        value={text}
        onChange={handleChangeDescription}
        // onKeyDown={checkCharacterCount}
        modules={{
          toolbar: {
            container: toolbarOptions
          }
        }}
        formats={formats}
        id={id}
        className={'record-minutes'}
        ref={editorRef}
      />
    </div>
  )

}

TextEditor.displayName = displayName;
TextEditor.propTypes = propTypes;

export default TextEditor;