import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

const TextResponse = (props) => {
    const { visibleQuestion } = props;
    return (
        <div className="new-energy-meter-wrapper" style={{ margin: '10px' }}>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {visibleQuestion.text && visibleQuestion.text.length ? visibleQuestion.text.map((data, key) => {
                    return (
                        <li key={key} className={`tip-top ${key % 2 === 0 ? 'left' : 'right'}`} style={{ marginBottom: '1%' }}>
                            <p className="question-user-name">
                                <PersonIcon style={{ fontSize: '20px' }} />{data.userName}</p>
                            <div className="triangle-tip"></div>
                            <div className="tip-top-container">
                                <div className="tip-top-cat">
                                    <span className="tip-top-sub-text">{visibleQuestion.catName}</span>
                                    {
                                        visibleQuestion.subCatName ?
                                            <span>{`>`}</span>
                                            :
                                            null
                                    }
                                    <span className="tip-top-sub-text">{visibleQuestion.subCatName}</span>
                                </div>
                                <div className="tip-top-text">
                                    <div className="verticle-line-color tip-line" style={{ background: '#f56102' }}></div>
                                    <div className="nav-logout">
                                        {
                                            data.value
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                }) : <li style={{ textAlign: 'center' }}>Responses not available</li>
                }
            </ul>
        </div>
    );
}

export default TextResponse;