import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
// import expandMore from "../../../../assets/expandMore.svg";
// import editIcon from "../../../../assets/editIcon.svg";
// import arrowRight from "../../../../assets/arrowRight.png";
import styles from "../../../CreateSurvey/css/addQuestion.module.css";
import "../../../CreateSurvey/css/survey.css";
import PropTypes from "prop-types";
import PrimaryButton from "../../../../common/PrimaryButton";
import ToastNotify from "../../../../common/Toast/ToastNotify";
import TextField from '@mui/material/TextField';
import styled from "../../css/ikigai.module.css"
// import BasicPopover from "./Popup";


const propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  isMobileLayout: PropTypes.bool,
  isDesktopLayout: PropTypes.bool,
  ikigaiQuestion: PropTypes.any,
  ikigaiResponse: PropTypes.obj,
  postIkigaiQuestion: PropTypes.func,
  editIkigaiQuestion: PropTypes.func,
  getIkigaiQuestionResponse: PropTypes.func,
  ikigaiQues: PropTypes.any,
  ikigaiDetails: PropTypes.any,
  ikigaiAllQuestions: PropTypes.array,
  ikigaiDetailsCount: PropTypes.obj
};


const IkigaiPreviewQuestions = (props) => {
  const [expanded1, setExpanded1] = React.useState(props?.ikigaiAllQuestions.map(item => item.identifier));
  const [successMsg1, setSuccessMsg1] = React.useState(false);
  const [questionResponses, setQuestionResponses] = React.useState({});
  // const [editValue, seteditValue] = React.useState([0,1,2,3,4,5,6,7]);
  const [ikigaiOperation, setIkigaiOperation] = React.useState('');
  const [toastQuestion, setToastQuestion] = React.useState(false);
  const [addInput, setAddInput] = React.useState({});
  // const [detailResponses, setDetailResponse] = React.useState([]);
  const [lastValue, setLastValue] = React.useState({});
  const [questionKey, setQuestionKey] = React.useState([]);
  // const [currentIdentifier, setCurrentIdentifier] = React.useState('');


  const handleChange = (panel) => (event, isExpanded1) => {
    let arr = [...expanded1];
    if (isExpanded1) {
      if (!arr.includes(panel)) {
        arr.push(panel);
      } else {
        const i = arr.indexOf(panel)
        if (i > -1) {
          arr.splice(i, 1);
        }
      }
    }
    if (!event.target.className.includes('previewDivCol')) {
      setExpanded1(arr);
    }
    setQuestionResponses(props?.ikigaiResponse?.responseData?.[0]);
  };

  const initialiseIkigaiDetails = () => {
    let temp = {};
    props.ikigaiAllQuestions?.map((item) => {
      let count = props?.ikigaiResponse?.responseData?.[0]?.[item.identifier]?.length; 
      temp[item.identifier] = count ? count : 1;
    });
    setAddInput(temp);
  }

  // const handleDetailsChange = (e, i, identifier) => {
  //   let flag = false;
  //   if(currentIdentifier !== identifier){
  //     setQuestionKey([]);
  //     flag = true;
  //   }
  //   setCurrentIdentifier(identifier);
  //   let temp;
  //   let keys = [];
  //   if(props?.ikigaiResponse?.responseData?.[0]){
  //     keys = Object.keys(JSON.parse(JSON.stringify(props?.ikigaiResponse?.responseData?.[0])));
  //   }
  //   let idExist = keys.includes(identifier);
  //   if(i === 0 || idExist){
  //     temp = (props?.ikigaiResponse?.responseData?.[0]?.[identifier]) ? JSON.parse(JSON.stringify(props?.ikigaiResponse?.responseData?.[0]?.[identifier])) : [];

  //     let arr = flag ? [] : [...questionKey];
  //     if(arr.find(ele => ele.key === i)){
  //       arr.find(ele => ele.key === i).value = e.target.value;
        
  //     }else{
  //       arr.push({key: i, value : e.target.value});
  //     }
      
  //     setQuestionKey(arr);
  //     temp[i] = e.target.value;
  //     arr.map((data)=>{
  //       temp[data.key] = data.value;
  //     })
  //   }else{
  //     temp = [...detailResponses];
  //     temp[i] = e.target.value;
  //   }
  //   console.log(temp);
  //   setDetailResponse(temp);
  //   setQuestionResponses({ ...questionResponses, [identifier]: temp });
  //   setLastValue({...lastValue, [identifier]:e.target.value});
  // }

  const handleAnswerChange = (e, i, identifier) => {
    let temp;
    let keys = [];
    if(props?.ikigaiResponse?.responseData?.[0]){
      keys = Object.keys(JSON.parse(JSON.stringify(props?.ikigaiResponse?.responseData?.[0])));
    }
    let idExist = keys.includes(identifier);
    if(i === 0 || idExist){
      temp = (props?.ikigaiResponse?.responseData?.[0]?.[identifier]) ? JSON.parse(JSON.stringify(props?.ikigaiResponse?.responseData?.[0]?.[identifier])) : [];

      let arr = [...questionKey];
      if(arr.find(ele => ele.identifier === identifier)){
        let dataArray = arr.find(ele => ele.identifier === identifier).data;
        if(dataArray.find(j => j.index === i)){
          dataArray.find(j => j.index === i).value = e.target.value;
        }else{
          dataArray.push({index: i, value: e.target.value})
        }
      }else{
        arr.push({identifier: identifier, data : [{index: i, value: e.target.value }]});
      }

      setQuestionKey(arr);
      temp[i] = e.target.value;
      const matchedObject = arr.find(obj => obj.identifier === identifier).data;

      matchedObject.map((data)=>{
        temp[data.index] = data.value;
      })
    }else{
      temp = [];
      let arr = [...questionKey];
      if(arr.find(ele => ele.identifier === identifier)){
        let dataArray = arr.find(ele => ele.identifier === identifier).data;
        if(dataArray.find(j => j.index === i)){
          dataArray.find(j => j.index === i).value = e.target.value;
        }else{
          dataArray.push({index: i, value: e.target.value})
        }
      }else{
        arr.push({identifier: identifier, data : [{index: i, value: e.target.value }]});
      }
      const matchedObject = arr.find(obj => obj.identifier === identifier).data;
      matchedObject.map((data)=>{
        temp[data.index] = data.value;
      })

      setQuestionKey(arr);
      // temp = [...detailResponses];
      temp[i] = e.target.value;
    }
    // setDetailResponse(temp);
    setQuestionResponses({ ...questionResponses, [identifier]: temp });
    setLastValue({...lastValue, [identifier]:e.target.value});
  }

  React.useEffect(() => {
    switch (props?.status) {
      case "POST_IKIGAI_QUESTION_SUCCESS":
        setSuccessMsg1(true);
        // setLastValue(null);
        break;
      case "EDIT_IKIGAI_QUESTION_SUCCESS":
        setSuccessMsg1(true);
        // setLastValue(null);
        break;
      case "GET_IKIGAI_QUESTION_RESPONSE_SUCCESS":
        initialiseIkigaiDetails();
        setTimeout(() => setSuccessMsg1(false), 3000);
        setTimeout(() => setToastQuestion(false), 3000);
        setQuestionResponses(props?.ikigaiResponse?.responseData?.[0]);
        // setDetailResponse([]);  
        break;
      case "GET_ALL_IKIGAI_QUESTIONS_SUCCESS":
        setExpanded1(props?.ikigaiAllQuestions.map(item => item.identifier));
        break;
    }
  }, [props?.status]);

  const addInputField = (id) => {
    let count = addInput[id];
    setAddInput({ ...addInput, [id]: count + 1 });
    setLastValue({...lastValue, [id]:null});
  }

  React.useEffect(() => {
    setQuestionResponses(props?.ikigaiResponse?.responseData?.[0]);
    // setLastValue()
    let ikigaiDetailsArray = props.ikigaiAllQuestions;
    let quesResponseObj = props?.ikigaiResponse?.responseData?.[0];
    const newObj = ikigaiDetailsArray.reduce((acc, cur) => {
      const arr = quesResponseObj?.[cur.identifier];
      acc[cur.identifier] = arr ? arr[arr.length - 1] : null;
      return acc;
    }, {});
    setLastValue(newObj);
  }, [props.ikigaiResponse, props.ikigaiAllQuestions]);

  React.useEffect(()=>{
    initialiseIkigaiDetails();
  },[props.ikigaiAllQuestions]);

  React.useEffect(()=>{
    setExpanded1(props?.ikigaiAllQuestions.map(item => item.identifier));
  },[props.ikigaiAllQuestions])

  // const textFieldChange = (e, identifier) => {
  //   let value = e.target.value;
  //   setQuestionResponses({ ...questionResponses, [identifier]: value })
  // }

  const onSaveQuestion = (identifier, method) => {
    // seteditValue(null);
    setToastQuestion(true);
    setIkigaiOperation(method);
    // if(type === 'ikigai_question'){
    //   let postObj = !(props?.ikigaiResponse?.responseData?.[0]) ? {} : props?.ikigaiResponse?.responseData?.[0];
    //   let updatedResponse = questionResponses[identifier];
    //   const cleanedText = updatedResponse.replace(/\n\s*\n/g, '\n');
    //   postObj[identifier] = cleanedText;
    //   let newObj = {};
    //   newObj['responseData'] = [postObj];
    //   if (Object.keys(props.ikigaiResponse).length === 0) {
    //     props.postIkigaiQuestion(newObj);
    //   } else {
    //     let identifier = props.ikigaiResponse.identifier;
    //     props.editIkigaiQuestion(identifier, newObj);
    //   }
    // }else{
      let postObj = !(props?.ikigaiResponse?.responseData?.[0]) ? {} : props?.ikigaiResponse?.responseData?.[0];
      let updatedResponse = questionResponses[identifier];
      const noEmptyStrings = updatedResponse.filter((str) => str !== '');
      postObj[identifier] = noEmptyStrings;
      let newObj = {};
      newObj['responseData'] = [postObj];
      if (Object.keys(props.ikigaiResponse).length === 0) {
        props.postIkigaiQuestion(newObj);
      } else {
        let identifier = JSON.parse(JSON.stringify(props.ikigaiResponse.identifier));
        props.editIkigaiQuestion(identifier, newObj);
      }
    // }
    setQuestionKey([]);
    // setCurrentIdentifier('');
  }

  // const onCancel = () =>{
  //   seteditValue(null);
  //   setQuestionResponses(props?.ikigaiResponse?.responseData?.[0]);
  //   initialiseIkigaiDetails();
  //   setQuestionKey([]);
  // }

  // const onEditQuestion = (index, identifier) => {
  //   let arr = [...expanded1];
  //   if (!arr.includes(identifier)) {
  //     arr.push(identifier);
  //   }
  //   seteditValue(index);
  //   setExpanded1(arr);
  //   questionResponses?.[identifier]?
  //   setLastValue(questionResponses?.[identifier]?.[questionResponses[identifier].length-1]) : setLastValue(null);
  // }
  
  return (
    <div className={styles.previewQuestionsBox}>
      <div style={{ width: '90%', margin: 'auto' }}>
        {props?.ikigaiAllQuestions?.map((item, index) => {
          return (
            <Accordion
              className={
                "activeAccordion"
              }
              expanded={expanded1.includes(item.identifier)}
              onChange={handleChange(item.identifier)}
              style={{ padding: props.isMobileLayout ? '0px' : '20px 100px' }}
              key={index}
            >
              <AccordionSummary
                // expandIcon={<img src={expandMore} alt="ExpandMore" style={{ marginTop: expanded1.includes(item.identifier) ? '26px' : '-27px' }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  className={styles.previewQuestionTexts}
                  sx={{ color: "text.secondary" }}
                >
                  <div>
                    <div className={styles.questionFieldPreview} > 
                      <div className={styled.ikigaiQuestionDetail} style={{ fontSize: props.isMobileLayout ? '15px' : null}}>
                        {item.text}
                      </div>
                      {/* <BasicPopover content={item.value}></BasicPopover> */}
                    </div>
                    {/* {item.type === 'ikigai_details' ? 
                      <div className={styled.detailsDescription}>
                            {item.commentData}
                      </div> : null} */}
                  </div>
                  {/* <div className={styles.isDesktop} style={{ width: '15%', alignSelf: 'center', marginTop: '5px', marginLeft: '85%' }}>
                    <div className={styles.previewDiv}>
                      <div className={styles.previewDivColum} style={{marginTop: item.type === 'ikigai_details' ? '-65px':'-48px'}}>
                        <img
                          src={editIcon}
                          className={styles.previewDivCol}
                          alt="editIcon"
                          onClick={() => onEditQuestion(index, item.identifier)}
                        />
                      </div>
                    </div>
                  </div> */}
                  
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordianInput">
                {/* {editValue.includes(index) ?  */}
                <>
                {/* {item.type === 'ikigai_question' ? 
                  <div style={{marginLeft:'-75px'}}>
                    <TextField
                      id={item.identifier}
                      label={questionResponses?.[item.identifier]?"":"Type here..."}
                      // multiline
                      // maxRows={10}
                      variant="standard"
                      value={questionResponses?.[item.identifier]}
                      size={'medium'}
                      style={{ width: '100%', padding: '20px 0px', color: '#000000' }}
                      onChange={(e) => textFieldChange(e, item.identifier)} 
                    />
                  </div> : */}
                  <><div className={styled.ikigaiTextFieldBlock}>
                        {Array.from({ length: addInput[item.identifier] }, (_, i) => (
                          <TextField
                            key={i}
                            id={item.identifier}
                            label={questionResponses?.[item.identifier]?.[i] ? "" : "Type Here...."}
                            // multiline
                            // maxRows={10}
                            variant="standard"
                            value={questionResponses?.[item.identifier] ? questionResponses?.[item.identifier]?.[i] : ''}
                            style={{ width: props.isMobileLayout ? '90%' : '100%', padding: '20px 0px', color: '#000000', marginLeft: props.isMobileLayout ? '22px' : null }}
                            onChange={(e) => handleAnswerChange(e, i, item.identifier)} />
                        ))}
                      </div><div>
                          { lastValue?.[item.identifier]?
                            <div><button className={styled.addMore} style={{ marginLeft: props.isMobileLayout ? '0px' : null }} onClick={() => addInputField(item.identifier)}>+ Add more</button></div> : null}
                        </div></>
                {/* } */}
                  <div style={{display:'flex', flexDirection:'row-reverse', width: props.isMobileLayout ? '72%' : null}}>
                    <div>
                      <PrimaryButton
                        text={props?.ikigaiResponse?.['responseData']?.[0]?.[item.identifier] ? 'Update' : 'Save'}
                        onClick={() => onSaveQuestion(item.identifier, props?.ikigaiResponse?.['responseData']?.[0]?.[item.identifier] ? 'Update' : 'Save')}
                        className={styled.saveButton}
                        disabled={!questionResponses?.[item.identifier] || (item.type === 'ikigai_details' && questionResponses?.[item.identifier].every(str => str === ''))}
                      />
                    </div>
                    {/* <div style={{marginRight:'20px'}}>
                      <PrimaryButton
                        text={'Cancel'}
                        onClick={onCancel}
                        className={styled.saveButton}
                      />
                    </div> */}
                  </div>
                    
                  </>
                  {/* :
                  <div>
                    {item.type === 'ikigai_question' ?
                      <div className={styled.answers}>{questionResponses?.[item.identifier]}</div>:
                      <div>
                        {Array.from({ length: props?.ikigaiResponse?.responseData?.[0]?.[item.identifier]?.length ?  props?.ikigaiResponse?.responseData?.[0]?.[item.identifier]?.length : 1}, (_, i) => (
                          <div className={styled.answersIkigiDetails} style={{ marginLeft: props.isMobileLayout ? '20px' : null}}>{props?.ikigaiResponse?.['responseData']?.[0]?.[item.identifier]?.[i]}</div>
                      ))}
                      </div>
                    }
                  </div>
                 */}
                {/* } */}
                {/* <div className={styles.isMobile} style={{ alignSelf: 'center', marginLeft:'65%'}}>
                    <div className={styles.previewDiv}>
                      <div className={styles.previewDivColum}>
                        <img
                          src={editIcon}
                          className={styles.previewDivCol}
                          alt="editIcon"
                          onClick={() => onEditQuestion(index, item.identifier)}
                        />
                      </div>
                    </div>
                  </div> */}
              </AccordionDetails>
            </Accordion>)
        })}
      </div>
      {successMsg1 && toastQuestion ? (
        <ToastNotify
          data={ikigaiOperation ==='Update' ? "Ikigai Updated successfully" : "Ikigai saved successfully"}
          openAlert={true}
          type={"success"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

IkigaiPreviewQuestions.propTypes = propTypes;
export default IkigaiPreviewQuestions;
