/* eslint-disable no-console */
import axios from 'axios'
// import store from '../store/index'
// import { authLogout } from '../modules/auth/store/actions'
import { config, responseConfig } from "./Resusables";
export const axiosInstance = axios.create(config);
export const axiosInstanceOther = axios.create(responseConfig);

export const reExecuteInstance = () => {
  axiosInstance.defaults.headers.common['connectId'] = localStorage?.getItem("connectId");
  axiosInstance.defaults.headers.common['Authorization'] = localStorage?.getItem("access_token");
}