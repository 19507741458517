import React from "react";
import styles from "../../../css/pageNotFound.module.css";
import xeleron from "../../../../../assets/xeleron.svg";
import notFound from "../../../../../assets/notFound.svg";
import PrimaryButton from "../../../../../common/PrimaryButton";
import Constants from "../../../../../config";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className={styles.pageNotFound}>
      <div className={styles.pageNotFoundInner}>
        <div className={styles.notFoundImg1}>
          <img src={xeleron} className='xeleron-logo' alt="xeleron" />
        </div>
        <div className={styles.notFoundImg2}>
          <img src={notFound} alt="notFound" className={styles.image404}/>
        </div>
        <div className={styles.oopsText}>{Constants.OOPS}</div>
        <div className={styles.pageNotFoundText}>{Constants.PAGE_NOT_FOUND}</div>
        <div className={styles.pageNotFoundButton}>
            <div className={styles.notFoundInnerButton}>
            <PrimaryButton text={Constants.GO_TO_HOME} className={styles.goToHomeButton} onClick={()=>navigate('/')}/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
