import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import agileCockpit from "../../assets/agileCockpit.svg";
import "./header.css";
// import SearchIcon from "@mui/icons-material/Search";
// import { styled } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import ExpandMore from "@mui/icons-material/ExpandMore";
//import NotificationsIcon from "@mui/icons-material/Notifications";
import menu from "../../assets/menu.svg";
// import searchImg from "../../assets/search.svg";
// import crossButton from "../../assets/crossButton.svg";
import activity from "../../assets/activity.svg";
import reports from "../../assets/reports.svg";
import survey from "../../assets/survey.svg";
import myPeers from "../../assets/myPeers.svg";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ProfileMenu from "./profileMenu";
import Constants from "../../config";
import { connect } from "react-redux";
import { getUserDetails } from "../../modules/auth/store/actions";
import { getQuarterList, createQuarterlyGoal } from "../../modules/Survey/store/actions";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ikigai from "../../assets/ikigai.svg";
import RateReviewIcon from '@mui/icons-material/RateReview';
import xeleron from '../../assets/xeleron.svg';

const propTypes = {
  active: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  userDetail: PropTypes.any,
  getUserDetails: PropTypes.func,
  getQuarterList: PropTypes.func,
  quarterList: PropTypes.array,
  quarterTotalRecords: PropTypes.number,
  createQuarterlyGoal: PropTypes.func,
  status: PropTypes.string,
  surveyDetail: PropTypes.object
};

const Header = (props) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isAllQuarterVisible, setAllQuarterVisible] = React.useState(false);
  // const [searchModal, setsearchModal] = React.useState(false);
  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      props.getUserDetails();
    }
  }, []);

  React.useEffect(() => {
    switch (props?.status) {
      case "CREATE_QUARTERLY_GOAL_SUCCESS":
        navigate(`/add-goals?id=${props?.surveyDetail?.identifier}`);
        break;
      case "GET_QUARTER_LIST_SUCCESS":
        setSelectedIndex('');
        break;
    }
  }, [props?.status]);

  React.useEffect(() => {
    if (props?.surveyDetail && props?.surveyDetail.quarterId && props?.surveyDetail.quarterId !== '') {
      const index = props?.quarterList?.findIndex(obj => obj._id === props?.surveyDetail.quarterId);
      if (index > -1) {
        setSelectedIndex(index);
      }
    }
  }, [props.surveyDetail])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ').length > 1 ? name.split(' ')[0][0] : name[0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ''}`,
    };
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event,
    index,
    all,
    data
  ) => {
    if (!all) {
      setSelectedIndex(index);
      setAnchorEl(null);
      localStorage.setItem("createQuestion", 1);
      props.createQuarterlyGoal({ quarterId: data._id });
    } else {
      setAllQuarterVisible(true);
      props.getQuarterList({ type: 'all' });
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position={"fixed"}>
      <Container maxWidth="xl" className="headerTop">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <img
              src={xeleron}
              alt="Xeleron"
              className="appLogo"
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <img src={menu} alt="menu" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/dashboard");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={
                    props?.active === Constants.DASHBOARD
                      ? {
                        color: "#14009B",
                        fontWeight: "500",
                        fontSize: "12px",
                      }
                      : {
                        fontWeight: "500",
                        fontSize: "12px",
                      }
                  }
                >
                  {props?.active === Constants.DASHBOARD ? (
                    <img
                      src={activity}
                      alt="activity"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.DASHBOARD}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/ikigai");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={props?.active === Constants.IKIGAI ? {
                    color: "#14009B",
                    fontWeight: "500",
                    fontSize: "12px",
                  }
                    : {
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                >
                  {props?.active === Constants.IKIGAI ? (
                    <img
                      src={ikigai}
                      alt="ikigai"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.IKIGAI}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/my-quarters");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={
                    props?.active === Constants.GOAL ? {
                      color: "#14009B",
                      fontWeight: "500",
                      fontSize: "12px",
                    }
                      : {
                        fontWeight: "500",
                        fontSize: "12px",
                      }
                  }
                >
                  {props?.active === Constants.GOAL ? (
                    <img
                      src={survey}
                      alt="survey"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.QUARTERLY_GOALS}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/received");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={props?.active === Constants.REQUESTED_REVIEWS ? {
                    color: "#14009B",
                    fontWeight: "500",
                    fontSize: "12px",
                  }
                    : {
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                >
                  {props?.active === Constants.REQUESTED_REVIEWS ? (
                    <img
                      src={myPeers}
                      alt="myPeers"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.REQUESTED_REVIEWS}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/reports");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={
                    props?.active === Constants.REPORTS ? {
                      color: "#14009B",
                      fontWeight: "500",
                      fontSize: "12px",
                    }
                      : {
                        fontWeight: "500",
                        fontSize: "12px",
                      }
                  }
                >
                  {props?.active === Constants.REPORTS ? (
                    <img
                      src={reports}
                      alt="reports"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.REVIEW_REPORTS}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/minutes");
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  style={
                    props?.active === Constants.MINUTES ? {
                      color: "#14009B",
                      fontWeight: "500",
                      fontSize: "12px",
                    }
                      : {
                        fontWeight: "500",
                        fontSize: "12px",
                      }
                  }
                >
                  {props?.active === Constants.MINUTES ? (
                    <img
                      src={reports}
                      alt="reports"
                      style={{ position: "relative", top: "10%" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {Constants.MINUTES}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img
              src={searchImg}
              alt="searchImg"
              className="searchIcon"
              onClick={() => setsearchModal(true)}
            />
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/dashboard");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.DASHBOARD ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.DASHBOARD ? (
                <img
                  src={activity}
                  alt="activity"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.DASHBOARD}
            </Button>
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/ikigai")
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.IKIGAI ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.IKIGAI ? (
                <img
                  src={ikigai}
                  alt="ikigai"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.IKIGAI}
            </Button>
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/my-quarters");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.GOAL ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.GOAL ? (
                <img
                  src={survey}
                  alt="survey"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.QUARTERLY_GOALS}
            </Button>
            {/*<Button
              className="hearderOption"
              onClick={() => {
                navigate("/dashboard");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.TEMPLATES ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.TEMPLATES ? (
                <img
                  src={activity}
                  alt="activity"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.TEMPLATES}
              </Button>*/}
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/received");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.RECEIVED ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.RECEIVED ? (
                <img
                  src={myPeers}
                  alt="myPeers"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.REQUESTED_REVIEWS}
            </Button>
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/reports");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.REPORTS ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.REPORTS ? (
                <img
                  src={reports}
                  alt="reports"
                  style={{ position: "relative", top: "10%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.REVIEW_REPORTS}
            </Button>
            <Button
              className="hearderOption"
              onClick={() => {
                navigate("/minutes");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, color: "white", display: "block" }}
              style={props?.active === Constants.MINUTES ? { color: "#14009B" } : {}}
            >
              {props?.active === Constants.MINUTES ? (
                <RateReviewIcon

                  style={{ position: "relative", top: "25%", width: "20%" }}
                />
              ) : (
                ""
              )}{" "}
              {Constants.MINUTES}
            </Button>
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Search>
              <StyledInputBase
                placeholder="Search Surveys"
                inputProps={{ "aria-label": "search" }}
              />
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            </Search>
          </Typography> */}
          <div style={{ marginRight: '20px' }}>
            <List
              component="nav"
              aria-label="Device settings"
              sx={{ bgcolor: 'background.paper' }}
            >
              <ListItem
                button
                id="lock-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
                className="createSurveyButton"
              >
                {Constants.CREATE_GOAL} <ArrowDropDownIcon />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              className="createQuarterDropdown"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {props?.quarterList?.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index, false, option)}
                  className="createQuarterMenu"
                >
                  {option.title}
                </MenuItem>
              ))}
              {
                !isAllQuarterVisible ?
                  <MenuItem
                    onClick={(event) => handleMenuItemClick(event, 'last', true)}
                    className="createQuarterMenu showAll"
                  >
                    Show All
                  </MenuItem> : null
              }
            </Menu>
          </div>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                style={{ display: "flex" }}
                className="openMenu"
              >
                {
                  props.userDetail && props.userDetail.name && props.userDetail.name !== '' ?
                    <Avatar {...stringAvatar(props.userDetail.name)} /> :
                    <Avatar alt="Name" src="" />
                }
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <ExpandMore
                    sx={{ fontSize: 30 }}
                    style={{ paddingTop: "10px", color: "#000" }}
                  />
                </Typography>
              </IconButton>
            </Tooltip>
            <ProfileMenu
              anchorElUser={anchorElUser}
              handleCloseUserMenu={handleCloseUserMenu}
            />
          </Box>
          {/*<IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <NotificationsIcon style={{ color: "#82868C" }} />
              </IconButton>*/}
          <Box style={{ width: '10px' }} />
        </Toolbar>
      </Container>
      {/* {searchModal ? (
        <div className="appBarPopup" position="fixed">
          <div style={{ width: "85%" }}>
            <Search>
              <StyledInputBase
                placeholder={Constants.SEARCH_GOAL}
                inputProps={{ "aria-label": "search" }}
              />
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            </Search>
          </div>
          <div style={{ width: "15%", textAlign: "center", marginTop: "10px" }}>
            <img
              src={crossButton}
              alt="crossButton"
              style={{ cursor: "pointer" }}
              onClick={() => setsearchModal(false)}
            />
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </AppBar>
  );
};
Header.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userDetail: state.auth.userDetail,
    quarterList: state.surveyList.quarter.items,
    quarterTotalRecords: state.surveyList.quarter.totalRecords,
    status: state.status,
    surveyDetail: state.surveyList.surveyDetail,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    getQuarterList: (data) => {
      dispatch(getQuarterList(data));
    },
    createQuarterlyGoal: (data) => {
      dispatch(createQuarterlyGoal(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
