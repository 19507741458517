import React, { useEffect, useState } from "react";
import Header from "../../../../../common/Header";
import MainTable from "../../../../../common/Table";
import DropDown from "../../../../../common/DropDown";
import PropTypes from "prop-types";
import Constants from "../../../../../config";
//import styled from "../../../css/surveyList.module.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import styled from "../../../css/surveyList.module.css";
import "../../../css/surveyList.css";
const propTypes = {
  getSurveySent: PropTypes.func,
  surveyList: PropTypes.object,
  isLoading: PropTypes.bool,
  getQuarterList: PropTypes.func,
  getPeersList: PropTypes.func,
  selectedYear: PropTypes.string
};

const yearOptions = ['2022', '2023', '2024', '2025'];

function MyGoal(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [mount, setMount] = useState(true);
  const [selectedYear, setSelectedYear] = useState(props.selectedYear);

  useEffect(() => {
    if (mount) {
      apiCall({ page, year: selectedYear });
      props.getQuarterList({ recPerPage: 3, offset: 0 });
      setMount(false);
      props?.getPeersList({ all: true });
    }
  }, []);

  useEffect(() => {
    switch (props?.status) {
      case "DELETE_GOAL_SUCCESS":
        apiCall({ page, year: selectedYear });
        break;
    }
  }, [props?.status]);

  const apiCall = (value) => {
    props?.getSurveySent(value);
  };
  const columns = [
    { id: "name", label: Constants.GOAL, minWidth: 140, type: "myGoal" },
    {
      id: "selfieScheduleDate",
      label: Constants.SELFIE_START_DATE,
      minWidth: 90,
    },
    {
      id: "selfieEndDate",
      label: Constants.SELFIE_END_DATE,
      minWidth: 90,
    },
    {
      id: "peerScheduleDate",
      label: Constants.PEER_START_DATE,
      minWidth: 90,
    },
    {
      id: "peerEndDate",
      label: Constants.PEER_END_DATE,
      minWidth: 90,
    },
    {
      id: "status",
      label: Constants.REVIEW_STATUS,
      minWidth: 80,
    },
    // {
    //   id: "review",
    //   label: '',
    //   minWidth: 50,
    // }
  ];

  const pageFun = (value) => {
    setPage(value === 1 ? 0 : value - 1);
    apiCall({ page: value === 1 ? 0 : value - 1, year: selectedYear });
  };
  const responseToSurvey = (item) => {
    if (
      item?.clubbedId !== null &&
      item?.teamId !== null
    ) {
      window.open(
        `${props.userDetail.hostUrl}/response/${item?.surveyId}/report/${item?.teamId}/clubbedId/${item?.clubbedId}/${localStorage?.getItem("access_token")}`
      );
    }
    else {
      navigate(`/add-goals?id=${item?.surveyId}`);
    }
  };
  const clickSurvey = (value) => {
    navigate(`/add-goals?id=${value}`);
  };

  const handleChangeYear = (value) => {
    setSelectedYear(value);
    apiCall({ page, year: value });
  }

  return (
    <>
      <Header active="Objective" />
      <div className={styled.surveyList}>
        <div className={styled.surveyListMain + " bg-color-login"}>
          <div className={styled.emptySpacer} />
          <div className={styled.mainTable}>
            <div className="mySurvey myGoal">
              <DropDown
                options={yearOptions}
                style={{ color: "#333333" }}
                updateValue={handleChangeYear}
                placeholder="Select year"
                value={selectedYear}
                label={'Select year'}
              />
              <div className={styled.tableWrapper}>
                {props?.surveyList?.items?.length > 0 ? props?.isLoading ? (
                  <div style={{ margin: '50px auto 0' }}>
                    <Skeleton variant="text" height={75} />
                    <Skeleton variant="text" height={120} />
                    <Skeleton variant="text" height={120} />
                    <Skeleton variant="text" height={120} />
                  </div>
                ) : (
                  <MainTable
                    columns={columns}
                    rows={props?.surveyList?.items}
                    totalRecords={props?.surveyList?.totalRecords}
                    pageFun={pageFun}
                    pageNumber={page}
                    buttonClick={responseToSurvey}
                    surveyId={clickSurvey}
                    buttonDeleteClick={(value) => props?.deleteGoal(value?.surveyId)}
                  />
                ) : (
                  <div className={"noData"}>{Constants.NO_DATA_FOUND}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

MyGoal.propTypes = propTypes;
export default MyGoal;
