export const GET_GOAL_RECEIVE = 'GET_GOAL_RECEIVE'
export const GET_GOAL_RECEIVE_SUCCESS = 'GET_GOAL_RECEIVE_SUCCESS'
export const GET_GOAL_RECEIVE_ERROR = 'GET_GOAL_RECEIVE_ERROR'

export const GET_GOAL_SENT = 'GET_GOAL_SENT'
export const GET_GOAL_SENT_SUCCESS = 'GET_GOAL_SENT_SUCCESS'
export const GET_GOAL_SENT_ERROR = 'GET_GOAL_SENT_ERROR'

export const GET_GOAL_DETAILS = 'GET_GOAL_DETAILS'
export const GET_GOAL_DETAILS_SUCCESS = 'GET_GOAL_DETAILS_SUCCESS'
export const GET_GOAL_DETAILS_ERROR = 'GET_GOAL_DETAILS_ERROR'

export const GET_GOAL_REPORT = 'GET_GOAL_REPORT'
export const GET_GOAL_REPORT_SUCCESS = 'GET_GOAL_REPORT_SUCCESS'
export const GET_GOAL_REPORT_ERROR = 'GET_GOAL_REPORT_ERROR'

export const DELETE_GOAL = 'DELETE_GOAL'
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS'
export const DELETE_GOAL_ERROR = 'DELETE_GOAL_ERROR'

export const GET_QUARTER_LIST = 'GET_QUARTER_LIST'
export const GET_QUARTER_LIST_SUCCESS = 'GET_QUARTER_LIST_SUCCESS'
export const GET_QUARTER_LIST_ERROR = 'GET_QUARTER_LIST_ERROR'

export const CREATE_QUARTERLY_GOAL = 'CREATE_QUARTERLY_GOAL'
export const CREATE_QUARTERLY_GOAL_SUCCESS = 'CREATE_QUARTERLY_GOAL_SUCCESS'
export const CREATE_QUARTERLY_GOAL_ERROR = 'CREATE_QUARTERLY_GOAL_ERROR'

export const GET_REPORT_SURVEY = 'GET_REPORT_SURVEY'
export const GET_REPORT_SURVEY_SUCCESS = 'GET_REPORT_SURVEY_SUCCESS'
export const GET_REPORT_SURVEY_ERROR = 'GET_REPORT_SURVEY_ERROR'

export const GET_REPORT_SURVEY_DETAIL = 'GET_REPORT_SURVEY_DETAIL'
export const GET_REPORT_SURVEY_DETAIL_SUCCESS = 'GET_REPORT_SURVEY_DETAIL_SUCCESS'
export const GET_REPORT_SURVEY_DETAIL_ERROR = 'GET_REPORT_SURVEY_DETAIL_ERROR'

export const GET_REPORT_GRAPH = 'GET_REPORT_GRAPH'
export const GET_REPORT_GRAPH_SUCCESS = 'GET_REPORT_GRAPH_SUCCESS'
export const GET_REPORT_GRAPH_ERROR = 'GET_REPORT_GRAPH_ERROR'

export const GET_REPORT_QUESTION = 'GET_REPORT_QUESTION'
export const GET_REPORT_QUESTION_SUCCESS = 'GET_REPORT_QUESTION_SUCCESS'
export const GET_REPORT_QUESTION_ERROR = 'GET_REPORT_QUESTION_ERROR'

export const GET_REVIEW_USER_LIST = 'GET_REVIEW_USER_LIST'
export const GET_REVIEW_USER_LIST_SUCCESS = 'GET_REVIEW_USER_LIST_SUCCESS'
export const GET_REVIEW_USER_LIST_ERROR = 'GET_REVIEW_USER_LIST_ERROR'

export const GET_REVIEW_MINUTES = 'GET_REVIEW_MINUTES'
export const GET_REVIEW_MINUTES_SUCCESS = 'GET_REVIEW_MINUTES_SUCCESS'
export const GET_REVIEW_MINUTES_ERROR = 'GET_REVIEW_MINUTES_ERROR'

export const CREATE_REVIEW_MINUTES = 'CREATE_REVIEW_MINUTES'
export const CREATE_REVIEW_MINUTES_SUCCESS = 'CREATE_REVIEW_MINUTES_SUCCESS'
export const CREATE_REVIEW_MINUTES_ERROR = 'CREATE_REVIEW_MINUTES_ERROR'

export const FINALIZE_REVIEW_MINUTES = 'FINALIZE_REVIEW_MINUTES'
export const FINALIZE_REVIEW_MINUTES_SUCCESS = 'FINALIZE_REVIEW_MINUTES_SUCCESS'
export const FINALIZE_REVIEW_MINUTES_ERROR = 'FINALIZE_REVIEW_MINUTES_ERROR'

export const DELETE_REVIEW_MINUTES = 'DELETE_REVIEW_MINUTES'
export const DELETE_REVIEW_MINUTES_SUCCESS = 'DELETE_REVIEW_MINUTES_SUCCESS'
export const DELETE_REVIEW_MINUTES_ERROR = 'DELETE_REVIEW_MINUTES_ERROR'

export const UPDATE_REVIEW_MINUTES = 'UPDATE_REVIEW_MINUTES'
export const UPDATE_REVIEW_MINUTES_SUCCESS = 'UPDATE_REVIEW_MINUTES_SUCCESS'
export const UPDATE_REVIEW_MINUTES_ERROR = 'UPDATE_REVIEW_MINUTES_ERROR'

export const GET_REVIEW_MINUTE_DETAIL = 'GET_REVIEW_MINUTE_DETAIL'
export const GET_REVIEW_MINUTE_DETAIL_SUCCESS = 'GET_REVIEW_MINUTE_DETAIL_SUCCESS'
export const GET_REVIEW_MINUTE_DETAIL_ERROR = 'GET_REVIEW_MINUTE_DETAIL_ERROR'

export const SHARE_REVIEW_MINUTE = 'SHARE_REVIEW_MINUTE'
export const SHARE_REVIEW_MINUTE_SUCCESS = 'SHARE_REVIEW_MINUTE_SUCCESS'
export const SHARE_REVIEW_MINUTE_ERROR = 'SHARE_REVIEW_MINUTE_ERROR'

export const GET_REVIEW_MINUTE_PEERS = 'GET_REVIEW_MINUTE_PEERS'
export const GET_REVIEW_MINUTE_PEERS_SUCCESS = 'GET_REVIEW_MINUTE_PEERS_SUCCESS'
export const GET_REVIEW_MINUTE_PEERS_ERROR = 'GET_REVIEW_MINUTE_PEERS_ERROR'