import * as React from "react";
import "../../../css/survey.css";
import PropTypes from "prop-types";
const propTypes = {
  index: PropTypes.number,
  value: PropTypes.any,
  isMobileLayout: PropTypes.bool,
  setValue: PropTypes.func
};
const RatingQuestion = (props) => {

  React.useEffect(() => {

    resetRatingsBtn(props.value);

    const localEle = document.getElementById(`rating-${props.index}`)
      ? document.getElementById(`rating-${props.index}`)
      : document;

    const ratingsBtn = localEle.querySelectorAll(".response-table-count");

    for (let i = 0; i < ratingsBtn.length; i++) {
      ratingsBtn[i].addEventListener("mouseenter", (e) => {
        const equalSplit = 1 / parseInt(e.target.value);
        let opacity = equalSplit;
        for (let i = 0; i < e.target.value; i++) {
          ratingsBtn[i].classList.add(`active`);
          ratingsBtn[i].style.opacity = opacity;
          opacity = equalSplit + opacity;
        }
      });
      ratingsBtn[i].addEventListener("mouseleave", (e) => {
        for (let i = 0; i < e.target.value; i++) {
          if (props.value === "" || i > props.value - 1) {
            ratingsBtn[i].classList.remove(`active`);
            ratingsBtn[i].style.opacity = 1;
          }
        }
        resetRatingsBtn(props.value);
      });
    }
  }, []);

  React.useEffect(() => {
    resetRatingsBtn(props.value);
    const localEle = document.getElementById(`rating-${props.index}`)
      ? document.getElementById(`rating-${props.index}`)
      : document;
    const ratingsBtn = localEle.querySelectorAll(".response-table-count");
    for (let i = 0; i < ratingsBtn.length; i++) {
      ratingsBtn[i].addEventListener("mouseenter", (e) => {
        const equalSplit = 1 / parseInt(e.target.value);
        let opacity = equalSplit;
        for (let i = 0; i < e.target.value; i++) {
          ratingsBtn[i].classList.add(`active`);
          ratingsBtn[i].style.opacity = opacity;
          opacity = equalSplit + opacity;
        }
      });
      ratingsBtn[i].addEventListener("mouseleave", (e) => {
        for (let i = 0; i < e.target.value; i++) {
          if (props.value === "" || i > props.value - 1) {
            ratingsBtn[i].classList.remove(`active`);
            ratingsBtn[i].style.opacity = 1;
          }
        }
        resetRatingsBtn(props.value);
      });
    }
  }, [props.value]);

  const resetRatingsBtn = (val) => {
    const localEle = document.getElementById(`rating-${props.index}`)
      ? document.getElementById(`rating-${props.index}`)
      : document;
    const ratingsBtn = localEle.querySelectorAll(
      ".response-table-count.rating-type"
    );
    for (let i = 0; i < ratingsBtn.length; i++) {
      ratingsBtn[i].classList.remove(`active`);
      ratingsBtn[i].style.opacity = 1;
      const equalSplit = 1 / parseInt(val);
      let opacity = equalSplit;
      for (let i = 0; i < val; i++) {
        ratingsBtn[i].classList.add(`active`);
        ratingsBtn[i].style.opacity = opacity;
        opacity = equalSplit + opacity;
      }
    }
  };

  return (
    <div id={`rating-${props.index}`}>
      <p style={{ marginBottom: 0 }}>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(1)}
          style={{ backgroundColor: props.value === 1 ? "#FF6100" : "" }}
          value="1"
        >
          1
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(2)}
          style={{ backgroundColor: props.value === 2 ? "#FF6100" : "" }}
          value="2"
        >
          2
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(3)}
          style={{ backgroundColor: props.value === 3 ? "#FF6100" : "" }}
          value="3"
        >
          3
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(4)}
          style={{ backgroundColor: props.value === 4 ? "#FF6100" : "" }}
          value="4"
        >
          4
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(5)}
          style={{ backgroundColor: props.value === 5 ? "#FF6100" : "" }}
          value="5"
        >
          5
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(6)}
          style={{ backgroundColor: props.value === 6 ? "#FF6100" : "" }}
          value="6"
        >
          6
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(7)}
          style={{ backgroundColor: props.value === 7 ? "#FF6100" : "" }}
          value="7"
        >
          7
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(8)}
          style={{ backgroundColor: props.value === 8 ? "#FF6100" : "" }}
          value="8"
        >
          8
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(9)}
          style={{ backgroundColor: props.value === 9 ? "#FF6100" : "" }}
          value="9"
        >
          9
        </button>
        <button
          className="response-table-count rating-type"
          onClick={() => props.setValue(10)}
          style={{ backgroundColor: props.value === 10 ? "#FF6100" : "" }}
          value="10"
        >
          10
        </button>
        <button
          className={`response-table-count last-dnk ${props.value === "DNK" ? "active" : ""
            }`}
          onClick={() => props.setValue("DNK")}
        >
          Don&#180;t Know
        </button>
      </p>
    </div>
  );
};

RatingQuestion.propTypes = propTypes;
export default RatingQuestion;
