import React, { Component } from 'react';
//import store from '../../store/index';
//import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    //store.dispatch(push('/error'));
    //location.replace('/unauthorized');
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div id="error-container" className="access-denied-conatiner">
          <h1>Oops! Something went wrong.</h1>
          <div className="error-image"></div>
          <p>
            <span id="platformErrorMessage">An error occurred, please try again or contact the administrator</span>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;