import React, { useEffect, useState, useRef } from "react";
import Header from "../../../../../common/Header";
import PrimaryButton from "../../../../../common/PrimaryButton";
import styles from "../../../css/inviteParticipant.module.css";
import addFilled from "../../../../../assets/addFilled.svg";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBox from "../../../../../common/Checkbox";
import "../../../css/survey.css";
//import { useNavigate } from "react-router-dom";
import Constants from "../../../../../config";
import BasicModal from "../../../../../common/Modal";
import Field from "../../../../../common/Field";
import PropTypes from "prop-types";
import BreadCrumbs from "../../../../../common/BreadCrumbs";
import translations from "../../../../../config/translations.json";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import useDebounce from "../../../../../common/Debounce/use-debounce";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../../../../common/loader/index";
import CheckIcon from '@mui/icons-material/Check';
import { useSearchParams } from "react-router-dom";
import styled from "../../../css/addQuestion.module.css";
import moment from "moment";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  createSurvey: PropTypes.func,
  getSurveyDescription: PropTypes.func,
  items: PropTypes.object,
  getPeersList: PropTypes.func,
  surveyDetail: PropTypes.array,
  peerList: PropTypes.array,
  addExternalPeers: PropTypes.func,
  editsurveyDetail: PropTypes.object,
  getSurveyDetails: PropTypes.func,
  invitePeers: PropTypes.func,
  searchPeerList: PropTypes.any,
  searchPeer: PropTypes.func,
  searchAddPeer: PropTypes.func,
  userDetail: PropTypes.any,
  getUserDetails: PropTypes.func,
  isDesktopLayout: PropTypes.bool,
  sendSurvey: PropTypes.func
};

const ModelContent = ({
  handleClose,
  saveExternalPeers,
  surveyDetail,
  errorFalse,
}) => {
  const [formObj, setData] = useState({
    name: { value: "", error: false },
    email: { value: "", error: false },
  });

  const onChange = (event) => {
    if (event.target.value.trim() !== '' || event.target.value === '') {
      setData({
        ...formObj,
        [event.target.name]: { value: event.target.value, error: false },
      });
    }
    errorFalse();
  };

  const onSave = (e) => {
    errorFalse();
    e.preventDefault();
    setData({
      ...formObj,
      email: {
        ...formObj["email"],
        error:
          formObj["email"].value.trim() === "" ||
          !emailValidation(formObj["email"].value.trim()),
      },
      name: {
        ...formObj["name"],
        error: formObj["name"].value === "",
      },
    });
    if (
      formObj["email"].value !== "" &&
      formObj["name"].value !== "" &&
      emailValidation(formObj["email"].value.trim())
    ) {
      let data = {
        surveyId: surveyDetail?._id,
        email: formObj["email"].value.trim(),
        name: formObj["name"].value.trim(),
      };
      saveExternalPeers(data);
    }
  };
  const emailValidation = (email) => {
    const regex = RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regex.test(email);
  };
  return (
    <div className="passwordChangeField">
      <Field
        placeholder={Constants.ENTER_NAME}
        type={"text"}
        name={"name"}
        value={formObj.name.value}
        onChange={onChange}
        error={formObj.name.error}
        helperText={
          formObj.name.error ? translations.errorMessage.emptyField[0] : null
        }
      />
      <Field
        placeholder={Constants.ENTER_EMAIL}
        type={"text"}
        name={"email"}
        value={formObj.email.value}
        onChange={onChange}
        error={formObj.email.error}
        helperText={
          formObj.email.error ? translations.errorMessage.email1[0] : null
        }
      />
      <div>
        <div className="cardModalButton">
          <PrimaryButton
            text={Constants.CANCEL}
            onClick={handleClose}
            className="cancelButton"
          />
          <PrimaryButton
            text={Constants.SAVE}
            onClick={onSave}
            className="saveButton"
          />
        </div>
      </div>
    </div>
  );
};

const InviteParticipants = (props) => {
  //const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [showError, setError] = useState(false);
  const [savePeers, setSavePeers] = useState([]);

  const [selfEvaluationChecked, setSelfEvaluationChecked] = useState(true);

  const [debouncevalue, setdebouncevalue] = useState(false);

  const [value, setvalue] = useState("");

  const debouncedSearchTerm = useDebounce(value, debouncevalue && 500);

  const [showMsg, setShowMsg] = useState(false);

  const [isLoading, setLoader] = useState(false);

  const [isLoadingOther, setLoaderOther] = useState(false);

  const [isLoadingSmall, setLoaderSmall] = useState(false);

  const [allPeersIdList, setAllPeersIdList] = useState([]);

  const [dateError, setDateError] = React.useState(false);
  const [dateErrorOther, setDateErrorOther] = React.useState(false);
  //const [selectDays, setselectDays] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startSelfDate, setStartSelfDate] = React.useState("");
  const [endSelfDate, setendSelfDate] = React.useState("");
  //const [repeatSelfValue, setrepeatSelfValue] = React.useState("");
  //const [frequencySelfValue, setfrequencySelfValue] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [isLoadingSendSurvey,setIsLoadingSendSurvey] = useState(false);
  const [showSendSurveyMessage,setShowSendSurveyMessage] = useState(false);

  // const openMenu = Boolean(anchorEl);

  const handleClick = (event,index) => {
    let data = [...anchorEl];
    data[index] = event.currentTarget

    setAnchorEl(data);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl([]);
  };

  const handleMenuItemClick = (item) => {
    console.log(item);
    setIsLoadingSendSurvey(true);
    props.sendSurvey({email:item.email,memberId:item.memberId,name:item.name},searchParams.get("id") ? searchParams.get("id") : props?.surveyId)
  }
  const wrapperRef = useRef(null);

  const breadCrumbsList = [
    {
      name: Constants.ADD_QUESTIONAIRE,
      click: `/add-goals?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.MANAGE_PEER,
      click: `/invite-participants?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.SCHEDULE_SETTINGS,
      click: `/send-survey?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    }
  ];

  useEffect(() => {
    if (debouncedSearchTerm) {
      resultApiCall(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    props?.getPeersList({ all: true,surveyId:searchParams.get("id") ? searchParams.get("id") : props?.surveyId });
  }, []);
  useEffect(() => {
    switch (props?.status) {
      case "ADD_EXTERNAL_PEERS_SUCCESS":
        handleClose();
        props?.getPeersList({ all: true,surveyId:searchParams.get("id") ? searchParams.get("id") : props?.surveyId });
        break;
      case "ADD_EXTERNAL_PEERS_ERROR":
        setError(true);
        break;
      case "INVITE_PEERS":
        setLoaderOther(true);
        break;
      case "INVITE_PEERS_SUCCESS":
        setLoaderOther(false);
        props.getUserDetails();
        break;
      case "INVITE_PEERS_ERROR":
        setLoaderOther(false);
        setError(true);
        break;
      case "GET_GOAL_DETAILS":
        setLoaderSmall(true);
        break;
      case "GET_GOAL_DETAILS_SUCCESS":
        var totalPeerList = [];
        props?.peerList?.length && props?.peerList?.map((data) => {
          totalPeerList.push(data.memberId)
        });
        if (props?.userDetail?.excludeUserPeers?.length) {
          totalPeerList = [...totalPeerList];
          props?.userDetail?.excludeUserPeers.map(item => {
            if (totalPeerList.includes(item)) {
              const index = totalPeerList.indexOf(item);
              totalPeerList.splice(index, 1);
            }
          });
        }
        setSavePeers(totalPeerList);
        setSelfEvaluationChecked(props?.editsurveyDetail?.isSelfieEnabled
          ? props?.editsurveyDetail?.isSelfieEnabled
          : false);
        setLoaderSmall(false);
        /*if (props?.surveyDetail?.responderDuration && props?.surveyDetail?.responderDuration !== '') {
          setselectDays(`${props?.surveyDetail?.responderDuration} Day`);
        }*/
        if (Object.keys(props?.surveyDetail).length && Object.keys(props?.surveyDetail?.quarter).length) {
          /*setrepeatSelfValue(props?.surveyDetail?.quarter.selfieRecurrance);
          if (props?.surveyDetail?.quarter.selfieRecurrance && props?.surveyDetail?.quarter.selfieRecurrance !== '' && props?.surveyDetail?.quarter.selfieRecurrance !== 'never') {
            setfrequencySelfValue(`Every ${props?.surveyDetail?.quarter.selfieRepeat} ${props?.surveyDetail?.quarter?.selfieRecurrance === 'weekly' ? 'Week' : 'Day'}${Number(props?.surveyDetail?.quarter.selfieRepeat) > 1 ? 's' : ''}`);
          }*/
          let startDate = new Date(moment.unix(props?.surveyDetail?.selfieScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          //startDate.setHours(calculateMinTime(startDate).getHours() + 1, 0, 0, 0);
          setStartSelfDate(startDate);
          let endSelfieDate = new Date(props?.surveyDetail?.newSelfieEndDate);
          setendSelfDate(endSelfieDate);

          let startDatePeer = new Date(moment.unix(props?.surveyDetail?.peerScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          setStartDate(startDatePeer);
          let endPeerDate = new Date(props?.surveyDetail?.newPeerEndDate);
          setEndDate(endPeerDate);
        }
        break;
      case "GET_GOAL_DETAILS_ERROR":
        setLoaderSmall(false);
        break;
      case "ADD_SEARCH_PEERS_SUCCESS":
        props?.getPeersList({ all: true,surveyId:searchParams.get("id") ? searchParams.get("id") : props?.surveyId });
        setShowMsg(true);
        setTimeout(() => setShowMsg(false), 3000);
        break;
      case "ADD_SEARCH_PEERS_ERROR":
        setShowMsg(false);
        break;
      case "GET_PEER_LIST_SUCCESS":
        props?.getSurveyDetails(searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id);
        // externalList.concat(props?.peerList)
        var localPeerList = [];
        props?.peerList?.map((data) => {
          localPeerList.push(data.memberId)
        });
        setAllPeersIdList(localPeerList);
        setSavePeers(localPeerList);
        break;
      case "SEARCH_PEERS":
        setLoader(true);
        break;
      case "SEARCH_PEERS_SUCCESS":
        setLoader(false);
        break;
      case "SEARCH_PEERS_ERROR":
        setLoader(false);
        break;
      case "SCHEDULE_REVIEW_PEER_SUCCESS":
        setSuccessMsg(true);
        setTimeout(() => setSuccessMsg(false), 3000);
        props?.getSurveyDetails(searchParams.get("id") ? searchParams.get("id") : props?.surveyId);
        break;
      case "SEND_SURVEY_SUCCESS":
        setAnchorEl([]);
        setIsLoadingSendSurvey(false);
        setShowSendSurveyMessage(true);
        props.getUserDetails();
        props?.getPeersList({ all: true,surveyId:searchParams.get("id") ? searchParams.get("id") : props?.surveyId });
        break;
    }
  }, [props?.status, props?.getSurveyDetails]);

  const resultApiCall = (debouncedSearchTerm) => {
    props?.searchPeer(debouncedSearchTerm);
  };

  const onChange = (e) => {
    setvalue(e.target.value);
    setdebouncevalue(true);
    setShowMsg(false);
  };

  const handleAdd = (item) => {
    let data = {
      userIds: [
        {
          userId: item?.id,
          isManager: 0,
        },
      ],
    };
    props?.searchAddPeer(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(!open);
  };
  const saveExternalPeers = (value) => {
    props?.addExternalPeers(value);
  };
  const handleChange = (event, id) => {
    setError(false);
    let tempSavePeers = [];
    if (event.target.checked) {
      tempSavePeers = [...savePeers, id];
      setSavePeers(tempSavePeers);
    } else {
      let temp = [...savePeers];
      tempSavePeers = temp.filter((value) => value !== id);
      setSavePeers(tempSavePeers);
    }
    props?.invitePeers({
      surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
      peers: getExcludedPeers(tempSavePeers),
      isSelfieEnabled: selfEvaluationChecked ? 1 : 0
    })
  };

  const getExcludedPeers = (tempSavePeers) => {
    let excludedId = [];
    if (allPeersIdList.length !== tempSavePeers.length) {
      excludedId = allPeersIdList.filter(x => !tempSavePeers.includes(x));
    }
    return excludedId;
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setvalue('');
          console.log(onSave);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onSave = () => {
    let sDate1 = new Date(startDate);
    let eDate1 = new Date(endDate);
    let sDate2 = new Date(startSelfDate);
    let eDate2 = new Date(endSelfDate);
    let cDate = new Date();
    let error1 = false;
    let error2 = false;
    if (props?.editsurveyDetail?.isSelfieEnabled === 1) {
      if (eDate2 < sDate2 || eDate1 < sDate1) {
        error1 = true;
      }
      if (sDate2 < cDate || eDate2 < cDate || sDate1 < cDate) {
        error2 = true;
      }
    }
    if (error1) {
      setDateError(true);
      setTimeout(() => setDateError(false), 3000);
    } else if (error2) {
      setDateErrorOther(true);
      setTimeout(() => setDateErrorOther(false), 3000);
    } else {
      let data = {
        surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
        status: 'SCHEDULED'
        /*duration: Number(selectDays.split(" ")[0]),
        schedule_later: 1,
        peer_review: {
          scheduleDate: startDate,
          repeat: '',
          repeate_frequency_value: '',
          endDate: endDate,
        },
        self_evaluation: props?.editsurveyDetail?.isSelfieEnabled === 1 ? {
          scheduleDate: startSelfDate,
          repeat: repeatSelfValue,
          repeat_frequency_value:
            frequencySelfValue !== '' ?
              repeatSelfValue === "never"
                ? ""
                : Number(frequencySelfValue.split("Every ")[1].split(" ")[0]) : '',
          endDate: endSelfDate
        } : null,*/
      };
      props?.sendSurveyPeer(data);
    }
  }

  useOutsideAlerter(wrapperRef);

  return (
    <>
      <Header active="Goal" />
      <div className={styles.inviteParticipantsPage}>
        <div className={styles.inviteParticipantsInnerPage}>
          <div className={styled.tabRowOther} style={{width: '65%'}}>
            <div className={styled.topDiv}>
              {props?.surveyDetail?.name}
            </div>
            <div className={styled.isDesktop}>
              {
                props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
                  <div className={styled.statusMessage}>
                    <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat preferences. The changes made will be reflected in your Performance selfies and peer Performance review.</div>
                  </div> :
                  <div style={{ width: '45%' }} />
              }
            </div>
            {/* <div className={styled.scheduleWrapper}>
              <PrimaryButton
                text={Constants.SCHEDULE_REVIEW}
                onClick={onSave}
                className={styled.createSurvey}
                disabled={!props?.previewQuestion?.length || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
              />
            </div> */}
          </div>
          <div className={styled.isMobile}>
            {
              props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
                <div className={styled.statusMessage}>
                  <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                  <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat prefernces. You can not delete the goals. The changes made will be reflected in your selfies and peer review.</div>
                </div>
                : null
            }
          </div>
          <BreadCrumbs list={breadCrumbsList} active={2} />
          <div className={styles.isDesktop}>
            <div className={styles.inviteParticipantHead}>
              <div className={styles.inviteParticipantHeadCol1}>
                <div className={styles.inviteParticipantheadText}>
                  {Constants.MANAGE_PEER}
                </div>
                <div className={styles.inviteParticipantheadPara}>
                  {Constants.INVITE_PEERS_DES}
                </div>
              </div>
              <div className={styles.inviteParticipantHeadCol2}>
                {/*<CheckBox
                  checked={allPeersIdList.length === savePeers.length}
                  handleChange={changeSelectAll}
                />
                <span className={styles.selfEvaluationText}>{Constants.SELECT_ALL}</span>
                <CheckBox
                  checked={selfEvaluationChecked}
                  handleChange={() => changeSelfIncluded()}
                  disabled={true}
                  />
                <span className={styles.selfEvaluationText}>
                  {Constants.SELFIE}
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                      <IconButton aria-describedby={id} onClick={handleClick} style={{ marginTop: '-5px' }}>
                        <HelpIcon style={{ fontSize: '18px' }} />
                      </IconButton>
                      <Popper id={id} open={openCheck} anchorEl={anchorEl}>
                        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', fontSize: '12px' }}>
                          The same goal can be used as a selfie and peer review.
                        </Box>
                      </Popper>
                    </Box>
                  </ClickAwayListener>
  </span>*/}
                <PrimaryButton
                  text={
                    <>
                      <img src={addFilled} style={{ marginRight: "10px" }} />
                      {Constants.ADD_EXTERNAL_PEERS}
                    </>
                  }
                  onClick={handleClickOpen}
                  className={styles.addExternal}
                />
              </div>
            </div>
          </div>
          <div className={styles.isMobile}>
            <div className={styles.inviteParticipantMobileHead}>
              <div className={styles.inviteParticipantHeadRow}>
                <div className={styles.inviteParticipantheadText}>
                  {Constants.INVITE_PEERS}
                </div>
                <div
                  className={styles.addExternalText}
                  onClick={handleClickOpen}
                >
                  <AddCircleIcon
                    style={{
                      marginRight: "5px",
                      fontSize: "15px",
                      color: "#14009B",
                    }}
                  />
                  {Constants.ADD_EXTERNAL_PEERS}
                </div>
              </div>
              <div className={styles.inviteParticipantheadPara}>
                {Constants.INVITE_PEERS_DES}
              </div>
            </div>
          </div>
          <div className={styles.mapCardBox}>
            <div className={styles.mapCardsOther} style={{ position: 'relative' }}>
              <Loader isLoading={isLoading} />
              <Field
                placeholder={Constants.SEARCH_ADD_PEERS}
                type={"text"}
                name={"searchText"}
                value={value}
                onChange={onChange}
                className={"search-add-peer"}
                endadornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle search and close button"
                      onClick={() => debouncedSearchTerm && debouncedSearchTerm !== '' ? setvalue('') : null}
                      edge="end"
                    >
                      {debouncedSearchTerm && debouncedSearchTerm !== '' ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                value !== '' ?
                  <div className={styles.popUpList} ref={wrapperRef}>
                    {
                      props?.searchPeerList?.userList?.length ?
                        props?.searchPeerList?.userList?.map((item) => {
                          return (
                            item.id !== props.userDetail.loggedUserIdNew ?
                              <div className={styles.searchMapContent} key={item?.id}>
                                <div className={styles.cardBoxPop}>
                                  <div>
                                    <div className={styles.cardName}>{item?.name}</div>
                                    <div className={styles.cardEmail}>{item?.email}</div>
                                  </div>
                                </div>
                                <div className={styles.cardBoxright}>
                                  {
                                    props?.peerList?.length && props?.peerList?.findIndex(obj => obj.memberId === item.id) > -1 ?
                                      <div className={styles.peerAddedText}><CheckIcon style={{ fontSize: '12px', marginRight: '3px' }} /> Added as Peer</div> :
                                      <PrimaryButton
                                        text={
                                          <>
                                            <img src={addFilled} style={{ marginRight: "5px" }} />
                                            {Constants.ADD_TO_PEER}
                                          </>
                                        }
                                        onClick={() => handleAdd(item)}
                                        className={styles.peerAddBut}
                                      />
                                  }
                                </div>
                              </div> : null
                          );
                        }) :
                        <p className={styles.noData}>{Constants.NO_DATA_FOUND}</p>
                    }
                  </div>
                  : null
              }
            </div>
            {props?.peerList?.map((item, index) => {
              // let arrName = item?.name.split(" ");
              let iniName = item?.name.charAt(0);
              // let iniLname = arrName.length > 0  ? arrName[arrName?.length - 1].charAt(0) : '';
              return (
                <div key={index} className={styles.mapCards}>
                  <div className={styles.checkBlock + " checkInviteBox"}>
                    <CheckBox
                      checked={savePeers.includes(item?.memberId)}
                      handleChange={(event) => handleChange(event, item?.memberId)}
                    />
                  </div>
                  <div className={styles.cardBox} style={{ position: 'relative' }}>
                    <Loader isLoading={isLoadingSmall} />
                    <div className={styles.cardBoxCircle}>
                      <span className={styles.cardBoxCircleText}>
                        {iniName}
                      </span>
                    </div>
                    <div>
                      <div className={styles.cardName}>{item?.name} {item?.isExternalPeer ? <span className={styles.mapCardRowspanOther}>{Constants.EXTERNAL}</span> : null}{item?.manager ? <span className="smallTag">{Constants.MANAGER}</span> : null}</div>
                      <div className={styles.cardEmail}>
                        {item?.email}
                      </div>
                    </div>
                  </div>
                  {
                    !item?.reviewSent && (props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED') && props?.surveyDetail?.reviewSurveySentStatus ?
                    <div style={{marginTop:'2%'}}>
                        <Loader isLoading={isLoadingSendSurvey} />
                        <MoreVertRoundedIcon style={{ color: '#f56102',cursor:'pointer'}} onClick={(event) => handleClick(event,index)}/>
                        <Menu
                          key={index}
                          id="demo-customized-menu"
                          anchorEl={index in anchorEl ? anchorEl[index] : null}
                          open={Boolean(index in anchorEl ? anchorEl[index]: 0)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem
                          onClick={() => handleMenuItemClick(item)}
                          className="createQuarterMenu"
                        >
                          Send Review
                        </MenuItem>
                        </Menu>
                        </div>
                    :
                     null
                  }
                  </div>
              );
            })}
          </div>
          {showError ? (
            <ToastNotify
              data={props?.errorMessage}
              openAlert={true}
              type={"error"}
            />
          ) : (
            ""
          )}
          {showSendSurveyMessage ? (
            <ToastNotify
              data={"Survey has been shared Successfully"}
              openAlert={true}
              type={"success"}
            />
          ) : (
            ""
          )}
          <Loader isLoading={isLoadingOther} />
        </div>
      </div>
      {open ? (
        <BasicModal
          open={open}
          handleClose={handleClose}
          heading={Constants.ADD_EXTERNAL_PEERS}
          content={
            <ModelContent
              handleClose={handleClose}
              saveExternalPeers={saveExternalPeers}
              surveyDetail={props?.surveyDetail}
              errorMessage={props?.errorMessage}
              errorFalse={() => setError(false)}
            />
          }
        />
      ) : (
        <></>
      )}
      {showMsg ? (
        <ToastNotify
          data={Constants.PEER_ADD_TOAST}
          openAlert={true}
          type={"success"}
        />
      ) : (
        ""
      )}
      <ToastNotify
        data={'Active Till cannot be less than start date'}
        openAlert={dateError}
        type={"error"}
      />
      <ToastNotify
        data={'Please change the Selfie Start date to current or future dates and then Schedule the Review again'}
        openAlert={dateErrorOther}
        type={"error"}
      />
      <ToastNotify
        data={"Survey has been scheduled Successfully"}
        openAlert={successMsg}
        type={"success"}
      />
    </>
  );
}
InviteParticipants.propTypes = propTypes;
export default InviteParticipants;
