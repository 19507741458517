export const fakeDelay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function parseJwt(token) {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
}

export function getCookie(name) {
  let v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function setCookie(name, value, days, domain) {
  let d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie =
    name +
    "=" +
    value +
    ";domain=" +
    domain +
    ";path=/;expires=" +
    d.toGMTString();
}

export function deleteCookie(name) {
  setCookie(name, "", -1, "");
}

export const generateUniqueSessionID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const setLoginSession = (name, value) => {
  if (window.localStorage) {
    //const sessionId = generateUniqueSessionID();
    localStorage.setItem(name, value);
  }
};

export const getLoginSession = (name) => {
  if (window.localStorage) {
    const sessionId = localStorage.getItem(name);
    return sessionId;
  } else {
    return null;
  }
};

export const removeLoginSession = (name) => {
  if (window.localStorage) {
    localStorage.removeItem(name);
  }
};

export const config = {
  baseURL: `https://test.stateofagility.com/api/v1`,
  // baseURL: `https://portal.stateofagility.com/api/v1`,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  },
  // withCredentials: true
};
export const responseConfig = {
  baseURL: `https://test.stateofagility.com/cwb`,
  // baseURL: `https://portal.stateofagility.com/cwb`,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  },
};

export const responseConfigOther = {
  baseURL: `https://test.stateofagility.com/cwb`,
  // baseURL: `https://portal.stateofagility.com/cwb`,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
};

export const generateError = (error, flag) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 422:
        return error.response &&
          error.response.data &&
          error.response.data.errors
          ? error.response.data.errors
          : "Something went wrong";
      case 400:
        return error.response &&
          error.response.data &&
          error.response.data.message && error.response.data.message !== '' && typeof error.response.data.message !== 'boolean'
          ? error.response.data.message :
          typeof error.response.data.data === "object"
            ? Object.values(error.response.data.data)
            : error.response.data.data[0];
      case 401:
        localStorage.clear();
        if (!flag) {
          location.replace('/login');
        }
        return error.response &&
          error.response.data &&
          error.response.data.message
          ? error.response.data.message
          : "Something went wrong";
      case 500:
        location.replace('/unauthorized');
        break;
      default:
        return "Something went wrong";
    }
  } else return "Something went wrong";
};

export const findClosingBracketMatchIndex = (arr, pos) => {
  if (arr[pos] != "(") {
    throw new Error("No '(' at index " + pos);
  }
  let depth = 1;
  for (let i = pos + 1; i < arr.length; i++) {
    switch (arr[i]) {
      case "(":
        depth++;
        break;
      case ")":
        if (--depth == 0) {
          return i;
        }
        break;
    }
  }
  return -1; // No matching closing parenthesis
};

export const customMethodToCalculate = (str) => {
  return Function(`'use strict'; return (${str})`)();
};

export const getInitials = (string) => {
  let names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const sort_by_key = (array, key) =>
  array.sort((a, b) => (parseFloat(a[key]) < parseFloat(b[key]) ? 1 : -1));
