import { connect } from 'react-redux'
import Dashboard from './components/dashboard'
import {
  getDashboardDetails,
  getTeamDetails,
  getSelfieGraphDetails,
  postExtendDate,
  getMeDetails,
  getGoalDetails,
  getQuarterListData,
  setSelectedQuarter,
  setSelectedYear,
  excludeUnExcludePeer
} from "../store/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardDetails: state.dashboard.dashboardDetails,
    teamDetails: state.dashboard.teamDetails,
    selfieGraphDetails: state.dashboard.selfieGraphDetails,
    meDetails: state.dashboard.meDetails,
    goalDetails: state.dashboard.goalDetails,
    errorMessage: state.dashboard.errorMessage,
    isLoading: state.dashboard.isLoading,
    status: state.status,
    userDetail: state.auth.userDetail,
    selectedQuarter: state.dashboard.selectedQuarter,
    selectedYear: state.dashboard.selectedYear,
    ownProps,
    quarterList: state.surveyList.quarter.items,
    publicGoalUserList: state.dashboard.publicGoalUserList,
    isDesktopLayout: state.browser.screenLayout === "desktop",
    isTabletLayout: state.browser.screenLayout === "tablet",
    isMobileLayout: state.browser.screenLayout === "mobile",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardDetails: (data) => {
      dispatch(getDashboardDetails(data));
    },
    getQuarterList: (data) => {
      dispatch(getQuarterListData(data));
    },
    getTeamDetails: (quarterId) => {
      dispatch(getTeamDetails(quarterId));
    },
    getSelfieGraphDetails: (quarterId) => {
      dispatch(getSelfieGraphDetails(quarterId));
    },
    postExtendDate: (data) => {
      dispatch(postExtendDate(data));
    },
    getMeDetails: (quarterId) => {
      dispatch(getMeDetails(quarterId));
    },
    getGoalDetails: (surveyId, request) => {
      dispatch(getGoalDetails(surveyId, request));
    },
    setSelectedQuarter: (quarterId) => {
      dispatch(setSelectedQuarter(quarterId));
    },
    setSelectedYear: (year) => {
      dispatch(setSelectedYear(year));
    },
    excludeUnexcludePeer: (quarterId, data) => {
      dispatch(excludeUnExcludePeer(quarterId, data));
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)