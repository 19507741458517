const Constants = {
    APP_TITLE: 'Customer portal for State of Agility',
    AUTH_SIDEBOX_HEADING: 'Performance Booster',
    AUTH_SIDEBOX_PARA: 'Accelerate your growth with regular reflection and feedback.',
    FORGOT_PASSWORD: 'Forgot Password',
    ENTER_EMAIL: 'Enter Email',
    SEND_TEMPORARY_PASSWORD: 'Send Temporary Password',
    TEMPORARY_PASSWORD_TEXT: 'Temporary password has been successfully sent to your registered Email ID',
    LOGIN_HEADING: 'Sign In',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    ENTER_PASSWORD: 'Enter Password',
    VALID_GOAL: 'Please enter valid Objective with minimum 3 characters',
    REMEMBER_ME: 'Remember me',
    LOGIN: 'Login',
    SIGNIN: 'Sign In',
    SIGNINWITHOUTLOOK: 'Login with Microsoft Office 365',
    OOPS: 'Oops!',
    PAGE_NOT_FOUND: 'Page not Found',
    YOU_ARE_NOT_AUTHORIZED: 'You are not Authorized',
    GO_TO_HOME: 'Go to Home',
    DASHBOARD: 'Dashboard',
    GOAL: 'Objective',
    QUARTERLY_GOALS: 'Quarterly Objectives',
    TEMPLATES: 'Templates',
    IKIGAI: 'Ikigai',
    MY_PEERS: 'My Peers',
    SEARCH_GOAL: 'Search Objective',
    PROFILE: 'Profile',
    CHANGE_PASSWORD: 'Change Password',
    LOGOUT: 'Logout',
    SAVE: 'Save',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    ENTER_CURRENT_PASSWORD: 'Enter Current Password',
    ENTER_NEW_PASSWORD: 'Enter New Password',
    MY_GOAL: 'My Objectives',
    RECEIVED: 'Received',
    REVIEWS: 'Reviews',
    REQUESTED_REVIEWS: 'Requested Reviews',
    SENT_REVIEWS: 'Sent Reviews',
    SENT_DATE: 'Sent Date',
    REPORTS: 'Reports',
    REVIEW_REPORTS: 'Review Reports',
    EDIT: 'Edit',
    GOAL_NAME: 'Objective Name',
    PARTICIPANTS: 'Participants',
    START_DATE: 'Start Date',
    SELFIE_START_DATE: 'Selfie Start Date',
    SELFIE_END_DATE: 'Selfie End Date',
    END_DATE: 'End Date',
    PEER_SCHEDULED_DATE: 'Peer Review Date',
    PEER_START_DATE: 'Peer Start Date',
    PEER_END_DATE: 'Peer End Date',
    REVIEW_DATE: 'Review Date',
    REVIEW_END_DATE: 'Review End Date',
    REVIEW_STATUS: 'Review Status',
    STATUS: 'Status',
    ACTION: 'Action',
    REQUESTED_FROM: 'Requested From',
    RESPONSE_TO_GOAL: 'Respond',
    REPORT_TITLE: 'Report Title',
    DOWNLOAD_REPORT: 'Download Report',
    VIEW_REPORT: 'View Report',
    START_SUREVY_HEADING: 'How would you like to create your Objective?',
    EXISTING_TEMPLATE: 'Create Objective from existing template',
    EXISTING_TEMPLATE_PARA: 'Select our precreated tempelates for you to stand out different in the crowd',
    FROM_STRATCH: 'Create Objective from scratch',
    FROM_STRATCH_PARA: 'Create your own Objective from scratch let’s get started now',
    NEXT: 'Next',
    BROWSE_TEMPLATES: 'Browse Templates',
    BROWSE_TEMPLATES_PARA: 'Need Ideas? Have your pick from our range of custom made Objective templates, just for you!',
    SELECT_TEMPLATES: 'Search Templates',
    ENTER_GOAL_DETAILS: 'Please enter your Objective details below',
    ENTER_NAME: 'Enter Name',
    ENTER_GOAL_NAME: 'Enter Objective Name',
    CANCEL: 'Cancel',
    BACK: 'Back',
    CREATE_GOAL: 'Create Quarterly Objective',
    EDIT_GOAL: 'Edit Objective',
    ADD_NEW: 'Add New',
    AGILE_SCRUM_MASTER_GOAL: 'Agile Scrum Master Objective',
    ADD_NEW_GOAL: 'Add New Objective',
    SHORT_TEXT: 'Short Text',
    SHORT_TEXT_PARA: 'A free text answer paragraph for qualitative feedbacks.',
    MULTI_CHOICE: 'Multiple Choice',
    MULTI_CHOICE_PARA: 'Select one answer from the list of Options.',
    YES_NO: 'Yes & No',
    YES_NO_PARA: 'A free text answer paragraph for qualitative feedbacks.',
    RATINGS: 'Ratings',
    RATINGS_PARA: 'A free text answer paragraph for qualitative feedbacks.',
    QUESTION_PREVIEW: 'Questionaire Preview',
    SAVE_GOAL: 'Save Objective',
    MANDATORY: 'Mandatory',
    ADD_NEW_CHOICE: 'Add New Choice',
    GOAL_SUMMARY: 'Objective Summary',
    ADD_QUESTIONAIRE: 'Add Objective',
    INVITE_PEERS: 'Invite Peers',
    SEND_GOAL: 'Send Objective',
    SCHEDULE_SETTINGS: 'Schedule Settings',
    SCHEDULE_REVIEW: 'Submit Objectives',
    ANALYZE_RESULT: 'Analyze Result',
    GOAL_DETAILS: 'Objective Details',
    GOAL_QUESTIONS: 'Objective Questions',
    GOAL_DESCRIPTION: 'Objective Description',
    INVITE_PEERS_DES: 'Manage peers or search and add new peer(s) to review this quarter Objectives.',
    ADD_EXTERNAL_PEERS: 'Add External Peers',
    ADD_MORE_PEERS: 'Add More Peers',
    SAVE_NEXT: 'Save & Next',
    SENT: 'Sent',
    SEARCH_ADD_PEERS: "Search & Add Peers",
    CLOSE: 'Close',
    NO_DATA_FOUND: 'No Data Found',
    TYPE_SOMETHING_TO_SEARCH: 'Type something to search',
    CATEGORY: 'Category',
    SUB_CATEGORY: 'Sub Category',
    QUESTION_TYPE: 'Question Type',
    TOTAL_PARTICIPANT: 'Total Participants',
    YES: 'Yes',
    MANAGER: 'Lead',
    MANAGER_SUPERVISOR: 'Manager/Supervisor',
    NAME: 'Name',
    PEER_NAME: 'Peer Name',
    REMOVE: 'Remove',
    MANAGE_MY_PEER: 'Manage My Peers',
    MANAGE_PEER: 'Manage Peers',
    MY_PEER_TEXT: 'Browse the list of your peers',
    SEARCH_PEER: 'Search Peers',
    SEARCH_YOUR_PEER: 'Search Your Peers',
    REMOVE_PEER_TEXT: 'Are you sure you want to remove this peer ?',
    ADD_TO_PEER: 'Add To Peer',
    PEER_ADD_TOAST: 'Added 1 Peer Successfully',
    EXTERNAL_USER: 'External User',
    EXTERNAL: 'External',
    POTRAL_USER: 'Portal User',
    SEND_GOAL_PARA: 'Peer review dates are managed by administrator. You can only change selfie start date and selfie repeat settings as per your preference.',
    SEND_GOAL_PARA_PUB: 'Peer review dates are managed by administrator. You can only change selfie repeat setting as per your preference.',
    SCHEDULAR_LATER_TEXT: 'Do you want to schedule it for later?',
    SELECT_DAYS: 'Select Days',
    REPEAT: 'Repeat',
    REPEAT_FREQUENCY: 'Repeat Frequency',
    PEER_REVIEW: 'Peer Review',
    SELF_EVALUATION: 'Self Evaluation',
    SELECTED_PEER: 'Selected Peers',
    SCHEDULED_PARTICIPANTS: 'Scheduled Participants',
    VIEW_PARTICIPANTS: 'View Participants',
    SCHEDULED_REVIEWS: 'Scheduled Reviews',
    GOAL_STATUS: 'Survey Status',
    MODIFY_PEER: 'Modify Peers',
    ADD_A_NEW_QUESTION: 'Add a new question ?',
    INCLUDE_SELF_EVALUATION: 'Include Self Evaluation',
    SELECT_ALL: 'Select All',
    GOAL_PARTICIPANTS: 'Objective Participants',
    UPDATE_AND_NEXT: 'Update & Next',
    SELFIE: 'Selfie',
    IS_THIS_A_SELFIE: 'Is this a Selfie',
    SELF_GOAL: 'Self Objective',
    SCHEDULE_DATE: 'Schedule Date',
    NO_PEER_SELECTED: 'No Peer Selected',
    NO_GOAL_SCHEDULED_YET: 'No Survey Scheduled Yet',
    NO_GOAL_SENT_YET: 'No Survey Sent Yet',
    ENTER_GOAL: 'Enter Objective',
    ENTER_EXPLAINATION: 'Enter Key Results (SMART)',
    EXPLAINATION: 'Explaination',
    SELECTION_DATES_ERROR: 'Selection of dates are mandatory',
    ACTIVE_TILL: 'Active Till',
    DELETE: 'Delete',
    SEARCH_WITH_KEYWORD: 'Search with keyword',
    SEARCH: 'Search',
    SEND_REMINDER: 'Send Reminder',
    REVIEW_ACTIVE_DAYS: 'Review Active Days',
    KEY_RESULTS: 'Key Results',
    SUBMIT: 'Submit',
    MY_TEAM: 'My Team',
    GOALS: 'Objectives',
    NEXT_SCHEDULED_SELFIE: 'Next Scheduled Selfie',
    NEXT_SCHEDULED_REVIEW: 'Scheduled Review',
    VIEW_GOALS: 'View Objective',
    ME: 'Me',
    EXTEND_DATE: 'Extend Date',
    SAVEANDSHARE: 'Save & Share',
    FINALISE_MINUTES: 'Finalise Minutes',
    MINUTES_FINALIZED: 'Minutes Finalised',
    RECORD_MINUTES: 'Record Minutes',
    MINUTES: 'Minutes',
    UPDATE: "Update"
};

export default Constants;
