import * as types from "./action-types";

const initialState = {
  items: [],
  totalRecords: 0,
  errorMessage: "",
  isLoadingList: false,
  surveyDetail: {},
  quarter: {
    isLoadingList: false,
    items: [],
    totalRecords: 0,
    errorMessage: ""
  },
  reportDetail: {
    reportDetailItems: {},
    reportGraphError: '',
    isLoading: true
  },
  reportGraph: {
    reportGraphItemsNew: {},
    reportGraphErrorNew: '',
    isLoading: true
  },
  reportQuestions: {
    reportQuestionsItems: {},
    reportQuestionsError: '',
    isLoading: true
  },
  reportSurvey: {
    reportSurveyItems: {},
    reportSurveyError: '',
    isLoading: true
  },
  reviewUserList: {
    reviewUserListItems: [],
    reviewUserListError: '',
    reviewMinutesDetails: [],
    isLoading: true
  },
  reviewMinutesDetailData: [],
  reviewMinutesPeer: []
};

const surveyList = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GOAL_RECEIVE:
      return {
        ...state,
        isLoadingList: true,
        errorMessage: "",
      };
    case types.GET_GOAL_RECEIVE_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        items: action.result.list,
        totalRecords: action.result.totalRecords,
        errorMessage: "",
      };
    case types.GET_GOAL_RECEIVE_ERROR:
      return {
        ...state,
        isLoadingList: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_GOAL_SENT:
      return {
        ...state,
        isLoadingList: true,
        errorMessage: "",
      };
    case types.GET_GOAL_SENT_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        items: action.result.surveyList,
        totalRecords: action.result.totalRecord,
        errorMessage: "",
      };
    case types.GET_GOAL_SENT_ERROR:
      return {
        ...state,
        isLoadingList: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_GOAL_DETAILS:
      return {
        ...state,
        //isLoadingList: true,
        errorMessage: "",
      };
    case types.GET_GOAL_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        surveyDetail: action.result,
        errorMessage: "",
      };
    case types.GET_GOAL_DETAILS_ERROR:
      return {
        ...state,
        isLoadingList: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_GOAL_REPORT:
      return {
        ...state,
        isLoadingList: true,
        errorMessage: "",
      };
    case types.GET_GOAL_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        items: action.result.list,
        totalRecords: action.result.totalRecords,
        errorMessage: "",
      };
    case types.GET_GOAL_REPORT_ERROR:
      return {
        ...state,
        isLoadingList: false,
        errorMessage: action.errorMessage,
      };
    case types.DELETE_GOAL:
      return {
        ...state,
        isLoadingList: true,
        errorMessage: "",
      };
    case types.DELETE_GOAL_SUCCESS:
      return {
        ...state,
        isLoadingList: true,
        items: action.result.list,
        totalRecords: action.result.totalRecords,
        errorMessage: "",
      };
    case types.DELETE_GOAL_ERROR:
      return {
        ...state,
        isLoadingList: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_QUARTER_LIST:
      return {
        ...state,
        quarter: {
          ...state.quarter,
          isLoadingList: true,
          errorMessage: ''
        }
      };
    case types.GET_QUARTER_LIST_SUCCESS:
      return {
        ...state,
        quarter: {
          ...state.quarter,
          isLoadingList: false,
          items: action.result.list,
          totalRecords: action.result.totalRecords,
          errorMessage: ''
        }
      };
    case types.GET_QUARTER_LIST_ERROR:
      return {
        ...state,
        quarter: {
          ...state.quarter,
          isLoadingList: false,
          items: [],
          totalRecords: 0,
          errorMessage: action.errorMessage
        }
      };
    case types.CREATE_QUARTERLY_GOAL:
      return {
        ...state,
        errorMessage: "",
        quarter: {
          ...state.quarter,
          isLoadingList: true
        }
      };
    case types.CREATE_QUARTERLY_GOAL_SUCCESS:
      return {
        ...state,
        surveyDetail: action.result.survey,
        errorMessage: "",
        quarter: {
          ...state.quarter,
          isLoadingList: true
        }
      };
    case types.CREATE_QUARTERLY_GOAL_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        quarter: {
          ...state.quarter,
          isLoadingList: true
        }
      };
    case types.GET_REPORT_SURVEY_DETAIL:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          reportDetailItems: {},
          isLoading: true
        }
      };
    case types.GET_REPORT_SURVEY_DETAIL_SUCCESS:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          reportDetailItems: action.result,
          reportGraphError: '',
          isLoading: false
        }
      };
    case types.GET_REPORT_SURVEY_DETAIL_ERROR:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          reportDetailItems: {},
          reportGraphError: action.errorMessage,
          isLoading: false
        }
      };
    case types.GET_REPORT_GRAPH:
      return {
        ...state,
        reportGraph: {
          // ...state.reportGraph,
          reportGraphItemsNew: {},
          isLoading: true
        }
      };
    case types.GET_REPORT_GRAPH_SUCCESS:
      return {
        ...state,
        reportGraph: {
          ...state.reportGraph,
          reportGraphItemsNew: action.result,
          reportGraphError: '',
          isLoading: false
        },
        // pdfName: action.result.data.reportPdf
      };
    case types.GET_REPORT_GRAPH_ERROR:
      return {
        ...state,
        reportGraph: {
          ...state.reportGraph,
          reportGraphItemsNew: {},
          reportGraphError: action.errorMessage,
          isLoading: false
        },
        pdfName: ''
      };
    case types.GET_REPORT_QUESTION:
      return {
        ...state,
        reportQuestions: {
          ...state.reportQuestions,
          reportQuestionsItems: {},
          isLoading: true
        }
      };
    case types.GET_REPORT_QUESTION_SUCCESS:
      return {
        ...state,
        reportQuestions: {
          ...state.reportQuestions,
          reportQuestionsItems: action.result,
          reportQuestionsError: '',
          isLoading: false
        }
      };
    case types.GET_REPORT_QUESTION_ERROR:
      return {
        ...state,
        reportQuestions: {
          ...state.reportQuestions,
          reportQuestionsItems: {},
          reportQuestionsError: action.errorMessage,
          isLoading: false
        }
      };
    case types.GET_REPORT_SURVEY:
      return {
        ...state,
        reportSurvey: {
          ...state.reportSurvey,
          reportSurveyItems: {},
          isLoading: true
        }
      };
    case types.GET_REPORT_SURVEY_SUCCESS:
      return {
        ...state,
        reportSurvey: {
          ...state.reportSurvey,
          reportSurveyItems: action.result,
          reportSurveyError: '',
          isLoading: false
        }
      };
    case types.GET_REPORT_SURVEY_ERROR:
      return {
        ...state,
        reportSurvey: {
          ...state.reportSurvey,
          reportSurveyItems: {},
          reportSurveyError: action.errorMessage,
          isLoading: false
        }
      };
    case types.GET_REVIEW_USER_LIST:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          isLoading: true
        }
      };
    case types.GET_REVIEW_USER_LIST_SUCCESS:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          reviewUserListItems: action.result,
          reviewUserListError: '',
          isLoading: false
        }
      };
    case types.GET_REVIEW_USER_LIST_ERROR:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          reviewUserListItems: [],
          reviewUserListError: action.errorMessage,
          isLoading: false
        }
      };
    case types.GET_REVIEW_MINUTES:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          reviewMinutesDetails: [],
          reviewUserListError: '',
          isLoading: false
        }
      };
    case types.GET_REVIEW_MINUTES_SUCCESS:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          reviewMinutesDetails: action.result,
          reviewUserListError: '',
          isLoading: false
        }
      };
    case types.GET_REVIEW_MINUTES_ERROR:
      return {
        ...state,
        reviewUserList: {
          ...state.reviewUserList,
          reviewMinutesDetails: [],
          reviewUserListError: action.errorMessage,
          isLoading: false
        }
      };
    case types.GET_REVIEW_MINUTE_DETAIL:
      return {
        ...state,
        reviewMinutesDetailData: [],
      };
    case types.GET_REVIEW_MINUTE_DETAIL_SUCCESS:
      return {
        ...state,
        reviewMinutesDetailData: action.result
      };
    case types.GET_REVIEW_MINUTE_PEERS:
      return {
        ...state,
        reviewMinutesPeer: []
      };
    case types.GET_REVIEW_MINUTE_PEERS_SUCCESS:
      return {
        ...state,
        reviewMinutesPeer: action.result
      };
    case types.GET_REVIEW_MINUTE_PEERS_ERROR:
      return {
        ...state,
        reviewMinutesPeer: action.result
      };
    default:
      return state;
  }
};

export default surveyList;
