import * as types from "./action-types";

const initialState = {
  isLoading: false,
  ikigaiQuestion: [],
  ikigaiDetails: [],
  ikigaiResponse: {},
  errorMessage: "",
  ikigaiAllQuestions:[]
};

const ikigai = (state = initialState, action) => {
  switch (action?.type) {
    case types.GET_IKIGAI_QUESTION:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_IKIGAI_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ikigaiQuestion: action.result,
      };
    case types.GET_IKIGAI_QUESTION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        ikigaiQuestion: [],
      };
    case types.GET_IKIGAI_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_IKIGAI_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ikigaiDetails: action.result,
      };
    case types.GET_IKIGAI_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        ikigaiDetails: [],
      };

    case types.GET_IKIGAI_QUESTION_RESPONSE:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_IKIGAI_QUESTION_RESPONSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ikigaiResponse: action.result && action.result.length ? action.result[0] : {},
      }
    case types.GET_IKIGAI_QUESTION_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        ikigaiResponse: {},
      }
      case types.GET_ALL_IKIGAI_QUESTIONS:
        return {
          ...state,
          isLoading: true,
          errorMessage: "",
        };
      case types.GET_ALL_IKIGAI_QUESTIONS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          ikigaiAllQuestions: action.result,
        };
      case types.GET_ALL_IKIGAI_QUESTIONS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: action.errorMessage,
          ikigaiAllQuestions: [],
        };
    default:
      return state;
  }
}

export default ikigai;
