import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import "./modal.css";
const propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  heading: PropTypes.string,
  content: PropTypes.any.isRequired,
  contentCss: PropTypes.object,
  className: PropTypes.string
};
function BasicModal(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        className={`modalBasic${props?.className ? ` ${props?.className}` : ''}`}
      >
        <DialogTitle id="responsive-dialog-title">{props.heading}</DialogTitle>
        <DialogContent className={props?.contentCss}>{props.content}</DialogContent>
      </Dialog>
    </div>
  );
}

BasicModal.propTypes = propTypes;
export default BasicModal;
