import * as types from "./action-types";
import * as API from "./api";
import { v4 as uuid } from "uuid";

export const userLogin = (data) => {
  const localConnectId = uuid();
  localStorage.setItem("connectId", localConnectId);
  const request = API.postLogin(data, localConnectId);
  return {
    type: types.USER_LOGIN,
    payload: request,
  };
};

export const userLoginSuccess = (result) => {
  return {
    type: types.USER_LOGIN_SUCCESS,
    result,
  };
};

export const userLoginFailure = (errorMessage) => {
  return {
    type: types.USER_LOGIN_ERROR,
    errorMessage,
  }
};

export const userLogout = () => {
  const request = API.logout();
  return {
    type: types.USER_LOGOUT,
    payload: request,
  };
};

export const userLogoutSuccess = (result) => ({
  type: types.USER_LOGOUT_SUCCESS,
  result,
});

export const userLogoutFailure = (errorMessage) => ({
  type: types.USER_LOGOUT_ERROR,
  errorMessage,
});

export const userPasswordChange = (data) => {
  const request = API.passwordChange(data);
  return {
    type: types.USER_PASSWORD_CHANGE,
    payload: request,
  };
};

export const userPasswordChangeSuccess = (result) => ({
  type: types.USER_PASSWORD_CHANGE_SUCCESS,
  result,
});

export const userPasswordChangeFailure = (errorMessage) => ({
  type: types.USER_PASSWORD_CHANGE_ERROR,
  errorMessage,
});

export const userForgotPassword = (data) => {
  const request = API.forgotPassword(data);
  return {
    type: types.USER_FORGOT_PASSWORD,
    payload: request,
  };
};

export const userForgotPasswordSuccess = (result) => ({
  type: types.USER_FORGOT_PASSWORD_SUCCESS,
  result,
});

export const userForgotPasswordFailure = (errorMessage) => ({
  type: types.USER_FORGOT_PASSWORD_ERROR,
  errorMessage,
});

export const getUserDetails = () => {
  const request = API.getUserDetails();
  return {
    type: types.GET_USER_DETAILS,
    payload: request,
  };
};

export const getUserDetailsSuccess = (result) => ({
  type: types.GET_USER_DETAILS_SUCCESS,
  result,
});

export const getUserDetailsFailure = (errorMessage) => ({
  type: types.GET_USER_DETAILS_ERROR,
  errorMessage,
});

export const submitFeedback = (data) => {
  const request = API.submiteFeedback(data);
  return {
    type: types.SUBMIT_FEEDBACK,
    payload: request,
  };
};

export const submitFeedbackSuccess = (result) => ({
  type: types.SUBMIT_FEEDBACK_SUCCESS,
  result,
});

export const submitFeedbackFailure = (errorMessage) => ({
  type: types.SUBMIT_FEEDBACK_ERROR,
  errorMessage,
});

export const setDummyReset = () => ({
  type: types.SET_DUMMY_RESET
});
