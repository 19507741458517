import React, { useEffect } from "react";
import Header from "../../../../../common/Header";
import PrimaryButton from "../../../../../common/PrimaryButton";
import styles from "../../../css/sendSurvey.module.css";
import "../../../css/survey.css";
import SelectDropdown from "../../../../../common/Select";
// import Switch from "@mui/material/Switch";
//import DatePicker from "../../../../../common/DatePicker";
import BreadCrumbs from "../../../../../common/BreadCrumbs";
import Constants from "../../../../../config";
//import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SendSideTable from "./sendSideTable";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
//import TextField from '@mui/material/TextField';
import { FormHelperText } from "@mui/material";
import Stack from "@mui/material/Stack";
import BasicModal from "../../../../../common/Modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import "react-datepicker/dist/react-datepicker.css";
import styled from "../../../css/addQuestion.module.css";

const propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  surveyDetail: PropTypes.array,
  sendSurveyPeer: PropTypes.func,
  scheduleCandidate: PropTypes.object,
  getScheduleCandidate: PropTypes.func,
  deleteScheduleCandidate: PropTypes.func,
  peerList: PropTypes.any,
  getSurveyDetails: PropTypes.func,
  getPeersList: PropTypes.func,
  editsurveyDetail: PropTypes.object,
  quarterList: PropTypes.array,
  getSentCandidate: PropTypes.func,
  sentCandidate: PropTypes.object,
  isDesktopLayout: PropTypes.bool,
  surveyInfoSavedDummy: PropTypes.func
};

const ModelContent = ({
  filterValue,
  modifyFun,
  externalList,
  handleClose
}) => {
  return (
    <div className={styles.mapCardContainer}>
      <div className={filterValue?.length ? styles.mapCardBox : ''}>
        {filterValue?.length > 0 ?
          filterValue?.map((item, index) => {
            let iniName = item?.name.charAt(0);
            return (
              <div key={index} className={styles.mapCards}>
                <div className={styles.cardBox}>
                  <div className={styles.cardBoxCircle}>
                    <span className={styles.cardBoxCircleText}>
                      {iniName}
                    </span>
                  </div>
                  <div>
                    <div className={styles.cardName}>{item?.name} {externalList.map((a) => a?.id).includes(item?.id) ? <span className={styles.mapCardRowspanOther}>{Constants.EXTERNAL}</span> : null}</div>
                    <div className={styles.cardEmail}>
                      {item?.email}
                    </div>
                  </div>
                </div>
              </div>
            );
          }) : <p className="align-center">{Constants.NO_PEER_SELECTED}</p>
        }
      </div>
      <div
        className={styles.buttonBox}
        style={{
          textAlign: "center",
          padding: "20px 0"
        }}
      >
        <PrimaryButton
          text={Constants.BACK}
          onClick={handleClose}
          className={styles.secondaryButton}
          style={{ width: '50%' }}
        />
        <PrimaryButton
          text={Constants.MODIFY_PEER}
          onClick={modifyFun}
          className={styles.createSurvey}
          style={{ width: '50%' }}
        />
      </div>
    </div>
  );
};

const SendSurvey = (props) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [checked] = React.useState(true);
  const [selectDays, setselectDays] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startSelfDate, setStartSelfDate] = React.useState("");
  const [endSelfDate, setendSelfDate] = React.useState("");
  //const [repeatValue, setrepeatValue] = React.useState("");
  const [repeatSelfValue, setrepeatSelfValue] = React.useState("");
  //const [frequencyValue, setfrequencyValue] = React.useState("");
  const [frequencySelfValue, setfrequencySelfValue] = React.useState("");
  //const [frequencyList, setfrequencyList] = React.useState("");
  const [frequencySelfList, setfrequencySelfList] = React.useState("");
  //const [selectDaysError, setselectDaysError] = React.useState(false);
  const [startError, setStartError] = React.useState(false);
  const [startSelfError, setStartSelfError] = React.useState(false);
  const [endSelfError, setendSelfError] = React.useState(false);
  const [endPeerError, setendPeerError] = React.useState(false);
  //const [endError, setendError] = React.useState(false);
  //const [repeatError, setrepeatError] = React.useState(false);
  const [repeatSelfError, setrepeatSelfError] = React.useState(false);
  //const [frequencyError, setfrequencyError] = React.useState(false);
  const [frequencySelfError, setfrequencySelfError] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [dateErrorOther, setDateErrorOther] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [externalList, setExternalList] = React.useState([]);
  const [savePeers, setSavePeers] = React.useState([]);
  const [minTime, setMinTime] = React.useState('');
  const [errorMsg, seterrorMsg] = React.useState(false);
  const [isSaveClicked, setIsSaveClicked] = React.useState(false);
  const [successMsgOther, setSuccessMsgOther] = React.useState(false);

  const repeatList = ["daily", "weekly"];
  const breadCrumbsList = [
    {
      name: Constants.ADD_QUESTIONAIRE,
      click: `/add-goals?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.MANAGE_PEER,
      click: `/invite-participants?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.SCHEDULE_SETTINGS,
      click: `/send-survey?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    }
  ];

  useEffect(() => {
    setMinTime(calculateMinTime(new Date()));
    props?.getSurveyDetails(searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id);
    if (props?.surveyDetail && Object.keys(props?.surveyDetail).length) {
      repeatSelectSelfFun(props?.surveyDetail?.selfieRecurranceType);
      if (props?.surveyDetail?.selfieFrequency && props?.surveyDetail?.selfieFrequency !== '' && props?.surveyDetail?.selfieRecurranceType !== 'never') {
        setfrequencySelfValue(`Every ${props?.surveyDetail?.selfieFrequency} ${props?.surveyDetail?.selfieRecurranceType === 'weekly' ? 'Week' : 'Day'}${Number(props?.surveyDetail?.selfieFrequency) > 1 ? 's' : ''}`);
      }
      let startDate = new Date(moment.unix(props?.surveyDetail?.selfieScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
      //startDate.setHours(calculateMinTime(startDate).getHours() + 1, 0, 0, 0);
      setStartSelfDate(startDate);
      let endSelfieDate = new Date(moment.unix(props?.surveyDetail?.newSelfieEndDate?.sec).format("MMM DD, YYYY"));
      setendSelfDate(endSelfieDate);

      let startDatePeer = new Date(moment.unix(props?.surveyDetail?.peerScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
      setStartDate(startDatePeer);
      let endPeerDate = new Date(moment.unix(props?.surveyDetail?.newPeerEndDate?.sec).format("MMM DD, YYYY"));
      setEndDate(endPeerDate);
    }
  }, [])

  useEffect(() => {
    switch (props?.status) {
      case "SCHEDULE_REVIEW_PEER_SUCCESS":
        if (isSaveClicked) {
          props.surveyInfoSavedDummy();
          setIsSaveClicked(false);
          setSuccessMsgOther(true);
          setTimeout(() => setSuccessMsgOther(false), 3000);
        } else {
          setSuccessMsg(true);
          setStartDate('');
          setStartSelfDate("");
          setendSelfDate("");
          setrepeatSelfValue("");
          setfrequencySelfValue("");
          props?.getSurveyDetails(searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id);
        }
        break;
      case "SCHEDULE_REVIEW_PEER_ERROR":
        break;
      case "DELETE_SCHEDULE_CANDIDATE_ERROR":
        seterrorMsg(true);
        break;
      case "GET_GOAL_DETAILS_SUCCESS":
        setExternalList(
          props?.editsurveyDetail?.externalPeers?.length > 0
            ? props?.editsurveyDetail?.externalPeers
            : []
        );
        setSavePeers(
          props?.editsurveyDetail?.inviteDetails?.length > 0
            ? props?.editsurveyDetail?.inviteDetails
            : []
        );
        if (props?.surveyDetail?.responderDuration && props?.surveyDetail?.responderDuration !== '') {
          setselectDays(`${props?.surveyDetail?.responderDuration} Day`);
        }
        if (Object.keys(props?.surveyDetail).length) {
          repeatSelectSelfFun(props?.surveyDetail?.selfieRecurranceType);
          if (props?.surveyDetail?.selfieFrequency && props?.surveyDetail?.selfieFrequency !== '' && props?.surveyDetail?.selfieRecurranceType !== 'never') {
            setfrequencySelfValue(`Every ${props?.surveyDetail?.selfieFrequency} ${props?.surveyDetail?.selfieRecurranceType === 'weekly' ? 'Week' : 'Day'}${Number(props?.surveyDetail?.selfieFrequency) > 1 ? 's' : ''}`);
          }
          let startDate = new Date(moment.unix(props?.surveyDetail?.selfieScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          //startDate.setHours(calculateMinTime(startDate).getHours() + 1, 0, 0, 0);
          setStartSelfDate(startDate);
          let endSelfieDate = new Date(moment.unix(props?.surveyDetail?.newSelfieEndDate?.sec).format("MMM DD, YYYY"));
          setendSelfDate(endSelfieDate);

          let startDatePeer = new Date(moment.unix(props?.surveyDetail?.peerScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          setStartDate(startDatePeer);
          let endPeerDate = new Date(moment.unix(props?.surveyDetail?.newPeerEndDate?.sec).format("MMM DD, YYYY"));
          setEndDate(endPeerDate);
        }
        break;
    }
  }, [props?.status]);
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  /*const repeatSelectFun = (value) => {
    setrepeatValue(value);
    let listValue =
      value === "days" ? "day" : value === "months" ? "month" : "week";
    if (value === 'never') {
      setfrequencyList([]);
      setfrequencyValue('')
    } else {
      /*setfrequencyList([
        `Every 1 ${listValue}`,
        `Every 2 ${listValue}s`,
        `Every 3 ${listValue}s`,
        `Every 4 ${listValue}s`,
        `Every 5 ${listValue}s`,
        `Every 6 ${listValue}s`,
        `Every 7 ${listValue}s`,
        `Every 8 ${listValue}s`,
        `Every 9 ${listValue}s`,
        `Every 10 ${listValue}s`,
      ]
      );
    }
  };*/
  const repeatSelectSelfFun = (value) => {
    setrepeatSelfValue(value);
    let listValue =
      value === "daily" ? "Day" : "Week";
    if (value === 'never') {
      setfrequencySelfList([]);
      setfrequencySelfValue('');
    } else if (value === "weekly") {
      setfrequencySelfList([
        `Every 1 ${listValue}`,
        `Every 2 ${listValue}s`,
        `Every 3 ${listValue}s`,
        `Every 4 ${listValue}s`
      ]
      );
      setfrequencySelfValue('')
    } else {
      setfrequencySelfList([
        `Every 1 ${listValue}`,
        `Every 2 ${listValue}s`,
        `Every 3 ${listValue}s`,
        `Every 4 ${listValue}s`,
        `Every 5 ${listValue}s`,
        `Every 6 ${listValue}s`,
        `Every 7 ${listValue}s`,
        `Every 8 ${listValue}s`,
        `Every 9 ${listValue}s`,
        `Every 10 ${listValue}s`,
      ]
      );
      setfrequencySelfValue('')
    }
  };

  const closeError = () => {
    setStartError(false);
    setStartSelfError(false);
    setendSelfError(false);
    //setendError(false);
    //setrepeatError(false);
    setrepeatSelfError(false);
    //setfrequencyError(false);
    setfrequencySelfError(false);
  };
  const onSave = () => {
    if (checked) {
      if (startDate === "" || (props?.editsurveyDetail?.isSelfieEnabled === 1 && (startSelfDate === "" ||
        endSelfDate === ""))) {
        setStartError(startDate === '');
        setStartSelfError(startSelfDate === "");
        setendSelfError(endSelfDate === "");
        //setendError(endDate === "" ? true : false);
      } else {
        setStartError(false);
        setStartSelfError(false);
        setendSelfError(false);
        let sDate1 = new Date(startDate);
        let eDate1 = new Date(endDate);
        let sDate2 = new Date(startSelfDate);
        let eDate2 = new Date(endSelfDate);
        let cDate = new Date();
        let error1 = false;
        let error2 = false;
        if (props?.editsurveyDetail?.isSelfieEnabled === 1) {
          if (eDate2 < sDate2 || eDate1 < sDate1) {
            error1 = true;
          }
          if (sDate2 < cDate || eDate2 < cDate || sDate1 < cDate) {
            error2 = true;
          }
        }
        if (error1) {
          setDateError(true);
          setTimeout(() => setDateError(false), 3000);
        } else if (error2) {
          setDateErrorOther(true);
          setTimeout(() => setDateErrorOther(false), 3000);
        } else {
          let data = {
            surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
            status: 'SCHEDULED'
            /*duration: Number(selectDays.split(" ")[0]),
            schedule_later: 1,
            peer_review: {
              scheduleDate: startDate,
              repeat: repeatValue,
              repeate_frequency_value:
                frequencyValue !== '' ?
                  repeatValue === "never"
                    ? ""
                    : Number(frequencyValue.split("Every ")[1].split(" ")[0]) : '',
              endDate: endDate,
            },
            self_evaluation: props?.editsurveyDetail?.isSelfieEnabled === 1 ? {
              scheduleDate: startSelfDate,
              repeat: repeatSelfValue,
              repeat_frequency_value:
                frequencySelfValue !== '' ?
                  repeatSelfValue === "never"
                    ? ""
                    : Number(frequencySelfValue.split("Every ")[1].split(" ")[0]) : '',
              endDate: endSelfDate
            } : null,*/
          };
          console.log(selectDays);
          props?.sendSurveyPeer(data);
        }
      }
    } else {
      let dataValue = {
        surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
        status: 'SCHEDULED'
        //duration: Number(selectDays.split(" ")[0]),
        //schedule_later: 0,
        //peer_review: null,
        //self_evaluation: null,
      };
      props?.sendSurveyPeer(dataValue);
    }
  };

  const onSaveInfo = (repeatSelfValue, frequencySelfValue, startSelfDate) => {
    let dataValue = {
      surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
      selfieFrequency: frequencySelfValue !== '' ?
        repeatSelfValue === "never"
          ? ""
          : Number(frequencySelfValue.split("Every ")[1].split(" ")[0]) : '',
      selfieRecurranceType: repeatSelfValue,
      selfieScheduleDate: startSelfDate
    };
    setIsSaveClicked(true);
    props?.sendSurveyPeer(dataValue);
  }

  const handleClose = () => {
    setOpen(!open);
  };

  let peerArray = externalList?.concat(props?.peerList?.list);
  let filterValue = savePeers && savePeers.length && peerArray.length ? peerArray.filter(
    (elem) => !savePeers.find((id) => elem.id === id)
  ) : [];

  const modifyFun = () => {
    navigate(`/invite-participants?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`);
  };

  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), 'day');
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return moment().startOf('day').toDate(); // set to 12:00 am today
  }

  const handleDateChangeStart = (date) => {
    if (date === null) {
      setMinTime(calculateMinTime(new Date()));
      setStartSelfDate('');
    } else {
      if (date < calculateMinTime(new Date())) {
        date.setHours(calculateMinTime(date).getHours() + 1, 0, 0, 0);
      }
      setStartSelfError(false);
      setStartSelfDate(date);
      onSaveInfo(repeatSelfValue, frequencySelfValue, date);
      setMinTime(calculateMinTime(date));
      // set end date + 7 days on change
      let endSelfieDate = moment(date).add(7, 'days');
      setendSelfDate(new Date(endSelfieDate));
    }
  }

  const handleDateChangeEnd = (date) => {
    if (date === null) {
      setMinTime(calculateMinTime(new Date()));
      setendSelfDate('');
    } else {
      if (date < calculateMinTime(new Date())) {
        date.setHours(calculateMinTime(date).getHours() + 1, 0, 0, 0);
      }
      setendSelfError(false);
      setendSelfDate(date);
      setMinTime(calculateMinTime(date));
    }
  }

  const handleDateChangeEndPeer = (date) => {
    if (date === null) {
      setMinTime(calculateMinTime(new Date()));
      setEndDate('');
    } else {
      if (date < calculateMinTime(new Date())) {
        date.setHours(calculateMinTime(date).getHours() + 1, 0, 0, 0);
      }
      setendPeerError(false);
      setEndDate(date);
      setMinTime(calculateMinTime(date));
    }
  }

  const handleDateChangeStartPeer = (date) => {
    if (date === null) {
      setMinTime(calculateMinTime(new Date()));
      setStartDate('');
    } else {
      if (date < calculateMinTime(new Date())) {
        console.log(onSave);
        date.setHours(calculateMinTime(date).getHours() + 1, 0, 0, 0);
      }
      setStartError(false);
      setStartDate(date);
      setMinTime(calculateMinTime(date));
    }
  }

  return (
    <>
      <Header active="Goal" />
      <div className={styles.sendSurveyPage}>
        <div className={styles.sendSurveyInnerPage}>
          <div className={styled.tabRowOther} style={{width: '65%'}}>
            <div className={styled.topDiv}>
              {props?.surveyDetail?.name}
            </div>
            <div className={styled.isDesktop}>
              {
                props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
                  <div className={styled.statusMessage}>
                    <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat preferences. The changes made will be reflected in your Performance selfies and peer Performance review.</div>
                  </div> :
                  <div style={{ width: '45%' }} />
              }
            </div>
            {/* <div className={styled.scheduleWrapper}>
              <PrimaryButton
                text={Constants.SCHEDULE_REVIEW}
                onClick={onSave}
                className={styled.createSurvey}
                disabled={!props?.previewQuestion?.length || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
              />
            </div> */}
          </div>
          <div className={styled.isMobile}>
            {
              props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
                <div className={styled.statusMessage}>
                  <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                  <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat prefernces. You can not delete the goals. The changes made will be reflected in your selfies and peer review.</div>
                </div>
                : null
            }
          </div>
          <BreadCrumbs list={breadCrumbsList} active={3} />
          <div className={styles.sendSurveyHead}>
            <div className={styles.sendSurveyHeadCol1}>
              <div className={styles.sendSurveyheadText}>
                {Constants.SCHEDULE_SETTINGS}
              </div>
              <div className={styles.sendSurveyheadPara}>
                {props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ? Constants.SEND_GOAL_PARA_PUB : Constants.SEND_GOAL_PARA}
              </div>
              {/*<div className={styles.dropDownDiv}>
                <div className={styles.checkedBoxHead} style={{ margin: '15px 0 10px' }}>
                  {Constants.REVIEW_ACTIVE_DAYS}
                </div>
                <SelectDropdown
                  placeholder={Constants.SELECT_DAYS}
                  options={dayList}
                  updateValue={(value) => setselectDays(value)}
                  error={selectDaysError}
                  closeError={closeError}
                  freqUpdate={selectDays}
                />
              </div>*/}
              {/* <div style={{ display: "flex" }}>
                <div className={styles.toggleText}>
                  {Constants.SCHEDULAR_LATER_TEXT}
                </div>
                <div className={styles.toggleSwitch + " toogleSwitch"}>
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div> */}
              <div>
                {checked && (
                  <div className={styles.checkedBox}>
                    {
                      'isSelfieEnabled' in props.editsurveyDetail && props?.editsurveyDetail?.isSelfieEnabled === 1 ?
                        <React.Fragment>
                          <div>
                            <div className={styles.checkedBoxHead} style={{ marginTop: '15px' }}>
                              {Constants.SELFIE}
                            </div>
                            <div className={styles.checkedBoxCol}>
                              <div className={styles.selectPickerDate}>
                                <Stack spacing={3} className={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ? "date-picker-wrapper-disabled" : ''}>
                                  <DatePicker
                                    name={"scheduleDate"}
                                    id={"scheduleStartDate"}
                                    placeholderText={"Start date"}
                                    autoComplete={"off"}
                                    showTimeSelect
                                    onChange={handleDateChangeStart}
                                    selected={startSelfDate}
                                    minDate={new Date() > new Date(moment.unix(props?.surveyDetail?.quarter?.startDate?.sec)) ? new Date() : new Date(moment.unix(props?.surveyDetail?.quarter?.startDate?.sec))}
                                    maxDate={new Date(moment.unix(props?.surveyDetail?.quarter?.endDate?.sec))}
                                    shouldCloseOnSelect={true}
                                    isClearable={false}
                                    className={"new-layout-input"}
                                    dateFormat={"MMM d, yyyy h:mm aa"}
                                    minTime={minTime}
                                    maxTime={moment().endOf('day').toDate()}
                                    error={startSelfError}
                                    disabled={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
                                  />
                                </Stack>
                                <InputLabel className="date-label-custom">{"Start date"}</InputLabel>
                              </div>
                              <div className={styles.selectPickerDate} style={{ marginRight: 0 }}>
                                <Stack spacing={3} className={endSelfDate !== '' ? "date-picker-wrapper-disabled" : ''}>
                                  <DatePicker
                                    name={"scheduleDate"}
                                    id={"scheduleEndDate"}
                                    placeholderText={"Active Till"}
                                    autoComplete={"off"}
                                    onChange={handleDateChangeEnd}
                                    selected={endSelfDate}
                                    minDate={new Date()}
                                    maxDate={new Date(moment.unix(props?.surveyDetail?.quarter?.endDate?.sec))}
                                    shouldCloseOnSelect={true}
                                    isClearable={false}
                                    className={"new-layout-input"}
                                    dateFormat={"MMM d, yyyy"}
                                    error={endSelfError}
                                    disabled={true}
                                  />
                                  <InputLabel className="date-label-custom">{"Active Till"}</InputLabel>
                                  {/*(endSelfError) && <FormHelperText htmlFor='render-select' error>{Constants.SELECTION_DATES_ERROR}</FormHelperText>*/}
                                </Stack>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.checkedBoxCol}
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className={styles.selectPicker}>
                              <SelectDropdown
                                placeholder={Constants.REPEAT}
                                options={repeatList}
                                updateValue={(value) => {
                                  repeatSelectSelfFun(value);
                                  if (value === 'never') {
                                    onSaveInfo(value, '', startSelfDate);
                                  }
                                }}
                                error={repeatSelfError}
                                closeError={closeError}
                                freqUpdate={repeatSelfValue}
                              //label={repeatSelfValue !== '' ? Constants.REPEAT : undefined}
                              />
                              <InputLabel className="date-label-custom">{Constants.REPEAT}</InputLabel>
                            </div>
                            <div className={styles.selectPicker} style={{ marginRight: 0 }}>
                              <SelectDropdown
                                placeholder={Constants.REPEAT_FREQUENCY}
                                options={frequencySelfList}
                                updateValue={(value) => {
                                  setfrequencySelfValue(value);
                                  onSaveInfo(repeatSelfValue, value, startSelfDate);
                                }}
                                freqUpdate={frequencySelfValue}
                                error={frequencySelfError}
                                closeError={closeError}
                              //label={frequencySelfValue !== '' ? Constants.REPEAT_FREQUENCY : undefined}
                              />
                              <InputLabel className="date-label-custom">{Constants.REPEAT_FREQUENCY}</InputLabel>
                            </div>
                          </div>
                        </React.Fragment>
                        : null
                    }
                    <div className={styles.checkedBoxHead} style={{ marginTop: '20px' }}>
                      {Constants.PEER_REVIEW}
                    </div>
                    <div className={styles.checkedBoxCol}>
                      <div className={styles.selectPickerDate}>
                        <Stack spacing={3} className={startDate !== '' ? "date-picker-wrapper-disabled" : ''}>
                          <DatePicker
                            name={"scheduleDate"}
                            id={"scheduleDatePeer"}
                            placeholderText={"Start date"}
                            autoComplete={"off"}
                            showTimeSelect
                            onChange={handleDateChangeStartPeer}
                            selected={startDate}
                            minDate={new Date()}
                            maxDate={new Date(moment.unix(props?.surveyDetail?.quarter?.endDate?.sec))}
                            shouldCloseOnSelect={true}
                            isClearable={false}
                            className={"new-layout-input"}
                            dateFormat={"MMM d, yyyy h:mm aa"}
                            minTime={minTime}
                            maxTime={moment().endOf('day').toDate()}
                            error={startError}
                            disabled={true}
                          />
                          <InputLabel className="date-label-custom">{"Start date"}</InputLabel>
                          {(startError || startSelfError || endSelfError) && <FormHelperText htmlFor='render-select' error>{Constants.SELECTION_DATES_ERROR}</FormHelperText>}
                        </Stack>
                      </div>
                      <div className={styles.selectPickerDate} style={{ marginRight: 0 }}>
                        <Stack spacing={3} className={endDate !== '' ? "date-picker-wrapper-disabled" : ''}>
                          <DatePicker
                            name={"scheduleDate"}
                            id={"scheduleEndDate"}
                            placeholderText={"Active Till"}
                            autoComplete={"off"}
                            onChange={handleDateChangeEndPeer}
                            selected={endDate}
                            minDate={new Date()}
                            maxDate={new Date(moment.unix(props?.surveyDetail?.quarter?.endDate?.sec))}
                            shouldCloseOnSelect={true}
                            isClearable={false}
                            className={"new-layout-input"}
                            dateFormat={"MMM d, yyyy"}
                            error={endPeerError}
                            disabled={true}
                          />
                          <InputLabel className="date-label-custom">{Constants.ACTIVE_TILL}</InputLabel>
                          {/*(endSelfError) && <FormHelperText htmlFor='render-select' error>{Constants.SELECTION_DATES_ERROR}</FormHelperText>*/}
                        </Stack>
                      </div>
                    </div>
                    {/*<div
                      className={styles.checkedBoxCol}
                      style={{ paddingBottom: "10px" }}
                    >
                      <div className={styles.selectPicker}>
                        <SelectDropdown
                          placeholder={Constants.REPEAT}
                          options={repeatList}
                          updateValue={repeatSelectFun}
                          error={repeatError}
                          closeError={closeError}
                          freqUpdate={repeatValue}
                        />
                      </div>
                      <div className={styles.selectPicker}>
                        <SelectDropdown
                          placeholder={Constants.REPEAT_FREQUENCY}
                          options={frequencyList}
                          updateValue={(value) => setfrequencyValue(value)}
                          freqUpdate={frequencyValue}
                          error={frequencyError}
                          closeError={closeError}
                        />
                      </div>
                        </div>*/}
                  </div>
                )}
              </div>
              {/*<div className={styles.modifyPeer} style={{ marginTop: '30px' }}>
                <span onClick={handleClickOpen} style={{ cursor: "pointer" }}>
                  {Constants.VIEW_PARTICIPANTS}
                </span>
              </div>*/}
              {open ? (
                <BasicModal
                  open={open}
                  handleClose={handleClose}
                  heading={Constants.SCHEDULED_PARTICIPANTS}
                  content={
                    <ModelContent
                      handleClose={handleClose}
                      filterValue={filterValue}
                      modifyFun={modifyFun}
                      externalList={externalList}
                    />
                  }
                  className="participants-modal"
                />
              ) : (
                <></>
              )}
            </div>
            <div className={styles.sendSurveyHeadCol2}>
              <SendSideTable
                scheduleCandidate={props?.scheduleCandidate}
                getScheduleCandidate={props?.getScheduleCandidate}
                deleteScheduleCandidate={props?.deleteScheduleCandidate}
                surveyId={searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}
                seterrorMsg={() => seterrorMsg(false)}
                status={props?.status}
                editsurveyDetail={props?.editsurveyDetail}
                getPeersList={props?.getPeersList}
                peerList={props?.peerList}
                getSentCandidate={props?.getSentCandidate}
                sentCandidate={props?.sentCandidate}
                isSaveClicked={isSaveClicked}
              />
            </div>
          </div>
          {errorMsg ? (
            <ToastNotify
              data={props?.errorMessage}
              openAlert={true}
              type={"error"}
            />
          ) : (
            ""
          )}
          <ToastNotify
            data={'Active Till cannot be less than start date'}
            openAlert={dateError}
            type={"error"}
          />
          <ToastNotify
            data={'Please change the Selfie Start date to current or future dates or ask Administrator to change Peer date in case it is older than today and then Schedule the Review again'}
            openAlert={dateErrorOther}
            type={"error"}
          />
          {successMsg ? (
            <ToastNotify
              data={"Review has been scheduled Successfully"}
              openAlert={true}
              type={"success"}
            />
          ) : (
            ""
          )}
          <ToastNotify
            data={"The information has been updated Successfully"}
            openAlert={successMsgOther}
            type={"success"}
          />
        </div>
      </div>
    </>
  );
}
SendSurvey.propTypes = propTypes;
export default SendSurvey;
