import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
const propTypes = {
  Component: PropTypes.any,
};
const PublicRoute = ({ Component }) => {
  return localStorage.getItem("access_token") ? <Navigate to="/dashboard" /> : <Component />;
};

PublicRoute.propTypes = propTypes;
export default PublicRoute;