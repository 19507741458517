import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
//import { PersistGate } from "redux-persist/integration/react";
//import { persistStore } from "redux-persist";
import ErrorBoundary from './common/ErrorBoundary/ErrorBoundary'

//let persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/*<PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
</PersistGate>*/}
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
