// import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import loader from '../../assets/xeleron-favicon.svg'
import "./loader.css";
const propTypes = {
  isLoading: PropTypes.bool,
};
function Loader(props) {
  return (
    <div
      className={props.isLoading ? "loader_block" : "loader_block hide-display"}
    >
      <div className="loader">
        <div className="loader-img">
          <img src={loader} alt="loader" style={{width:'50px'}}/>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isLoading:
      state.auth.isLoading ||
      state.createSurvey.isLoading ||
      state.surveyList.isLoading,
  };
};
Loader.propTypes = propTypes;
export default connect(mapStateToProps)(Loader);
