/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import Header from "../../../../../common/Header";
import styles from "../../../css/addQuestion.module.css";
import "../../../css/survey.css";
import addicon from "../../../../../assets/addicon.svg";
//import Radio from "@mui/material/Radio";
//import cardCircle from "../../../../../assets/cardCircle.svg";
import QuestionBlock from "./questionBlock";
import PreviewQuestions from "./previewQuestions";
import Constants from "../../../../../config";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../../../common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../../../common/BreadCrumbs";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { responseConfig } from "../../../../../utils/Resusables";
import PrintIcon from '@mui/icons-material/Print';
import { ImportExport } from "@mui/icons-material";
import BasicModal from "../../../../../common/Modal"
import CloseIcon from '@mui/icons-material/Close';
// import Loader from "../../../../../common/loader/index";
import DropDown from "../../../../../common/DropDown";
import CheckBox from "../../../../../common/Checkbox";
import Typography from '@mui/material/Typography';
import arrowRight from "../../../../../assets/arrowRight.png";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const propTypes = {
  errorMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  surveyDetail: PropTypes.array,
  copySurveyQuestion: PropTypes.func,
  questionDetail: PropTypes.object,
  getPreviewQuestion: PropTypes.func,
  previewQuestion: PropTypes.array,
  categoryList: PropTypes.array,
  subCategoryList: PropTypes.array,
  //getDefaultCategoreyList: PropTypes.func,
  getCategoreyList: PropTypes.func,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  sendSurveyPeer: PropTypes.func,
  isDesktopLayout: PropTypes.bool,
  getSurveySent: PropTypes.func,
  surveyList: PropTypes.array,
  importGoals: PropTypes.func,
  getImportQuestion: PropTypes.func,
  importQuestion: PropTypes.array,
  getQuarterList: PropTypes.func,
  quarterList: PropTypes.array,
  getIkigaiDetails: PropTypes.func,
  ikigaiDetails: PropTypes.any
};
const scrollToRef = (ref) => window.scrollTo(0, ref?.current?.offsetTop);

const AddQuestions = (props) => {
  const navigate = useNavigate();
  const myRef = useRef(null);
  const myNewRef = useRef(null);
  const dispatch = useDispatch();
  //const [openAdd, setOpenAdd] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [questionArray, setQuestionArray] = useState([]);
  const [showError, setError] = useState(false);
  const [addSuccessMsg, setAddSuccessMsg] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [deleteGoalTitleError, setDeleteGoalTitleError] = useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [dateErrorOther, setDateErrorOther] = React.useState(false);
  //const [selectDays, setselectDays] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startSelfDate, setStartSelfDate] = React.useState("");
  const [endSelfDate, setendSelfDate] = React.useState("");
  //const [repeatSelfValue, setrepeatSelfValue] = React.useState("");
  //const [frequencySelfValue, setfrequencySelfValue] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [viewGoal, setViewGoal] = useState(false);
  const [isGoalLoading, setGoalLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [page, setPage] = useState('all');
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [currentQuarter, setCurrentQuarter] = useState('');
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [importQuestionLocal, setImportQuestionLocal] = useState([]);
  const [reviewTeamId, setReviewTeamId] = React.useState("");
  const [reviewClubbedId, setReviewClubbedId] = React.useState("");
  let [searchParams] = useSearchParams();

  const yearOptions = ['2022', '2023', '2024'];

  const typeOptions = [
    { type: "shortText", text: "Short Text" },
    { type: "scale", text: "Ratings" }
  ];

  const breadCrumbsList = [
    {
      name: Constants.ADD_QUESTIONAIRE,
      click: `/add-goals?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.MANAGE_PEER,
      click: `/invite-participants?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    },
    {
      name: Constants.SCHEDULE_SETTINGS,
      click: `/send-survey?id=${searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id}`,
    }
  ];

  useEffect(() => {
    props.getQuarterList({ type: 'all' });
    props.getIkigaiDetails();
  }, []);

  useEffect(() => {
    if (searchParams.get("id")) {
      props?.getSurveyDetails(searchParams.get("id"));
      props.getPreviewQuestion(searchParams.get("id"));
      props?.getCategoreyList(searchParams.get("id"));
    } else {
      props?.getSurveyDetails(props?.surveyDetail?._id);
      props?.getCategoreyList(props?.surveyDetail?._id);
    }
  }, [searchParams.get("id")]);

  React.useEffect(() => {
    if (props?.surveyList?.length && searchParams.get("id")) {
      let surveyResponse = props?.surveyList?.find(ele => ele.surveyId == searchParams.get("id"));
      if (surveyResponse) {
        setReviewTeamId(surveyResponse?.teamId);
        setReviewClubbedId(surveyResponse?.clubbedId);
      }
    }
  }, [props.surveyList, searchParams.get("id")])

  useEffect(() => {
    switch (props?.status) {
      case "GET_GOAL_DETAILS_SUCCESS":
        if (!searchParams.get("id")) {
          if (!props?.surveyDetail?._id || props?.surveyDetail?._id === '')
            previewQuestionApi();
        }
        /*if (props?.surveyDetail?.responderDuration && props?.surveyDetail?.responderDuration !== '') {
          setselectDays(`${props?.surveyDetail?.responderDuration} Day`);
        }*/
        if (Object.keys(props?.surveyDetail).length && Object.keys(props?.surveyDetail?.quarter).length) {
          /*setrepeatSelfValue(props?.surveyDetail?.quarter.selfieRecurrance);
          if (props?.surveyDetail?.quarter.selfieRecurrance && props?.surveyDetail?.quarter.selfieRecurrance !== '' && props?.surveyDetail?.quarter.selfieRecurrance !== 'never') {
            setfrequencySelfValue(`Every ${props?.surveyDetail?.quarter.selfieRepeat} ${props?.surveyDetail?.quarter?.selfieRecurrance === 'weekly' ? 'Week' : 'Day'}${Number(props?.surveyDetail?.quarter.selfieRepeat) > 1 ? 's' : ''}`);
          }*/
          let startDate = new Date(moment.unix(props?.surveyDetail?.selfieScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          //startDate.setHours(calculateMinTime(startDate).getHours() + 1, 0, 0, 0);
          setStartSelfDate(startDate);
          let endSelfieDate = new Date(props?.surveyDetail?.newSelfieEndDate);
          setendSelfDate(endSelfieDate);

          let startDatePeer = new Date(moment.unix(props?.surveyDetail?.peerScheduleDate?.sec).format("MMM DD, YYYY HH:mm"));
          setStartDate(startDatePeer);
          let endPeerDate = new Date(props?.surveyDetail?.newPeerEndDate);
          setEndDate(endPeerDate);
        }
        break;
      case "GET_CATEGORY_LIST_SUCCESS":
        setCategoryList(props?.categoryList);
        break;
      case "GOAL_TITLE_DELETE_ERROR":
      case "GOAL_TITLE_EDIT_ERROR":
        setDeleteGoalTitleError(true);
        setTimeout(() => setDeleteGoalTitleError(false), 3000);
        break;
      case "SCHEDULE_REVIEW_PEER_SUCCESS":
        setSuccessMsg(true);
        setTimeout(() => setSuccessMsg(false), 3000);
        props?.getSurveyDetails(searchParams.get("id") ? searchParams.get("id") : props?.surveyId);
        break;
      /*case "CREATE_QUARTERLY_GOAL_SUCCESS":
        if (searchParams.get("id")) {
          props?.getCategoreyList(searchParams.get("id"));
        }
        break;*/
      case "GET_QUARTER_LIST_SUCCESS":
        getCurrentQuarter();
        break;
      // case "GET_IMPORT_QUESTIONS":
      //   setGoalLoading(true);
      //   break;
      case "GET_IMPORT_QUESTIONS_SUCCESS":
        setImportQuestionLocal(props.importQuestion);
        break;
      // case "GET_IMPORT_QUESTIONS_ERROR":
      //   setGoalLoading(false);
      //   break;
      case "IMPORT_GOALS_SUCCESS":
        setViewGoal(false);
        setSelectedGoals([]);
        setSelectedQuarter('');
        props?.getCategoreyList(searchParams.get("id"));
        props.getPreviewQuestion(searchParams.get("id"));
        props.getImportQuestion(null);
        break;
      case "GET_GOAL_SENT_SUCCESS":
        setsQuarterOptions();
        break;
      default:
        break;
    }
  }, [props?.status]);

  useEffect(() => {
    switch (props?.status) {
      case "POST_ADD_QUESTION_SUCCESS":
        setAddSuccessMsg(true);
        break;
    }
  }, [props?.status]);

  useEffect(() => {
    apiCall({ page, year: selectedYear });
  }, [selectedYear]);

  const previewQuestionApi = () => {
    props.getPreviewQuestion(props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"));
  };
  /*const handleChange = (value) => {
    addhandleChange(value);
    setOpenAdd(!openAdd);
  };*/
  const addhandleChange = (value) => {
    setSelectedValue(value);
    setQuestionArray([...questionArray, value]);
  };
  const handleDelete = (removeIndex) => {
    setQuestionArray((oldArray) => {
      return oldArray.filter((value, i) => i !== removeIndex);
    });
  };
  const addOptionCategory = (data) => {
    let arr = JSON.parse(JSON.stringify(categoryList));
    arr.push({ name: data });
    setCategoryList(arr);
  }
  const onSave = () => {
    let sDate1 = new Date(startDate);
    let eDate1 = new Date(endDate);
    let sDate2 = new Date(startSelfDate);
    let eDate2 = new Date(endSelfDate);
    let cDate = new Date();
    let error1 = false;
    let error2 = false;
    if (props?.surveyDetail?.isSelfieEnabled === 1) {
      if (eDate2 < sDate2 || eDate1 < sDate1) {
        error1 = true;
      }
      if (sDate2 < cDate || eDate2 < cDate || sDate1 < cDate) {
        error2 = true;
      }
    }
    if (error1) {
      setDateError(true);
      setTimeout(() => setDateError(false), 3000);
    } else if (error2) {
      setDateErrorOther(true);
      setTimeout(() => setDateErrorOther(false), 3000);
    } else {
      let data = {
        surveyId: searchParams.get("id") ? searchParams.get("id") : props?.surveyDetail?._id,
        status: 'SCHEDULED'
        /*duration: Number(selectDays.split(" ")[0]),
        schedule_later: 1,
        peer_review: {
          scheduleDate: startDate,
          repeat: '',
          repeate_frequency_value: '',
          endDate: endDate,
        },
        self_evaluation: props?.surveyDetail?.isSelfieEnabled === 1 ? {
          scheduleDate: startSelfDate,
          repeat: repeatSelfValue,
          repeat_frequency_value:
            frequencySelfValue !== '' ?
              repeatSelfValue === "never"
                ? ""
                : Number(frequencySelfValue.split("Every ")[1].split(" ")[0]) : '',
          endDate: endSelfDate
        } : null,*/
      };
      props?.sendSurveyPeer(data);
    }
  };

  const downloadGoalPdf = () => {
    window.open(
      `${responseConfig.baseURL}/customers/${props?.surveyDetail?._id}/generate/goal/pdf`
    );
  };

  const getCurrentQuarter = () => {
    if (currentQuarter || currentQuarter === '') {
      let selectedQuarterIndex = 0;
      if (props.quarterList?.length) {
        let today = new Date();
        if (props.quarterList?.find(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate))) {
          selectedQuarterIndex = props.quarterList?.findIndex(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate));
        }
      }
      setCurrentQuarter(props.quarterList?.length ? props.quarterList[selectedQuarterIndex]?._id : '');
    }
  }

  const handleCloseGoal = () => {
    setViewGoal(false);
    setSelectedGoals([]);
    setSelectedQuarter('');
    setSelectedYear(new Date().getFullYear());
    setImportQuestionLocal([]);
  }

  const handleGoalView = () => {
    setViewGoal(true);
  }

  const handleChangeYear = (value) => {
    setSelectedGoals([]);
    setSelectedQuarter('');
    setSelectedYear(value);
    props.getImportQuestion(null);
  }

  const apiCall = (value) => {
    props?.getSurveySent(value);
  };

  const handleChange = (event, id) => {
    let questionIds = [...selectedGoals];
    if (event.target.checked) {
      questionIds.push(id);
    } else {
      let index = questionIds.findIndex(elem => elem === id);
      questionIds.splice(index, 1);
    }
    setSelectedGoals(questionIds);
  }

  const handleChangeQuarter = (value) => {
    setSelectedQuarter(value);
    setSelectedGoals([]);
    props.getImportQuestion(value);
  }

  const importGoalsFunc = () => {
    setSelectedYear(new Date().getFullYear());
    let data = {};
    data.questionIds = selectedGoals;
    data.lqSurveyId = selectedQuarter;
    let surveyId = searchParams.get("id");
    props.importGoals(data, surveyId);
  }

  const setsQuarterOptions = () => {
    let temp = [];
    props.surveyList.map((item, index) => {
      if (item.quarterId !== currentQuarter) {
        temp.push(item);
      }
    });
    setQuarterOptions(temp);
  }

  const ModelContentGoal = ({ handleClose, importQuestion, handleChangeProps, selectedGoalsProps }) => {
    return (
      <div>
        <div className='flex-end'>
          <CloseIcon onClick={handleClose} style={{ color: '#f56102', fontSize: '32px', cursor: 'pointer' }} title="Close" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'left' }}>
          <DropDown
            options={yearOptions}
            style={{ color: "#333333" }}
            updateValue={handleChangeYear}
            placeholder="Select year"
            value={selectedYear}
            label={'Select year'}
            className="dashboard-dropdown"
          />
          {quarterOptions.length ?
            <DropDown
              options={quarterOptions?.length ? quarterOptions : []}
              style={{ color: "#333333" }}
              updateValue={handleChangeQuarter}
              placeholder="Quarter"
              value={selectedQuarter}
              label={'Quarter'}
              className="dashboard-dropdown quarter"
            // hideAddBtn={quarterOptions.length ? false}
            /> : <p style={{ fontSize: '18px', margin: '8px 0 0 10px' }}>No quarter available</p>
          }


          {importQuestion.length ?
            <div className="flex-end" style={{ marginLeft: '20px', marginTop: '-5px' }}>
              <button className="printBtn" onClick={importGoalsFunc} disabled={!selectedGoalsProps.length}>
                <div className="text">Import</div><ImportExport />
              </button>
            </div> : null
          }
        </div>

        {selectedYear && importQuestion.map((item, index) => {
          return (

            <div key={index}>
              <div key={index} style={{ marginBottom: '20px' }}>
                <Typography
                  className={styles.previewQuestionTexts}
                  sx={{ color: "text.secondary" }}
                >
                  <div>
                    <div className={styles.questionFieldPreview} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <CheckBox
                        handleChange={(event) => handleChangeProps(event, item?.identifier)}
                        checked={selectedGoalsProps.includes(item?.identifier)}
                      />
                      <div className={styles.surveyDetailSpan}>
                        objective {index + 1}
                      </div>
                      <div>
                        <img
                          src={arrowRight}
                          alt="arrowRight"
                          className={styles.surveyDetailimg}
                        />
                      </div>
                      <div className={styles.surveyDetailQuestion} style={{ textAlign: 'left' }}>
                        {item?.question}
                      </div>
                    </div>
                    <div className={styles.moreSummary}>
                      <div className={styles.typeSummary}>
                        {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                      </div>
                      <div className={styles.catSummary}>
                        {item?.category_name && item?.category_name !== '' ? item?.category_name : '-'}
                        {
                          item?.subcategory_name && item?.subcategory_name !== '' ?
                            <React.Fragment>
                              <ArrowRightAltIcon style={{ color: '#fff', margin: '0 5px', fontSize: '16px' }} />
                              {item?.subcategory_name}
                            </React.Fragment> : null
                        }
                      </div>
                    </div>
                  </div>
                </Typography>
              </div>
            </div>)
        })
        }
      </div>
    );
  };

  return (
    <>
      <Header active="Goal" />
      <div className={styles.addQuestionsPage} id="scrollTop">
        <div className={styles.tabRowOther} style={{ width: '65%' }}>
          <div className={styles.topDiv}>
            {props?.surveyDetail?.name}
          </div>
          <div className={styles.isDesktop}>
            {
              props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
                <div className={styles.statusMessage}>
                  <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                  <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat preferences. The changes made will be reflected in your Performance selfies and peer Performance review.</div>
                </div> :
                props?.previewQuestion?.length ?
                  <div className={styles.statusMessage}>
                    <div style={{ textAlign: 'center', fontWeight: 500 }}>Your Objective setting is not completed yet.</div>
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>Please click on Submit Objectives to complete your Objective setting.</div>
                  </div> :
                  <div style={{ width: '45%' }} />
            }
          </div>
          {/* <div className={styles.scheduleWrapper}>
            <PrimaryButton
              text={Constants.SCHEDULE_REVIEW}
              onClick={onSave}
              className={styles.createSurvey}
              disabled={!props?.previewQuestion?.length || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
            />
          </div> */}
        </div>
        <div className={styles.isMobile}>
          {
            props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED' ?
              <div className={styles.statusMessage}>
                <div style={{ textAlign: 'center', fontWeight: 500 }}>The review is scheduled.</div>
                <div style={{ textAlign: 'center', fontSize: '12px' }}>You can modify Objectives, peers, selfie repeat prefernces. You can not delete the Objectives. The changes made will be reflected in your selfies and peer review.</div>
              </div>
              : props?.previewQuestion?.length ?
                <div className={styles.statusMessage}>
                  <div style={{ textAlign: 'center', fontWeight: 500 }}>Your Objective setting is not completed yet.</div>
                  <div style={{ textAlign: 'center', fontSize: '12px' }}>Please click on Submit Objectives to complete your Objective setting.</div>
                </div> :
                null
          }
        </div>
        <BreadCrumbs list={breadCrumbsList} active={1} />
        <div className={styles.mainBox}>
          {/*<PrimaryButton
            text={Constants.ADD_NEW_GOAL}
            className={styles.addNewBtn}
            onClick={() => {
              document.getElementById('scrollTop').scrollIntoView();
              setSelectedValue("");
              //setOpenAdd(true);
              addhandleChange("Ratings");
              dispatch(props.copySurveyQuestion({}));
              setError(false);
            }}
            ref={myNewRef}
            disabled={questionArray.length}
          />*/}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: props.isDesktopLayout ? '10px' : '30px' }}>
            {props.surveyDetail.quarterId === currentQuarter ?
              <div className="flex-end" style={{ marginTop: '-60px', marginRight: '10px' }}>
                <button className="printBtn" onClick={handleGoalView} style={{ height: 'auto' }}>
                  <div className="text">Import Objectives</div><ImportExport />
                </button>
              </div> : null
            }

            {
              props?.previewQuestion?.length ?
                <div className="flex-end" style={{ marginTop: '-60px' }}>
                  <button className="printBtn" onClick={downloadGoalPdf} style={{ height: 'auto' }}>
                    <div className="text">Print Objectives</div><PrintIcon />
                  </button>
                </div>
                :
                null
            }
          </div>

          <QuestionBlock
            questionArray={questionArray}
            selectedValue={selectedValue}
            surveyDetail={props?.surveyDetail}
            handleDelete={handleDelete}
            addhandleChange={addhandleChange}
            questionDetail={props?.questionDetail}
            previewQuestionApi={previewQuestionApi}
            errorMessage={props?.errorMessage}
            isLoading={props?.isLoading}
            status={props?.status}
            categoryList={categoryList?.length ? categoryList : props?.categoryList?.length ? props.categoryList : []}
            previewQuestion={props?.previewQuestion}
            subCategoryList={props?.subCategoryList}
            addOptionCategory={(data) => addOptionCategory(data)}
            deleteGoalTitle={props?.deleteGoalTitle}
            editGoalTitle={props?.editGoalTitle}
            ikigaiDetails={props?.ikigaiDetails}
          />

          {props?.previewQuestion?.length ? (
            <>
              <PreviewQuestions
                questionArray={questionArray}
                selectedValue={selectedValue}
                surveyDetail={props?.surveyDetail}
                handleDelete={handleDelete}
                addhandleChange={addhandleChange}
                questionDetail={props?.questionDetail}
                previewQuestionApi={previewQuestionApi}
                errorMessage={props?.errorMessage}
                isLoading={props?.isLoading}
                status={props?.status}
                categoryList={categoryList?.length ? categoryList : props?.categoryList?.length ? props.categoryList : []}
                previewQuestion={props?.previewQuestion}
                subCategoryList={props?.subCategoryList}
                copySurveyQuestion={props?.copySurveyQuestion}
                addOptionCategory={(data) => addOptionCategory(data)}
                deleteGoalTitle={props?.deleteGoalTitle}
                editGoalTitle={props?.editGoalTitle}
                isDesktopLayout={props?.isDesktopLayout}
                ikigaiDetails={props?.ikigaiDetails}
                reviewTeamId={reviewTeamId}
                reviewClubbedId={reviewClubbedId}
              />
            </>
          ) : (
            <></>
          )}
          {
            !questionArray.length ?
              props?.surveyDetail?.status === 'PUBLISHED' && reviewTeamId && reviewClubbedId && reviewTeamId !== '' && reviewClubbedId !== ''
                ?
                null :
                <div
                  className={styles.addBlock}
                  onClick={() => {
                    document.getElementById('scrollTop').scrollIntoView();
                    //setOpenAdd(!openAdd);
                    addhandleChange("Ratings");
                    setSelectedValue("");
                    dispatch(props.copySurveyQuestion({}));
                    setError(false);
                  }}
                  ref={myRef}
                >
                  <span className={styles.addIconBox}>
                    <img src={addicon} alt="addIcon" />
                  </span>
                  {Constants.ADD_NEW_GOAL}
                </div> : null
          }
        </div>
        {showError ? (
          <ToastNotify
            data="Please add or save a objective"
            openAlert={true}
            type={"error"}
          />
        ) : (
          ""
        )}
        <ToastNotify
          data={props.errorMessage}
          openAlert={deleteGoalTitleError}
          type={"error"}
        />
        {
          addSuccessMsg ?
            <ToastNotify
              data="Objective added successfully"
              openAlert={true}
              type={"success"}
            /> : null
        }
        <ToastNotify
          data={'Active Till cannot be less than start date'}
          openAlert={dateError}
          type={"error"}
        />
        <ToastNotify
          data={'Please change the Selfie Start date to current or future dates and then Schedule the Review again'}
          openAlert={dateErrorOther}
          type={"error"}
        />
        <ToastNotify
          data={"Survey has been scheduled Successfully"}
          openAlert={successMsg}
          type={"success"}
        />

        {
          viewGoal ?
            <BasicModal
              open={viewGoal}
              handleClose={handleCloseGoal}
              className={'view-goal-modal'}
              selectedGoals={selectedGoals}
              content={
                <ModelContentGoal
                  handleClose={handleCloseGoal}
                  // goalDetails={JSON.parse(JSON.stringify(props.goalDetails))}
                  isGoalLoading={isGoalLoading}
                  importQuestion={importQuestionLocal}
                  handleChangeProps={handleChange}
                  selectedGoalsProps={selectedGoals}
                />
              }
            /> : null
        }
      </div>
    </>
  );
};

AddQuestions.propTypes = propTypes;
export default AddQuestions;
