import React, { useEffect, useState } from "react";
import styles from "../../../css/sendSurvey.module.css";
import "../../../css/survey.css";
import PropTypes from "prop-types";
import PrimaryButton from "../../../../../common/PrimaryButton";
import Constants from "../../../../../config";
import BasicModal from "../../../../../common/Modal";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import Pagination from "@mui/material/Pagination";
import Field from "../../../../../common/Field";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import moment from "moment";
const propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  scheduleCandidate: PropTypes.object,
  sentCandidate: PropTypes.object,
  getScheduleCandidate: PropTypes.func,
  getSentCandidate: PropTypes.func,
  deleteScheduleCandidate: PropTypes.func,
  surveyId: PropTypes.string,
  setsuccessMsg: PropTypes.func,
  seterrorMsg: PropTypes.func,
  peerList: PropTypes.any,
  getPeersList: PropTypes.func,
  editsurveyDetail: PropTypes.object,
  isSaveClicked: PropTypes.bool
};

const ModelContent = ({ handleClose }) => {
  const onSave = (e) => {
    e.preventDefault();
    //deleteScheduleCandidate(removeData);
  };

  return (
    <div>
      <div className="cardModalButton">
        <PrimaryButton
          text={Constants.CANCEL}
          onClick={handleClose}
          className="cancelButton"
        />
        <PrimaryButton
          text={Constants.YES}
          onClick={onSave}
          className="saveButton"
        />
      </div>
    </div>
  );
};
function SendSideTable(props) {
  const [open, setOpen] = useState(false);
  const [successMsg, setsuccessMsg] = React.useState(false);
  const [selectedSearch, setSelectedSearch] = useState('');
  const [active, setActive] = useState("Scheduled");
  const [originalScheduleCandidate, setOriginalScheduleCandidate] = useState({});
  const [originalSentCandidate, setOriginalSentCandidate] = useState({});
  //const [selectedSearchDisabled, setSelectedSearchDisabled] = useState(false);
  const [page, setPage] = React.useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    props?.getScheduleCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
    props?.getSentCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
    props?.getPeersList({ all: true });
  }, []);
  useEffect(() => {
    switch (props?.status) {
      case "DELETE_SCHEDULE_CANDIDATE_SUCCESS":
        setOpen(!open);
        setsuccessMsg(true);
        if (props?.scheduleCandidate?.list.length === 1 && page > 1) {
          setPage(page - 1);
          props?.getScheduleCandidate({ id: props?.surveyId, page: page - 2, recPerPage: PER_PAGE, name: selectedSearch });
        } else {
          props?.getScheduleCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
        }
        break;
      case "SCHEDULE_REVIEW_PEER_SUCCESS":
        if (!props?.isSaveClicked) {
          props?.getScheduleCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
        }
        break;
    }
  }, [props?.status]);

  useEffect(() => {
    if (Object.keys(props?.scheduleCandidate).length && selectedSearch === '') {
      setOriginalScheduleCandidate(props?.scheduleCandidate);
    }
  }, [props?.scheduleCandidate]);

  useEffect(() => {
    if (Object.keys(props?.sentCandidate).length && selectedSearch === '') {
      setOriginalSentCandidate(props?.sentCandidate);
    }
  }, [props?.sentCandidate]);

  const handleChange = (e, p) => {
    setPage(p);
    if (active === 'Scheduled') {
      props?.getScheduleCandidate({ id: props?.surveyId, page: p - 1, recPerPage: PER_PAGE, name: selectedSearch });
    } else {
      props?.getSentCandidate({ id: props?.surveyId, page: p - 1, recPerPage: PER_PAGE, name: selectedSearch });
    }
  };

  /*const sendReminder = (id) => {
    console.log(id);
  }*/

  const handleClose = () => {
    setOpen(!open);
  };

  const handleChangeSearch = (e) => {
    setSelectedSearch(e.target.value);
    if (active === 'Scheduled') {
      props?.getScheduleCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: e.target.value });
    } else {
      props?.getSentCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: e.target.value });
    }
  }

  return (
    <>
      <div className={styles.sendSurveyCard}>
        <div className="flex-space">
          <div style={{ width: '10%' }} />
          <div className={styles.tabRow}>
            <div
              className={
                active === "Scheduled" ? styles.activetab : styles.inActivetab
              }
              onClick={() => {
                setActive("Scheduled");
                setPage(1);
                setSelectedSearch('');
                props?.getScheduleCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
              }}
            >
              {Constants.SCHEDULED_REVIEWS}
            </div>
            <div
              className={
                active === "Sent" ? styles.activetab : styles.inActivetab
              }
              onClick={() => {
                setActive("Sent");
                setPage(1);
                setSelectedSearch('');
                props?.getSentCandidate({ id: props?.surveyId, page: page - 1, recPerPage: PER_PAGE, name: selectedSearch });
              }}
            >
              {Constants.SENT_REVIEWS}
            </div>
          </div>
          {
            (active === 'Scheduled' &&
              originalScheduleCandidate?.list?.length) ||
              (active === 'Sent' &&
                originalSentCandidate?.list?.length) ?
              <div className="searchField">
                <Field
                  placeholder={Constants.SEARCH_WITH_KEYWORD}
                  type={"text"}
                  name={"searchText"}
                  value={selectedSearch}
                  label={Constants.SEARCH_WITH_KEYWORD}
                  onChange={handleChangeSearch}
                  endadornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                //disabled={selectedSearchDisabled}
                />
              </div> : <div style={{ width: '33%' }} />
          }
        </div>
        {
          active === 'Scheduled' ?
            props?.scheduleCandidate?.list?.length ? (
              <div>
                <div className={styles.mapCardRow}>
                  <div className={styles.mapCardColumn1}>{Constants.NAME}</div>
                  <div className={styles.mapCardColumn2}>{Constants.REVIEW_STATUS}</div>
                  <div className={styles.mapCardColumn3}>{Constants.SCHEDULE_DATE}</div>
                  <div className={styles.mapCardColumn4}>{Constants.ACTIVE_TILL}</div>
                </div>
                <div className={styles.mapCardRow2}>
                  {
                    props?.scheduleCandidate?.list?.map((item, index) => {
                      return (
                        <div key={index} className={styles.mapCards}>
                          <div className={styles.mapCardRowCol1}>
                            <div className={styles.cardBox}>
                              <div>
                                <div className={styles.cardName}>
                                  {item?.userName} {item?.isSelfAssessment ? <span className={styles.mapCardRowspanOther}>Selfie</span> : null} {item?.isExternalShare ? <span className={styles.mapCardRowspanOther}>{Constants.EXTERNAL}</span> : null}
                                </div>
                                <div className={styles.cardEmail}>
                                  {item?.email}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.mapCardRowCol2}>
                            <span className={styles.mapCardRowspan}>
                              {item?.surveyStatus}
                            </span>
                          </div>
                          <div className={styles.mapCardRowCol3}>
                            <span className={styles.mapCardDate}>
                              {moment.unix(item?.scheduleDate?.sec).format("MMM DD, YYYY hh:mm a")}
                            </span>
                          </div>
                          <div className={styles.mapCardRowCol4}>
                            <span className={styles.mapCardDate}>
                              {item?.endDate && item?.endDate?.sec ? moment.unix(item?.endDate?.sec).utc().format("MMM DD, YYYY") : 'N/A'}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className={styles.modifyPeer} style={{ width: 'fit-content', margin: 'auto' }}>
                  <Pagination
                    variant="outlined"
                    count={Math.ceil((props?.scheduleCandidate?.totalRecords) / PER_PAGE)}
                    page={page}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.mapCardRow2}>
                <p className="align-center">{Constants.NO_GOAL_SCHEDULED_YET}</p>
              </div>) :
            props?.sentCandidate?.list?.length ? (
              <div>
                <div className={styles.mapCardRow}>
                  <div className={styles.mapCardColumn1}>{Constants.NAME}</div>
                  <div className={styles.mapCardColumn2}>{Constants.REVIEW_STATUS}</div>
                  <div className={styles.mapCardColumn3}>{Constants.SENT_DATE}</div>
                  <div className={styles.mapCardColumn4}>{Constants.ACTIVE_TILL}</div>
                </div>
                <div className={styles.mapCardRow2}>
                  {
                    props?.sentCandidate?.list?.map((item, index) => {
                      return (
                        <div key={index} className={styles.mapCards}>
                          <div className={styles.mapCardRowCol1}>
                            <div className={styles.cardBox}>
                              <div>
                                <div className={styles.cardName}>
                                  {item?.userName} {item?.isSelfAssessment ? <span className={styles.mapCardRowspanOther}>Selfie</span> : null} {item?.isExternalShare ? <span className={styles.mapCardRowspanOther}>{Constants.EXTERNAL}</span> : null}
                                </div>
                                <div className={styles.cardEmail}>
                                  {item?.email}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.mapCardRowCol2}>
                            <span className={styles.mapCardRowspan}>
                              {item?.surveyStatus}
                            </span>
                          </div>
                          <div className={styles.mapCardRowCol3}>
                            <span className={styles.mapCardDate}>
                              {moment.unix(item?.scheduleDate?.sec).format("MMM DD, YYYY hh:mm a")}
                            </span>
                          </div>
                          <div className={styles.mapCardRowCol4}>
                            <span className={styles.mapCardDate}>
                              {item?.endDate && item?.endDate?.sec ? moment.unix(item?.endDate?.sec).utc().format("MMM DD, YYYY") : 'N/A'}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className={styles.modifyPeer} style={{ width: 'fit-content', margin: 'auto' }}>
                  <Pagination
                    variant="outlined"
                    count={Math.ceil((props?.sentCandidate?.totalRecords) / PER_PAGE)}
                    page={page}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.mapCardRow2}>
                <p className="align-center">{Constants.NO_GOAL_SENT_YET}</p>
              </div>)
        }
      </div>
      {successMsg ? (
        <ToastNotify
          data="Review deleted successfully"
          openAlert={true}
          type={"success"}
        />
      ) : (
        ""
      )}
      {open ? (
        <BasicModal
          open={open}
          handleClose={handleClose}
          heading={Constants.REMOVE_PEER_TEXT}
          content={
            <ModelContent
              handleClose={handleClose}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
SendSideTable.propTypes = propTypes;
export default SendSideTable;
