import React from "react";
import "./breadCrumbs.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
const propTypes = {
  list: PropTypes.array,
  active: PropTypes.number,
};
const BreadCrumbs = (props) => {
  const navigate = useNavigate();
  return (
    <div className="breadCrumbs">
      {props?.list?.map((item, index) => {
        return (
          <div
            key={index}
            className={
              index < props?.active
                ? "breadCrumbsStepsActive"
                : "breadCrumbsSteps"
            }
            onClick={() => navigate(item?.click)}
          >
            {item.name}
            <hr
              className={index === props?.active - 1 ? "activeLine" : "inActiveLine"}
            />
          </div>
        );
      })}
    </div>
  );
};
BreadCrumbs.propTypes = propTypes;
export default BreadCrumbs;
