import { axiosInstance, axiosInstanceOther } from "../../../utils/Http";
import {
  getSurveyReceiveFailure,
  getSurveyReceiveSuccess,
  getSurveySentFailure,
  getSurveySentSuccess,
  getSurveyDetailsFailure,
  getSurveyDetailsSuccess,
  getSurveyReportsSuccess,
  getSurveyReportsFailure,
  deleteGoalSuccess,
  deleteGoalFailure,
  getQuarterListSuccess,
  getQuarterListFailure,
  createQuarterlyGoalSuccess,
  createQuarterlyGoalFailure,
  getReportSurveySuccess,
  getReportSurveyFailure,
  getReportSurveyDetailSuccess,
  getReportSurveyDetailFailure,
  getReportGraphSuccess,
  getReportGraphFailure,
  getReportQuestionSuccess,
  getReportQuestionFailure,
  getReviewUserListSuccess,
  getReviewUserListFailure,
  getReviewMinutesSuccess,
  getReviewMinutesFailure,
  createReviewMinutesSuccess,
  createReviewMinutesFailure,
  finalizeReviewMinutesSuccess,
  finalizeReviewMinutesFailure,
  // getReviewUserList,
  getReportGraph,
  deleteReviewMinuteFailure,
  deleteReviewMinuteSuccess,
  updateReviewMinuteSuccess,
  updateReviewMinuteFailure,
  getReviewMinuteDetailSuccess,
  getReviewMinuteDetailFailure,
  shareReviewMinutesSuccess,
  shareReviewMinutesFailure,
  getReviewMinutesPeerSuccess,
  getReviewMinutesPeerFailure
} from "./actions";
import store from "../../../store/index";
import { generateError } from "../../../utils/Resusables";
import { setSelectedQuarter } from "../../Dashboard/store/actions";

export const getSurveyReceive = (data) => {
  return axiosInstance
    .get(`survey/review/list?offset=${data.page}${data.recPerPage ? `&recPerPage=${data.recPerPage}` : ''}${data.year ? `&year=${data.year}` : ''}`)
    .then((response) => {
      return store.dispatch(getSurveyReceiveSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSurveyReceiveFailure(generateError(error)))
    );
};

export const getSurveySent = (data) => {
  return axiosInstance
    .get(`customer/sent/survey?offset=${data.page}${data.year ? `&year=${data.year}` : ''}`)
    .then((response) => {
      return store.dispatch(getSurveySentSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSurveySentFailure(generateError(error)))
    );
};

export const getSurveyDetails = (data) => {
  return axiosInstance
    .get(`surveys/${data}`)
    .then((response) => {
      return store.dispatch(getSurveyDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSurveyDetailsFailure(generateError(error)))
    );
};

export const getSurveyReports = (data) => {
  return axiosInstance
    .get(`survey/report/list?offset=${data.page}${data.recPerPage ? `&recPerPage=${data.recPerPage}` : ''}${data.year ? `&year=${data.year}` : ''}`)
    .then((response) => {
      return store.dispatch(getSurveyReportsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSurveyReportsFailure(generateError(error)))
    );
};

export const deleteGoal = (surveyId) => {
  return axiosInstance
    .delete(`customers/${surveyId}/survey`)
    .then((response) => {
      return store.dispatch(deleteGoalSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(deleteGoalFailure(generateError(error)))
    );
};

export const getQuarterList = (data) => {
  return axiosInstance
    .get(`customers/quarters?recPerPage=${data.recPerPage ? data.recPerPage : ''}${data.offset || data.offset === 0 ? `&offset=${data.offset}` : ''}${data.type ? `&type=${data.type}` : ''}`)
    .then((response) => {
      let selectedQuarterIndex = 0;
      if (response?.data?.data?.list?.length) {
        let today = new Date();
        if (response?.data?.data?.list?.find(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate))) {
          selectedQuarterIndex = response?.data?.data?.list?.findIndex(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate));
        }
      }
      store.dispatch(setSelectedQuarter(response.data.data.list[selectedQuarterIndex]?._id));
      return store.dispatch(getQuarterListSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getQuarterListFailure(generateError(error)))
    );
};

export const createQuarterlyGoal = (data) => {
  return axiosInstance
    .post(`customers/surveys/createfromquarters`, data)
    .then((response) => {
      return store.dispatch(createQuarterlyGoalSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(createQuarterlyGoalFailure(generateError(error)))
    );
};

export const getReportSurvey = (request) => {
  return axiosInstanceOther.get(`publicreports/${request.surveyId}/survey?teamId=${request.teamId}` + "&clubbedId=" + request.clubbedId + "&prc=" + request.prc + `&customerSid=${request.customerSid ? request.customerSid : ""}`)
    .then((response) => {
      return store.dispatch(getReportSurveySuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReportSurveyFailure(generateError(error)))
    );
}

export const getReportSurveyDetail = (request) => {
  return axiosInstanceOther.get(`publicreports/gn/surveys/${request.surveyId}/detail?prc=${request.prc}&customerSid=${request.customerSid ? request.customerSid : ""}`)
    .then((response) => {
      return store.dispatch(getReportSurveyDetailSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReportSurveyDetailFailure(generateError(error)))
    );
}

export const getReportGraphData = (request) => {
  return axiosInstanceOther.get(`publicreports/gn/surveys/${request.surveyId}/graph?teamId=${request.teamId}&clubbedId=${request.clubbedId}&prc=${request.prc}${request.excludeUser ? `&excludeUser=${request.excludeUser}` : ''}&customerSid=${request.customerSid ? request.customerSid : ""}`)
    .then((response) => {
      return store.dispatch(getReportGraphSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReportGraphFailure(generateError(error)))
    );
}

export const getReportQuestion = (request) => {
  return axiosInstanceOther.get("/surveys/" + request.surveyId + "/all/questions?teamId=" + request.teamId + "&clubbedId=" + request.clubbedId + "&prc=" + request.prc + `&customerSid=${request.customerSid ? request.customerSid : ""}`)
    .then((response) => {
      getReportGraph(request)
      return store.dispatch(getReportQuestionSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReportQuestionFailure(generateError(error)))
    );
}

export const getReviewUserListAPI = (surveyId) => {
  return axiosInstance.get(`reviews/${surveyId}/minute/user`)
    .then((response) => {
      return store.dispatch(getReviewUserListSuccess(response.data.data));
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(getReviewUserListFailure(generateError(error)))
    }
    );
}

export const getReviewMinutes = (request) => {
  return axiosInstance.get(`customer/review/minutes${request.userId ? `?userId=${request.userId}` : ""}${request.quarterId ? `&quarterId=${request.quarterId}` : ""}${request.reviewerId ? `&reviewerId=${request.reviewerId}` : ""}`)
    .then((response) => {
      // getReviewUserList(surveyId);
      return store.dispatch(getReviewMinutesSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReviewMinutesFailure(generateError(error)))
    );
}

export const createReviewMinutes = (request) => {
  return axiosInstance.post(`customers/reviews/minutes?teamId=${request.teamId}&clubbedId=${request.clubbedId}&revieweeId=${request.revieweeId}`, request.data)
    .then((response) => {
      return store.dispatch(createReviewMinutesSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(createReviewMinutesFailure(generateError(error)))
    );
}

export const finalizeReviewMinutes = (request) => {
  return axiosInstance.put(`finalises/${request.surveyId}/review/minutes?teamId=${request.teamId}&clubbedId=${request.clubbedId}`, request.data)
    .then((response) => {
      return store.dispatch(finalizeReviewMinutesSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(finalizeReviewMinutesFailure(generateError(error)))
    );
}

export const deleteReviewMinute = (identifier) => {
  return axiosInstance.delete(`customers/${identifier}/review/minutes`)
    .then((response) => {
      return store.dispatch(deleteReviewMinuteSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(deleteReviewMinuteFailure(generateError(error)))
    );
}

export const updateReviewMinute = (identifier, payload) => {
  return axiosInstance.put(`customers/${identifier}/review/minutes`, payload)
    .then((response) => {
      return store.dispatch(updateReviewMinuteSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(updateReviewMinuteFailure(generateError(error)))
    );
}

export const getReviewMinuteDetail = (quarterId, userId) => {
  return axiosInstance.get(`reviews/${quarterId}/minutes/${userId}/detail`)
    .then((response) => {
      return store.dispatch(getReviewMinuteDetailSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReviewMinuteDetailFailure(generateError(error)))
    );
}

export const shareReviewMinutes = (surveyId, payload) => {
  return axiosInstance.put(`shares/${surveyId}/review/minutes`, payload)
    .then((response) => {
      return store.dispatch(shareReviewMinutesSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(shareReviewMinutesFailure(generateError(error)))
    );
}

export const getReviewMinutesPeer = (quarterId) => {
  return axiosInstance.get(`review/minutes/peer?quarterId=${quarterId}`)
    .then((response) => {
      return store.dispatch(getReviewMinutesPeerSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getReviewMinutesPeerFailure(generateError(error)))
    );
}