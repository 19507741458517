import React from "react";
import "./field.css";
import PropTypes from 'prop-types';
import { TextField } from "@mui/material";

const propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  endadornment: PropTypes.object,
  onKeyUp: PropTypes.func,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  autoFocus: PropTypes.bool
};

function Field(props) {
  return (
    <div className={`fieldInput${props?.className ? ` ${props?.className}` : ''}`}>
      <TextField
        {...props}
        name={props.name}
        fullWidth
        placeholder={props?.placeholder}
        variant="outlined"
        InputProps={{
          autoComplete: "off",
          endAdornment: props?.endadornment
        }}
        inputProps={props?.inputProps}
        type={props?.type}
        value={props?.value}
        onChange={props?.onChange}
        error={props?.error}
        onKeyUp={props?.onKeyUp}
        autoFocus={props?.autoFocus}
      />
    </div>
  );
}

Field.propTypes = propTypes;

export default Field;
