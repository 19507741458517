import React, { Component } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import pane from "highcharts/highcharts-more";
import { correctFloat } from "highcharts";
pane(Highcharts);

Highcharts.seriesTypes.pie.prototype.pointClass.prototype.getDataLabelPath = function (label) {
	var renderer = this.series.chart.renderer,
		shapeArgs = this.shapeArgs,
		start = shapeArgs.start,
		end = shapeArgs.end,
		angle = start + (end - start) / 2, // arc middle value
		upperHalf = angle < 0 &&
			angle > -Math.PI ||
			angle > Math.PI,
		r = (shapeArgs.r + (label.options.distance || 0)),
		moreThanHalf;
	// Check if point is a full circle
	if (start === -Math.PI / 2 &&
		correctFloat(end) === correctFloat(Math.PI * 1.5)) {
		start = -Math.PI + Math.PI / 360;
		end = -Math.PI / 360;
		upperHalf = true;
	}
	// Check if dataLabels should be render in the
	// upper half of the circle
	if (end - start > Math.PI) {
		upperHalf = false;
		moreThanHalf = true;
	}
	if (this.dataLabelPath) {
		this.dataLabelPath = this.dataLabelPath.destroy();
	}
	this.dataLabelPath = renderer
		.arc({
			open: true,
			longArc: moreThanHalf ? 1 : 0
		})
		// Add it inside the data label group so it gets destroyed
		// with the label
		.add(label);
	this.dataLabelPath.attr({
		start: (upperHalf ? start : end),
		end: (upperHalf ? end : start),
		clockwise: +upperHalf,
		x: shapeArgs.x,
		y: shapeArgs.y,
		r: (r + shapeArgs.innerR) / 2
	});
	return this.dataLabelPath;
}

class MultiLevelRadar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// legends: [{ color: "black", text: this.props.translate('Reports.Peer') },
			// { color: "lightblue", text: this.props.translate('Reports.PeerAverage') },
			// { color: "red", text: this.props.translate('Reports.You') }],
			options: {},
			config: {},
			series: [],
			loading: true,
			showRadar: false,
			subCategoriesCount: null,
			browserWidth: props.browserWidth
		}
	}

	componentDidMount() {
		if (this.props.reportDetailItems && this.props.reportQuestionsItems && this.props.reportGraphItemsNew && this.props.selectedQuarter) {
			this.callMainFunctionForMultilevelRadar();
		}
		(function (H) {
			H.wrap(H.Tooltip.prototype, 'refresh', function (proceed, points) {
				proceed.apply(this, Array.prototype.slice.call(arguments, 1));
				this.points = points;
			});
		}(Highcharts));

		if (document.getElementById('container1')) {
			document.getElementById('container1').addEventListener('mouseenter', function () {
				var chart = undefined;
				Highcharts.charts.map((chartItem, i) => {
					if (chartItem) {
						chart = Highcharts.charts[i];
						return
					}
				});
				if (chart && chart.pointer && !chart.startChartPosY) {
					chart.pointer.getChartPosition();
					chart.startChartPosY = chart.pointer.chartPosition.top;
				}
			});
		}

		if (document.getElementById('outer')) {
			document.getElementById('outer').addEventListener('scroll', function () {
				var H = Highcharts,
					chart = H.charts[H.hoverChartIndex];
				if (chart) {
					var tooltip = chart.tooltip;
				}

				if (chart && chart.pointer && chart.startChartPosY && chart.pointer.chartPosition) {
					chart.pointer.chartPosition.top = chart.startChartPosY - this.scrollTop;
				}

				if (tooltip && !tooltip.isHidden) {
					tooltip.refresh(tooltip.points);
				}
			});
		}

	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.viewTypeRadar !== nextProps.viewTypeRadar || this.props.reportGraphItemsNew !== nextProps.reportGraphItemsNew || this.props.reportQuestionsItems !== nextProps.reportQuestionsItems || this.props.reportDetailItems !== nextProps.reportDetailItems) {
			this.setState({
				options: {},
				config: {},
				series: []
			}, () => {
				this.callMainFunctionForMultilevelRadar();
			});
		}
		const { status } = nextProps;

		switch (status) {
			case "redux-responsive/CALCULATE_RESPONSIVE_STATE":
				this.setState({ browserWidth: nextProps.browserWidth });
				break;
			default:
				break;
		}
	}


	shouldComponentUpdate(nextProps) {
		if (this.props.isPDFLoading !== nextProps.isPDFLoading) {
			return false;
		} else {
			return true;
		}
	}
	// function to find rotation angle
	getRotationAnglesArray = (divisions, condition, headCatDimensionsData) => {
		let sum = 0;
		let anglesArray = [];
		let angle1 = 0;
		let angle2 = 0;
		let angle3 = 0;
		if (condition == false) {
			for (let i = 0; i < divisions; i++) {
				angle2 = angle1 + 360 / divisions;
				angle3 = angle2 - 360 / (divisions * 2);
				anglesArray.push(angle3);
				angle1 = angle2;
			}
		}
		else {
			for (let i = 0; i < headCatDimensionsData.length; i++) {
				sum = sum + headCatDimensionsData[i];
			}
			for (let i = 0; i < divisions; i++) {
				angle2 = angle1 + headCatDimensionsData[i] * 360 / sum;
				angle3 = angle2 - headCatDimensionsData[i] * 360 / (sum * 2);
				anglesArray.push(angle3);
				angle1 = angle2;
			}
		}

		return anglesArray;
	}

	getHeadCatDimensionsData = (categoriesData) => {
		let headCatDimensionsDataArray = [];
		for (var i in categoriesData) {
			let data = categoriesData[i].length == undefined ? categoriesData[i].data.length : categoriesData[i][0].data.length;
			headCatDimensionsDataArray.push(data);
		}
		return headCatDimensionsDataArray
	}

	getHeadCatSeries = (dataCat, categoriesData) => {
		let selectedIndex = null;
		let radarDepth = this.props.reportSurveyItems.depth;
		let headCatDimensionsData = [];
		if (radarDepth == 2) {
			headCatDimensionsData = this.getHeadCatDimensionsData(categoriesData);
		}
		if (this.props.viewType == 11) {
			for (var i in dataCat) {
				if (dataCat[i].categoryId == this.props.selectedCategoryId) {
					selectedIndex = i;
				}
			}
		}
		let colorArray = ["#D62F93", "#2FB351", "#74439A", "#4D54A5", "#E9A423", "#31AEA6", "#941C30", "#164A20"];
		let headCatSeries = {
			type: 'pie',
			innerSize: this.props.viewType === 0 ? '85%' : '85%',
			size: this.props.isPdfView ? 940 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 88 : (this.props.browserWidth / 100) * 45 : (this.props.browserWidth / 100) * 36, // 940 : 790
			startAngle: 0,
			enableMouseTracking: true,
			endAngle: 360,
			center: ['50%', '50%'],
			dataLabels: {
				enabled: true,
				padding: 0,
				distance: dataCat.length > 1 ? 0 : '-7%',
				crop: false,
				overflow: 'allow',
				style: {
					fontSize: this.props.viewType === 0 ? this.props.browserWidth < 1160 ? this.props.isMobileLayout ? '10px' : '12px' : '15px' : this.props.browserWidth < 1250 ? '9px' : '11px',
					color: 'white',
					width: this.props.isMobileLayout ? 150 : 'auto',
					textOverflow: 'ellipsis'
				},
				textPath: {
					enabled: dataCat.length > 1 ? true : false,
					attributes: {
						dy: 5
					}
				}
			},
			data: null
		}
		let data = [];
		for (let i = 0; i < dataCat.length; i++) {
			let dataHeadCat = {
				name: '',
				y: headCatDimensionsData[i] == undefined ? 10 : headCatDimensionsData[i],
				color: colorArray[i],
				borderWidth: 2,
				opacity: (this.props.viewType == 11) ? (selectedIndex == i ? 1 : 0.2) : 1,
				categoryId: dataCat[i].categoryId
			}
			dataHeadCat.name = dataCat[i].categoryName === undefined ? this.props.viewType === 0 && !this.props.isMobileLayout ? dataCat[i].name : dataCat[i].name.split(' Agility')[0] : this.props.viewType === 0 && !this.props.isMobileLayout ? dataCat[i].categoryName : dataCat[i].categoryName.split(' Agility')[0];
			data.push(dataHeadCat);
			if (dataHeadCat.name === 'Innovation Agility') {
				if (this.props.browserWidth < 1500) {
					headCatSeries.dataLabels.style.fontSize = '11px';
				} else if (this.props.browserWidth < 1650) {
					headCatSeries.dataLabels.style.fontSize = '12px';
				} else {
					headCatSeries.dataLabels.style.fontSize = '14px';
				}
			}
		}
		headCatSeries.data = data;
		return headCatSeries
	}

	drawHeadCategories = (data, condition, categoriesData) => {
		let selectedIndex = null;
		let headCatDimensionsData = [];
		if (this.props.reportSurveyItems.depth == 2) {
			headCatDimensionsData = this.getHeadCatDimensionsData(categoriesData);
		}
		if (this.props.viewType == 11) {
			for (var i in data) {
				if (data[i].categoryId == this.props.selectedCategoryId) {
					selectedIndex = i;
				}
			}
		}
		let colorArray = ["#EE8CCB", "#97E19D", "#9583C1", "#8098D3", "#F0C573", "#2EE5DA", "#DE6378", "#32BD4C"];
		let varPieSeries = {
			type: "pie",
			minPointSize: 0,
			startAngle: condition == true ? 0 : 0,
			endAngle: condition == true ? 360 : 360,
			zIndex: condition == false ? 4 : 5,
			innerSize: this.props.viewType === 0 ? this.props.isMobileLayout ? '87%' : '90.5%' : '89%',
			size: this.props.isPdfView ? 820 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 75 : (this.props.browserWidth / 100) * 39 : (this.props.browserWidth / 100) * 30.7, // 820 : 670
			zMin: 0,
			zMax: 10,
			name: '',
			dataLabels: {
				enabled: true,
				padding: 0,
				distance: 0,
				style: {
					fontSize: this.props.viewType === 0 ? this.props.browserWidth < 1160 ? this.props.isMobileLayout ? '10px' : '12px' : '15px' : this.props.browserWidth < 1250 ? '9px' : '11px',
					color: 'white'
				},
				textPath: {
					enabled: true,
					attributes: {
						dy: 5
					}
				}
			},
			enableMouseTracking: false,
			data: null,
			showInLegend: false
		}

		let dataVarPieSeries = [];
		for (let i = 0; i < data.length; i++) {
			let dataVarPieSeriesDetails = {
				y: headCatDimensionsData[i] == undefined ? 1 : headCatDimensionsData[i],
				z: condition == true ? 8 : 10,
				color: condition == true ? colorArray[i] : 'white',
				borderWidth: 2,
				opacity: (this.props.viewType == 11) ? (selectedIndex == i ? 1 : 0.2) : 1,
				name: this.props.totalUsers === this.props.reportQuestionsItems?.header?.length ? this.setScores(data[i].categoryId) : this.reCalculateScores(i, categoriesData)
			}
			dataVarPieSeries.push(dataVarPieSeriesDetails);
		}

		varPieSeries.data = dataVarPieSeries;
		return varPieSeries;
	}

	setScores = (categoryId) => {
		let seriesData = this.props.reportGraphItemsNew.comparision.series;
		let peerScore = 0;
		let youScore = 0;
		if (seriesData.length == 2) {
			let peerSeries = [];
			let youSeries = [];
			for (var i in seriesData) {
				if (seriesData[i].name == "You") {
					youSeries = seriesData[i].data;
				}
				else {
					peerSeries = seriesData[i].data;
				}
			}
			for (var j in peerSeries) {
				if (peerSeries[j].categoryId == categoryId) {
					peerScore = this.convertLongDecimalToTwoPlace(peerSeries[j].y);
				}
			}
			for (var k in youSeries) {
				if (youSeries[k].categoryId == categoryId) {
					youScore = this.convertLongDecimalToTwoPlace(youSeries[k].y);
				}
			}
			return peerScore > 0 ? peerScore : youScore;
		}
		else {
			for (var m in seriesData[0].data) {
				if (categoryId == seriesData[0].data[m].categoryId) {
					peerScore = this.convertLongDecimalToTwoPlace(seriesData[0].data[m].y)
				}
			}
			return peerScore;
		}

	}

	reCalculateScores = (i, categoriesData) => {
		let dataArray = [];
		if (this.props.reportSurveyItems.depth == 2) {
			const tempData = categoriesData[i][0] && categoriesData[i][0].data ? categoriesData[i][0].data : categoriesData[i].data;
			let totalSubCat = 0;
			tempData.map(obj => {
				let score = 0;
				let dnkCount = 0;
				obj.question && obj.question.length ? obj.question.map(item => {
					this.props.reportQuestionsItems.header.map(name => {
						if (this.props.reportQuestionsItems.header.length > 1) {
							if (name !== this.props.activeUser) {
								if (item.userRating.find(ele => ele.userName === name).value === 'DNK') {
									dnkCount = dnkCount + 1;
								} else {
									score = score + item.userRating.find(ele => ele.userName === name).value;
								}
							}
						} else {
							if (item.userRating.find(ele => ele.userName === name).value === 'DNK') {
								dnkCount = dnkCount + 1;
							} else {
								score = score + item.userRating.find(ele => ele.userName === name).value;
							}
						}
					});
				}) : null;
				let totalUserlength = 0;
				if (this.props?.reportQuestionsItems?.header?.length > 1) {
					if (this.props.activeUser) {
						totalUserlength = this.props.reportQuestionsItems.header.length - 1;
					} else {
						totalUserlength = this.props.reportQuestionsItems.header.length;
					}
				} else {
					totalUserlength = this.props.reportQuestionsItems.header.length;
				}
				if (obj.question) {
					totalSubCat = totalSubCat + score / ((obj.question.length * totalUserlength) - dnkCount);
				}
				if (!this.props.reportQuestionsItems.header.length) {
					totalSubCat = 0;
				}
			});
			let avg = this.convertLongDecimalToTwoPlace(totalSubCat / tempData.length);
			return avg;
		} else {
			const tempData = categoriesData[0] && categoriesData[0].data ? categoriesData[0].data : categoriesData.data;
			tempData.map(obj => {
				let score = 0;
				let dnkCount = 0;
				obj.question && obj.question.length ? obj.question.map(item => {
					this.props.reportQuestionsItems.header.map(name => {
						if (this.props.reportQuestionsItems.header.length > 1) {
							if (name !== this.props.activeUser) {
								if (item.userRating.find(ele => ele.userName === name).value === 'DNK') {
									dnkCount = dnkCount + 1;
								} else {
									score = score + item.userRating.find(ele => ele.userName === name).value;
								}
							}
						} else {
							if (item.userRating.find(ele => ele.userName === name).value === 'DNK') {
								dnkCount = dnkCount + 1;
							} else {
								score = score + item.userRating.find(ele => ele.userName === name).value;
							}
						}
					});
				}) : null;
				let totalUserlength = 0;
				if (this.props.reportQuestionsItems.header.length > 1) {
					if (this.props.activeUser) {
						totalUserlength = this.props.reportQuestionsItems.header.length - 1;
					} else {
						totalUserlength = this.props.reportQuestionsItems.header.length;
					}
				} else {
					totalUserlength = this.props.reportQuestionsItems.header.length;
				}
				score = score / ((obj.question.length * totalUserlength) - dnkCount);
				if (!this.props.reportQuestionsItems.header.length) {
					score = 0;
				}
				dataArray.push(score * 10);
			});
		}
	}

	convertLongDecimalToTwoPlace = (theform) => {
		var num = theform;
		var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,1})?/) ? num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] : 0;
		return with2Decimals;
	}

	getSubCategoriesNames = (categoriesData) => {
		let subCatNamesArray = [];
		for (var i in categoriesData) {
			let dataToBeTraversed = categoriesData[i][0] == undefined ? categoriesData[i].data : categoriesData[i][0].data;
			for (var j in dataToBeTraversed) {
				if (dataToBeTraversed[j].categoryName) {
					subCatNamesArray.push(dataToBeTraversed[j].categoryName);
				}
			}
		}

		return subCatNamesArray
	}


	getDistance = (noOfSubCats) => {
		let distanceArray = [];
		for (let i = 0; i < noOfSubCats; i++) {
			distanceArray.push(-20);
		}
		distanceArray[0] = -30;
		distanceArray[noOfSubCats - 1] = -30;
		if (noOfSubCats % 2 == 0) {
			distanceArray[(noOfSubCats / 2) - 1] = -15;
			distanceArray[(noOfSubCats / 2)] = -15;
		}
		else {
			distanceArray[((noOfSubCats + 1) / 2) - 1] = -15
		}
		if (noOfSubCats >= 9) {
			distanceArray[1] = -25;
			distanceArray[noOfSubCats - 2] = -25;
		}

		if (noOfSubCats >= 15) {
			distanceArray[2] = -25;
			distanceArray[noOfSubCats - 3] = -25;
		}
		return distanceArray;
	}

	getFontAndWidth = (browserWidth, categoriesNum) => {
		let newObj = {};
		let font = '';
		let widthArray = [];
		switch (true) {
			case (browserWidth > 1900):
				font = '13px', widthArray = [180, 80];
				break;
			case (browserWidth > 1650 && browserWidth < 1900):
				font = this.props.viewType === 0 ? '11px' : '10px', widthArray = [180, 60];
				break;
			case (browserWidth > 1350 && browserWidth < 1650):
				font = this.props.viewType === 0 ? '11px' : '10px', widthArray = [120, 55];
				break;
			case (browserWidth > 1200 && browserWidth < 1350):
				font = '10px', widthArray = [100, 50];
				break;
			case (browserWidth > 950 && browserWidth < 1270):
				font = '10px', widthArray = [80, 40];
				break;
			default: font = '10px', widthArray = [80, 40];
		}
		newObj.font = font;
		if (categoriesNum > 20) {
			let x1 = browserWidth > 1650 ? 95 : 50;
			let x2 = browserWidth > 1650 ? 30 : 20;
			widthArray[0] = widthArray[0] - x1;
			widthArray[1] = widthArray[1] - x2;
		} else if (categoriesNum > 16 && this.props.viewType === 0) {
			let x1 = browserWidth > 1650 ? 80 : 40;
			let x2 = browserWidth > 1650 ? 18 : 7;
			widthArray[0] = widthArray[0] - x1;
			widthArray[1] = widthArray[1] - x2;
			if (browserWidth <= 1650) {
				newObj.font = '10px';
			}
		} else {
			if (browserWidth > 1650) {
				widthArray[0] = 200;
			} else if (browserWidth > 1400) {
				widthArray[0] = 190;
			} else if (browserWidth > 1200) {
				widthArray[0] = 180;
			}
		}
		if (this.props.isMobileLayout) {
			widthArray[0] = categoriesNum > 16 ? 35 : categoriesNum > 12 ? 40 : categoriesNum > 7 ? 45 : 50;
			newObj.font = '7px';
		}
		newObj.widthArray = widthArray;
		return newObj
	}
	drawSubCatHeads = (categoriesData) => {
		let radarDepth = this.props.reportSurveyItems.depth;
		let selectedCategoryId = this.props.selectedCategoryId;
		let names = [];
		let namesLength = [];
		let noOfCategoriesInSub = null;
		let headCatDimensionsData = [];
		let categoryIdForData = [];
		if (radarDepth == 2) {
			names = this.getSubCategoriesNames(categoriesData);
			for (let i = 0; i < names.length; i++) {
				if (names[i]?.length) {
					namesLength.push(names[i]?.length);
				}
			}
			noOfCategoriesInSub = names?.length;
			headCatDimensionsData = this.getHeadCatDimensionsData(categoriesData);
			categoryIdForData = this.getCategoryIdForData(headCatDimensionsData);
		}
		let headCatSeries = {
			type: 'pie',
			innerSize: this.props.viewType === 0 ? '85%' : '85%',
			showInLegend: false,
			size: this.props.isPdfView ? 737 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 64 : (this.props.browserWidth / 100) * 35 : (this.props.browserWidth / 100) * 27, // 737 : 587
			startAngle: 0,
			enableMouseTracking: false,
			borderWidth: 2,
			borderColor: 'black',
			endAngle: 360,
			data: null
		}
		let data = [];
		if (radarDepth == 2) {
			let fontAndWidth = this.getFontAndWidth(this.props.browserWidth, noOfCategoriesInSub);
			const { font, widthArray } = fontAndWidth;
			for (let i = 0; i < noOfCategoriesInSub; i++) {
				let dataHeadCat = {
					name: names[i],
					y: 1,
					color: 'white',
					dataLabels: {
						enabled: true,
						distance: 0,
						style: {
							fontSize: font,
							width: this.props.viewType === 0 ? widthArray[0] : widthArray[1],
							textOverflow: 'ellipsis',
							opacity: (this.props.viewType == 11) ? (categoryIdForData[i] == selectedCategoryId ? 1 : 0.2) : 1
						},
						textPath: {
							enabled: true,
							attributes: {
								dy: 3
							}
						}
					},
					categoryId: categoryIdForData[i] === undefined ? null : categoryIdForData[i]
				}
				data.push(dataHeadCat);
			}
		}
		headCatSeries.data = data;
		return headCatSeries


	}

	getColumnSeriesData = (categoriesData, radarDepth) => {
		let dataArray = [];
		let arrayIndex = 0;
		if (radarDepth == 2) {
			arrayIndex = categoriesData[0] && categoriesData[0].length == 2 ? 1 : 0;
			for (var i in categoriesData) {
				let newData = arrayIndex == 1 ? categoriesData[i][arrayIndex].data : categoriesData[i].data;
				for (var j in newData) {
					dataArray.push(newData[j].y * 10)
				}
			}
			return dataArray;
		}
		else {
			arrayIndex = categoriesData.length === 2 ? 1 : 0;
			let newData = categoriesData[arrayIndex].data;
			for (var o in newData) {
				dataArray.push(newData[o].y * 10)
			}
			return dataArray;
		}
	}

	getCategoryIdForData = (headCatDimensionsData) => {
		const { reportGraphItemsNew } = this.props;
		let seriesData = reportGraphItemsNew?.comparision?.series[0]?.data ? reportGraphItemsNew?.comparision?.series[0]?.data?.filter((ele) => ele.categoryId !== null) : [];
		// let seriesData = this.props.reportGraphItemsNew.comparision.series[0].data;
		let catArray = [];
		for (var i in seriesData) {
			let array = [];
			for (let j = 0; j < headCatDimensionsData[i]; j++) {
				array.push(seriesData[i].categoryId);
			}
			catArray = catArray.concat(array);
		}
		return catArray
	}

	getColumnSeries = (categoriesData) => {
		let radarDepth = this.props.reportSurveyItems.depth;
		let innerData = this.getColumnSeriesData(categoriesData, radarDepth);
		let dataArray = [];
		let headCatDimensionsData = [];
		let categoryIdForData = [];
		if (radarDepth == 2) {
			headCatDimensionsData = this.getHeadCatDimensionsData(categoriesData);
			categoryIdForData = this.getCategoryIdForData(headCatDimensionsData);
		}
		let colorArray = ["rgb(238,140,203,0.2)", "rgb(151,225,157,0.2)", "rgb(149,131,193,0.2)", "rgb(128,152,211,0.2)", "rgb(240,197,115,0.2)", "rgb(46,229,218,0.2)", "rgb(222,99,120,0.2)", "rgb(50,189,76,0.2)"];
		let colorArrayFinal = [];
		categoriesData.map((obj, i) => {
			if (obj[0]) {
				obj[0].data.map(() => {
					colorArrayFinal.push(colorArray[i]);
				});
			} else if (obj && obj.data && obj.data.length) {
				obj.data.map(() => {
					colorArrayFinal.push(colorArray[i]);
				});
			}
		});
		for (var i in innerData) {
			let d = {
				y: 0,
				color: colorArrayFinal[i] ? colorArrayFinal[i] : '#B1D9F3',
				categoryId: categoryIdForData[i],
				opacity: (this.props.viewType == 11) ? (categoryIdForData[i] == this.props.selectedCategoryId ? 1 : 0.2) : 1
			}
			d.y = innerData[i];
			dataArray.push(d);
		}
		if (innerData.length != 0) {
			let varColumnSeries = {
				name: 'Peer Average',
				type: 'column',
				data: dataArray,
				zIndex: 2,
				pointPlacement: 'between',
				borderWidth: 2,
				borderColor: 'black',
				enableMouseTracking: false,
				pointStart: 0,
				pointInterval: 360 / (innerData.length),
				pointPadding: 0,
				groupPadding: 0,
			};
			return varColumnSeries
		}
	}

	callAllFunctions = (pieData, categoriesData) => {
		let headCategories = [];
		let headCatSeries = this.getHeadCatSeries(pieData, categoriesData);
		let headCatOne = this.drawHeadCategories(pieData, true, categoriesData);
		let subCatHeads = this.drawSubCatHeads(categoriesData);
		let columnSeries = this.getColumnSeries(categoriesData);
		headCategories.push(headCatSeries, headCatOne, subCatHeads, columnSeries);
		return headCategories
	}


	rearrangeSubCategoriesAPIsData = (subCategoryIdArray) => {
		let radarSubCategoriesAPIsData = this.props.radarSubCategoriesAPIsData;
		let radarSubCategoriesAPIsDataNew = [];
		for (var i in subCategoryIdArray) {
			for (var j in radarSubCategoriesAPIsData) {
				if (radarSubCategoriesAPIsData[j].subCat == subCategoryIdArray[i]) {
					radarSubCategoriesAPIsDataNew.push(radarSubCategoriesAPIsData[j])
				}
			}
		}

		return radarSubCategoriesAPIsDataNew
	}

	getRearrangedCategoriesAPIsData = (categoryIdArray) => {
		let drillDownData = JSON.parse(JSON.stringify(this.props.reportGraphItemsNew.drillDownData));
		let rearrangedCategoriesAPIsData = [];
		for (var i in categoryIdArray) {
			let index = categoryIdArray[i];
			if (drillDownData[index]) {
				rearrangedCategoriesAPIsData.push(drillDownData[index]);
			}
		}
		return rearrangedCategoriesAPIsData
	}

	makeRadarData = (categoriesData, subCategoriesData) => {
		let reportGraphItemsNew = this.props.reportGraphItemsNew;
		let reportQuestionsItems = this.props.reportQuestionsItems;
		let filteredSeriesData = reportGraphItemsNew?.comparision?.series[0]?.data ? reportGraphItemsNew?.comparision?.series[0]?.data?.filter((ele) => ele.categoryId !== null) : [];
		let headCategories = this.callAllFunctions(filteredSeriesData, categoriesData);
		let lineSeriesData = this.lineSeriesData(subCategoriesData);
		let lineSeries = this.getLineSeries(lineSeriesData);
		if (reportGraphItemsNew?.comparision?.series?.length == 2) {
			let activeUserList = reportQuestionsItems.activeUserList;
			let nextToActiveIndex = null;
			let activeIndex = null;
			for (var i in activeUserList) {
				if (activeUserList[i] !== "") {
					activeIndex = i;
				}
			}
			if (activeIndex != null) {
				if (activeIndex == activeUserList.length) {
					nextToActiveIndex = 0;
				}
				else {
					if (activeIndex > 0) {
						nextToActiveIndex = Number(activeIndex) - 1;
					} else {
						nextToActiveIndex = Number(activeIndex) + 1;
					}
				}
				lineSeries[activeIndex].color = 'red';
				lineSeries[activeIndex].lineWidth = 1.5;
				lineSeries[activeIndex].showInLegend = false;
				if (lineSeries[nextToActiveIndex] != undefined && activeIndex != 1) {
					lineSeries[nextToActiveIndex].showInLegend = false;
				}
			}
		}
		this.mergeSeries(headCategories, lineSeries);
	}

	getInnerCircles = () => {
		let innerCircles = [];
		let circleOne = {
			data: [[0, 0]],
			zIndex: 3,
			marker: {
				radius: this.props.isPdfView ? 65 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 7 : (this.props.browserWidth / 100) * 3 : (this.props.browserWidth / 100) * 2, // 65 : 50
				lineColor: 'black',
				fillColor: 'transparent',
				lineWidth: 1,
				symbol: 'circle'
			},
			enableMouseTracking: false,
			showInLegend: false
		}
		let circleTwo = {
			data: [[0, 0]],
			zIndex: 3,
			marker: {
				radius: this.props.isPdfView ? 130 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 14 : (this.props.browserWidth / 100) * 6 : (this.props.browserWidth / 100) * 4.5, // 130 : 100
				lineColor: 'black',
				fillColor: 'transparent',
				lineWidth: 1,
				symbol: 'circle'
			},
			enableMouseTracking: false,
			showInLegend: false
		}
		let circleThree = {
			data: [[0, 0]],
			zIndex: 3,
			marker: {
				radius: this.props.isPdfView ? 195 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 21 : (this.props.browserWidth / 100) * 9 : (this.props.browserWidth / 100) * 7, // 195 : 150
				lineColor: 'black',
				fillColor: 'transparent',
				lineWidth: 1,
				symbol: 'circle'
			},
			enableMouseTracking: false,
			showInLegend: false
		}
		let circleFour = {
			data: [[0, 0]],
			zIndex: 3,
			marker: {
				radius: this.props.isPdfView ? 260 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 28 : (this.props.browserWidth / 100) * 12 : (this.props.browserWidth / 100) * 9.5, // 260 : 200
				lineColor: 'black',
				fillColor: 'transparent',
				lineWidth: 1,
				symbol: 'circle'
			},
			enableMouseTracking: false,
			showInLegend: false
		}
		innerCircles = innerCircles.concat(circleOne, circleTwo, circleThree, circleFour);
		return innerCircles
	}
	// setLegendForIReport = (lineSeries) => {
	// 	let legendDefault = this.state.legends;
	// 	if (lineSeries.length === 1) {
	// 		if (lineSeries[0].color === "red") {
	// 			let newLegendToBeSet = {
	// 				color: "red",
	// 				text: lineSeries[0].name
	// 			}
	// 			let dataArray = [];
	// 			dataArray.push(newLegendToBeSet);
	// 			// this.props.sendLegendProps(dataArray);
	// 		}
	// 		else if (lineSeries[0].color === "black") {
	// 			let dataArray = [];
	// 			dataArray.push(legendDefault[0], legendDefault[1]);
	// 			// this.props.sendLegendProps(dataArray);
	// 		}
	// 	}
	// 	else {
	// 		let youFilled = false;
	// 		for (var i in lineSeries) {
	// 			if (lineSeries[i].color == "red") {
	// 				youFilled = true;
	// 				let youLegendDetails = legendDefault[2];
	// 				youLegendDetails.text = lineSeries[i].name;
	// 				let dataArray = [];
	// 				dataArray.push(legendDefault[0], legendDefault[1], youLegendDetails);
	// 				// this.props.sendLegendProps(dataArray);
	// 			}
	// 		}
	// 		if (youFilled == false) {
	// 			let dataArray = [];
	// 			dataArray.push(legendDefault[0], legendDefault[1]);
	// 			// this.props.sendLegendProps(dataArray);
	// 		}
	// 	}
	// }

	mergeSeries = (headSeries, lineSeries) => {
		if (this.props.viewType == 0) {
			// this.setLegendForIReport(lineSeries);
		}
		let newArray = [];
		let innerCircles = this.getInnerCircles();
		let backgroundForRadar = lineSeries[0] && lineSeries[0].data ? this.getBackgroundForRadar(lineSeries[0].data.length) : {};
		newArray = newArray.concat(headSeries, lineSeries, innerCircles, backgroundForRadar);
		this.setFinalSeries(newArray, lineSeries[0] && lineSeries[0].data ? lineSeries[0].data.length : 0);
	}

	callEventOnRadarClick = (event) => {
		this.props.sendSelectedCategoryIdToDashboard(event.point.options.categoryId);
		this.props.changeViewType(1);
	}
	setFinalSeries = (finalSeries, subCategoriesCount) => {
		let config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				height: this.props.isPdfView ? 1000 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 94 : (this.props.browserWidth / 100) * 47 : (this.props.browserWidth / 100) * 38, // 1000 : 800
				width: this.props.isPdfView ? this.props.browserWidth - 60 : '',
				polar: true
			},
			title: {
				text: ''
			},
			subtitle: {
				text: ''
			},
			pane: {
				startAngle: 0,
				endAngle: 360,
				size: this.props.isPdfView ? 655 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 55 : (this.props.browserWidth / 100) * 30 : (this.props.browserWidth / 100) * 22.8, // 655 : 505
				zIndex: 1,
				background: [{
					backgroundColor: '#F7F9F9'
				}]
			},
			xAxis: {
				min: 0,
				max: 360,
				labels: {
					enabled: false
				}
			},

			yAxis: {
				min: 0,
				max: 100,
				labels: {
					enabled: false
				}
			},
			legend: {
				enabled: false
			},
			series: finalSeries,
			credits: {
				enabled: false
			},
			plotOptions: {
				line: {
					pointInterval: 360 / (subCategoriesCount),
					pointStart: 360 / (subCategoriesCount * 2),
				},
				series: {
					cursor: 'pointer',
					events: {
						legendItemClick: function (e) {
							e.preventDefault();
						},
					},
					point: {
						events: {
							click: event => {
								if (event.point.options.categoryId) {
									this.callEventOnRadarClick(event, finalSeries, subCategoriesCount);
								}
							}
						}
					},
					states: {
						inactive: {
							opacity: 1
						}
					}
				}
			},
			navigation: {
				buttonOptions: {
					enabled: false
				}
			},
			tooltip: {
				formatter: function () {
					var num = this.y / 10;
					var finalValue = num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
					if (this.series.name == 'Series 1') {
						return "Click Here to Know More"
					}
					else {
						return '<b>' + this.series.name +
							'</b> : <b>' + finalValue + '</b>'
					}
				},
				outside: true
			},
		}

		let options = {
			series: config.series
		}
		this.setState({ series: finalSeries, loading: false, showRadar: true, subCategoriesCount: subCategoriesCount, config: config, options: options });
	}
	getBackgroundForRadar = (length) => {

		let genericData = {
			y: 10,
			color: 'white'
		}
		let backgroundRadarData = [];
		for (let i = 0; i < length; i++) {
			backgroundRadarData.push(genericData);
		}
		let pieData = {
			type: 'pie',
			size: this.props.isPdfView ? 655 : this.props.viewType === 0 ? this.props.isMobileLayout ? (this.props.browserWidth / 100) * 56 : (this.props.browserWidth / 100) * 30 : (this.props.browserWidth / 100) * 23, // 655 : 505
			startAngle: 0,
			enableMouseTracking: false,
			borderWidth: 2,
			borderColor: 'black',
			dataLabels: {
				enabled: false
			},
			color: 'white',
			endAngle: 360,
			data: null
		};

		pieData.data = backgroundRadarData;

		return pieData
	}
	getLineSeries = (lineSeriesData) => {
		let reportQuestionsItems = this.props.reportQuestionsItems ? JSON.parse(JSON.stringify(this.props.reportQuestionsItems)) : {};
		let lineSeriesNames = reportQuestionsItems.header;
		let dataArray = [];
		for (let i = 0; i < lineSeriesNames?.length; i++) {
			let dataForLine = []
			for (let j = 0; j < lineSeriesData.length; j++) {
				dataForLine.push(lineSeriesData[j][i]);
			}
			let lineSer = {
				type: 'line',
				name: lineSeriesNames[i],
				showInLegend: i == 0 ? false : false,
				zIndex: 6,
				lineWidth: 0,
				marker: {
					lineWidth: 1,
					symbol: 'circle',
				},
				animation: {
					duration: 2000
				},
				data: dataForLine,
				color: 'black'
			};
			dataArray.push(lineSer);
		}


		return dataArray


	}
	lineSeriesData = (data) => {
		let dataArray = [];
		for (var i in data) {
			let dataQuestions = data[i];
			if (dataQuestions != undefined) {
				let dataA = this.getScoresFromData(dataQuestions);
				dataArray.push(dataA)
			}
		}
		return dataArray;
	}

	getScoresFromData = (data) => {
		let dataArray = [];
		for (let i = 0; i < data[0].userRating.length; i++) {
			let count = 0, total = 0,
				avgValue = 0;
			for (let j = 0; j < data.length; j++) {
				const reArrangeUserData = this.reArrangeUserData(data[j].userRating);
				if (reArrangeUserData[i] && reArrangeUserData[i].value != "-" && reArrangeUserData[i].value != "DNK") {
					count = count + 1;
					total = total + reArrangeUserData[i].value;
				}
			}
			if (count != 0) {
				let newVal = (total / count) * 10;
				avgValue = newVal;
			}
			dataArray.push(avgValue);
		}
		return dataArray;
	}

	reArrangeUserData = (userRating) => {
		let reArrangedUsrRating = [];
		let lineSeriesNames = this.props.reportGraphItemsNew.userList;
		lineSeriesNames.map((obj) => {
			const result = userRating.find(item => item.uniqueId == obj.uniqueId);
			reArrangedUsrRating.push(result);
		});
		return reArrangedUsrRating;
	}

	getCategoryIdArrayFromNewReportProps = () => {
		const { reportGraphItemsNew } = this.props;
		let catIdArray = [];
		// let seriesData = reportGraphItemsNew.comparision.series[0].data;
		let seriesData = reportGraphItemsNew?.comparision?.series[0]?.data ? reportGraphItemsNew?.comparision?.series[0]?.data?.filter((ele) => ele.categoryId !== null) : [];
		for (var i in seriesData) {
			catIdArray.push(seriesData[i].categoryId)
		}
		return catIdArray
	}
	rearrangeReceivedPropsData = () => {
		let radarDepth = this.props.reportSurveyItems.depth;
		let categoryIdArray = this.getCategoryIdArrayFromNewReportProps();
		let rearrangedCategoriesAPIsData = [];
		let rearrangedSubCategoriesAPIsData = [];
		let properSubCategoriesIdArray = [];
		if (radarDepth == 2) {
			rearrangedCategoriesAPIsData = this.getRearrangedCategoriesAPIsData(categoryIdArray);
			for (var i in rearrangedCategoriesAPIsData) {
				let rearrangeCategoriesAPIsDataSeries = rearrangedCategoriesAPIsData[i][0] == undefined ? rearrangedCategoriesAPIsData[i].data : rearrangedCategoriesAPIsData[i][0].data;
				for (var j in rearrangeCategoriesAPIsDataSeries) {
					properSubCategoriesIdArray.push(rearrangeCategoriesAPIsDataSeries[j].categoryId)
				}
			}
			rearrangedSubCategoriesAPIsData = this.getRearrangedSubCategoriesAPIsData(properSubCategoriesIdArray);
			this.makeRadarData(rearrangedCategoriesAPIsData, rearrangedSubCategoriesAPIsData);
		}
		else {
			const rearrangedQuestionData = this.getRearrangedSubCategoriesAPIsData(categoryIdArray);
			this.makeRadarData(this.props.reportGraphItemsNew.comparision.series, rearrangedQuestionData)
		}

	}

	getRearrangedSubCategoriesAPIsData = (properSubCategoriesIdArray) => {

		let questionsData = this.props.reportQuestionsItems?.questions ? JSON.parse(JSON.stringify(this.props.reportQuestionsItems?.questions)) : [];
		let rearrangeSubCategoriesAPIsData = [];
		for (var i in properSubCategoriesIdArray) {
			let index = properSubCategoriesIdArray[i];
			rearrangeSubCategoriesAPIsData.push(questionsData[index]);
		}

		return rearrangeSubCategoriesAPIsData;
	}


	callMainFunctionForMultilevelRadar = () => {
		this.rearrangeReceivedPropsData();
	}

	render() {
		const { config } = this.state;
		return (

			<div id="container1">
				{
					this.props.viewTypeRadar ?
						<HighchartsReact
							options={config}
							highcharts={Highcharts}
						/>
						:
						null
				}
			</div>

		);
	}
}

export default MultiLevelRadar;
