import React, { useEffect, useState } from "react";
import Header from "../../../../../common/Header";
import MainTable from "../../../../../common/Table";
import PropTypes from "prop-types";
import Constants from "../../../../../config";
//import styled from "../../../css/surveyList.module.css";
import Skeleton from "@mui/material/Skeleton";
import DropDown from "../../../../../common/DropDown";
import styled from "../../../css/surveyList.module.css";
import "../../../css/surveyList.css";

const propTypes = {
  getSurveyReports: PropTypes.func,
  surveyList: PropTypes.object,
  isLoading: PropTypes.bool,
  reportsValue: PropTypes.string,
};

const yearOptions = ['2022', '2023', '2024', '2025'];

const Reports = (props) => {
  const [page, setPage] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const PER_PAGE = 10;

  useEffect(() => {
    apiCall({ page, recPerPage: PER_PAGE, year: selectedYear });
  }, [props?.reportsValue]);

  const apiCall = (value) => {
    props?.getSurveyReports(value);
  };
  const columns = [
    { id: "name", label: Constants.REPORT_TITLE, minWidth: 140 },
    {
      id: "accesseename",
      label: Constants.REQUESTED_FROM,
      minWidth: 140,
    },
    {
      id: "createdAt",
      label: Constants.REVIEW_DATE,
      minWidth: 140,
    },
    {
      id: "Action",
      label: Constants.ACTION,
      minWidth: 250,
      align: "center",
      type: "reports",
      buttonText1: Constants.DOWNLOAD_REPORT,
      buttonText2: Constants.VIEW_REPORT,
    },
  ];

  const pageFun = (value) => {
    setPage(value === 0 ? 0 : value - 1);
    apiCall({ page: value === 0 ? 0 : value - 1, recPerPage: PER_PAGE, year: selectedYear });
  };

  const responseToSurvey = (item) => {
    window.open(
      `${props.userDetail.hostUrl}/response/${item?.surveyId}/report/${item?.teamId}/clubbedId/${item?.clubbedId}/${localStorage?.getItem("access_token")}`
    );
  };

  const handleChangeYear = (value) => {
    setSelectedYear(value);
    apiCall({ page, recPerPage: PER_PAGE, year: value });
  }

  return (
    <>
      <Header active="Reports" />
      <div className={styled.surveyList}>
        <div className={styled.surveyListMain + " bg-color-login"}>
          <div className={styled.emptySpacer} />
          <div className={styled.mainTable}>
            <div className="mySurvey">
              <DropDown
                options={yearOptions}
                style={{ color: "#333333" }}
                updateValue={handleChangeYear}
                placeholder="Select year"
                value={selectedYear}
                label={'Select year'}
              />
              {props?.surveyList?.items?.length > 0 ? (
                props?.isLoading ? (
                  <div style={{ margin: '50px auto 0' }}>
                    <Skeleton variant="text" height={75} />
                    <Skeleton variant="text" height={120} />
                    <Skeleton variant="text" height={120} />
                    <Skeleton variant="text" height={120} />
                  </div>
                ) : (
                  <MainTable
                    columns={columns}
                    rows={props?.surveyList?.items}
                    totalRecords={props?.surveyList?.totalRecords}
                    pageFun={pageFun}
                    pageNumber={page}
                    buttonClick={responseToSurvey}
                    type={'small'}
                  />
                )
              ) : (
                <div className={"noData"}>{Constants.NO_DATA_FOUND}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Reports.propTypes = propTypes;
export default Reports;
