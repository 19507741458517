// import libs
import { connect } from 'react-redux'

// import components
import login from './components/login'
import {
  userLogin,
} from "../../store/actions";

const mapStateToProps = (state,ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    isLoading: state.auth.isLoading,
    status: state.status,
    isDesktopLayout: state.browser.screenLayout === "desktop",
    isTabletLayout: state.browser.screenLayout === "tablet",
    isMobileLayout: state.browser.screenLayout === "mobile",
    ownProps
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userLogin: (data) => {
      dispatch(userLogin(data));
    },
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(login)
