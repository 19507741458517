/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import translations from "../../../../../config/translations.json";
import { useNavigate } from "react-router-dom";
import styled from "../../../css/auth.module.css";
import loginImg from "../../../../../assets/login.svg";
import xeleron from "../../../../../assets/xeleron.svg";
import waadaLogo from "../../../../../assets/waadaLogo.svg";
import Field from "../../../../../common/Field";
import PrimaryButton from "../../../../../common/PrimaryButton";
import Checkbox from "../../../../../common/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import Constants from "../../../../../config";
import Loader from "../../../../../common/loader/loader";
import Divider from '@mui/material/Divider';
import { v4 as uuid } from "uuid";
import { parseJwt } from "../../../../../utils/Resusables";

const displayName = "login";
const propTypes = {
  isDesktopLayout: PropTypes.bool,
  isTabletLayout: PropTypes.bool,
  isMobileLayout: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  userLogin: PropTypes.func,
  status: PropTypes.string,
  userForgotPassword: PropTypes.func,
};

const login = (props) => {
  const navigate = useNavigate();

  const [formObj, setData] = useState({
    email: { value: "", error: false },
    password: { value: "", error: false },
  });
  const [showError, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    var checkLogin = !!localStorage.getItem("access_token") || location.href.indexOf('/sso?token=') > -1;
    if (location.href.indexOf('/sso?token=') > -1) {
      const parsedToken = parseJwt(location.href.split('/sso?token=')[1]);
      localStorage.setItem("access_token", parsedToken.sid);
    }
    if (checkLogin) {
      navigate("/dashboard");
    }
    if (window.location.href.indexOf('msg=You%20do%20not%20have%20access%20permission') > -1) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    switch (props.status) {
      case "USER_LOGIN":
        setLoading(true);
        break;
      case "USER_LOGIN_SUCCESS":
        var checkLogin = !!localStorage.getItem("access_token");
        if (checkLogin) {
          navigate("/dashboard");
          setLoading(false);
        }
        break;
      case "USER_LOGIN_ERROR":
        setError(true);
        setTimeout(() => setError(false), 3000);
        setLoading(false);
        break;
    }
  }, [props.status]);

  const onChange = (event) => {
    const inputValue = event.target.value.replace(/\s/g, "");
    setData({
      ...formObj,
      [event.target.name]: { value: inputValue, error: false },
    });
    setError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onLogin = (e, flag) => {
    if (e.key === 'Enter' || flag) {
      localStorage.setItem("keyUpdate", 0)
      e.preventDefault();
      setData({
        ...formObj,
        email: {
          ...formObj["email"],
          error:
            formObj["email"].value.trim() === "" ||
            !emailValidation(formObj["email"].value.trim()),
        },
        password: {
          ...formObj["password"],
          error: formObj["password"].value === "",
        },
      });
      if (
        formObj["email"].value !== "" &&
        formObj["password"].value.trim() !== "" &&
        emailValidation(formObj["email"].value.trim())
      ) {
        /*if (checkPasswordValid(formObj["password"].value)) {
          props.userLogin({
            email: formObj["email"].value,
            password: formObj["password"].value,
          });
        } else {
          setIsPasswordError(true);
        }*/
        props.userLogin({
          email: formObj["email"].value,
          password: formObj["password"].value,
        });
      }
    }
  };
  const emailValidation = (email) => {
    const regex = RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regex.test(email);
  };

  const checkPasswordValid = (validateData) => {
    const minLength = 6;
    const maxLength = 20;
    const password = validateData.trim();
    const fieldValid = new RegExp(
      "^.{" + minLength + "," + maxLength + "}$"
    ).test(password);
    return fieldValid;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSSO = (e) => {
    e.preventDefault();
    const localConnectId = uuid();
    localStorage.setItem("connectId", localConnectId);
    const API_URL = process.env.NODE_ENV === 'development' || window.location.href.indexOf('personal.stateofagility.com') > -1 ? 'https://test.stateofagility.com/cwb/sso/login' : 'https://portal.stateofagility.com/cwb/sso/login';
    window.location.replace(`${API_URL}?connectId=${localConnectId}`);
  }

  return (
    <div className="login-container bg-color-login">
      <div className={styled.authPage}>
        <div className={styled.authLeftSec}>
          <div className={styled.authHead}>
            {Constants.AUTH_SIDEBOX_HEADING}
          </div>
          <div className={styled.authPara}>
            {Constants.AUTH_SIDEBOX_PARA}
          </div>
          <div className={styled.loginImageDiv}>
            <img src={loginImg} alt="loginImg" className={styled.loginImage} />
          </div>
        </div>
        <div className={styled.authRightSec}>
          <img
            src={waadaLogo}
            alt="waadaLogo"
            className={styled.mainLogo}
          />
          <img src={loginImg} alt="loginImg" className={styled.isMobile} />
          <div className={styled.loginHeading}>{Constants.LOGIN_HEADING}</div>
          <button type="submit" className={styled.ssoBtn}
            onClick={onSSO}
          >
            <span className={styled.outlookImage} /><span className={styled.outlookText}>{Constants.SIGNINWITHOUTLOOK}</span>
          </button>
          <form>
            <Loader is_loading={isLoading} />
            <Divider orientation="horizontal" flexItem style={{ margin: '15px auto 0', width: '60%', color: '#999' }}>
              OR
            </Divider>
            <div className={styled.formField}>
              <div className={styled.formLable}>{Constants.EMAIL}</div>
              <Field
                placeholder={Constants.ENTER_EMAIL}
                name={"email"}
                value={formObj.email.value}
                error={formObj.email.error}
                helperText={
                  formObj.email.error
                    ? translations.errorMessage.email1[0]
                    : null
                }
                onChange={onChange}
              />
              <div className={styled.formLable}>{Constants.PASSWORD}</div>
              <Field
                placeholder={Constants.ENTER_PASSWORD}
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={formObj.password.value}
                error={formObj.password.error}
                helperText={
                  formObj.password.error
                    ? translations.errorMessage.password5[0]
                    : null
                }
                onChange={onChange}
                endadornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onKeyUp={onLogin}
              />
              <div className={styled.textForm}>
                <div className={styled.leftText}>
                  <Checkbox /> {Constants.REMEMBER_ME}
                </div>
                <div
                  className={styled.rightText}
                  onClick={() => navigate("/forgot-password")}
                >
                  {Constants.FORGOT_PASSWORD}?
                </div>
              </div>
              <div className={styled.loginButton}>
                {showError && props.errorMessage === '' ? <ToastNotify data={'You do not have access permission'} openAlert={showError} type={"error"} /> :
                  <ToastNotify data={props.errorMessage} openAlert={showError} type={"error"} />}
                <PrimaryButton
                  text={Constants.SIGNIN}
                  onClick={(e) => onLogin(e, true)}
                  className={styled.temporaryButton}
                />
              </div>
            </div>
          </form>
          <div className="footer-image" style={{ textAlign: 'center', }}>
            <img
              src={xeleron}
              alt="Xeleron"
              style={{marginTop: '30px'}}
              className="xeleron-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

login.displayName = displayName;
login.propTypes = propTypes;

export default login;
