import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem('access_token')) {
      navigate('/login')
    }
   }, []);
    return <p>Loading</p>
}

Authentication.propTypes = {
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
}

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps)(Authentication)
