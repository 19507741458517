import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import "./select.css";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
  addButton: PropTypes.func,
  freqUpdate: PropTypes.bool,
  freqUpdateFun: PropTypes.func,
  error: PropTypes.bool,
  closeError: PropTypes.func,
  label: PropTypes.string
};

function getStyles() {
  return {
    fontWeight: 500,
    fontSize: "12px",
    color: "#1F384C",
  };
}

const SelectDropdown = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    props?.freqUpdateFun && props?.freqUpdateFun()
    props?.closeError && props?.closeError()
    const {
      target: { value },
    } = event;
    props?.updateValue(value);
    setOpen(false);
  };

  return (
    <div className="selectDropdown">
      <FormControl>
        {
          props.label ?
            <InputLabel id="select-label">{props.label}</InputLabel> : null
        }
        <Select
          id={props.label ? 'select-label' : ''}
          displayEmpty
          value={props?.freqUpdate}
          onChange={handleChange}
          input={<OutlinedInput />}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          error={props?.error}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <div className="selectDropdownPlaceholder">
                  {props?.placeholder}
                </div>
              );
            }
            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          style={props?.style}
        >
          {props?.options?.length > 0 ? (
            props?.options?.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, props?.freqUpdate, theme)}
              >
                {name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Data Found</MenuItem>
          )}
        </Select>
      </FormControl>
      {(props?.error) && <FormHelperText htmlFor='render-select' error>This field is required</FormHelperText>}
    </div>
  );
};
SelectDropdown.propTypes = propTypes;
export default SelectDropdown;
