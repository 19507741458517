import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import "./table.css";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import moment from "moment";
import usePagination from "../Pagination";
import Switch from "@mui/material/Switch";
import Constants from "../../config";
import Checkbox from '@mui/material/Checkbox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import RateReviewIcon from '@mui/icons-material/RateReview';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import excludeGrey from "../../assets/excludeGrey.png";
import excludeGreyf from "../../assets/excludeGreyf.svg";
import add from "../../assets/add.svg";
import addGrey1 from "../../assets/addGrey1.png";

const propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  totalRecords: PropTypes.number,
  pageFun: PropTypes.func,
  pageNumber: PropTypes.number,
  buttonClick: PropTypes.func,
  buttonDeleteClick: PropTypes.func,
  surveyId: PropTypes.func,
  toggleClick: PropTypes.func,
  isPagination: PropTypes.bool,
  openModal: PropTypes.func,
  checkedList: PropTypes.func,
  withoutCheckBox: PropTypes.bool,
  selectedYear: PropTypes.string,
  selectedQuarter: PropTypes.string,
  userDetail: PropTypes.any,
  reviewsClick: PropTypes.func,
  handleExcludePeers: PropTypes.func,
  excludeUserId: PropTypes.array,
  setExcludeUserId: PropTypes.func,
  includeUserId: PropTypes.array,
  setIncludeUserId: PropTypes.func,
  handleIncludePeers: PropTypes.func,
  openPopup: PropTypes.bool,
  setOpenPopUp: PropTypes.func,
  isLoadingPeers: PropTypes.bool,
  setIsLoadingPeers: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
  recordMinutesPage: PropTypes.bool,
  meSection: PropTypes.bool
};

const MainTable = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(props.pageNumber + 1 || 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isFilledChecked, setIsFilledChecked] = React.useState(true);
  const [isNotFilledChecked, setIsNotFilledChecked] = React.useState(true);
  const [localRows, setLocalRows] = React.useState([]);

  React.useEffect(() => {
    if (props.rows && props.rows.length) {
      setLocalRows(props.rows);
    }
  }, [props.rows]);

  React.useEffect(() => {
    setIsFilledChecked(true);
    setIsNotFilledChecked(true);
  }, [props.selectedQuarter, props.selectedYear]);

  // const [checked, setChecked] = React.useState(false);
  const PER_PAGE = 10;
  const count = Math.ceil(props.totalRecords / PER_PAGE);
  const _DATA = usePagination(props.columns, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    props?.pageFun && props?.pageFun(p);
  };
  const actionButton = (item) => {
    props.buttonClick(item);
  };
  /*const actionDeleteButton = (item) => {
    props.buttonDeleteClick(item);
  };*/
  const rowClick = (rows) => {
    if (props?.recordMinutesPage) {
      if (props.selectedQuarter && rows?.id && rows?.shared == 1) {
        navigate(`/review/report/${props.selectedQuarter}/finalize/${rows?.id}`);
      }
    }
    else {
      props?.surveyId && props?.surveyId(rows?.surveyId);
    }
  };
  const handleSwitch = (event) => {
    props?.toggleClick && props?.toggleClick(event);
  };
  const handleChangeUser = (e, id, userId, tRow) => {
    if (id !== 'all') {
      let temp = JSON.parse(JSON.stringify(props.checkedList));
      let tempExcluded = JSON.parse(JSON.stringify(props.excludeUserId));
      let tempIncluded = JSON.parse(JSON.stringify(props.includeUserId));
      if (e.target.checked) {
        if (!temp.includes(id)) {
          temp.push(id)
        }
        if (tRow.isExcluded === 1 && !tempExcluded.includes(userId)) {
          tempExcluded.push(userId);
        }
        if (tRow.isExcluded === 0 && !tempIncluded.includes(userId)) {
          tempIncluded.push(userId);
        }
      } else {
        const index = temp.indexOf(id);
        const index1 = tRow.isExcluded === 1 ? tempExcluded.indexOf(userId) : tempIncluded.indexOf(userId);
        if (index > -1) {
          temp.splice(index, 1);
        }
        if (index1 > -1) {
          tRow.isExcluded === 1 ? tempExcluded.splice(index1, 1) : tempIncluded.splice(index1, 1);
        }
        props.setCheckedList(temp);
      }
      setDisabled(temp);
      props.setCheckedList(temp);
      if (tRow.isExcluded === 1) {
        props.setExcludeUserId(tempExcluded);
      }
      else {
        props.setIncludeUserId(tempIncluded);
      }
    } else {
      if (e.target.checked) {
        let arr = [];
        let tempExcluded = [];
        let tempIncluded = [];
        localRows.map((row) => {
          arr.push(row.surveyId);
          if (row.id !== '' && row.isExcluded === 0) {
            tempIncluded.push(row.id);
          }
          if (row.id !== '' && row.isExcluded === 1) {
            tempExcluded.push(row.id);
          }
        })
        setDisabled(arr);
        props.setCheckedList(arr);
        props.setExcludeUserId(tempExcluded);
        props.setIncludeUserId(tempIncluded);
      } else {
        props.setCheckedList([]);
        props.setIsDisabled(true);
        props.setExcludeUserId([]);
        props.setIncludeUserId([]);
      }
    }
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeFilled = (e) => {
    if (e.target.checked) {
      if (isNotFilledChecked) {
        setLocalRows(props.rows);
      } else {
        let arr = JSON.parse(JSON.stringify(props.rows));
        arr = arr.filter(obj => obj.surveyId !== '');
        setLocalRows(arr);
      }
    } else {
      if (isNotFilledChecked) {
        let arr = JSON.parse(JSON.stringify(props.rows));
        arr = arr.filter(obj => obj.surveyId === '');
        setLocalRows(arr);
      } else {
        setLocalRows([]);
      }
    }
    setIsFilledChecked(e.target.checked);
  }
  const handleChangeNotFilled = (e) => {
    if (e.target.checked) {
      if (isFilledChecked) {
        setLocalRows(props.rows);
      } else {
        let arr = JSON.parse(JSON.stringify(props.rows));
        arr = arr.filter(obj => obj.surveyId === '');
        setLocalRows(arr);
      }
    } else {
      if (isFilledChecked) {
        let arr = JSON.parse(JSON.stringify(props.rows));
        arr = arr.filter(obj => obj.surveyId !== '');
        setLocalRows(arr);
      } else {
        setLocalRows([]);
      }
    }
    setIsNotFilledChecked(e.target.checked);
  }
  const startReviewFunc = (e, userId) => {
    e.preventDefault();
    navigate(`/review/report/${props.selectedQuarter}/finalize/${userId}`);
  }
  const startReviewFuncOther = (e) => {
    e.preventDefault();
    navigate(`/minutes`);
  }
  const handleReviewsClick = (item, column) => {
    if (column && column.label === 'Reviews') {
      props.reviewsClick(item);
    }
  }

  const setDisabled = (arr) => {
    let count = 0;
    for (let i = 0; i < localRows.length; i++) {
      if (localRows[i].isExcluded === 0 && arr.includes(localRows[i].surveyId) && localRows[i].surveyId !== '') {
        count++;
        break;
      }
    }
    if (count > 0) {
      props.setIsDisabled(false);
      console.log(count);
    }
    else {
      props.setIsDisabled(true);
    }
  }

  return (
    <div className="tableBoxOther">
      {
        !props.withoutCheckBox ?
          <>
            <Button className="extend-btn" disabled={props.isDisabled} onClick={() => props.openModal(true)}><CalendarMonthIcon /><span>Extend Date</span></Button>
            <Button className="extend-btn" disabled={!props.includeUserId.length} onClick={props.handleExcludePeers}><div style={{ display: 'flex', alignItems: 'center' }}>{!props.includeUserId.length ? <img src={excludeGrey} style={{ height: '17px' }} alt="excludeGrey" /> : <img src={excludeGreyf} style={{ height: '17px' }} alt="exclude" />}<span>Deactivate Team Member</span></div></Button>
            <Button className="extend-btn" disabled={!props.excludeUserId.length} onClick={props.handleIncludePeers}><div style={{ display: 'flex', alignItems: 'center' }}>{!props.excludeUserId.length ? <img src={addGrey1} style={{ height: '26px', marginLeft: '-4px' }} alt="excludeGrey" /> : <img src={add} style={{ height: '17px' }} alt="Include" />}<span>Activate Team Member</span></div></Button>
          </> :
          null
      }
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props?.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="tableHeading"
                >
                  {
                    column?.id === "name" && column?.type === 'myTeam' && !props.withoutCheckBox ? (
                      <div className="flex">
                        <Checkbox
                          checked={props.checkedList.length && props.checkedList.length === localRows.length}
                          onChange={(e) => handleChangeUser(e, 'all', 'all')}
                          className='check-box'
                          sx={{
                            color: '#14009B',
                            '&.Mui-checked': {
                              color: '#14009B',
                            },
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={localRows.every(obj => obj.surveyId === '')}
                        />
                        <div style={{ marginLeft: '5px' }}>
                          {column.label}
                        </div>
                      </div>
                    ) : !props.withoutCheckBox && column?.id === "surveyId" ?
                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={open ? 'demo-customized-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          {column.label}
                        </Button>
                        <Menu
                          id="demo-customized-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleClose} disableRipple>
                            <FormControlLabel
                              label="Filled"
                              control={
                                <Checkbox
                                  checked={isFilledChecked}
                                  onChange={handleChangeFilled}
                                  className='check-box'
                                  sx={{
                                    color: '#14009B',
                                    '&.Mui-checked': {
                                      color: '#14009B',
                                    },
                                  }}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />}
                            />
                          </MenuItem>
                          <MenuItem onClick={handleClose} disableRipple>
                            <FormControlLabel
                              label="Not Filled"
                              control={
                                <Checkbox
                                  checked={isNotFilledChecked}
                                  onChange={handleChangeNotFilled}
                                  className='check-box'
                                  sx={{
                                    color: '#14009B',
                                    '&.Mui-checked': {
                                      color: '#14009B',
                                    },
                                  }}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />}
                            />
                          </MenuItem>
                        </Menu>
                      </div>
                      :
                      column.label
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {localRows?.length > 0 &&
              localRows?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {props?.columns.map((column) => {
                      const value = column?.type === 'quarter' ? row[column?.type] ? row[column?.type][column?.id] : '-' : row[column?.id] ? row[column?.id] : '-';
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={
                            column?.id === "Action"
                              ? () => { }
                              : () => rowClick(row)
                          }
                          className={row.isExcluded ? 'td-cell-custom' : null}
                        >
                          {column?.id === "Action" ? (
                            column?.type === "reports" ? (
                              row.surveyId !== '' ?
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                  <div>
                                    {
                                      !props?.recordMinutesPage ?
                                        <IconButton aria-label="review" title={column?.buttonText2} onClick={() => actionButton(row)} className="iconBtn-with-text">
                                          <VisibilityIcon style={{ color: '#14009b' }} />
                                          <div className="btn-small-text">{column?.buttonText2}</div>
                                        </IconButton> : null
                                    }
                                  </div>
                                  {(props.withoutCheckBox) || (!props.withoutCheckBox && row?.reviewTeamId && row?.reviewClubbedId && row?.reviewTeamId !== '' && row?.reviewClubbedId !== '') ?
                                    props?.recordMinutesPage ?
                                      <IconButton aria-label="review" title={'Review Minutes'} onClick={(e) => props.withoutCheckBox && !props?.recordMinutesPage ? startReviewFuncOther(e) : startReviewFunc(e, row.id)} className="iconBtn-with-text">
                                        <RateReviewIcon style={{ color: '#f56102' }} />
                                        <div className="btn-small-text">Review Minutes</div>
                                      </IconButton>
                                      : null
                                    : null}
                                </div> : '-'
                            ) : column.type === "mySurvey" ?
                              (row?.teamId == null && row?.clubbedId == null)
                                ?
                                (
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text="Edit"
                                      className="reportButton type0"
                                      onClick={() => actionButton(row)}
                                    />
                                  </div>
                                )
                                :
                                (
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text={column?.buttonText}
                                      className="reportButton type1"
                                      onClick={() => actionButton(row)}
                                    />
                                    {/*
                                      column?.type === "mySurvey" ?
                                        <PrimaryButton
                                          text={"Remove"}
                                          className="reportButton type3 other"
                                          onClick={() => actionDeleteButton(row)}
                                        />
                                        : null
                                */}
                                  </div>
                                )
                              :
                              column?.type === "recievedSurvey"
                                ?
                                row?.status === "SUBMITTED" ?
                                  <div className="green">Responded</div>
                                  :
                                  row?.status === "CREATED" && moment(row?.endDate?.sec) < moment(new Date().getTime() / 1000) ? (
                                    <div className="red">Review Closed</div>
                                  )
                                    :
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                      <PrimaryButton
                                        text={column?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(row)}
                                      />
                                    </div>
                                :
                                column?.type === "myPeer"
                                  ?
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text={column?.buttonText}
                                      className="reportButton type1"
                                      onClick={() => actionButton(row)}
                                    />
                                  </div>
                                  :
                                  (
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                      <PrimaryButton
                                        text={column?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(row)}
                                      />
                                    </div>
                                  )
                          ) : column?.id === "endDate" || column?.id === "startDate" ||
                            column?.id === "selfieStartDate" || column?.id === "peerScheduleDate" ||
                            column?.id === "createdAt" || column?.id === "selfieEndDate" ||
                            column?.id === "selfieScheduleDate" || column?.id === "selfieEndDate" || column?.id === "peerEndDate" ? (
                            value !== '-' ? moment.unix(value?.sec).utc().format("DD/MM/YYYY") : value
                          ) : column?.id === "isManager" ? (
                            <div className="toogleSwitch">
                              <Switch
                                checked={row.manager}
                                onChange={() => handleSwitch(row?.id)}
                                inputProps={{ "aria-label": "controlled" }}
                                disabled
                              />
                            </div>
                          ) : column?.id === "isSelfAssessment" ? (
                            <span>{row[column?.id] === false ? 'No' : 'Yes'}</span>
                          ) : column?.id === "filledSelfCount" || column?.id === "reviewFilledCount" ? (
                            <span onClick={() => handleReviewsClick(row, column)}>{`${row[column?.id]}/${row[column?.type]}`}</span>
                          ) : column?.id === "name" && column?.type === 'myTeam' ? (
                            <div className="flex">
                              {
                                !props.withoutCheckBox ?
                                  <Checkbox
                                    checked={props.checkedList.includes(row[column?.identifier]) || props.checkedList.includes(row.id)}
                                    onChange={(e) => handleChangeUser(e, row[column.identifier] ? row[column.identifier] : row.id, row.id, row)}
                                    className='check-box'
                                    sx={{
                                      color: '#14009B',
                                      '&.Mui-checked': {
                                        color: '#14009B',
                                      },
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  /> : null
                              }
                              <div className={'cardBoxCircle'} style={{ marginLeft: '4px' }}>
                                <span className={'cardBoxCircleText'}>
                                  {row[column?.id].charAt(0)}
                                </span>
                              </div>
                              <div style={{ marginTop: '10px' }}>
                                {row[column?.id]}
                              </div>
                            </div>
                          ) : column?.id === "surveyId" ? (
                            <span>{row[column?.id] !== '' ? <CheckIcon className={'icon-inside green'} /> : <CloseIcon className={'icon-inside red'} />}</span>
                          ) : column?.id === "reviewMinutesStatus" && !props.withoutCheckBox ?
                            (
                              <span>{row[column?.id] !== '' && row[column?.id] === 1 ? <CheckIcon className={'icon-inside green'} /> : <CloseIcon className={'icon-inside red'} />}</span>
                            ) :
                            props?.meSection ?
                              (
                                <span>{row[column?.id] !== '' && row[column?.id] === 1 ? <CheckIcon className={'icon-inside green'} /> : <CloseIcon className={'icon-inside red'} />}</span>
                              )
                              :
                              column?.id === "reviewMinutesStatus" && props.recordMinutesPage ? (
                                <span>{row[column?.id] !== '' && row[column?.id] === 1 ? <CheckIcon className={'icon-inside green'} /> : <CloseIcon className={'icon-inside red'} />}</span>
                              ) :
                                column?.id === "name" && column?.type !== 'myGoal' ? (
                                  <span>{row[column?.id]}</span>
                                ) : value ? (
                                  column?.type === "myPeer" && row?.manager ? <span>{value} <span className="smallTag">{Constants.MANAGER}</span></span> : value
                                ) : (
                                  "-"
                                )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        props?.isPagination === false ? (
          <div ></div>
        ) : (
          <div className="pagination">
            <Pagination
              variant="outlined"
              count={count}
              page={page}
              onChange={handleChange}
            />
          </div>
        )
      }
    </div >
  );
}

MainTable.propTypes = propTypes;
export default MainTable;
