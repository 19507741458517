import * as types from "./action-types";
import * as API from "./api";

export const resetAddQuestion = () => {
  return {
    type: types.RESET_ADD_QUESTION_STORE,
  };
};

export const createSurvey = (data) => {
  const request = API.postCreateSurvey(data);
  return {
    type: types.CREATE_GOAL,
    payload: request,
  };
};

export const createSurveySuccess = (result) => {
  return {
    type: types.CREATE_GOAL_SUCCESS,
    result,
  };
};

export const createSurveyFailure = (errorMessage) => ({
  type: types.CREATE_GOAL_ERROR,
  errorMessage,
});

export const getSurveyDescription = (payload) => {
  API.getSurveyDescription(payload);
  return {
    type: types.GET_GOAL_DESCRIPTION,
  };
};

export const getSurveyDescriptionSuccess = (result) => {
  return {
    type: types.GET_GOAL_DESCRIPTION_SUCCESS,
    result,
  };
};

export const getSurveyDescriptionFailure = (errorMessage) => ({
  type: types.GET_GOAL_DESCRIPTION_ERROR,
  errorMessage,
});

export const copySurveyQuestion = (payload) => {
  return {
    type: types.COPY_GOAL_QUESTION,
    payload,
  };
};

export const postAddQuestion = (data) => {
  const request = API.postAddQuestion(data);
  return {
    type: types.POST_ADD_QUESTION,
    payload: request,
  };
};

export const postAddQuestionSuccess = (result) => {
  return {
    type: types.POST_ADD_QUESTION_SUCCESS,
    result,
  };
};

export const postAddQuestionFailure = (errorMessage) => ({
  type: types.POST_ADD_QUESTION_ERROR,
  errorMessage,
});

export const getPreviewQuestion = (payload) => {
  API.getPreviewQuestion(payload);
  return {
    type: types.GET_PREVIEW_QUESTION,
  };
};

export const getPreviewQuestionSuccess = (result) => {
  return {
    type: types.GET_PREVIEW_QUESTION_SUCCESS,
    result,
  };
};

export const getPreviewQuestionFailure = (errorMessage) => ({
  type: types.GET_PREVIEW_QUESTION_ERROR,
  errorMessage,
});

export const getCategoreyList = (payload) => {
  API.getCategoreyList(payload);
  return {
    type: types.GET_CATEGORY_LIST,
  };
};

export const getCategoreyListSuccess = (result) => {
  return {
    type: types.GET_CATEGORY_LIST_SUCCESS,
    result,
  };
};

export const getCategoreyListFailure = (errorMessage) => ({
  type: types.GET_CATEGORY_LIST_SUCCESS,
  errorMessage,
});

export const editQuestions = (payload) => {
  API.editQuestions(payload);
  return {
    type: types.EDIT_QUESTIONS,
  };
};

export const editQuestionsSuccess = (result) => {
  return {
    type: types.EDIT_QUESTIONS_SUCCESS,
    result,
  };
};

export const editQuestionsFailure = (errorMessage) => ({
  type: types.EDIT_QUESTIONS_ERROR,
  errorMessage,
});

export const getSubCategoreyList = (payload, surveyId) => {
  API.getSubCategoreyList(payload, surveyId);
  return {
    type: types.GET_SUB_CATEGORY_LIST,
  };
};

export const getSubCategoreyListSuccess = (result) => {
  return {
    type: types.GET_SUB_CATEGORY_LIST_SUCCESS,
    result,
  };
};

export const getSubCategoreyListFailure = (errorMessage) => ({
  type: types.GET_SUB_CATEGORY_LIST_SUCCESS,
  errorMessage,
});

export const getDefaultCategoreyList = (payload) => {
  API.getDefaultCategoreyList(payload);
  return {
    type: types.GET_DEFAULT_CATEGORY_LIST,
  };
};

export const getDefaultCategoreyListSuccess = (result) => {
  return {
    type: types.GET_DEFAULT_CATEGORY_LIST_SUCCESS,
    result,
  };
};

export const getDefaultCategoreyListFailure = (errorMessage) => ({
  type: types.GET_DEFAULT_CATEGORY_LIST_SUCCESS,
  errorMessage,
});

export const getPeersList = (payload) => {
  API.getPeersList(payload);
  return {
    type: types.GET_PEER_LIST,
  };
};

export const getPeersListSuccess = (result) => {
  return {
    type: types.GET_PEER_LIST_SUCCESS,
    result,
  };
};

export const getPeersListFailure = (errorMessage) => ({
  type: types.GET_PEER_LIST_ERROR,
  errorMessage,
});

export const addExternalPeers = (payload) => {
  API.addExternalPeers(payload);
  return {
    type: types.ADD_EXTERNAL_PEERS,
  };
};

export const addExternalPeersSuccess = (result) => {
  return {
    type: types.ADD_EXTERNAL_PEERS_SUCCESS,
    result,
  };
};

export const addExternalPeersFailure = (errorMessage) => ({
  type: types.ADD_EXTERNAL_PEERS_ERROR,
  errorMessage,
});


export const invitePeers = (payload) => {
  API.invitePeers(payload);
  return {
    type: types.INVITE_PEERS,
  };
};

export const invitePeersSuccess = (result) => {
  return {
    type: types.INVITE_PEERS_SUCCESS,
    result,
  };
};

export const invitePeersFailure = (errorMessage) => ({
  type: types.INVITE_PEERS_ERROR,
  errorMessage,
});

export const editSurvey = (data) => {
  const request = API.editSurvey(data);
  return {
    type: types.EDIT_GOAL,
    payload: request,
  };
};

export const editSurveySuccess = (result) => {
  return {
    type: types.EDIT_GOAL_SUCCESS,
    result,
  };
};

export const editSurveyFailure = (errorMessage) => ({
  type: types.EDIT_GOAL_ERROR,
  errorMessage,
});

export const searchPeer = (data) => {
  const request = API.searchPeer(data);
  return {
    type: types.SEARCH_PEERS,
    payload: request,
  };
};

export const searchPeerSuccess = (result) => {
  return {
    type: types.SEARCH_PEERS_SUCCESS,
    result,
  };
};

export const searchPeerFailure = (errorMessage) => ({
  type: types.SEARCH_PEERS_ERROR,
  errorMessage,
});

export const searchAddPeer = (data) => {
  const request = API.searchAddPeer(data);
  return {
    type: types.ADD_SEARCH_PEERS,
    payload: request,
  };
};

export const searchAddPeerSuccess = (result) => {
  return {
    type: types.ADD_SEARCH_PEERS_SUCCESS,
    result,
  };
};

export const searchAddPeerFailure = (errorMessage) => ({
  type: types.ADD_SEARCH_PEERS_ERROR,
  errorMessage,
});

export const sendSurveyPeer = (data) => {
  const request = API.sendSurveyPeer(data);
  return {
    type: types.SCHEDULE_REVIEW_PEER,
    payload: request,
  };
};

export const sendSurveyPeerSuccess = (result) => {
  return {
    type: types.SCHEDULE_REVIEW_PEER_SUCCESS,
    result,
  };
};

export const sendSurveyPeerFailure = (errorMessage) => ({
  type: types.SCHEDULE_REVIEW_PEER_ERROR,
  errorMessage,
});

export const getScheduleCandidate = (data) => {
  const request = API.getScheduleCandidate(data);
  return {
    type: types.GET_SCHEDULE_CANDIDATE,
    payload: request,
  };
};

export const getScheduleCandidateSuccess = (result) => {
  return {
    type: types.GET_SCHEDULE_CANDIDATE_SUCCESS,
    result,
  };
};

export const getScheduleCandidateFailure = (errorMessage) => ({
  type: types.GET_SCHEDULE_CANDIDATE_ERROR,
  errorMessage,
});

export const deleteScheduleCandidate = (data) => {
  const request = API.deleteScheduleCandidate(data);
  return {
    type: types.DELETE_SCHEDULE_CANDIDATE,
    payload: request,
  };
};

export const deleteScheduleCandidateSuccess = (result) => {
  return {
    type: types.DELETE_SCHEDULE_CANDIDATE_SUCCESS,
    result,
  };
};

export const deleteScheduleCandidateFailure = (errorMessage) => ({
  type: types.DELETE_SCHEDULE_CANDIDATE_ERROR,
  errorMessage,
});

export const questionDelete = (data) => {
  const request = API.questionDelete(data);
  return {
    type: types.QUESTION_DELETE,
    payload: request,
  };
};

export const questionDeleteSuccess = (result) => {
  return {
    type: types.QUESTION_DELETE_SUCCESS,
    result,
  };
};

export const questionDeleteFailure = (errorMessage) => ({
  type: types.QUESTION_DELETE_ERROR,
  errorMessage,
});

export const deleteGoalTitle = (data) => {
  const request = API.deleteGoalTitle(data);
  return {
    type: types.GOAL_TITLE_DELETE,
    payload: request,
  };
};

export const deleteGoalTitleSuccess = (result) => {
  return {
    type: types.GOAL_TITLE_DELETE_SUCCESS,
    result,
  };
};

export const deleteGoalTitleFailure = (errorMessage) => ({
  type: types.GOAL_TITLE_DELETE_ERROR,
  errorMessage,
});

export const editGoalTitle = (data) => {
  const request = API.editGoalTitle(data);
  return {
    type: types.GOAL_TITLE_EDIT,
    payload: request,
  };
};

export const editGoalTitleSuccess = (result) => {
  return {
    type: types.GOAL_TITLE_EDIT_SUCCESS,
    result,
  };
};

export const editGoalTitleFailure = (errorMessage) => ({
  type: types.GOAL_TITLE_EDIT_ERROR,
  errorMessage,
});

export const getSentCandidate = (data) => {
  const request = API.getSentCandidate(data);
  return {
    type: types.GET_SENT_CANDIDATE,
    payload: request,
  };
};

export const getSentCandidateSuccess = (result) => {
  return {
    type: types.GET_SENT_CANDIDATE_SUCCESS,
    result,
  };
};

export const getSentCandidateFailure = (errorMessage) => ({
  type: types.GET_SENT_CANDIDATE_ERROR,
  errorMessage,
});

export const surveyInfoSavedDummy = () => ({
  type: types.SURVEY_INFO_CHANGED_DUMMY
});

export const sendSurvey = (data,surveyId) => {
  const request = API.sendSurvey(data,surveyId);
  return {
    type: types.SEND_SURVEY,
    payload: request,
  };
};

export const sendSurveySuccess = (errorMessage) => ({
  type: types.SEND_SURVEY_SUCCESS,
  errorMessage,
});

export const sendSurveyFailure = (result) => {
  return {
    type: types.SEND_SURVEY_ERROR,
    result,
  };
};

export const importGoals = (data,surveyId) => {
  const request = API.importGoals(data,surveyId);
  return {
    type: types.IMPORT_GOALS,
    payload: request,
  };
};

export const importGoalsSuccess = (errorMessage) => ({
  type: types.IMPORT_GOALS_SUCCESS,
  errorMessage,
});

export const importGoalsFailure = (result) => {
  return {
    type: types.IMPORT_GOALS_ERROR,
    result,
  };
};

export const getImportQuestion = (payload) => {
  API.getImportQuestion(payload);
  return {
    type: types.GET_IMPORT_QUESTIONS,
  };
};

export const getImportQuestionSuccess = (result) => {
  return {
    type: types.GET_IMPORT_QUESTIONS_SUCCESS,
    result,
  };
};

export const getImportQuestionFailure = (errorMessage) => ({
  type: types.GET_IMPORT_QUESTIONS_ERROR,
  errorMessage,
});