import React from "react";
import PropTypes from "prop-types";
// import styles from "../../../css/addQuestion.module.css";
import QuestionField from "./questionField";
import { connect, useDispatch } from 'react-redux'
import {
  copySurveyQuestion,
  postAddQuestion,
  getCategoreyList,
  getSubCategoreyList
} from "../../../store/actions";
import { useSearchParams } from "react-router-dom";

const propTypes = {
  errorMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  questionArray: PropTypes.array,
  selectedValue: PropTypes.string,
  surveyDetail: PropTypes.array,
  handleDelete: PropTypes.func,
  addhandleChange: PropTypes.func,
  questionDetail: PropTypes.object,
  previewQuestionApi: PropTypes.func,
  categoryList: PropTypes.array,
  previewQuestion: PropTypes.array,
  subCategoryList: PropTypes.array,
  addOptionCategory: PropTypes.func,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  ikigaiDetails: PropTypes.array
};
const QuestionBlock = (props) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const categoryApiCall = () => {
    if (props?.surveyDetail && props?.surveyDetail?._id) {
      dispatch(props?.getCategoreyList(props?.surveyDetail?._id))
    } else {
      if (searchParams.get("id")) {
        dispatch(props?.getCategoreyList(searchParams.get("id")));
      }
    }
  }
  return (
    <>
      {props.questionArray.length > 0
        ? props.questionArray.map((data, index) => {
          return (
            <div key={index}>
              <QuestionField
                title={`Goal`}
                value={index}
                id={data}
                surveyDetail={props.surveyDetail}
                handleDelete={props.handleDelete}
                copySurveyQuestion={props.copySurveyQuestion}
                addhandleChange={props.addhandleChange}
                questionDetail={props?.questionDetail}
                postAddQuestion={props?.postAddQuestion}
                previewQuestionApi={props?.previewQuestionApi}
                errorMessage={props?.errorMessage}
                isLoading={props?.isLoading}
                status={props?.status}
                categoryList={props?.categoryList}
                categoryApiCall={categoryApiCall}
                subCategoryList={props?.subCategoryList}
                getSubCategoreyList={props?.getSubCategoreyList}
                selectedValue={props?.selectedValue}
                addOptionCategory={props?.addOptionCategory}
                deleteGoalTitle={props?.deleteGoalTitle}
                editGoalTitle={props?.editGoalTitle}
                ikigaiDetails={props?.ikigaiDetails}
              />
            </div>
          );
        })
        : ""}
    </>
  );
}


const mapDispatchToProps = () => {
  return {
    copySurveyQuestion,
    postAddQuestion,
    getCategoreyList,
    getSubCategoreyList
  }
};

QuestionBlock.propTypes = propTypes;
export default connect(mapDispatchToProps)(QuestionBlock);
