export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS'
export const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS'
export const GET_DASHBOARD_DETAILS_ERROR = 'GET_DASHBOARD_DETAILS_ERROR'

export const GET_TEAM_DETAILS = 'GET_TEAM_DETAILS'
export const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS'
export const GET_TEAM_DETAILS_ERROR = 'GET_TEAM_DETAILS_ERROR'

export const GET_SELFIE_GRAPH_DETAILS = 'GET_SELFIE_GRAPH_DETAILS'
export const GET_SELFIE_GRAPH_DETAILS_SUCCESS = 'GET_SELFIE_GRAPH_DETAILS_SUCCESS'
export const GET_SELFIE_GRAPH_DETAILS_ERROR = 'GET_SELFIE_GRAPH_DETAILS_ERROR'

export const EXTEND_DATE = 'EXTEND_DATE'
export const EXTEND_DATE_SUCCESS = 'EXTEND_DATE_SUCCESS'
export const EXTEND_DATE_ERROR = 'EXTEND_DATE_ERROR'

export const GET_ME_DETAILS = 'GET_ME_DETAILS'
export const GET_ME_DETAILS_SUCCESS = 'GET_ME_DETAILS_SUCCESS'
export const GET_ME_DETAILS_ERROR = 'GET_ME_DETAILS_ERROR'

export const GET_GOAL_DETAILS = 'GET_GOAL_DETAILS'
export const GET_GOAL_DETAILS_SUCCESS = 'GET_GOAL_DETAILS_SUCCESS'
export const GET_GOAL_DETAILS_ERROR = 'GET_GOAL_DETAILS_ERROR'

export const GET_QUARTER_LIST = 'GET_QUARTER_LIST'
export const GET_QUARTER_LIST_SUCCESS = 'GET_QUARTER_LIST_SUCCESS'
export const GET_QUARTER_LIST_ERROR = 'GET_QUARTER_LIST_ERROR'

export const SET_SELECTED_QUARTER = 'SET_SELECTED_QUARTER'
export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR'

export const EXCLUDE_UNEXCLUDE_PEER = 'EXCLUDE_UNEXCLUDE_PEER'
export const EXCLUDE_UNEXCLUDE_PEER_SUCCESS = 'EXCLUDE_UNEXCLUDE_PEER_SUCCESS'
export const EXCLUDE_UNEXCLUDE_PEER_ERROR = 'EXCLUDE_UNEXCLUDE_PEER_ERROR'