import * as types from "./action-types";
import * as API from "./api";

export const getSurveyReceive = (payload) => {
  API.getSurveyReceive(payload);
  return {
    type: types.GET_GOAL_RECEIVE,
  };
};

export const getSurveyReceiveSuccess = (result) => {
  return {
    type: types.GET_GOAL_RECEIVE_SUCCESS,
    result,
  };
};

export const getSurveyReceiveFailure = (errorMessage) => ({
  type: types.GET_GOAL_RECEIVE_ERROR,
  errorMessage,
});


export const getSurveySent = (payload) => {
  API.getSurveySent(payload);
  return {
    type: types.GET_GOAL_SENT,
  };
};

export const getSurveySentSuccess = (result) => {
  return {
    type: types.GET_GOAL_SENT_SUCCESS,
    result,
  };
};

export const getSurveySentFailure = (errorMessage) => ({
  type: types.GET_GOAL_SENT_ERROR,
  errorMessage,
});

export const getSurveyDetails = (payload) => {
  API.getSurveyDetails(payload);
  return {
    type: types.GET_GOAL_DETAILS,
  };
};

export const getSurveyDetailsSuccess = (result) => {
  return {
    type: types.GET_GOAL_DETAILS_SUCCESS,
    result,
  };
};

export const getSurveyDetailsFailure = (errorMessage) => ({
  type: types.GET_GOAL_DETAILS_ERROR,
  errorMessage,
});

export const getSurveyReports = (payload) => {
  API.getSurveyReports(payload);
  return {
    type: types.GET_GOAL_REPORT,
  };
};

export const getSurveyReportsSuccess = (result) => {
  return {
    type: types.GET_GOAL_REPORT_SUCCESS,
    result,
  };
};

export const getSurveyReportsFailure = (errorMessage) => ({
  type: types.GET_GOAL_REPORT_ERROR,
  errorMessage,
});

export const deleteGoal = (payload) => {
  API.deleteGoal(payload);
  return {
    type: types.DELETE_GOAL,
  };
};

export const deleteGoalSuccess = (result) => {
  return {
    type: types.DELETE_GOAL_SUCCESS,
    result,
  };
};

export const deleteGoalFailure = (errorMessage) => ({
  type: types.DELETE_GOAL_ERROR,
  errorMessage,
});

export const getQuarterList = (payload) => {
  API.getQuarterList(payload);
  return {
    type: types.GET_QUARTER_LIST,
  };
};

export const getQuarterListSuccess = (result) => {
  return {
    type: types.GET_QUARTER_LIST_SUCCESS,
    result,
  };
};

export const getQuarterListFailure = (errorMessage) => ({
  type: types.GET_QUARTER_LIST_ERROR,
  errorMessage,
});

export const createQuarterlyGoal = (payload) => {
  API.createQuarterlyGoal(payload);
  return {
    type: types.CREATE_QUARTERLY_GOAL,
  };
};

export const createQuarterlyGoalSuccess = (result) => {
  return {
    type: types.CREATE_QUARTERLY_GOAL_SUCCESS,
    result,
  };
};

export const createQuarterlyGoalFailure = (errorMessage) => ({
  type: types.CREATE_QUARTERLY_GOAL_ERROR,
  errorMessage,
});

export const getReportSurvey = (payload) => {
  API.getReportSurvey(payload);
  return {
    type: types.GET_REPORT_SURVEY,
  };
};

export const getReportSurveySuccess = (result) => {
  return {
    type: types.GET_REPORT_SURVEY_SUCCESS,
    result,
  };
};

export const getReportSurveyFailure = (errorMessage) => ({
  type: types.GET_REPORT_SURVEY_ERROR,
  errorMessage,
});

export const getReportSurveyDetailData = (payload) => {
  API.getReportSurveyDetail(payload);
  return {
    type: types.GET_REPORT_SURVEY_DETAIL,
  };
};

export const getReportSurveyDetailSuccess = (result) => {
  return {
    type: types.GET_REPORT_SURVEY_DETAIL_SUCCESS,
    result,
  };
};

export const getReportSurveyDetailFailure = (errorMessage) => ({
  type: types.GET_REPORT_SURVEY_DETAIL_ERROR,
  errorMessage,
});

export const getReportGraph = (payload) => {
  API.getReportGraphData(payload);
  return {
    type: types.GET_REPORT_GRAPH,
  };
};

export const getReportGraphSuccess = (result) => {
  return {
    type: types.GET_REPORT_GRAPH_SUCCESS,
    result,
  };
};

export const getReportGraphFailure = (errorMessage) => ({
  type: types.GET_REPORT_GRAPH_ERROR,
  errorMessage,
});

export const getReportQuestion = (payload) => {
  API.getReportQuestion(payload);
  return {
    type: types.GET_REPORT_QUESTION,
  };
};

export const getReportQuestionSuccess = (result) => {
  return {
    type: types.GET_REPORT_QUESTION_SUCCESS,
    result,
  };
};

export const getReportQuestionFailure = (errorMessage) => ({
  type: types.GET_REPORT_QUESTION_ERROR,
  errorMessage,
});

export const getReviewUserList = (payload) => {
  API.getReviewUserListAPI(payload);
  return {
    type: types.GET_REVIEW_USER_LIST,
  };
};

export const getReviewUserListSuccess = (result) => {
  return {
    type: types.GET_REVIEW_USER_LIST_SUCCESS,
    result,
  };
};

export const getReviewUserListFailure = (errorMessage) => ({
  type: types.GET_REVIEW_USER_LIST_ERROR,
  errorMessage,
});

export const getReviewMinutes = (payload) => {
  API.getReviewMinutes(payload);
  return {
    type: types.GET_REVIEW_MINUTES,
  };
};

export const getReviewMinutesSuccess = (result) => {
  return {
    type: types.GET_REVIEW_MINUTES_SUCCESS,
    result,
  };
};

export const getReviewMinutesFailure = (errorMessage) => ({
  type: types.GET_REVIEW_MINUTES_ERROR,
  errorMessage,
});

export const createReviewMinutes = (payload) => {
  API.createReviewMinutes(payload);
  return {
    type: types.CREATE_REVIEW_MINUTES,
  };
};

export const createReviewMinutesSuccess = (result) => {
  return {
    type: types.CREATE_REVIEW_MINUTES_SUCCESS,
    result,
  };
};

export const createReviewMinutesFailure = (errorMessage) => ({
  type: types.CREATE_REVIEW_MINUTES_ERROR,
  errorMessage,
});

export const finalizeReviewMinutes = (payload) => {
  API.finalizeReviewMinutes(payload);
  return {
    type: types.FINALIZE_REVIEW_MINUTES,
  };
};

export const finalizeReviewMinutesSuccess = (result) => {
  return {
    type: types.FINALIZE_REVIEW_MINUTES_SUCCESS,
    result,
  };
};

export const finalizeReviewMinutesFailure = (errorMessage) => ({
  type: types.FINALIZE_REVIEW_MINUTES_ERROR,
  errorMessage,
});

export const deleteReviewMinute = (payload) => {
  API.deleteReviewMinute(payload);
  return {
    type: types.DELETE_REVIEW_MINUTES,
  };
};

export const deleteReviewMinuteSuccess = (result) => {
  return {
    type: types.DELETE_REVIEW_MINUTES_SUCCESS,
    result,
  };
};

export const deleteReviewMinuteFailure = (errorMessage) => ({
  type: types.DELETE_REVIEW_MINUTES_ERROR,
  errorMessage,
});

export const updateReviewMinute = (identifier, payload) => {
  API.updateReviewMinute(identifier, payload);
  return {
    type: types.UPDATE_REVIEW_MINUTES,
  };
};

export const updateReviewMinuteSuccess = (result) => {
  return {
    type: types.UPDATE_REVIEW_MINUTES_SUCCESS,
    result,
  };
};

export const updateReviewMinuteFailure = (errorMessage) => ({
  type: types.UPDATE_REVIEW_MINUTES_ERROR,
  errorMessage,
});

export const getReviewMinuteDetail = (quarterId, surveyId) => {
  API.getReviewMinuteDetail(quarterId, surveyId);
  return {
    type: types.GET_REVIEW_MINUTE_DETAIL,
  };
};

export const getReviewMinuteDetailSuccess = (result) => {
  return {
    type: types.GET_REVIEW_MINUTE_DETAIL_SUCCESS,
    result,
  };
};

export const getReviewMinuteDetailFailure = (errorMessage) => ({
  type: types.GET_REVIEW_MINUTE_DETAIL_ERROR,
  errorMessage,
});

export const shareReviewMinutes = (surveyId, payload) => {
  API.shareReviewMinutes(surveyId, payload);
  return {
    type: types.SHARE_REVIEW_MINUTE,
  };
};

export const shareReviewMinutesSuccess = (result) => {
  return {
    type: types.SHARE_REVIEW_MINUTE_SUCCESS,
    result,
  };
};

export const shareReviewMinutesFailure = (errorMessage) => ({
  type: types.SHARE_REVIEW_MINUTE_ERROR,
  errorMessage,
});

export const getReviewMinutesPeer = (quarterId) => {
  API.getReviewMinutesPeer(quarterId);
  return {
    type: types.GET_REVIEW_MINUTE_PEERS,
  };
};

export const getReviewMinutesPeerSuccess = (result) => {
  return {
    type: types.GET_REVIEW_MINUTE_PEERS_SUCCESS,
    result,
  };
};

export const getReviewMinutesPeerFailure = (errorMessage) => ({
  type: types.GET_REVIEW_MINUTE_PEERS_ERROR,
  errorMessage,
});