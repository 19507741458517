import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import "./table.css";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import moment from "moment";
import usePagination from "../Pagination";
import Switch from "@mui/material/Switch";
import Constants from "../../config";
// import RateReviewIcon from '@mui/icons-material/RateReview';
// import IconButton from '@mui/material/IconButton';
// import { useNavigate } from "react-router-dom";

const propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  totalRecords: PropTypes.number,
  pageFun: PropTypes.func,
  pageNumber: PropTypes.number,
  buttonClick: PropTypes.func,
  buttonDeleteClick: PropTypes.func,
  surveyId: PropTypes.func,
  toggleClick: PropTypes.func,
  isPagination: PropTypes.bool,
  type: PropTypes.string
};

function MainTable(props) {
  // const navigate = useNavigate();
  const [page, setPage] = React.useState(props.pageNumber + 1 || 1);
  // const [checked, setChecked] = React.useState(false);
  const PER_PAGE = 10;
  const count = Math.ceil(props.totalRecords / PER_PAGE);
  const _DATA = usePagination(props.columns, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    props?.pageFun && props?.pageFun(p);
  };
  const actionButton = (item) => {
    props.buttonClick(item);
  };
  /*const actionDeleteButton = (item) => {
    props.buttonDeleteClick(item);
  };*/
  const rowClick = (rows) => {
    props?.surveyId && props?.surveyId(rows?.surveyId);
  };
  const handleSwitch = (event) => {
    props?.toggleClick && props?.toggleClick(event);
  };
  // const startReviewFunc = (e, surveyId, teamId, clubbedId) => {
  //   e.preventDefault();
  //   navigate(`/review/${surveyId}/report/${teamId}/clubbedId/${clubbedId}`);
  // }
  return (
    <div className={`tableBox${props.type === 'small' ? ` ${props.type}` : ''}`}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props?.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.id === 'review' ? '50px' : undefined }}
                  className="tableHeading"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.rows?.length > 0 &&
              props?.rows?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {props?.columns.map((column) => {
                      const value = column?.type === 'quarter' ? row[column?.type] ? row[column?.type][column?.id] : '-' : row[column?.id] ? row[column?.id] : '-';
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={
                            column?.id === "Action"
                              ? () => { }
                              : (e) => { !e.target.closest('.MuiSvgIcon-root') ? rowClick(row) : null }
                          }
                        >
                          {column?.id === "Action" ? (
                            column?.type === "reports" ? (
                              <div style={{ display: "flex", justifyContent: 'center' }}>
                                <PrimaryButton
                                  text={column?.buttonText2}
                                  className="reportButton type3"
                                  onClick={() => actionButton(row)}
                                />
                              </div>
                            ) : column.type === "mySurvey" ?
                              (row?.teamId == null && row?.clubbedId == null)
                                ?
                                (
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text="Edit"
                                      className="reportButton type0"
                                      onClick={() => actionButton(row)}
                                    />
                                  </div>
                                )
                                :
                                (
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text={column?.buttonText}
                                      className="reportButton type1"
                                      onClick={() => actionButton(row)}
                                    />
                                    {/*
                                      column?.type === "mySurvey" ?
                                        <PrimaryButton
                                          text={"Remove"}
                                          className="reportButton type3 other"
                                          onClick={() => actionDeleteButton(row)}
                                        />
                                        : null
                                */}
                                  </div>
                                )
                              :
                              column?.type === "recievedSurvey"
                                ?
                                row?.status === "SUBMITTED" ?
                                  <div className="green">Responded</div>
                                  :
                                  row?.status === "CREATED" && moment(row?.endDate?.sec) < moment(new Date().getTime() / 1000) ? (
                                    <div className="red">Review Closed</div>
                                  )
                                    :
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                      <PrimaryButton
                                        text={column?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(row)}
                                      />
                                    </div>
                                :
                                column?.type === "myPeer"
                                  ?
                                  <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                      text={column?.buttonText}
                                      className="reportButton type1"
                                      onClick={() => actionButton(row)}
                                    />
                                  </div>
                                  :
                                  (
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                      <PrimaryButton
                                        text={column?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(row)}
                                      />
                                    </div>
                                  )
                          ) : column?.id === "endDate" || column?.id === "startDate" ||
                            column?.id === "selfieStartDate" || column?.id === "peerScheduleDate" ||
                            column?.id === "createdAt" || column?.id === "selfieEndDate" ||
                            column?.id === "selfieScheduleDate" || column?.id === "selfieEndDate" || column?.id === "peerEndDate" ? (
                            value !== '-' ? moment.unix(value?.sec).utc().format("DD/MM/YYYY") : value
                          ) : column?.id === "isManager" ? (
                            <div className="toogleSwitch">
                              <Switch
                                checked={row.manager}
                                onChange={() => handleSwitch(row?.id)}
                                inputProps={{ "aria-label": "controlled" }}
                                disabled
                              />
                            </div>
                          ) : column?.id === "isSelfAssessment" ? (
                            <span>{row[column?.id] === false ? 'No' : 'Yes'}</span>
                          )
                            //  : column?.id === "review" ? (
                            //   <span>{row?.teamId && row?.clubbedId && row?.teamId !== '' && row?.clubbedId !== '' ? <IconButton aria-label="review" title={'Record Minutes'} onClick={(e) => startReviewFunc(e, row.surveyId, row.teamId, row.clubbedId)} className="iconBtn-with-text">
                            //     <RateReviewIcon style={{ color: '#f56102' }} />
                            //     <div className="btn-small-text">Record Minutes</div>
                            //   </IconButton> : ''}</span>
                            // )
                            :
                            column?.id === "name" && column?.type !== 'myGoal' ? (
                              <span>{`${row[column?.id]}${row['isSelfAssessment'] ? ' Selfie' : ' Review'}`}</span>
                            ) : value ? (
                              column?.type === "myPeer" && row?.manager ? <span>{value} <span className="smallTag">{Constants.MANAGER}</span></span> : value
                            ) : (
                              "-"
                            )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props?.isPagination === false ? (
        <div ></div>
      ) : (
        <div className="pagination">
          <Pagination
            variant="outlined"
            count={count}
            page={page}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}

MainTable.propTypes = propTypes;
export default MainTable;
