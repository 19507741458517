import * as types from "./action-types";

const initialState = {
  isLoading: false,
  dashboardDetails: [],
  errorMessage: "",
  teamDetails: [],
  selfieGraphDetails: {},
  meDetails: [],
  goalDetails: [],
  selectedQuarter: '',
  selectedYear: new Date().getFullYear(),
  publicGoalUserList: []
};

const dashboard = (state = initialState, action) => {
  switch (action?.type) {
    case types.GET_DASHBOARD_DETAILS:
    case types.GET_QUARTER_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardDetails: action.result?.dashboardData,
        publicGoalUserList: action.result?.publicGoalUserList
      };
    case types.GET_DASHBOARD_DETAILS_ERROR:
    case types.GET_QUARTER_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        dashboardDetails: [],
      };
    case types.GET_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        teamDetails: action.result
      };
    case types.GET_TEAM_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        teamDetails: [],
      };
    case types.GET_SELFIE_GRAPH_DETAILS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        selfieGraphDetails: action.result
      };
    case types.GET_SELFIE_GRAPH_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        selfieGraphDetails: {},
      };
    case types.GET_ME_DETAILS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        meDetails: action.result
      };
    case types.GET_ME_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        meDetails: {},
      };
    case types.GET_GOAL_DETAILS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        goalDetails: action.result
      };
    case types.GET_GOAL_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        goalDetails: [],
      };
    case types.SET_SELECTED_QUARTER:
      return {
        ...state,
        selectedQuarter: action.quaryerId
      }
    case types.SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.year
      }
    default:
      return state;
  }
};

export default dashboard;
