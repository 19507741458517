import React, { useEffect, useState } from "react";
import Header from "../../../../../common/Header";
import MainTable from "../../../../../common/Table";
import DropDown from "../../../../../common/DropDown";
import PropTypes from "prop-types";
import Constants from "../../../../../config";
import styled from "../../../css/surveyList.module.css";
//import styled from "../../../css/surveyList.module.css";
import Skeleton from "@mui/material/Skeleton";
import "../../../css/surveyList.css";
const propTypes = {
  getSurveyReceive: PropTypes.func,
  surveyList: PropTypes.object,
  isLoading: PropTypes.bool,
};
const yearOptions = ['2022', '2023', '2024', '2025'];

const Received = (props) => {
  const [page, setPage] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const PER_PAGE = 10;

  useEffect(() => {
    apiCall({ page, recPerPage: PER_PAGE, year: selectedYear });
  }, []);
  const apiCall = (value) => {
    props?.getSurveyReceive(value);
  };
  const columns = [
    { id: "name", label: Constants.REVIEWS, minWidth: 140 },
    {
      id: "accesseename",
      label: Constants.REQUESTED_FROM,
      minWidth: 120,
    },
    {
      id: "createdAt",
      label: Constants.START_DATE,
      minWidth: 120,
    },
    {
      id: "endDate",
      label: Constants.END_DATE,
      minWidth: 120,
    },
    {
      id: "Action",
      label: Constants.ACTION,
      minWidth: 120,
      align: "center",
      type: "recievedSurvey",
      buttonText: Constants.RESPONSE_TO_GOAL,
    },
  ];

  const pageFun = (value) => {
    setPage(value === 1 ? 0 : value - 1);
    apiCall({ page: value === 1 ? 0 : value - 1, recPerPage: PER_PAGE, year: selectedYear });
  };
  const responseToSurvey = (item) => {
    if (item.status === "CREATED" || item.status === 'DRAFT') {
      window.open(
        `${props.userDetail.hostUrl}/response/${item?.uniqueId}/start`
      );
    }
  };
  const handleChangeYear = (value) => {
    setSelectedYear(value);
    apiCall({ page, recPerPage: PER_PAGE, year: value });
  }

  return (
    <>
      <Header active="Received" />
      <div className={styled.surveyList}>
        <div className={styled.surveyListMain + " bg-color-login"}>
          <div className={styled.emptySpacer} />
          <div className={styled.mainTable}>
            <div className="mySurvey">
              <DropDown
                options={yearOptions}
                style={{ color: "#333333" }}
                updateValue={handleChangeYear}
                placeholder="Select year"
                value={selectedYear}
                label={'Select year'}
              />
              <div className={styled.tableWrapper}>
                {props?.surveyList?.items?.length > 0 ? (
                  props?.isLoading ? (
                    <div style={{ margin: '50px auto 0' }}>
                      <Skeleton variant="text" height={75} />
                      <Skeleton variant="text" height={120} />
                      <Skeleton variant="text" height={120} />
                      <Skeleton variant="text" height={120} />
                    </div>
                  ) : (
                    <MainTable
                      columns={columns}
                      rows={props?.surveyList?.items}
                      totalRecords={props?.surveyList?.totalRecords}
                      pageFun={pageFun}
                      pageNumber={page}
                      buttonClick={responseToSurvey}
                      type={'small'}
                    />
                  )
                ) : (
                  <div className={"noData"}>{Constants.NO_DATA_FOUND}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Received.propTypes = propTypes;
export default Received;
