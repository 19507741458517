import { connect } from "react-redux";
import InviteParticipants from "./components/inviteParticipants";
import { getPeersList, addExternalPeers, invitePeers, searchPeer, searchAddPeer, sendSurveyPeer,sendSurvey } from "../../store/actions";
import { getSurveyDetails } from '../../../Survey/store/actions';
import { getUserDetails } from "../../../auth/store/actions";
const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.createSurvey.errorMessage,
    isLoading: state.createSurvey.isLoading,
    status: state.status,
    ownProps,
    peerList: state?.createSurvey?.peerList?.list,
    totalRecords: state?.createSurvey?.peerList?.totalRecords,
    surveyDetail: state.surveyList.surveyDetail,
    editsurveyDetail: state.surveyList.surveyDetail,
    searchPeerList: state.createSurvey.searchPeer,
    userDetail: state.auth.userDetail,
    previewQuestion: state.createSurvey.previewQuestion,
    isDesktopLayout: state.browser.screenLayout === "desktop",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPeersList: (data) => {
      dispatch(getPeersList(data));
    },
    addExternalPeers: (data) => {
      dispatch(addExternalPeers(data));
    },
    getSurveyDetails: (data) => {
      dispatch(getSurveyDetails(data));
    },
    invitePeers: (data) => {
      dispatch(invitePeers(data));
    },
    searchPeer: (data) => {
      dispatch(searchPeer(data));
    },
    searchAddPeer: (data) => {
      dispatch(searchAddPeer(data));
    },
    sendSurveyPeer: (data) => {
      dispatch(sendSurveyPeer(data));
    },
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    sendSurvey: (data,surveyId) => {
      dispatch(sendSurvey(data,surveyId));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteParticipants);
