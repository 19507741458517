export const CREATE_GOAL = 'CREATE_GOAL'
export const CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS'
export const CREATE_GOAL_ERROR = 'CREATE_GOAL_ERROR'

export const GET_GOAL_DESCRIPTION = 'GET_GOAL_DESCRIPTION'
export const GET_GOAL_DESCRIPTION_SUCCESS = 'GET_GOAL_DESCRIPTION_SUCCESS'
export const GET_GOAL_DESCRIPTION_ERROR = 'GET_GOAL_DESCRIPTION_ERROR'

export const COPY_GOAL_QUESTION = 'COPY_GOAL_QUESTION'

export const POST_ADD_QUESTION = 'POST_ADD_QUESTION'
export const POST_ADD_QUESTION_SUCCESS = 'POST_ADD_QUESTION_SUCCESS'
export const POST_ADD_QUESTION_ERROR = 'POST_ADD_QUESTION_ERROR'

export const GET_PREVIEW_QUESTION = 'GET_PREVIEW_QUESTION'
export const GET_PREVIEW_QUESTION_SUCCESS = 'GET_PREVIEW_QUESTION_SUCCESS'
export const GET_PREVIEW_QUESTION_ERROR = 'GET_PREVIEW_QUESTION_ERROR'

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST'
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS'
export const GET_CATEGORY_LIST_ERROR = 'GET_CATEGORY_LIST_ERROR'

export const EDIT_QUESTIONS = 'EDIT_QUESTIONS'
export const EDIT_QUESTIONS_SUCCESS = 'EDIT_QUESTIONS_SUCCESS'
export const EDIT_QUESTIONS_ERROR = 'EDIT_QUESTIONS_ERROR'

export const GET_SUB_CATEGORY_LIST = 'GET_SUB_CATEGORY_LIST'
export const GET_SUB_CATEGORY_LIST_SUCCESS = 'GET_SUB_CATEGORY_LIST_SUCCESS'
export const GET_SUB_CATEGORY_LIST_ERROR = 'GET_SUB_CATEGORY_LIST_ERROR'

export const GET_DEFAULT_CATEGORY_LIST = 'GET_DEFAULT_CATEGORY_LIST'
export const GET_DEFAULT_CATEGORY_LIST_SUCCESS = 'GET_DEFAULT_CATEGORY_LIST_SUCCESS'
export const GET_DEFAULT_CATEGORY_LIST_ERROR = 'GET_DEFAULT_CATEGORY_LIST_ERROR'

export const GET_PEER_LIST = 'GET_PEER_LIST'
export const GET_PEER_LIST_SUCCESS = 'GET_PEER_LIST_SUCCESS'
export const GET_PEER_LIST_ALL_SUCCESS = 'GET_PEER_LIST_ALL_SUCCESS'
export const GET_PEER_LIST_ERROR = 'GET_PEER_LIST_ERROR'

export const ADD_EXTERNAL_PEERS = 'ADD_EXTERNAL_PEERS'
export const ADD_EXTERNAL_PEERS_SUCCESS = 'ADD_EXTERNAL_PEERS_SUCCESS'
export const ADD_EXTERNAL_PEERS_ERROR = 'ADD_EXTERNAL_PEERS_ERROR'

export const INVITE_PEERS = 'INVITE_PEERS'
export const INVITE_PEERS_SUCCESS = 'INVITE_PEERS_SUCCESS'
export const INVITE_PEERS_ERROR = 'INVITE_PEERS_ERROR'

export const RESET_ADD_QUESTION_STORE = 'RESET_ADD_QUESTION_STORE'

export const EDIT_GOAL = 'EDIT_GOAL'
export const EDIT_GOAL_SUCCESS = 'EDIT_GOAL_SUCCESS'
export const EDIT_GOAL_ERROR = 'EDIT_GOAL_ERROR'

export const SEARCH_PEERS = 'SEARCH_PEERS'
export const SEARCH_PEERS_SUCCESS = 'SEARCH_PEERS_SUCCESS'
export const SEARCH_PEERS_ERROR = 'SEARCH_PEERS_ERROR'

export const ADD_SEARCH_PEERS = 'ADD_SEARCH_PEERS'
export const ADD_SEARCH_PEERS_SUCCESS = 'ADD_SEARCH_PEERS_SUCCESS'
export const ADD_SEARCH_PEERS_ERROR = 'ADD_SEARCH_PEERS_ERROR'

export const SCHEDULE_REVIEW_PEER = 'SCHEDULE_REVIEW_PEER'
export const SCHEDULE_REVIEW_PEER_SUCCESS = 'SCHEDULE_REVIEW_PEER_SUCCESS'
export const SCHEDULE_REVIEW_PEER_ERROR = 'SCHEDULE_REVIEW_PEER_ERROR'

export const GET_SCHEDULE_CANDIDATE = 'GET_SCHEDULE_CANDIDATE'
export const GET_SCHEDULE_CANDIDATE_SUCCESS = 'GET_SCHEDULE_CANDIDATE_SUCCESS'
export const GET_SCHEDULE_CANDIDATE_ERROR = 'GET_SCHEDULE_CANDIDATE_ERROR'

export const DELETE_SCHEDULE_CANDIDATE = 'DELETE_SCHEDULE_CANDIDATE'
export const DELETE_SCHEDULE_CANDIDATE_SUCCESS = 'DELETE_SCHEDULE_CANDIDATE_SUCCESS'
export const DELETE_SCHEDULE_CANDIDATE_ERROR = 'DELETE_SCHEDULE_CANDIDATE_ERROR'

export const QUESTION_DELETE = 'QUESTION_DELETE'
export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS'
export const QUESTION_DELETE_ERROR = 'QUESTION_DELETE_ERROR'

export const GOAL_TITLE_DELETE = 'GOAL_TITLE_DELETE'
export const GOAL_TITLE_DELETE_SUCCESS = 'GOAL_TITLE_DELETE_SUCCESS'
export const GOAL_TITLE_DELETE_ERROR = 'GOAL_TITLE_DELETE_ERROR'

export const GOAL_TITLE_EDIT = 'GOAL_TITLE_EDIT'
export const GOAL_TITLE_EDIT_SUCCESS = 'GOAL_TITLE_EDIT_SUCCESS'
export const GOAL_TITLE_EDIT_ERROR = 'GOAL_TITLE_EDIT_ERROR'

export const GET_SENT_CANDIDATE = 'GET_SENT_CANDIDATE'
export const GET_SENT_CANDIDATE_SUCCESS = 'GET_SENT_CANDIDATE_SUCCESS'
export const GET_SENT_CANDIDATE_ERROR = 'GET_SENT_CANDIDATE_ERROR'

export const SURVEY_INFO_CHANGED_DUMMY = 'SURVEY_INFO_CHANGED_DUMMY'

export const SEND_SURVEY = 'SEND_SURVEY'
export const SEND_SURVEY_SUCCESS = 'SEND_SURVEY_SUCCESS'
export const SEND_SURVEY_ERROR = 'SEND_SURVEY_ERROR'

export const IMPORT_GOALS = 'IMPORT_GOALS'
export const IMPORT_GOALS_SUCCESS = 'IMPORT_GOALS_SUCCESS'
export const IMPORT_GOALS_ERROR = 'IMPORT_GOALS_ERROR'

export const GET_IMPORT_QUESTIONS = 'GET_IMPORT_QUESTIONS'
export const GET_IMPORT_QUESTIONS_SUCCESS = 'GET_IMPORT_QUESTIONS_SUCCESS'
export const GET_IMPORT_QUESTIONS_ERROR = 'GET_IMPORT_QUESTIONS_ERROR'