import * as types from "./action-types";
import * as API from "./api";

export const getIkigaiQuestion = () => {
  API.getIkigaiQuestion();
  return {
    type: types.GET_IKIGAI_QUESTION,
  };
};

export const getIkigaiQuestionSuccess = (result) => {
  return {
    type: types.GET_IKIGAI_QUESTION_SUCCESS,
    result,
  };
};

export const getIkigaiQuestionError = (errorMessage) => ({
  type: types.GET_IKIGAI_QUESTION_ERROR,
  errorMessage,
});


export const getIkigaiDetails = () => {
  API.getIkigaiDetails();
  return {
    type: types.GET_IKIGAI_DETAILS,
  };
};

export const getIkigaiDetailsSuccess = (result) => {
  return {
    type: types.GET_IKIGAI_DETAILS_SUCCESS,
    result,
  };
};

export const getIkigaiDetailsError = (errorMessage) => ({
  type: types.GET_IKIGAI_DETAILS_ERROR,
  errorMessage,
});

export const postIkigaiQuestion = (payload) => {
  API.postIkigaiQuestion(payload);
  return {
    type: types.POST_IKIGAI_QUESTION,
  };
};

export const postIkigaiQuestionSuccess = (result) => {
  return {
    type: types.POST_IKIGAI_QUESTION_SUCCESS,
    result,
  };
};

export const postIkigaiQuestionError = (errorMessage) => ({
  type: types.POST_IKIGAI_QUESTION_ERROR,
  errorMessage,
});

export const editIkigaiQuestion = (identifier, payload) => {
   API.editIkigaiQuestion(identifier, payload);
   return {
     type: types.EDIT_IKIGAI_QUESTION,
   };
 };
 
 export const editIkigaiQuestionSuccess = (result) => {
   return {
     type: types.EDIT_IKIGAI_QUESTION_SUCCESS,
     result,
   };
 };
 
 export const editIkigaiQuestionError = (errorMessage) => ({
   type: types.EDIT_IKIGAI_QUESTION_ERROR,
   errorMessage,
 });

 export const getIkigaiQuestionResponse = (payload) => {
   API.getIkigaiQuestionResponse(payload);
   return {
     type: types.GET_IKIGAI_QUESTION_RESPONSE,
   };
 };
 
 export const getIkigaiQuestionResponseSuccess = (result) => {
   return {
     type: types.GET_IKIGAI_QUESTION_RESPONSE_SUCCESS,
     result,
   };
 };
 
 export const getIkigaiQuestionResponseError = (errorMessage) => ({
   type: types.GET_IKIGAI_QUESTION_RESPONSE_ERROR,
   errorMessage,
 });

 export const getAllIkigaiQuestions = () => {
  API.getAllIkigaiQuestions();
  return {
    type: types.GET_ALL_IKIGAI_QUESTIONS,
  };
};

export const getAllIkigaiQuestionsSuccess = (result) => {
  return {
    type: types.GET_ALL_IKIGAI_QUESTIONS_SUCCESS,
    result,
  };
};

export const getAllIkigaiQuestionsError = (errorMessage) => ({
  type: types.GET_ALL_IKIGAI_QUESTIONS_ERROR,
  errorMessage,
});
 
 





