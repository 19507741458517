import React, { useState, useEffect } from 'react';
import TextResponse from "./TextResponse";
import PrimaryButton from "../../../../../common/PrimaryButton";

const OpenQuestions = (props) => {

    const [questionData, setQuestionData] = useState([]);

    const [visibleQuestion, setVisibleQuestion] = useState([]);

    const [index, setIndex] = useState(0);

    const [totalQuestion, setTotalQuestion] = useState(0);

    useEffect(() => {
        if (props?.openQuestions?.length > 0) {
            setVisibleQuestion(props.openQuestions[index]);
            setTotalQuestion(props.openQuestions.length);
            setQuestionData(props.openQuestions);
        }
    }, [])

    const movePrev = () => {
        let updateIndex = index - 1;
        setIndex(updateIndex);
        setVisibleQuestion(questionData[updateIndex])
    }

    const moveNext = () => {
        let updateIndex = index + 1;
        setIndex(updateIndex);
        setVisibleQuestion(questionData[updateIndex]);
    }

    return (
        <React.Fragment>
            <div className="flex-space" style={{ margin: '0 10px 0 30px' }}>
                <span className="ir-peer-count">
                    <span style={{ fontSize: '16px', color: '#14009b' }}>Question: </span>
                    <span className="ir-peer-filled" style={{ fontSize: '16px', color: '#14009b', fontWeight: 400 }}>{index + 1}</span>
                    <span className="ir-peer-all" style={{ fontSize: '16px', color: '#14009b' }}>/{totalQuestion}</span>
                </span>
                <div className='flex-end'>
                    <PrimaryButton
                        text={'Previous'}
                        onClick={movePrev}
                        className="saveButton small"
                        disabled={index === 0 || totalQuestion === 1}
                    />
                    <PrimaryButton
                        text={'Next'}
                        onClick={moveNext}
                        className="saveButton small"
                        disabled={index === totalQuestion - 1}
                    />
                </div>
            </div>
            <div className='question-container'>
                <div style={{ padding: '0' }} className="energy-question-wrapper">
                    <p style={{ fontSize: '14px', textAlign: "left", fontWeight: "500" }}>{visibleQuestion.question}</p>
                </div>
                <div style={{ padding: '0 3%', paddingBottom: visibleQuestion.text && visibleQuestion.text.length % 2 === 0 ? '110px' : '0', marginBottom: '20px', fontFamily: "Roboto, sans-serif" }}>
                    <div>
                        <TextResponse
                            visibleQuestion={visibleQuestion}
                        />
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default OpenQuestions;