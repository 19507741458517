import PropTypes from "prop-types";
import React from "react";
import MainTableSmallCard from "../TableSmallCard";
import excludeGrey from "../../assets/excludeGrey.png";
import excludeGreyf from "../../assets/excludeGreyf.svg";
import add from "../../assets/add.svg";
import addGrey1 from "../../assets/addGrey1.png";
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControlLabel from '@mui/material/FormControlLabel';

const propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array,
    totalRecords: PropTypes.number,
    pageFun: PropTypes.func,
    pageNumber: PropTypes.number,
    buttonClick: PropTypes.func,
    buttonDeleteClick: PropTypes.func,
    surveyId: PropTypes.func,
    toggleClick: PropTypes.func,
    isPagination: PropTypes.bool,
    openModal: PropTypes.func,
    checkedList: PropTypes.func,
    withoutCheckBox: PropTypes.bool,
    selectedYear: PropTypes.string,
    selectedQuarter: PropTypes.string,
    userDetail: PropTypes.any,
    reviewsClick: PropTypes.func,
    handleExcludePeers: PropTypes.func,
    excludeUserId: PropTypes.array,
    setExcludeUserId: PropTypes.func,
    includeUserId: PropTypes.array,
    setIncludeUserId: PropTypes.func,
    handleIncludePeers: PropTypes.func,
    openPopup: PropTypes.bool,
    setOpenPopUp: PropTypes.func,
    isLoadingPeers: PropTypes.bool,
    setIsLoadingPeers: PropTypes.func,
    isDisabled: PropTypes.bool,
    setIsDisabled: PropTypes.func,
    setCheckedList: PropTypes.func
}

const MainTableSmall = (props) => {

    const [localRows, setLocalRows] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isFilledChecked, setIsFilledChecked] = React.useState(true);
    const [isNotFilledChecked, setIsNotFilledChecked] = React.useState(true);

    React.useEffect(() => {
        if (props.rows && props.rows.length) {
            setLocalRows(props.rows);
        }
    }, [props.rows]);

    const handleChangeUser = (e, id, userId, tRow) => {
        console.log(id + " " + userId + " " + tRow);
        if (id !== 'all') {
            let temp = JSON.parse(JSON.stringify(props.checkedList));
            let tempExcluded = JSON.parse(JSON.stringify(props.excludeUserId));
            let tempIncluded = JSON.parse(JSON.stringify(props.includeUserId));
            if (e.target.checked) {
                if (!temp.includes(id)) {
                    temp.push(id)
                }
                if (tRow.isExcluded === 1 && !tempExcluded.includes(userId)) {
                    tempExcluded.push(userId);
                }
                if (tRow.isExcluded === 0 && !tempIncluded.includes(userId)) {
                    tempIncluded.push(userId);
                }
            } else {
                const index = temp.indexOf(id);
                const index1 = tRow.isExcluded === 1 ? tempExcluded.indexOf(userId) : tempIncluded.indexOf(userId);
                if (index > -1) {
                    temp.splice(index, 1);
                }
                if (index1 > -1) {
                    tRow.isExcluded === 1 ? tempExcluded.splice(index1, 1) : tempIncluded.splice(index1, 1);
                }
                props.setCheckedList(temp);
            }
            setDisabled(temp);
            props.setCheckedList(temp);
            if (tRow.isExcluded === 1) {
                props.setExcludeUserId(tempExcluded);
            }
            else {
                props.setIncludeUserId(tempIncluded);
            }
        } else {
            if (e.target.checked) {
                let arr = [];
                let tempExcluded = [];
                let tempIncluded = [];
                localRows.map((row) => {
                    arr.push(row.surveyId);
                    if (row.id !== '' && row.isExcluded === 0) {
                        tempIncluded.push(row.id);
                    }
                    if (row.id !== '' && row.isExcluded === 1) {
                        tempExcluded.push(row.id);
                    }
                })
                setDisabled(arr);
                props.setCheckedList(arr);
                props.setExcludeUserId(tempExcluded);
                props.setIncludeUserId(tempIncluded);
            } else {
                props.setCheckedList([]);
                props.setIsDisabled(true);
                props.setExcludeUserId([]);
                props.setIncludeUserId([]);
            }
        }
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeFilled = (e) => {
        if (e.target.checked) {
            if (isNotFilledChecked) {
                setLocalRows(props.rows);
            } else {
                let arr = JSON.parse(JSON.stringify(props.rows));
                arr = arr.filter(obj => obj.surveyId !== '');
                setLocalRows(arr);
            }
        } else {
            if (isNotFilledChecked) {
                let arr = JSON.parse(JSON.stringify(props.rows));
                arr = arr.filter(obj => obj.surveyId === '');
                setLocalRows(arr);
            } else {
                setLocalRows([]);
            }
        }
        setIsFilledChecked(e.target.checked);
    }
    const handleChangeNotFilled = (e) => {
        if (e.target.checked) {
            if (isFilledChecked) {
                setLocalRows(props.rows);
            } else {
                let arr = JSON.parse(JSON.stringify(props.rows));
                arr = arr.filter(obj => obj.surveyId === '');
                setLocalRows(arr);
            }
        } else {
            if (isFilledChecked) {
                let arr = JSON.parse(JSON.stringify(props.rows));
                arr = arr.filter(obj => obj.surveyId !== '');
                setLocalRows(arr);
            } else {
                setLocalRows([]);
            }
        }
        setIsNotFilledChecked(e.target.checked);
    }

    const setDisabled = (arr) => {
        console.log(localRows);
        let count = 0;
        for (let i = 0; i < localRows.length; i++) {
            if (localRows[i].isExcluded === 0 && arr.includes(localRows[i].surveyId) && localRows[i].surveyId !== '') {
                count++;
                break;
            }
        }
        if (count > 0) {
            props.setIsDisabled(false);
            console.log(count);
        }
        else {
            props.setIsDisabled(true);
        }
    }

    return (
        <React.Fragment>
            <div>
                {!props.withoutCheckBox ?
                    <>
                        <Button className="extend-btn" disabled={props.isDisabled} onClick={() => props.openModal(true)}><CalendarMonthIcon /><span>Extend Date</span></Button>
                        <Button className="extend-btn" disabled={!props.includeUserId.length} onClick={props.handleExcludePeers}><div style={{ display: 'flex', alignItems: 'center' }}>{!props.includeUserId.length ? <img src={excludeGrey} style={{ height: '17px' }} alt="excludeGrey" /> : <img src={excludeGreyf} style={{ height: '17px' }} alt="exclude" />}<span>Deactivate Team Member</span></div></Button>
                        <Button className="extend-btn" disabled={!props.excludeUserId.length} onClick={props.handleIncludePeers}><div style={{ display: 'flex', alignItems: 'center' }}>{!props.excludeUserId.length ? <img src={addGrey1} style={{ height: '26px', marginLeft: '-4px' }} alt="excludeGrey" /> : <img src={add} style={{ height: '17px' }} alt="Include" />}<span>Activate Team Member</span></div></Button>
                    </> :
                    null}
            </div>
            {!props.withoutCheckBox ?
                <div className="flex" style={{ alignItems: 'center', marginLeft: '10px' }}>
                    <Checkbox
                        checked={props.checkedList.length && props.checkedList.length === localRows.length}
                        onChange={(e) => handleChangeUser(e, 'all', 'all')}
                        className='check-box'
                        sx={{
                            color: '#14009B',
                            '&.Mui-checked': {
                                color: '#14009B',
                            },
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={localRows.every(obj => obj.surveyId === '')}
                    />
                    <div className="pm-label-small">
                        Select All
                    </div>
                    <div>
                        <Button
                            className="pm-label-small"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            style={{ color: '#14009b' }}
                        >
                            {'Objectives'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} disableRipple>
                                <FormControlLabel
                                    label="Filled"
                                    control={
                                        <Checkbox
                                            checked={isFilledChecked}
                                            onChange={handleChangeFilled}
                                            className='check-box'
                                            sx={{
                                                color: '#14009B',
                                                '&.Mui-checked': {
                                                    color: '#14009B',
                                                },
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                />
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <FormControlLabel
                                    label="Not Filled"
                                    control={
                                        <Checkbox
                                            checked={isNotFilledChecked}
                                            onChange={handleChangeNotFilled}
                                            className='check-box'
                                            sx={{
                                                color: '#14009B',
                                                '&.Mui-checked': {
                                                    color: '#14009B',
                                                },
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                />
                            </MenuItem>
                        </Menu>
                    </div>
                </div> : null}
            {
                localRows.map((row, key) =>
                    <MainTableSmallCard
                        columns={props.columns}
                        key={key}
                        row={row}
                        pageFun={props.pageFun}
                        pageNumber={props.pageNumber}
                        buttonClick={props.buttonClick}
                        buttonDeleteClick={props.buttonDeleteClick}
                        surveyId={props.surveyId}
                        toggleClick={props.toggleClick}
                        isPagination={props.isPagination}
                        openModal={props.openModal}
                        checkedList={props.checkedList}
                        withoutCheckBox={props.withoutCheckBox}
                        selectedYear={props.selectedYear}
                        selectedQuarter={props.selectedQuarter}
                        userDetail={props.userDetail}
                        reviewsClick={props.reviewsClick}
                        handleExcludePeers={props.handleExcludePeers}
                        excludeUserId={props.excludeUserId}
                        setExcludeUserId={props.setExcludeUserId}
                        includeUserId={props.includeUserId}
                        setIncludeUserId={props.setIncludeUserId}
                        handleIncludePeers={props.handleIncludePeers}
                        openPopup={props.openPopup}
                        setOpenPopUp={props.setOpenPopUp}
                        isLoadingPeers={props.isLoadingPeers}
                        setIsLoadingPeers={props.setIsLoadingPeers}
                        isDisabled={props.isDisabled}
                        setIsDisabled={props.setIsDisabled}
                        setCheckedList={props.setCheckedList}
                        localRows={localRows}
                        handleChangeUser={handleChangeUser}
                    />
                )
            }
        </React.Fragment>
    )
}

MainTableSmall.propTypes = propTypes;
export default MainTableSmall;