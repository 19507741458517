import * as types from "./action-types";

const initialState = {
  isLoading: false,
  surveyDetail: {},
  item: {},
  previewQuestion: [],
  questionDetail: {},
  categoryList: [],
  editQuestion: {},
  subCategoryList: [],
  defaultCategory: [],
  peerList: [],
  peerListAll: [],
  searchPeer: [],
  scheduleCandidate: [],
  errorMessage: "",
  sentCandidate: [],
  importQuestion:[],
};

const createSurvey = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_GOAL:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.CREATE_GOAL_SUCCESS:
      return {
        ...state,
        surveyDetail: action.result,
        isLoading: false,
      };
    case types.CREATE_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_GOAL_DESCRIPTION:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_GOAL_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.result,
        errorMessage: "",
      };
    case types.GET_GOAL_DESCRIPTION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.COPY_GOAL_QUESTION:
      return {
        ...state,
        questionDetail: action.payload,
      };
    case types.POST_ADD_QUESTION:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.POST_ADD_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.POST_ADD_QUESTION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        //isLoading: true,
        previewQuestion: [],
        errorMessage: "",
      };
    case types.GET_PREVIEW_QUESTION:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.GET_PREVIEW_QUESTION_SUCCESS:
      return {
        ...state,
        previewQuestion: action.result,
        isLoading: false,
      };
    case types.GET_PREVIEW_QUESTION_ERROR:
      return {
        ...state,
        isLoading: false,
        previewQuestion: [],
        errorMessage: action.errorMessage,
      };
    case types.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: [],
        errorMessage: "",
      };
    case types.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.result
      };
    case types.GET_CATEGORY_LIST_ERROR:
      return {
        ...state,
        categoryList: [],
        errorMessage: action.errorMessage,
      };
    case types.EDIT_QUESTIONS:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.EDIT_QUESTIONS_SUCCESS:
      return {
        ...state,
        editQuestion: action.result,
        isLoading: false,
      };
    case types.EDIT_QUESTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        editQuestion: {},
        errorMessage: action.errorMessage,
      };
    case types.GET_SUB_CATEGORY_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        subCategoryList: action.result,
        isLoading: false,
      };
    case types.GET_SUB_CATEGORY_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        subCategoryList: [],
        errorMessage: action.errorMessage,
      };
    case types.GET_DEFAULT_CATEGORY_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_DEFAULT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        defaultCategory: action.result,
        isLoading: false,
      };
    case types.GET_DEFAULT_CATEGORY_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        defaultCategory: [],
        errorMessage: action.errorMessage,
      };
    case types.GET_PEER_LIST:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.GET_PEER_LIST_SUCCESS:
      return {
        ...state,
        peerList: action.result,
        //isLoading: false,
      };
    case types.GET_PEER_LIST_ALL_SUCCESS:
      return {
        ...state,
        peerListAll: action.result,
        //isLoading: false,
      };
    case types.GET_PEER_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        peerList: [],
        peerListAll: [],
        errorMessage: action.errorMessage,
      };
    case types.ADD_EXTERNAL_PEERS:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.ADD_EXTERNAL_PEERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.ADD_EXTERNAL_PEERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.INVITE_PEERS:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.INVITE_PEERS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
      };
    case types.INVITE_PEERS_ERROR:
      return {
        ...state,
        //isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.RESET_ADD_QUESTION_STORE:
      return {
        isLoading: false,
        surveyDetail: {},
        item: {},
        previewQuestion: [],
        questionDetail: {},
        categoryList: [],
        editQuestion: {},
        subCategoryList: [],
        defaultCategory: [],
        peerList: [],
        searchPeer: [],
      };
    case types.EDIT_GOAL:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.EDIT_GOAL_SUCCESS:
      return {
        ...state,
        surveyDetail: action.result,
        isLoading: false,
      };
    case types.EDIT_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.SEARCH_PEERS:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.SEARCH_PEERS_SUCCESS:
      return {
        ...state,
        searchPeer: action.result,
        //isLoading: false,
      };
    case types.SEARCH_PEERS_ERROR:
      return {
        ...state,
        //isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.ADD_SEARCH_PEERS:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.ADD_SEARCH_PEERS_SUCCESS:
      return {
        ...state,
        //isLoading: false,
      };
    case types.ADD_SEARCH_PEERS_ERROR:
      return {
        ...state,
        //isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.SCHEDULE_REVIEW_PEER:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.SCHEDULE_REVIEW_PEER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.SCHEDULE_REVIEW_PEER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_SCHEDULE_CANDIDATE:
      return {
        ...state,
        //isLoading: true,
        errorMessage: "",
      };
    case types.GET_SCHEDULE_CANDIDATE_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        scheduleCandidate: action.result,
      };
    case types.GET_SCHEDULE_CANDIDATE_ERROR:
      return {
        ...state,
        //isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_SENT_CANDIDATE_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        sentCandidate: action.result,
      };
    case types.GET_SENT_CANDIDATE_ERROR:
      return {
        ...state,
        //isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.DELETE_SCHEDULE_CANDIDATE:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.DELETE_SCHEDULE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_SCHEDULE_CANDIDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.QUESTION_DELETE:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.QUESTION_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.QUESTION_DELETE_ERROR:
    case types.GOAL_TITLE_DELETE_ERROR:
    case types.GOAL_TITLE_EDIT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_IMPORT_QUESTIONS:
      return {
        ...state,
        errorMessage: "",
      };
    case types.GET_IMPORT_QUESTIONS_SUCCESS:
      return {
        ...state,
        importQuestion: action.result,
        isLoading: false,
      };
    case types.GET_IMPORT_QUESTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        importQuestion: [],
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default createSurvey;
