import { combineReducers } from 'redux';
import auth from '../modules/auth/store/reducer';
import { status } from './status';
import { browser } from './browser';
import createSurvey from '../modules/CreateSurvey/store/reducer';
import surveyList from '../modules/Survey/store/reducer';
import dashboard from '../modules/Dashboard/store/reducer';
import ikigai from '../modules/Ikigai/store/reducer';

const createRootReducer = () => combineReducers({
    auth,
    dashboard,
    browser,
    status,
    createSurvey,
    surveyList,
    ikigai
});

export default createRootReducer;

