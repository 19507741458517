// import { Label } from "@mui/icons-material"
import PropTypes from "prop-types";
import "./tableSmallCard.css";
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import PrimaryButton from "../PrimaryButton";
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import IconButton from '@mui/material/IconButton';
// import RateReviewIcon from '@mui/icons-material/RateReview';
// import { useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Switch from "@mui/material/Switch";
import React from 'react';
import Constants from '../../config';

const propTypes = {
    columns: PropTypes.array,
    row: PropTypes.any,
    totalRecords: PropTypes.number,
    pageFun: PropTypes.func,
    pageNumber: PropTypes.number,
    buttonClick: PropTypes.func,
    buttonDeleteClick: PropTypes.func,
    surveyId: PropTypes.func,
    toggleClick: PropTypes.func,
    isPagination: PropTypes.bool,
    openModal: PropTypes.func,
    checkedList: PropTypes.func,
    withoutCheckBox: PropTypes.bool,
    selectedYear: PropTypes.string,
    selectedQuarter: PropTypes.string,
    userDetail: PropTypes.any,
    reviewsClick: PropTypes.func,
    handleExcludePeers: PropTypes.func,
    excludeUserId: PropTypes.array,
    setExcludeUserId: PropTypes.func,
    includeUserId: PropTypes.array,
    setIncludeUserId: PropTypes.func,
    handleIncludePeers: PropTypes.func,
    openPopup: PropTypes.bool,
    setOpenPopUp: PropTypes.func,
    isLoadingPeers: PropTypes.bool,
    setIsLoadingPeers: PropTypes.func,
    isDisabled: PropTypes.bool,
    setIsDisabled: PropTypes.func,
    setCheckedList: PropTypes.func,
    localRows: PropTypes.array,
    handleChangeUser: PropTypes.func
}

const MainTableSmallCard = (props) => {

    // const navigate = useNavigate();

    const actionButton = (item) => {
        props.buttonClick(item);
    };

    // const startReviewFunc = (e, surveyId, teamId, clubbedId, userId) => {
    //     e.preventDefault();
    //     navigate(`/review/${surveyId}/report/${teamId}/clubbedId/${clubbedId}/finalize/${userId}`);
    // }

    // const startReviewFuncOther = (e, surveyId, teamId, clubbedId) => {
    //     e.preventDefault();
    //     navigate(`/review/${surveyId}/report/${teamId}/clubbedId/${clubbedId}`);
    // }

    const handleSwitch = (event) => {
        props?.toggleClick && props?.toggleClick(event);
    };

    const handleReviewsClick = (item, column) => {
        if (column && column.label === 'Reviews') {
            props.reviewsClick(item);
        }
    }

    const rowClick = (rows) => {
        props?.surveyId && props?.surveyId(rows?.surveyId);
    };

    console.log(props.row);

    let actionColumn = '';

    return (
        <div className="small-table-tile">
            <div className="flex" style={{ padding: '5px', flexWrap: 'wrap', lineHeight: '4px' }} key={props.key}>
                {props.columns.map((column, i) => {
                    const value = column?.type === 'quarter' ? props.row[column?.type] ? props.row[column?.type][column?.id] : '-' : props.row[column?.id] ? props.row[column?.id] : '-';
                    if (column.id === 'Action') {
                        actionColumn = column;
                    }
                    else {
                        return (
                            <div style={{ width: '32%', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: 'center' }} key={i} >
                                <div className="pm-label-small" >
                                    {i === 0 ?
                                        !props.withoutCheckBox ?
                                            <div className="checkboxDiv" style={{ display: 'flex', alignItems: 'center', width: '130px', marginBottom: '10px', justifyContent: 'left' }}>
                                                <Checkbox
                                                    checked={props.checkedList.includes(props.row[column?.identifier]) || props.checkedList.includes(props.row.id)}
                                                    onChange={(e) => props.handleChangeUser(e, props.row[column.identifier] ? props.row[column.identifier] : props.row.id, props.row.id, props.row)}
                                                    className='check-box'
                                                    sx={{
                                                        color: '#14009B',
                                                        '&.Mui-checked': {
                                                            color: '#14009B',
                                                        },
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                <div style={{ marginLeft: '5px' }} >
                                                    {column.label}
                                                </div>
                                            </div> :
                                            <><div>{column.label}</div><br /></> : <div >{column.label}</div>}
                                </div>
                                <div key={column.id}
                                    align={column.align}
                                    onClick={
                                        column?.id === "Action"
                                            ? () => { }
                                            : () => rowClick(props.row)
                                    }
                                    className={props.row.isExcluded ? 'td-cell-custom' : null}>
                                    {column.id === 'surveyId' ?
                                        <p className="font-bold" style={{
                                            fontSize: '18px',
                                        }}>{props.row.surveyId ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: 'red' }}>No</span>}</p>
                                        : column?.id === "reviewMinutesStatus" && !props.withoutCheckBox ? (
                                            <p className="font-bold" style={{
                                                fontSize: '18px',
                                            }}>
                                                {props.row.reviewFilledCount && props.row.reviewFilledCount > 0 ? props.row[column?.id] !== '' && props.row[column?.id] === 1 ? <span style={{ color: 'green' }}> Yes</span> : <span style={{ color: 'red' }}>No</span> : '-'}</p>
                                        )
                                            : column.id === 'filledSelfCount' || column.id === 'reviewFilledCount' ?
                                                <p className="font-bold" style={{ fontSize: '18px' }} onClick={() => handleReviewsClick(props.row, column)}>{`${props.row[column.id]}/${props.row[column.type]}`}</p>
                                                :
                                                column.id === 'selfieScheduleDate' || column.id === 'peerScheduleDate' || column.id === 'startDate' || column?.id === 'selfieStartDate' || column.id === 'endDate' || column?.id === 'createdAt' || column?.id === 'selfieEndDate' || column?.id === 'peerEndDate' ?
                                                    <p className="font-bold" style={{ fontSize: '18px' }}>{props.row[column.id]?.sec ? moment.unix(props.row[column.id].sec).utc().format("DD/MM/YYYY") : '-'}</p>
                                                    :
                                                    column?.id === "isManager" ? (
                                                        <div className="toogleSwitch">
                                                            <Switch
                                                                checked={props.row.manager}
                                                                onChange={() => handleSwitch(props.row?.id)}
                                                                inputProps={{ "aria-label": "controlled" }}
                                                                disabled
                                                            />
                                                        </div>
                                                    ) : column?.id === "isSelfAssessment" ? (
                                                        <span>{props.row[column?.id] === false ? 'No' : 'Yes'}</span>
                                                    ) :
                                                        // column.id === 'peerScheduleDate' ?
                                                        //     <p className="font-bold" style={{ fontSize: '18px' }}>{props.row[column.id]?.sec ? moment.unix(props.row[column.id].sec).utc().format("DD/MM/YYYY") : '-'}</p>
                                                        //     :
                                                        column.id === 'name' && column?.type === 'myTeam' ? (
                                                            <div className="font-bold peer-name" style={{ fontSize: '18px' }}>
                                                                {props.row[column?.id]}
                                                            </div>
                                                        )
                                                            : column?.id === "name" && column?.type !== 'myGoal' ? (
                                                                <span>{props.row[column?.id]}</span>
                                                            ) : value ? (
                                                                column?.type === "myPeer" && props.row?.manager ? <span>{value} <span className="smallTag">{Constants.MANAGER}</span></span> : value
                                                            ) :
                                                                column?.id === 'Action' ? null : ("-")
                                    }
                                </div>
                            </div>
                        )
                    }
                })}

            </div>
            <div>
                {
                    actionColumn?.type === "reports" ? (
                        props.row.surveyId !== '' ?
                            <div style={{ marginBottom: '8px' }}>
                                {(props.withoutCheckBox && props.row?.reviewFilledCount > 0) || (!props.withoutCheckBox && props.row?.reviewTeamId && props.row?.reviewClubbedId && props.row?.reviewTeamId !== '' && props.row?.reviewClubbedId !== '' && props.row?.reviewFilledCount > 0) ?
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <button aria-label="review" title={actionColumn?.buttonText2} onClick={() => actionButton(props.row)} style={{ backgroundColor: '#14009b', border: 'none', color: 'white', padding: '8px', width: '100%' }}>
                                            <div>{actionColumn?.buttonText2}</div>
                                        </button>
                                        {/* <button aria-label="review" title={'Record Minutes'} onClick={(e) => props.withoutCheckBox && props.row?.reviewFilledCount > 0 ? startReviewFuncOther(e, props.row.surveyId, props.row.reviewTeamId, props.row.reviewClubbedId) : startReviewFunc(e, props.row.surveyId, props.row.reviewTeamId, props.row.reviewClubbedId, props.row.id)} style={{ backgroundColor: '#f56102', border: 'none', color: 'white', padding: '8px', width: '100%' }}>
                                            <div>Record Minutes</div>
                                        </button> */}
                                    </div>
                                    :
                                    <div>
                                        <button aria-label="review" title={actionColumn?.buttonText2} onClick={() => actionButton(props.row)} style={{ backgroundColor: '#14009b', border: 'none', color: 'white', padding: '8px', width: '100%' }}>
                                            <div>{actionColumn?.buttonText2}</div>
                                        </button>
                                    </div>}
                            </div> : null
                    ) : actionColumn.type === "mySurvey" ?
                        (props.row?.teamId == null && props.row?.clubbedId == null)
                            ?
                            (
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                        text="Edit"
                                        className="reportButton type0"
                                        onClick={() => actionButton(props.row)}
                                    />
                                </div>
                            )
                            :
                            (
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                        text={actionColumn?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(props.row)}
                                    />
                                </div>
                            )
                        :
                        actionColumn?.type === "recievedSurvey"
                            ?
                            props.row?.status === "SUBMITTED" ?
                                <div className="green">Responded</div>
                                :
                                props.row?.status === "CREATED" && moment(props.row?.endDate?.sec) < moment(new Date().getTime() / 1000) ? (
                                    <div className="red">Review Closed</div>
                                )
                                    :
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <PrimaryButton
                                            text={actionColumn?.buttonText}
                                            className="reportButton type1"
                                            onClick={() => actionButton(props.row)}
                                        />
                                    </div>
                            :
                            actionColumn?.type === "myPeer"
                                ?
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                        text={actionColumn?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(props.row)}
                                    />
                                </div>
                                :

                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <PrimaryButton
                                        text={actionColumn?.buttonText}
                                        className="reportButton type1"
                                        onClick={() => actionButton(props.row)}
                                    />
                                </div>
                }
            </div>
        </div>
    )
}

MainTableSmallCard.propTypes = propTypes;
export default MainTableSmallCard;