import { connect } from 'react-redux'
import SendSurvey from './components/sendSurvey'
import {
  sendSurveyPeer,
  getScheduleCandidate,
  deleteScheduleCandidate,
  getPeersList,
  getSentCandidate,
  surveyInfoSavedDummy
} from "../../store/actions";
import { getSurveyDetails } from '../../../Survey/store/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.createSurvey.errorMessage,
    isLoading: state.createSurvey.isLoading,
    status: state.status,
    surveyDetail: state.surveyList.surveyDetail,
    ownProps,
    scheduleCandidate: state.createSurvey.scheduleCandidate,
    sentCandidate: state.createSurvey.sentCandidate,
    peerList: state.createSurvey.peerList,
    editsurveyDetail: state.surveyList.surveyDetail,
    quarterList: state.surveyList.quarter.items,
    previewQuestion: state.createSurvey.previewQuestion,
    isDesktopLayout: state.browser.screenLayout === "desktop",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendSurveyPeer: (data) => {
      dispatch(sendSurveyPeer(data));
    },
    getScheduleCandidate: (data) => {
      dispatch(getScheduleCandidate(data));
    },
    deleteScheduleCandidate: (data) => {
      dispatch(deleteScheduleCandidate(data));
    },
    getPeersList: (data) => {
      dispatch(getPeersList(data));
    },
    getSurveyDetails: (data) => {
      dispatch(getSurveyDetails(data));
    },
    getSentCandidate: (data) => {
      dispatch(getSentCandidate(data));
    },
    surveyInfoSavedDummy: () => {
      dispatch(surveyInfoSavedDummy())
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(SendSurvey)