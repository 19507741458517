import { axiosInstance } from "../../../utils/Http";
import {
  getDashboardDetailsSuccess,
  getDashboardDetailsFailure,
  getTeamDetailsSuccess,
  getTeamDetailsFailure,
  getSelfieGraphDetailsSuccess,
  getSelfieGraphDetailsFailure,
  postExtendDateSuccess,
  postExtendDateFailure,
  getMeDetailsSuccess,
  getMeDetailsFailure,
  getGoalDetailsSuccess,
  getGoalDetailsFailure,
  getQuarterListDataSuccess,
  getQuarterListDataFailure,
  excludeUnExcludePeerSuccess,
  excludeUnExcludePeerFailure,
  setSelectedQuarter
} from "./actions";
import store from "../../../store/index";
import { generateError } from "../../../utils/Resusables";

export const getDashboardDetails = (data) => {
  return axiosInstance
    .get(`customer/dashoard?quarterId=${data.quarterId}${data.compositionId ? `&compositionId=${data.compositionId}` : ''}`)
    .then((response) => {
      return store.dispatch(getDashboardDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getDashboardDetailsFailure(generateError(error)))
    );
};

export const getTeamDetails = (quarterId) => {
  return axiosInstance
    .get(`customer/dashboard/lead/team?quarterId=${quarterId}`)
    .then((response) => {
      return store.dispatch(getTeamDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getTeamDetailsFailure(generateError(error)))
    );
};

export const getSelfieGraphDetails = (quarterId) => {
  return axiosInstance
    .get(`customer/selfie/graph?quarterId=${quarterId}`)
    .then((response) => {
      return store.dispatch(getSelfieGraphDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getSelfieGraphDetailsFailure(generateError(error)))
    );
};

export const postExtendDate = (data) => {
  return axiosInstance
    .post(`customers/extends/selves/reviews/dates`, data)
    .then((response) => {
      return store.dispatch(postExtendDateSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(postExtendDateFailure(generateError(error)))
    );
};

export const getMeDetails = (quarterId) => {
  return axiosInstance
    .get(`customer/me/dashboard?quarterId=${quarterId}`)
    .then((response) => {
      return store.dispatch(getMeDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getMeDetailsFailure(generateError(error)))
    );
}

export const getGoalDetails = (surveyId, request) => {
  return axiosInstance
    .get(`customers/${surveyId}/dashboard/goal/list${request.email ? `?email=${request.email}` : ''}${request.quarterId ? `&quarterId=${request.quarterId}` : ''}`)
    .then((response) => {
      return store.dispatch(getGoalDetailsSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getGoalDetailsFailure(generateError(error)))
    );
}

export const getQuarterListData = (data, quarterId) => {
  return axiosInstance
    .get(`customers/quarters?recPerPage=${data.recPerPage ? data.recPerPage : ''}${data.offset || data.offset === 0 ? `&offset=${data.offset}` : ''}${data.type ? `&type=${data.type}` : ''}${data.year ? `&year=${data.year}` : ''}`)
    .then((response) => {
      if (!quarterId || quarterId === '') {
        let selectedQuarterIndex = 0;
        if (response?.data?.data?.list?.length) {
          let today = new Date();
          if (response?.data?.data?.list?.find(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate))) {
            selectedQuarterIndex = response?.data?.data?.list?.findIndex(quarter => today >= new Date(quarter.startDate) && today <= new Date(quarter.endDate));
          }
        }
        store.dispatch(setSelectedQuarter(response.data.data.list[selectedQuarterIndex]?._id));
        getTeamDetails(response?.data?.data?.list?.length ? response.data.data?.list[selectedQuarterIndex]?._id : '');
        getMeDetails(response?.data?.data?.list?.length ? response.data.data?.list[selectedQuarterIndex]?._id : '')
        getSelfieGraphDetails(response?.data?.data?.list?.length ? response.data.data?.list[selectedQuarterIndex]?._id : '');
        getDashboardDetails({ quarterId: response?.data?.data?.list?.length ? response.data.data?.list[selectedQuarterIndex]?._id : '' });
      }
      return store.dispatch(getQuarterListDataSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(getQuarterListDataFailure(generateError(error)))
    );
};

export const excludeUnExcludePeer = (quarterId, data) => {
  return axiosInstance
    .put(`quarters/${quarterId}/exclude/user`, data)
    .then((response) => {
      return store.dispatch(excludeUnExcludePeerSuccess(response.data.data));
    })
    .catch((error) =>
      store.dispatch(excludeUnExcludePeerFailure(generateError(error)))
    );
};