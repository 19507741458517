import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
const propTypes = {
  Component: PropTypes.any,
};
const AuthRoute = ( {Component} ) => {
  return localStorage.getItem("access_token") ? <Component /> : <Navigate to="/" />
};

AuthRoute.propTypes = propTypes;
export default AuthRoute;