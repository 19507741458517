// import libs
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactReduxContext } from "react-redux";
import Authentication from "./Authentication";
import LoginComponent from "../modules/auth/pages/login";
import DashboardComponent from "../modules/Dashboard/pages";
import ForgotPasswordComponent from "../modules/auth/pages/forgotPassword";
//import StartSurvey from "../modules/CreateSurvey/pages/startSurvey/components/startSurvey";
//import CreateFromScratch from "../modules/CreateSurvey/pages/fromScratch";
import PageNotFound from "../modules/PageNotFound/pages/notFound/components/pageNotFound";
import NoAuth from "../modules/Unauthorised/pages/unauthorised/components/unauthorised";
import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";
import Loader from "../common/loader/loader";
import { connect } from "react-redux";
import MyGoal from "../modules/Survey/pages/myGoals";
import Received from "../modules/Survey/pages/received";
import Reports from "../modules/Survey/pages/reports";
import AddQuestions from "../modules/CreateSurvey/pages/addQuestions";
import InviteParticipants from "../modules/CreateSurvey/pages/inviteParticipants";
import SendSurvey from "../modules/CreateSurvey/pages/sendSurvey";
import ReviewMinutes from "../modules/Survey/pages/reviewMinutes";
import Ikigai from "../modules/Ikigai/pages";
//import SurveyDetail from "../modules/Survey/pages/myGoals/components/surveyDetail";
//import myMessages from "../modules/Messages/pages/myMessages";
//import myPeers from "../modules/MyPeers/pages/myPeers";
//import AnalyzeResult from "../modules/CreateSurvey/pages/analyzeResult";

const Router = (props) => {
  return (
    <>
      {props.isLoading && <Loader is_loading={props.isLoading} />}
      <BrowserRouter context={ReactReduxContext}>
        <Routes>
          <Route
            exact
            path="/"
            element={<PublicRoute Component={Authentication} />}
          />
          <Route
            exact
            path="/login"
            element={<PublicRoute Component={LoginComponent} />}
          />
          <Route
            exact
            path="/sso"
            element={<PublicRoute Component={LoginComponent} />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<PublicRoute Component={ForgotPasswordComponent} />}
          />
          <Route
            exact
            path="/dashboard"
            element={<AuthRoute Component={DashboardComponent} />}
          />
          {/*<Route
            exact
            path="/create-survey"
            element={<AuthRoute Component={StartSurvey} />}
          />
          <Route
            exact
            path="/create-from-scratch"
            element={<AuthRoute Component={CreateFromScratch} />}
  />*/}
          <Route
            exact
            path="/my-quarters"
            element={<AuthRoute Component={MyGoal} />}
          />
          <Route
            exact
            path="/received"
            element={<AuthRoute Component={Received} />}
          />
          <Route
            exact
            path="/reports"
            element={<AuthRoute Component={Reports} />}
          />
          <Route
            exact
            path="/add-goals"
            element={<AuthRoute Component={AddQuestions} />}
          />
          <Route
            exact
            path="/invite-participants"
            element={<AuthRoute Component={InviteParticipants} />}
          />
          <Route
            exact
            path="/send-survey"
            element={<AuthRoute Component={SendSurvey} />}
          />
          {/* <Route
            exact
            path="/review/:id/report/:teamId/clubbedId/:clubbedId"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          <Route
            exact
            path="/review/:id/report/:teamId/clubbedId/:clubbedId/finalize"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          <Route
            exact
            path="/review/:id/report/:teamId/clubbedId/:clubbedId/finalize/:revieweeId"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          <Route
            exactstyle={{marginTop:'300px'}}
            path="/review/:id/report/:teamId/clubbedId/:clubbedId/finaliseReview/:revieweeId"
            element={<AuthRoute Component={ReviewMinutes} />}
          /> */}

          <Route
            exact
            path="/minutes"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          <Route
            exact
            path="/review/report/:quarterId/finalize/:revieweeId"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          <Route
            exact
            path="/review/report/:quarterId/finaliseReview/:revieweeId"
            element={<AuthRoute Component={ReviewMinutes} />}
          />
          {/*<Route
            exact
            path="/analyze-result"
            element={<AuthRoute Component={AnalyzeResult} />}
          />
          <Route
            exact
            path="/survey-detail"
            element={<AuthRoute Component={SurveyDetail} />}
          />
          <Route
            exact
            path="/my-messages"
            element={<AuthRoute Component={myMessages} />}
          />
          <Route
            exact
            path="/my-peers"
            element={<AuthRoute Component={myPeers} />}
  />*/}
          <Route
            exact
            path="/unauthorized"
            element={<NoAuth />}
          />
          <Route
            exact
            path="/ikigai"
            element={<AuthRoute Component={Ikigai} />}
          />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    isLoading:
      state.createSurvey.isLoading ||
      state.surveyList.isLoading || state.surveyList.quarter.isLoadingList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
