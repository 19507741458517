import * as types from "./action-types";
import { removeLoginSession } from "../../../utils/Resusables";

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  userDetail: {},
  errorMessage: "",
  user: {},
  isNew: false
};

const auth = (state = initialState, action) => {
  switch (action?.type) {
    case types.USER_LOGIN:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true
      };
    case types.USER_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        isAuthenticated: false,
      };
    case types.USER_LOGOUT:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.USER_LOGOUT_SUCCESS:
      removeLoginSession("access_token");
      location.replace("/login");
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.USER_LOGOUT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.GET_USER_DETAILS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetail: action.result[0],
        errorMessage: "",
        isAuthenticated: false,
        isNew: action.result[0].isForceResetPwd
      };
    case types.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        isAuthenticated: false,
        userDetail: {}
      };
    case types.USER_PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        isAuthenticated: false,
      };
    case types.USER_FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        isAuthenticated: false,
      };
    case types.USER_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        isAuthenticated: false,
      };
    case types.SET_DUMMY_RESET:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

export const getAuth = (state) => state.auth.isAuthenticated;

export default auth;
