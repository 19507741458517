export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR'

export const USER_PASSWORD_CHANGE = 'USER_PASSWORD_CHANGE'
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS'
export const USER_PASSWORD_CHANGE_ERROR = 'USER_PASSWORD_CHANGE_ERROR'

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR'

export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR'

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK'
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS'
export const SUBMIT_FEEDBACK_ERROR = 'SUBMIT_FEEDBACK_ERROR'

export const SET_DUMMY_RESET = 'SET_DUMMY_RESET';