import React, { useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from "prop-types"; 

const propTypes = {
  data: PropTypes.string,
  openAlert: PropTypes.bool,
  type: PropTypes.string,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ToastNotify(props) {
  const [open, setOpen] = React.useState(false);
  useEffect(()=>{
    setOpen(props?.openAlert)
  },[
    props?.openAlert
  ])
  const vertical = 'top'
  const horizontal = 'right'
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={props?.type} sx={{ width: '100%' }}>
        {props?.data}
        </Alert>
      </Snackbar> 
  )
}
ToastNotify.propTypes = propTypes;
export default ToastNotify;